import {
  OBTENER_USUARIOS,
  OBTENER_USUARIOS_EXITOSO,
  OBTENER_USUARIOS_ERROR,
  CARGANDO_USUARIOS,
  OBTENER_USUARIOS_CLIENTES,
  OBTENER_USUARIOS_CLIENTES_EXITOSO,
  OBTENER_USUARIOS_CLIENTES_ERROR
} from '../../constants/ActionTypes/Usuarios';


export const obtenerUsuarios = (params) => {
  return {
    type: OBTENER_USUARIOS,
    payload: params
  };
};

export const obtenerUsuariosExitoso = (usuarios) => {
  return {
    type: OBTENER_USUARIOS_EXITOSO,
    payload: usuarios
  };
};

export const obtenerUsuariosError = (mensaje) => {
  return {
    type: OBTENER_USUARIOS_ERROR,
    payload: mensaje
  };
};

export const cargandoUsuarios = (cargando) => {
  return {
    type: CARGANDO_USUARIOS,
    payload: cargando
  };
};

export const obtenerClientes = () => {
  return {
    type: OBTENER_USUARIOS_CLIENTES
  };
};

export const obtenerClientesExitoso = (clientes) => {
  return {
    type: OBTENER_USUARIOS_CLIENTES_EXITOSO,
    payload: clientes
  };
};

export const obtenerClientesError = (mensaje) => {
  return {
    type: OBTENER_USUARIOS_CLIENTES_ERROR,
    payload: mensaje
  };
};
