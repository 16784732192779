import {
  OBTENER_CLAVES_EXITOSO,
  OBTENER_CLAVES_ERROR,
  CARGANDO_CLAVES
} from '../../../constants/ActionTypes/Claves';


const clavesInicial = {
  claves: [],
  cantidad: 0,
  paginas: 1,
  error: false,
  cargando: false,
  mensaje: null
};

const listarClaves = (state=clavesInicial, action) => {
  switch (action.type) {

  case OBTENER_CLAVES_EXITOSO: {
    return {
      ...clavesInicial,
      ...action.payload
    };
  }

  case OBTENER_CLAVES_ERROR: {
    return {
      ...clavesInicial,
      error: true,
      mensaje: action.payload
    };
  }

  case CARGANDO_CLAVES: {
    return {
      ...clavesInicial,
      cargando: action.payload
    };
  }

  default:
    return state;
  }
};


export default listarClaves;
