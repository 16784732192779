import {
  OBTENER_ARCHIVO_EXITOSO,
  OBTENER_ARCHIVO_ERROR,
  GUARDAR_ARCHIVO_EXITOSO,
  GUARDAR_ARCHIVO_ERROR,
  GUARDANDO_ARCHIVO,
  ELIMINAR_ARCHIVO_EXITOSO,
  ELIMINAR_ARCHIVO_ERROR
} from '../../constants/ActionTypes/Galeria';


const archivoInicial = {
  archivo: {},
  error: false,
  mensaje: null,
  guardando: false,
  editando: false,
  eliminando: false,
  guardado_exitoso: false
};

const detalleGaleria = (state=archivoInicial, action) => {
  switch (action.type) {

  case OBTENER_ARCHIVO_EXITOSO: {
    return {
      ...archivoInicial,
      archivo: action.payload,
      editando: true
    };
  }

  case OBTENER_ARCHIVO_ERROR: {
    return {
      ...archivoInicial,
      error: true,
      mensaje: action.payload,
      editando: true
    };
  }

  case GUARDAR_ARCHIVO_EXITOSO: {
    return {
      ...archivoInicial,
      archivo: action.payload.archivo,
      mensaje: action.payload.mensaje,
      guardado_exitoso: true
    };
  }

  case GUARDAR_ARCHIVO_ERROR: {
    return {
      ...archivoInicial,
      error: true,
      mensaje: action.payload
    };
  }

  case GUARDANDO_ARCHIVO: {
    return {
      ...archivoInicial,
      guardando: action.payload
    };
  }

  case ELIMINAR_ARCHIVO_EXITOSO: {
    return {
      ...archivoInicial,
      mensaje: action.payload.mensaje,
      editando: true,
      eliminando: true
    };
  }

  case ELIMINAR_ARCHIVO_ERROR: {
    return {
      ...archivoInicial,
      error: true,
      mensaje: action.payload
    };
  }

  default:
    return state;
  }
};


export default detalleGaleria;
