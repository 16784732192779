import {
  MOSTRAR_MODAL,
  OCULTAR_MODAL
} from '../constants/ActionTypes/Modal';


export const mostrarModal = (modal) => {
  return {
    type: MOSTRAR_MODAL,
    payload: modal
  };
};

export const ocultarModal = () => {
  return {
    type: OCULTAR_MODAL
  };
};
