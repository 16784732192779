import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ScreensEnviarCampaniasListar from '../../screens/EnviarCampanias/EnviarCampaniasListar';
import ScreensEnviarCampaniasDetalle from '../../screens/EnviarCampanias/EnviarCampaniasDetalle';


const Campanias = (props) => (
  <div className="app-wrapper">
    <Switch>
      <Route exact path={`${props.match.url}/`} component={ScreensEnviarCampaniasListar}/>
      <Route path={`${props.match.url}/detalle/:uuid_campania`} component={ScreensEnviarCampaniasDetalle} />
      <Route path={`${props.match.url}/detalle`} component={ScreensEnviarCampaniasDetalle}/>
    </Switch>
  </div>
);


export default Campanias;
