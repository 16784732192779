import React from 'react';
import { NavLink } from 'react-router-dom';

import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import UIDataTable from '../../../UI/DataTable/DataTable';
import {USUARIO_TIPO_ADMIN, USUARIO_TIPO_PRINCIPAL} from '../../../../constants/Usuarios/Tipos';


const DobleViaClavesListar = (props) => (
  <div className="app-module">
    <div className="module-box">
      <div className="module-box-header">
        <div className="module-box-header-inner">
          <div className="module-box-header-right">
            <Button color="info" className="jr-btn" onClick={props.obtenerClaves}>
              <FontAwesomeIcon icon="sync" size="lg" className="mr-2" />
              <span>Actualizar</span>
            </Button>

            {
              (props.usuario.tipo === USUARIO_TIPO_ADMIN || props.usuario.tipo === USUARIO_TIPO_PRINCIPAL) &&
              <NavLink className="jr-btn btn btn-primary" to='/doble_via/claves/detalle'>
                <FontAwesomeIcon icon="plus-circle" size="lg" className="mr-2" />
                <span>Crear clave</span>
              </NavLink>
            }
          </div>
        </div>
      </div>
      <div className="module-box-content">

        <UIDataTable
          data={props.claves}
          manual
          filterable={true}
          filtered={props.filtrado}
          pages={props.paginas}
          page={props.paginacion.pagina}
          pageSize={props.paginacion.tamano_pagina}
          onPageChange={props.cambioPagina}
          onPageSizeChange={props.cambioTamanoPagina}
          onFilteredChange={props.cambioFiltro}
          columns={[
            {
              Header: 'Nombre',
              accessor: 'search',
              Cell: (row) => row.original.nombre
            },
            {
              Header: 'Descripción',
              accessor: 'descripcion',
              filterable: false
            }
          ]}
          getTrProps={(state, rowInfo) => ({
            onClick: () => {
              if (props.usuario.tipo === USUARIO_TIPO_ADMIN || props.usuario.tipo === USUARIO_TIPO_PRINCIPAL) {
                props.editarClave(rowInfo.original.uuid);
              }
            }
          })}
          noDataText="No se ha encontrando ninguna clave"
          rowsText="claves"
          loading={props.cargando}
        />
      </div>
    </div>
  </div>
);


export default DobleViaClavesListar;
