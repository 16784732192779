
// Listar
export const OBTENER_CLIENTES = 'obtener_clientes';
export const OBTENER_CLIENTES_EXITOSO = 'obtener_clientes_exitoso';
export const OBTENER_CLIENTES_ERROR = 'obtener_clientes_error';
export const CARGANDO_CLIENTES = 'cargando_clientes';

// Detalle
export const OBTENER_CLIENTE = 'obtener_cliente';
export const OBTENER_CLIENTE_EXITOSO = 'obtener_cliente_exitoso';
export const OBTENER_CLIENTE_ERROR = 'obtener_cliente_error';
export const GUARDAR_CLIENTE = 'guardar_cliente';
export const GUARDAR_CLIENTE_EXITOSO = 'guardar_cliente_exitoso';
export const GUARDAR_CLIENTE_ERROR = 'guardar_cliente_error';
export const GUARDANDO_CLIENTE = 'guardando_cliente';
export const ELIMINAR_CLIENTE = 'eliminar_cliente';
export const ELIMINAR_CLIENTE_EXITOSO = 'eliminar_cliente_exitoso';
export const ELIMINAR_CLIENTE_ERROR = 'eliminar_cliente_error';
export const LIMPIAR_CLIENTE = 'limpiar_cliente';

// Ciudades
export const OBTENER_CIUDADES = 'obtener_ciudades';
export const OBTENER_CIUDADES_EXITOSO = 'obtener_ciudades_exitoso';
export const OBTENER_CIUDADES_ERROR = 'obtener_ciudades_error';
