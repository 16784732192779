import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import uuid from 'uuid';
import { toast } from 'react-toastify';
import _ from 'lodash';

import {
  obtenerClave,
  guardarClave,
  guardandoClave,
  eliminarClave,
  errorClave
} from '../../../../actions/DobleVia/Claves/Detalle';
import { mostrarModal, ocultarModal } from '../../../../actions/Modal';
import { setTituloPagina } from '../../../../actions/Configuracion';
import ClavesDetalle from './Detalle';


class DobleViaClavesDetalleContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      destinatarios: [],
      procedimiento: {
        activo: false
      },
      clave: {
        nombre: '',
        descripcion: '',
        destinatarios: [],
        mensaje: '',
      }
    };
  }

  cargarClave = (clave) => {
    var datos = _.cloneDeep(this.state);
    datos.clave = clave;

    if (clave.destinatarios && clave.destinatarios.length && clave.mensaje) {
      datos.procedimiento.activo = true;
      datos.destinatarios = clave.destinatarios.map((destinatario) => ({'id': destinatario, 'text': destinatario}));
    }

    this.setState({
      ...datos
    });
  };

  agregarDestinatario = (destinatario) => {
    if (destinatario.id) {
      if (!isNaN(destinatario.id)) {
        if (destinatario.id.length === 10) {
          this.setState({
            destinatarios: [
              ...this.state.destinatarios,
              destinatario
            ]
          });
        } else {
          toast.error('El número de destinatario debe ser de 10 dígitos.');
        }
      } else {
        toast.error('Por favor ingrese un número de destinatario válido.');
      }
    }
  };

  eliminarDestinatario = (i) => {
    let destinatarios = _.isUndefined(i) ? [] : this.state.destinatarios.filter((_, index) => index !== i);

    this.setState({
      destinatarios
    });
  };

  toggleProcedimiento = () => {
    this.setState({
      procedimiento: {
        ...this.state.procedimiento,
        activo: !this.state.procedimiento.activo
      }
    });
  };

  handleActualizacionClave = () => {
    toast.success(this.props.mensaje);
    this.props.history.push('/doble_via/claves');
  };

  handleEliminar = (clave_uuid) => {
    this.props.actions.mostrarModal({
      uuid: uuid.v4(),
      atributos: {
        titulo: 'Eliminar clave de doble vía',
        mensaje: '¿Realmente desea eliminar esta clave de doble vía?',
        colorBotonAceptar: 'danger',
        textoBotonAceptar: 'Eliminar clave de doble vía',
        nota: 'Esta acción no se puede deshacer',
        colorNota: 'danger',
        tamano: 'sm',
        aceptar: () => this.props.actions.eliminarClave(clave_uuid)
      }
    });
  };

  handleSubmit = (values) => {
    let guardar_clave = true;
    let datos = {
      nombre: values.nombre,
      descripcion: values.descripcion
    };

    if (values.uuid) {
      datos.uuid = values.uuid;
    }

    if (this.state.procedimiento.activo) {
      datos.mensaje = values.mensaje;

      if (this.state.destinatarios.length) {
        datos.destinatarios = this.state.destinatarios.map((destinatario) => destinatario.id);
      }

      if (datos.destinatarios && datos.mensaje) {
        guardar_clave = true;
      } else {
        toast.error('Por favor ingrese los datos del procedmiento.');
      }
    }

    if (guardar_clave) {
      this.props.actions.guardandoClave(true);
      this.props.actions.guardarClave(datos);
    }
  };

  componentDidMount() {
    this.props.actions.setTituloPagina({titulo: 'Claves doble vía', icono: 'tags'});

    if (this.props.match.params.uuid_clave) {
      this.props.actions.obtenerClave(this.props.match.params.uuid_clave);
    }
  }

  componentDidUpdate(props) {
    // Verificar si existió algún error
    if (this.props.error) {
      if (this.props.mensaje) {
        toast.error(this.props.mensaje);
      }

      this.props.actions.errorClave(false);

    // Verificar si es una edición de claves
    } else if (this.props.editando) {
      if (this.props.eliminando) {
        this.props.actions.ocultarModal();
        this.handleActualizacionClave();
      } else if (props.clave !== this.props.clave) {
        this.cargarClave(this.props.clave);
      } else if (this.props.guardado_exitoso) {
        this.handleActualizacionClave();
      }

    // Verificar si es creación de una nueva clave
    } else if (this.props.guardado_exitoso) {
      this.handleActualizacionClave();
    }
  }

  render() {
    return (
      <ClavesDetalle
        handleSubmit={this.handleSubmit}
        handleEliminar={this.handleEliminar}
        valoresIniciales={this.state.clave}
        guardando={this.props.guardando}
        procedimiento={this.state.procedimiento}
        destinatarios={this.state.destinatarios}
        toggleProcedimiento={this.toggleProcedimiento}
        agregarDestinatario={this.agregarDestinatario}
        eliminarDestinatario={this.eliminarDestinatario}
      />
    );
  }
}


const mapStateToProps = ({ detalleDobleViaClaves }) => {
  return { ...detalleDobleViaClaves };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      setTituloPagina,
      mostrarModal,
      ocultarModal,
      obtenerClave,
      guardarClave,
      guardandoClave,
      eliminarClave,
      errorClave
    }, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DobleViaClavesDetalleContainer));
