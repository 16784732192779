import {
  OBTENER_ENCUESTA_PREGUNTAS_EXITOSO,
  OBTENER_ENCUESTA_PREGUNTAS_ERROR,
  CARGANDO_ENCUESTA_PREGUNTAS
} from '../../../constants/ActionTypes/Encuestas';


const encuestaPreguntasInicial = {
  preguntas: [],
  cantidad: 0,
  error: false,
  cargando: false,
  mensaje: null
};

const listarEncuestaPreguntas = (state=encuestaPreguntasInicial, action) => {
  switch (action.type) {

  case OBTENER_ENCUESTA_PREGUNTAS_EXITOSO: {
    return {
      ...encuestaPreguntasInicial,
      ...action.payload
    };
  }

  case OBTENER_ENCUESTA_PREGUNTAS_ERROR: {
    return {
      ...encuestaPreguntasInicial,
      error: true,
      mensaje: action.payload
    };
  }

  case CARGANDO_ENCUESTA_PREGUNTAS: {
    return {
      ...encuestaPreguntasInicial,
      cargando: action.payload
    };
  }

  default:
    return state;
  }
};


export default listarEncuestaPreguntas;
