import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { toast } from 'react-toastify';

import DestinatariosListar from './Listar';
import { obtenerDestinatarios, cargandoDestinatarios } from '../../../actions/Destinatarios/Listar';
import { setTituloPagina } from '../../../actions/Configuracion';


class DestinatariosListarContainer extends Component {
  constructor (props) {
    super(props);

    this.state = {
      paginacion: {
        pagina: 0,
        tamano_pagina: 20
      },
      filtrado: []
    };
  }

  handleCambioPagina = (pagina) => {
    this.setState({
      paginacion: {
        ...this.state.paginacion,
        pagina
      }
    });
  }

  handleCambioTamanoPagina = (tamano_pagina, pagina) => {
    this.setState({
      paginacion: {
        tamano_pagina,
        pagina
      }
    });
  }

  handleCambioFiltro = (filtrado) => {
    this.setState({
      paginacion: {
        ...this.state.paginacion,
        pagina: 0
      },
      filtrado
    });
  }

  handleObtenerDestinatarios = () => {
    const { pagina, tamano_pagina } = this.state.paginacion;

    let filtros = {pagina: pagina + 1, tamano_pagina};
    if (typeof this.state.filtrado !== 'undefined') {
      this.state.filtrado.map((filtro) => filtros[filtro.id] = filtro.value);
    }

    this.props.actions.cargandoDestinatarios(true);
    this.props.actions.obtenerDestinatarios({ filtros });
  };

  handleEditarDestinatario = (id_destinatario) => {
    this.props.history.push('destinatarios/detalle/' + id_destinatario);
  };

  componentDidMount() {
    this.props.actions.setTituloPagina({titulo: 'Destinatarios', icono: 'user-circle'});
    this.handleObtenerDestinatarios();
  }

  componentDidUpdate(props, state) {
    if (this.props.error) {
      if (this.props.mensaje) {
        toast.error(this.props.mensaje);
      }

      if (this.props.cargando) {
        this.props.actions.cargandoDestinatarios(false);
      }
    }

    if ((state.filtrado !== this.state.filtrado) || (state.paginacion !== this.state.paginacion)) {
      this.handleObtenerDestinatarios();
    }
  }

  render() {
    return (
      <DestinatariosListar
        destinatarios={this.props.destinatarios}
        cargando={this.props.cargando}
        paginas={this.props.paginas}
        paginacion={this.state.paginacion}
        filtrado={this.state.filtrado}
        obtenerDestinatarios={this.handleObtenerDestinatarios}
        editarDestinatario={this.handleEditarDestinatario}
        cambioPagina={this.handleCambioPagina}
        cambioTamanoPagina={this.handleCambioTamanoPagina}
        cambioFiltro={this.handleCambioFiltro}
      />
    );
  }
}


const mapStateToProps = ({ listarDestinatarios }) => {
  return { ...listarDestinatarios };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      setTituloPagina,
      obtenerDestinatarios,
      cargandoDestinatarios
    }, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DestinatariosListarContainer));
