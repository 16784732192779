import {
  OBTENER_REPORTE_CAMPANIAS_EXITOSO,
  OBTENER_REPORTE_CAMPANIAS_ERROR,
  CARGANDO_REPORTE_CAMPANIAS,
  EXPORTAR_REPORTE_CAMPANIAS_EXITOSO,
  EXPORTAR_REPORTE_CAMPANIAS_ERROR
} from '../../constants/ActionTypes/Reportes';


const campaniasInicial = {
  mensajes: [],
  cantidad: 0,
  paginas: 1,
  error: false,
  mensaje: null,
  cargando: false
};

const reporteCampanias = (state=campaniasInicial, action) => {
  switch (action.type) {

  case OBTENER_REPORTE_CAMPANIAS_EXITOSO: {
    return {
      ...campaniasInicial,
      ...action.payload
    };
  }

  case OBTENER_REPORTE_CAMPANIAS_ERROR: {
    return {
      ...campaniasInicial,
      error: true,
      mensaje: action.payload
    };
  }

  case CARGANDO_REPORTE_CAMPANIAS: {
    return {
      ...campaniasInicial,
      mensajes: state.mensajes,
      cargando: action.payload
    };
  }

  case EXPORTAR_REPORTE_CAMPANIAS_EXITOSO: {
    return {
      ...campaniasInicial,
      mensajes: state.mensajes,
      cantidad: state.cantidad,
      paginas: state.paginas,
      ...action.payload
    };
  }

  case EXPORTAR_REPORTE_CAMPANIAS_ERROR: {
    return {
      ...campaniasInicial,
      mensajes: state.mensajes,
      cantidad: state.cantidad,
      paginas: state.paginas,
      error: true,
      mensaje: action.payload
    };
  }

  default:
    return state;
  }
};


export default reporteCampanias;
