import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import uuid from 'uuid';
import _ from 'lodash';
import { toast } from 'react-toastify';

import {
  obtenerUsuario,
  guardarUsuario,
  guardandoUsuario,
  eliminarUsuario,
  generarClaveUsuario
} from '../../../actions/Usuarios/Detalle';
import { mostrarModal, ocultarModal } from '../../../actions/Modal';
import { setTituloPagina } from '../../../actions/Configuracion';
import { ESTADOS_USUARIO } from '../../../constants/Usuarios/Estados';
import UsuariosDetalle from './Detalle';


class UsuariosDetalleContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      usuario: {
        numero_documento: '',
        first_name: '',
        last_name: '',
        email: '',
        activo: ESTADOS_USUARIO[0]
      }
    };
  }

  cargarUsuario = (usuario) => {
    usuario.activo = ESTADOS_USUARIO.find(estado => estado.value === usuario.activo);

    this.setState({
      usuario
    });
  };

  handleActualizacionUsuario = () => {
    toast.success(this.props.mensaje);
    this.props.actions.ocultarModal();
    this.props.history.push('/usuarios');
  };

  handleEliminar = (usuario_uuid) => {
    this.props.actions.mostrarModal({
      uuid: uuid.v4(),
      atributos: {
        titulo: 'Eliminar usuario',
        mensaje: '¿Realmente desea eliminar este usuario?',
        colorBotonAceptar: 'danger',
        textoBotonAceptar: 'Eliminar usuario',
        nota: 'Esta acción no se puede deshacer',
        colorNota: 'danger',
        tamano: 'sm',
        aceptar: () => this.props.actions.eliminarUsuario(usuario_uuid)
      }
    });
  };

  handleGenerarClave = (usuario_uuid) => {
    this.props.actions.mostrarModal({
      uuid: uuid.v4(),
      atributos: {
        titulo: 'Generar clave al usuario',
        mensaje: '¿Realmente desea generar una nueva clave a este usuario?',
        colorBotonAceptar: 'info',
        textoBotonAceptar: 'Generar clave',
        nota: 'Esta acción no se puede deshacer',
        colorNota: 'danger',
        tamano: 'sm',
        aceptar: () => this.props.actions.generarClaveUsuario(usuario_uuid)
      }
    });
  };

  handleSubmit = (values) => {
    let datos = _.cloneDeep(values);

    datos.activo = values.activo.value;

    this.props.actions.guardandoUsuario(true);
    this.props.actions.guardarUsuario(datos);
  };

  componentDidMount() {
    this.props.actions.setTituloPagina({titulo: 'Usuarios', icono: 'users'});

    if (this.props.match.params.uuid_usuario) {
      this.props.actions.obtenerUsuario(this.props.match.params.uuid_usuario);
    }
  }

  componentDidUpdate(props) {
    // Verificar si existió algún error
    if (this.props.error) {
      if (this.props.mensaje) {
        toast.error(this.props.mensaje);
      }

      if (this.props.guardando) {
        this.props.actions.guardandoUsuario(false);
      }

      // Verificar si es una edición de usuarios
    } else if (this.props.editando) {
      if (this.props.eliminando) {
        this.props.actions.ocultarModal();
        this.handleActualizacionUsuario();
      } else if (props.usuario !== this.props.usuario) {
        this.cargarUsuario(this.props.usuario);
      } else if (this.props.guardado_exitoso) {
        this.handleActualizacionUsuario();
      }

      // Verificar si es creación de un nuevo usuario
    } else if (this.props.guardado_exitoso) {
      this.handleActualizacionUsuario();
    }
  }

  render() {
    return (
      <UsuariosDetalle
        valoresIniciales={this.state.usuario}
        handleSubmit={this.handleSubmit}
        handleEliminar={this.handleEliminar}
        handleGenerarClave={this.handleGenerarClave}
        guardando={this.props.guardando}
      />
    );
  }
}


const mapStateToProps = ({ detalleUsuarios }) => {
  return { ...detalleUsuarios };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      mostrarModal,
      ocultarModal,
      setTituloPagina,
      obtenerUsuario,
      guardarUsuario,
      guardandoUsuario,
      eliminarUsuario,
      generarClaveUsuario
    }, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsuariosDetalleContainer));
