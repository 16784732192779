import React from 'react';
// import PropTypes from 'prop-types';

import { Badge } from 'reactstrap';
import { DragSource } from 'react-dnd';


const campoListaSource = {
  beginDrag(props) {
    return {
      id: props.nombre
    };
  }
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
}


const UIFormsCampoFormMensajeCampoLista = (props) => {
  const { connectDragSource } = props;

  return connectDragSource(
    <span className="mr-1">
      <Badge
        color={props.isDragging ? 'dark' : 'info'}
        className="cursor-pointer"
      >
        {props.nombre}
      </Badge>
    </span>
  );
};


export default DragSource('campoLista', campoListaSource, collect)(UIFormsCampoFormMensajeCampoLista);
