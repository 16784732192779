import { put, call, all, fork, takeEvery } from 'redux-saga/effects';

import { sprintf } from 'sprintf-js';

import {
  OBTENER_ENCUESTA_PREGUNTA,
  GUARDAR_ENCUESTA_PREGUNTA,
  ELIMINAR_ENCUESTA_PREGUNTA
} from '../../../constants/ActionTypes/Encuestas';
import {
  ENCUESTA_PREGUNTAS_URL,
  ENCUESTA_PREGUNTA_URL
} from '../../../constants/Urls/Encuestas';
import {
  obtenerEncuestaPreguntaExitoso,
  obtenerEncuestaPreguntaError,
  guardarEncuestaPreguntaExitoso,
  guardarEncuestaPreguntaError,
  eliminarEncuestaPreguntaExitoso,
  eliminarEncuestaPreguntaError
} from '../../../actions/Encuestas/Preguntas';
import { httpFetch } from '../../../utils/Http/Fetch';
import { setUnidades } from '../../../actions/Configuracion';


const obtenerEncuestaPreguntaApi = async ({ uuid_encuesta, uuid }) => {
  const url = sprintf(ENCUESTA_PREGUNTA_URL, { uuid_encuesta, uuid });

  return httpFetch(url, 'GET')
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};

const guardarEncuestaPreguntaApi = async (datos) => {
  const verbo_http = datos.datos.uuid ? 'PUT' : 'POST';
  let url = sprintf(ENCUESTA_PREGUNTAS_URL, { uuid_encuesta: datos.encuesta });

  if (datos.datos.uuid) {
    url = sprintf(ENCUESTA_PREGUNTA_URL, {
      uuid: datos.datos.uuid
    });
  }

  return httpFetch(url, verbo_http, datos.datos)
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};

const eliminarEncuestaPreguntaApi = async (uuid) => {
  const url = sprintf(ENCUESTA_PREGUNTA_URL, {uuid});

  return httpFetch(url, 'DELETE')
    .catch((error) => {
      throw error;
    });
};


function* obtenerEncuestaPregunta(action) {
  try {
    const destinatario = yield call(obtenerEncuestaPreguntaApi, action.payload);

    if (typeof destinatario.saldo !== 'undefined') {
      yield put(setUnidades(destinatario.saldo));
    }

    yield put(obtenerEncuestaPreguntaExitoso(destinatario));
  } catch (error) {
    yield put(obtenerEncuestaPreguntaError(error.message));
  }
}

function* guardarEncuestaPregunta(action) {
  try {
    const destinatario = yield call(guardarEncuestaPreguntaApi, action.payload);
    const accion = action.payload.uuid ? 'actualizada' : 'creada';
    const mensaje = `Pregunta ${accion} correctamente.`;

    if (typeof destinatario.saldo !== 'undefined') {
      yield put(setUnidades(destinatario.saldo));
    }

    yield put(guardarEncuestaPreguntaExitoso({destinatario, mensaje}));
  } catch (error) {
    yield put(guardarEncuestaPreguntaError(error.message));
  }
}

function* eliminarEncuestaPregunta(action) {
  try {
    yield call(eliminarEncuestaPreguntaApi, action.payload);
    const mensaje = 'Pregunta eliminada correctamente.';

    yield put(eliminarEncuestaPreguntaExitoso({ mensaje }));
  } catch (error) {
    yield put(eliminarEncuestaPreguntaError(error.message));
  }
}


export function* obtenerEncuestaPreguntaWatcher() {
  yield takeEvery (OBTENER_ENCUESTA_PREGUNTA, obtenerEncuestaPregunta);
}

export function* guardarEncuestaPreguntaWatcher() {
  yield takeEvery (GUARDAR_ENCUESTA_PREGUNTA, guardarEncuestaPregunta);
}

export function* eliminarEncuestaPreguntaWatcher() {
  yield takeEvery (ELIMINAR_ENCUESTA_PREGUNTA, eliminarEncuestaPregunta);
}

export default function* rootSaga() {
  yield all ([
    fork (guardarEncuestaPreguntaWatcher),
    fork (obtenerEncuestaPreguntaWatcher),
    fork (eliminarEncuestaPreguntaWatcher)
  ]);
}
