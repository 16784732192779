import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCircleNotch,
  faSync,
  faPlusCircle,
  faCheck,
  faChevronLeft,
  faUsers,
  faUserPlus,
  faShoppingBasket,
  faBan,
  faStar,
  faCalendarAlt,
  faExclamationCircle,
  faTag,
  faInfoCircle,
  faUserCircle,
  faListOl,
  faFileDownload,
  faComments,
  faCommentDollar,
  faCommentsDollar,
  faSms,
  faCheckCircle,
  faExchangeAlt,
  faChartPie,
  faListAlt,
  faHandHoldingUsd,
  faUserTie,
  faExclamationTriangle,
  faTags,
  faUndo,
  faFileExcel,
  faQuestionCircle,
  faImage,
  faImages,
  faChevronUp,
  faChevronDown,
  faEdit,
  faEye,
  faChartBar
} from '@fortawesome/free-solid-svg-icons';


library.add(
  faCircleNotch,
  faSync,
  faPlusCircle,
  faCheck,
  faChevronLeft,
  faUsers,
  faUserPlus,
  faShoppingBasket,
  faBan,
  faStar,
  faCalendarAlt,
  faExclamationCircle,
  faTag,
  faInfoCircle,
  faUserCircle,
  faListOl,
  faFileDownload,
  faComments,
  faCommentDollar,
  faCommentsDollar,
  faSms,
  faCheckCircle,
  faExchangeAlt,
  faChartPie,
  faListAlt,
  faHandHoldingUsd,
  faUserTie,
  faExclamationTriangle,
  faTags,
  faUndo,
  faFileExcel,
  faQuestionCircle,
  faImage,
  faImages,
  faChevronUp,
  faChevronDown,
  faEdit,
  faEye,
  faChartBar
);
