import React from 'react';
import { NavLink } from 'react-router-dom';

import {
  Col, Row, Button, Collapse, Alert, FormGroup, Label, Card, CardHeader, CardText, CardBody
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Form } from 'formik';
import moment from 'moment';

import { campaniaSchema } from './Schema';
import UIFormsCampoFormSelect from '../../UI/Forms/CampoForm/Select';
import UIFormsCampoFormFecha from '../../UI/Forms/CampoForm/Fecha';
import UIFormsCampoFormHora from '../../UI/Forms/CampoForm/Hora';


const EnviarCampaniasDetalle = (props) => (
  <Formik
    enableReinitialize
    initialValues={props.valoresIniciales}
    onSubmit={props.handleSubmit}
    validationSchema={campaniaSchema}
    validateOnChange
  >
    {formikProps => (
      <Form>
        <Row>
          <Col sm={12}>
            <Row form>
              <Col md={12}>
                {
                  formikProps.values.uuid ?
                    <FormGroup>
                      <Label>Campaña</Label>
                      <p><b className="text-uppercase">{formikProps.values.campania.nombre}</b></p>
                    </FormGroup>
                    :
                    <UIFormsCampoFormSelect
                      id="campania"
                      name="campania"
                      label="Campaña"
                      labelClassName="requerido"
                      autoComplete="off"
                      formikProps={formikProps}
                      placeholder="Seleccione una opción"
                      className="padding-0"
                      options={props.campanias}
                      error={formikProps.errors.campania ? formikProps.errors.campania.value : null}
                    />
                }
              </Col>
            </Row>
          </Col>
          <Col sm={12}>
            {
              formikProps.values.uuid ?
                (formikProps.values.fecha_inicio && formikProps.values.hora_inicio &&
                  formikProps.values.fecha_finalizacion && formikProps.values.hora_finalizacion) ?
                  <Alert color={'info'}>
                    <FontAwesomeIcon icon={'check-circle'} size="lg" className="mr-2" />
                    La campaña será enviada desde el <b>{formikProps.values.fecha_inicio_str}</b> a
                    las <b>{formikProps.values.hora_inicio_str}</b> hasta máximo
                    el <b>{formikProps.values.fecha_finalizacion_str}</b> a las <b>{formikProps.values.hora_finalizacion_str}</b>
                  </Alert>
                  :
                  null
                :
                <div>
                  <Button color="light" className="jr-btn" type="button" onClick={props.toggleProgramarEnvio}>
                    {
                      props.programarEnvio ?
                        <FontAwesomeIcon icon={'check-circle'} size="lg" className="mr-2" />
                        :
                        <FontAwesomeIcon icon={['far', 'circle']} size="lg" className="mr-2" />
                    }
                    Programar envío de la campaña
                  </Button>
                  <Collapse isOpen={props.programarEnvio}>
                    <Row>
                      <Col md={12}>
                        <Row>
                          <Col md={6}>
                            <Card className="mb-1">
                              <CardHeader className="py-3">Fecha y hora de inicio</CardHeader>
                              <CardBody className="py-2">
                                <CardText className="text-justify mb-0">
                                  Use el calendario para seleccionar la <b>fecha de inicio de envío</b> de la campaña.
                                </CardText>

                                <UIFormsCampoFormFecha
                                  id="fecha_inicio_str"
                                  type="text"
                                  name="fecha_inicio_str"
                                  label="Fecha de inicio"
                                  labelClassName="requerido"
                                  className="col-12"
                                  placeholder=""
                                  autoComplete="off"
                                  readOnly={true}
                                  numberOfMonths={1}
                                  disabledDays={[{
                                    before: moment().toDate()
                                  }]}
                                  selectedDays={formikProps.values.fecha_inicio}
                                  onDayClick={(dia) => {
                                    formikProps.setFieldValue('fecha_inicio', dia);
                                    formikProps.setFieldValue('fecha_inicio_str', moment(dia).format('ll'));
                                  }}
                                />

                                <CardText className="text-justify">
                                  Seleccione la hora de inicio de envío de la campaña, entre
                                  las <b>{props.horasLimite.minima}</b> y las <b>{props.horasLimite.maxima}</b>.
                                </CardText>

                                <UIFormsCampoFormHora
                                  id="hora"
                                  type="text"
                                  name="hora_inicio"
                                  label="Hora de envío"
                                  allowEmpty={false}
                                  minuteStep={5}
                                  value={formikProps.values.hora_inicio}
                                  disabledHours={props.horasNoEnvio}
                                  onChange={(hora) => {
                                    formikProps.setFieldValue('hora_inicio', hora);
                                    formikProps.setFieldValue('hora_inicio_str', moment(hora).format('hh:mm A'));
                                  }}
                                />

                              </CardBody>
                            </Card>
                          </Col>
                          <Col md={6}>
                            <Card className="mb-1">
                              <CardHeader className="py-3">Fecha y hora de finalización</CardHeader>
                              <CardBody className="py-2">
                                <CardText className="text-justify mb-0">
                                  Use el calendario para seleccionar la <b>fecha de finalización</b> de la campaña.
                                </CardText>

                                <UIFormsCampoFormFecha
                                  id="fecha_finalizacion_str"
                                  type="text"
                                  name="fecha_finalizacion_str"
                                  label="Fecha de finalización"
                                  labelClassName="requerido"
                                  className="col-12"
                                  placeholder=""
                                  autoComplete="off"
                                  readOnly={true}
                                  numberOfMonths={1}
                                  disabledDays={[{
                                    before: moment().toDate()
                                  }]}
                                  selectedDays={formikProps.values.fecha_finalizacion}
                                  onDayClick={(dia) => {
                                    formikProps.setFieldValue('fecha_finalizacion', dia);
                                    formikProps.setFieldValue('fecha_finalizacion_str', moment(dia).format('ll'));
                                  }}
                                />

                                <CardText className="text-justify">
                                  Seleccione la hora de finalización de la campaña, entre
                                  las <b>{props.horasLimite.minima}</b> y las <b>{props.horasLimite.maxima}</b>.
                                </CardText>

                                <UIFormsCampoFormHora
                                  id="hora"
                                  type="text"
                                  name="hora_finalizacion"
                                  label="Hora de finalización"
                                  allowEmpty={false}
                                  minuteStep={5}
                                  value={formikProps.values.hora_finalizacion}
                                  disabledHours={props.horasNoEnvio}
                                  onChange={(hora) => {
                                    formikProps.setFieldValue('hora_finalizacion', hora);
                                    formikProps.setFieldValue('hora_finalizacion_str', moment(hora).format('hh:mm A'));
                                  }}
                                />

                              </CardBody>
                            </Card>
                          </Col>

                          <Col md={12}>
                            {
                              (formikProps.values.fecha_inicio && formikProps.values.hora_inicio &&
                                formikProps.values.fecha_finalizacion && formikProps.values.hora_finalizacion) ?
                                <Alert color={'success'}>
                                  <FontAwesomeIcon icon={'check-circle'} size="lg" className="mr-2" />
                                  La campaña será enviada desde el <b>{formikProps.values.fecha_inicio_str}</b> a
                                  las <b>{formikProps.values.hora_inicio_str}</b> hasta máximo
                                  el <b>{formikProps.values.fecha_finalizacion_str}</b> a las <b>{formikProps.values.hora_finalizacion_str}</b>
                                </Alert>
                                :
                                null
                            }
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Collapse>
                </div>
            }
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <hr/>
            <div className="text-center">
              {
                formikProps.values.uuid ?
                  <Button
                    color="danger"
                    className="jr-btn"
                    type="button"
                    onClick={() => props.handleCancelar(formikProps.values.uuid)}>
                    <FontAwesomeIcon icon="ban" size="lg" className="mr-2"/>
                    Cancelar campaña
                  </Button>
                  :
                  <Button color="success" className="jr-btn" type="submit" disabled={props.enviando}>
                    {
                      props.enviando ?
                        <FontAwesomeIcon icon="circle-notch" size="lg" spin className="mr-2"/>
                        :
                        <FontAwesomeIcon icon="check" size="lg" className="mr-2"/>
                    }

                    {
                      props.programarEnvio ?
                        'Programar campaña'
                        :
                        'Enviar campaña'
                    }
                  </Button>
              }

              <NavLink className="jr-btn btn btn-default" to='/campanias/enviar'>
                <span>Regresar al listado</span>
              </NavLink>
            </div>
          </Col>
        </Row>
      </Form>
    )}
  </Formik>
);


export default EnviarCampaniasDetalle;
