import * as Yup from 'yup';

import {
  CAMPO_REQUERIDO,
  CAMPO_NUMERO_POSITIVO,
  CAMPO_NUMERO,
  CAMPO_EMAIL
} from '../../../constants/Forms/Validacion';


export const usuarioSchema = Yup.object().shape({
  numero_documento: Yup.number()
    .typeError(CAMPO_NUMERO)
    .positive(CAMPO_NUMERO_POSITIVO)
    .required(CAMPO_REQUERIDO),

  first_name: Yup.string()
    .required(CAMPO_REQUERIDO),

  email: Yup.string()
    .email(CAMPO_EMAIL)
    .required(CAMPO_REQUERIDO),

  activo: Yup.object({
    label: Yup.string().required(CAMPO_REQUERIDO),
    value: Yup.string().required(CAMPO_REQUERIDO)
  })
});
