import { put, call, all, fork, takeEvery } from 'redux-saga/effects';

import { OBTENER_DESTINATARIOS } from '../../constants/ActionTypes/Destinatarios';
import { DESTINATARIOS_URL } from '../../constants/Urls/Destinatarios';
import { obtenerDestinatariosExitoso, obtenerDestinatariosError } from '../../actions/Destinatarios/Listar';
import { httpFetch } from '../../utils/Http/Fetch';
import { setUnidades } from '../../actions/Configuracion';


const obtenerDestinatariosApi = async (filtros) => {
  let esc = encodeURIComponent;
  let params = Object.keys(filtros).map(filtro => esc(filtro) + '=' + esc(filtros[filtro])).join('&');

  return httpFetch(`${DESTINATARIOS_URL}?${params}`, 'GET')
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};

function* obtenerDestinatarios(action) {
  try {
    const { filtros } = action.payload;
    const destinatarios = yield call(obtenerDestinatariosApi, filtros);
    const paginas = Math.ceil(destinatarios.cantidad / filtros.tamano_pagina);

    if (typeof destinatarios.saldo !== 'undefined') {
      yield put(setUnidades(destinatarios.saldo));
    }

    yield put(obtenerDestinatariosExitoso({destinatarios: destinatarios.resultado, cantidad: destinatarios.cantidad, paginas}));
  } catch (error) {
    yield put(obtenerDestinatariosError(error.message));
  }
}

export function* obtenerDestinatariosWatcher() {
  yield takeEvery (OBTENER_DESTINATARIOS, obtenerDestinatarios);
}

export default function* rootSaga() {
  yield all ([
    fork (obtenerDestinatariosWatcher)
  ]);
}
