import {
  ENVIAR_SALDO,
  ENVIAR_SALDO_EXITOSO,
  ENVIAR_SALDO_ERROR,
  ENVIANDO_SALDO,
  LIMPIAR_ENVIO_SALDO,
  OBTENER_CLIENTES,
  OBTENER_CLIENTES_EXITOSO,
  OBTENER_CLIENTES_ERROR
} from '../../constants/ActionTypes/Transacciones';


export const enviarSaldo = (datos) => {
  return {
    type: ENVIAR_SALDO,
    payload: datos
  };
};

export const enviarSaldoExitoso = (mensaje) => {
  return {
    type: ENVIAR_SALDO_EXITOSO,
    payload: mensaje
  };
};

export const enviarSaldoError = (mensaje) => {
  return {
    type: ENVIAR_SALDO_ERROR,
    payload: mensaje
  };
};

export const enviandoSaldo = (guardando) => {
  return {
    type: ENVIANDO_SALDO,
    payload: guardando
  };
};

export const limpiarEnvioSaldo = () => {
  return {
    type: LIMPIAR_ENVIO_SALDO
  };
};

export const obtenerClientes = (filtros) => {
  return {
    type: OBTENER_CLIENTES,
    payload: filtros
  };
};

export const obtenerClientesExitoso = (clientes) => {
  return {
    type: OBTENER_CLIENTES_EXITOSO,
    payload: clientes
  };
};

export const obtenerClientesError = (mensaje) => {
  return {
    type: OBTENER_CLIENTES_ERROR,
    payload: mensaje
  };
};
