import {
  OBTENER_CLAVE_EXITOSO,
  OBTENER_CLAVE_ERROR,
  GUARDAR_CLAVE_EXITOSO,
  GUARDAR_CLAVE_ERROR,
  GUARDANDO_CLAVE,
  ELIMINAR_CLAVE_EXITOSO,
  ELIMINAR_CLAVE_ERROR,
  ERROR_CLAVE
} from '../../../constants/ActionTypes/Claves';


const clavesInicial = {
  clave: {},
  error: false,
  mensaje: null,
  guardando: false,
  editando: false,
  eliminando: false,
  guardado_exitoso: false
};

const detalleClaves = (state=clavesInicial, action) => {
  switch (action.type) {

  case OBTENER_CLAVE_EXITOSO: {
    return {
      ...clavesInicial,
      clave: action.payload,
      editando: true
    };
  }

  case OBTENER_CLAVE_ERROR: {
    return {
      ...clavesInicial,
      error: true,
      mensaje: action.payload,
      editando: true
    };
  }

  case GUARDAR_CLAVE_EXITOSO: {
    return {
      ...clavesInicial,
      clave: action.payload.clave,
      mensaje: action.payload.mensaje,
      guardado_exitoso: true
    };
  }

  case GUARDAR_CLAVE_ERROR: {
    return {
      ...clavesInicial,
      error: true,
      mensaje: action.payload
    };
  }

  case GUARDANDO_CLAVE: {
    return {
      ...clavesInicial,
      guardando: action.payload
    };
  }

  case ELIMINAR_CLAVE_EXITOSO: {
    return {
      ...clavesInicial,
      mensaje: action.payload.mensaje,
      editando: true,
      eliminando: true
    };
  }

  case ELIMINAR_CLAVE_ERROR: {
    return {
      ...clavesInicial,
      error: true,
      mensaje: action.payload
    };
  }

  case ERROR_CLAVE: {
    return {
      ...clavesInicial,
      error: action.payload,
    };
  }

  default:
    return state;
  }
};


export default detalleClaves;
