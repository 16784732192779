import { applyMiddleware, compose, createStore } from 'redux';
// import createHistory from 'history/createHashHistory';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'react-router-redux';

import reducers from '../reducers';
import rootSaga from '../sagas';


// const history = createHistory();
// const routeMiddleware = routerMiddleware(history);
const routeMiddleware = routerMiddleware();
const sagaMiddleware = createSagaMiddleware();
const middlewares = [routeMiddleware, sagaMiddleware];

export default function configureStore (initialState) {
  const store = createStore(
    reducers,
    initialState,
    compose(
      applyMiddleware(...middlewares)
    )
  );

  sagaMiddleware.run(rootSaga);

  // if (module.hot) {
  //     // Enable Webpack hot module replacement for reducers
  //     module.hot.accept ('../reducers/index', () => {
  //         const nextRootReducer = require ('../reducers/index');
  //         store.replaceReducer (nextRootReducer);
  //     });
  // }
  return store;
}

// export { history };
