import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { toast } from 'react-toastify';
import { sprintf } from 'sprintf-js';
import moment from 'moment';

import ReportesMensajesListar from './Listar';
import { obtenerReporte, cargandoReporte, exportarReporte } from '../../../../actions/Reportes/Mensajes';
import { setTituloPagina } from '../../../../actions/Configuracion';
import { ESTADOS_CAMPANIA } from '../../../../constants/EnviarCampanias/Estados';


class ReportesMensajesListarContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      datos: [],
      fecha_desde: moment().subtract(1, 'days').toDate(),
      fecha_hasta: moment().toDate()
    };
  }

  descargarReporte = () => {
    toast.success(this.props.mensaje);
    const archivo = new Blob([this.props.archivo], {type: 'application/xlsx'});
    let url_archivo = URL.createObjectURL(archivo);

    // Simula descarga del archivo
    let link_archivo = document.createElement('a');
    link_archivo.href = url_archivo;
    link_archivo.download = sprintf('reporte_mensajes_enviados_%(fecha)s.xlsx', { fecha: moment().format('MMDDYYYY') });
    link_archivo.click();

    // Limpia descarga
    link_archivo.remove();
    URL.revokeObjectURL(url_archivo);
  };

  calcularGrafica = () => {
    let datos = [];

    for (let index in this.props.mensajes) {
      let mensaje = this.props.mensajes[index];
      let estado = ESTADOS_CAMPANIA.find((estado) => estado.value === mensaje.estado);
      let estado_label = estado ? estado.label.toUpperCase() : 'DESCONOCIDO';

      let existe = datos.find((dato) => dato.name === estado_label);
      if (existe) {
        existe.value++;
        datos[estado_label] = existe;
      } else {
        datos.push({
          name: estado_label,
          estado: estado ? estado.value : '',
          color: estado ? estado.color : '#CCC',
          value: 1,
        });
      }
    }

    this.setState({
      datos
    });
  };

  validarRangoFecha = () => {
    let fechas_validas = {
      valido: false
    };

    if (this.state.fecha_desde && this.state.fecha_hasta) {
      let inicio = moment(this.state.fecha_desde);
      let fin = moment(this.state.fecha_hasta);

      inicio.startOf('day');
      fin.add(1, 'days').startOf('day');

      fechas_validas.valido = true;
      fechas_validas.fecha_inicio = inicio;
      fechas_validas.fecha_fin = fin;
    }

    return fechas_validas;
  };

  handleExportarReporte = () => {
    let fechas_validas = this.validarRangoFecha();

    if (fechas_validas.valido) {
      let filtros = {
        fecha_inicio: fechas_validas.fecha_inicio.toISOString(true),
        fecha_fin: fechas_validas.fecha_fin.toISOString(true)
      };

      if (typeof this.state.filtrado !== 'undefined') {
        this.state.filtrado.map((filtro) => filtros[filtro.id] = filtro.value);
      }

      this.props.actions.cargandoReporte(true);
      this.props.actions.exportarReporte({ filtros });
    } else {
      toast.error('Por favor ingrese un rango de fechas válido.');
    }
  };

  handleObtenerReporte = () => {
    let fechas_validas = this.validarRangoFecha();

    if (fechas_validas.valido) {
      let filtros = {
        fecha_inicio: fechas_validas.fecha_inicio.toISOString(true),
        fecha_fin: fechas_validas.fecha_fin.toISOString(true)
      };

      if (typeof this.state.filtrado !== 'undefined') {
        this.state.filtrado.map((filtro) => filtros[filtro.id] = filtro.value);
      }

      this.props.actions.cargandoReporte(true);
      this.props.actions.obtenerReporte({ filtros });
    } else {
      toast.error('Por favor ingrese un rango de fechas válido.');
    }
  };

  handleDesdeChange = (fecha_desde) => {
    this.setState({ fecha_desde });
  };

  handleHastaChange = (fecha_hasta) => {
    this.setState({ fecha_hasta });
  };

  componentDidMount() {
    this.props.actions.setTituloPagina({titulo: 'Reporte de mensajes enviados', icono: 'chart-pie'});
    this.handleObtenerReporte();
  }

  componentDidUpdate(props) {
    // Verificar si existió algún error
    if (this.props.error) {
      if (this.props.mensaje) {
        toast.error(this.props.mensaje);
      }

      if (this.props.cargando) {
        this.props.actions.cargandoReporte(false);
      }
    } else if (props.mensajes !== this.props.mensajes) {
      this.calcularGrafica();
    } else if (this.props.archivo) {
      this.descargarReporte();
    }
  }

  render() {
    return (
      <ReportesMensajesListar
        mensajes={this.props.mensajes}
        datos={this.state.datos}
        cargando={this.props.cargando}
        fechaDesde={this.state.fecha_desde}
        fechaHasta={this.state.fecha_hasta}
        obtenerReporte={this.handleObtenerReporte}
        exportarReporte={this.handleExportarReporte}
        handleDesdeChange={this.handleDesdeChange}
        handleHastaChange={this.handleHastaChange}
      />
    );
  }
}


const mapStateToProps = ({ reporteMensajes }) => {
  return { ...reporteMensajes };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      setTituloPagina,
      obtenerReporte,
      cargandoReporte,
      exportarReporte
    }, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportesMensajesListarContainer));
