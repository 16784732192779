
// Listar
export const OBTENER_DESTINATARIOS = 'obtener_destinatarios';
export const OBTENER_DESTINATARIOS_EXITOSO = 'obtener_destinatarios_exitoso';
export const OBTENER_DESTINATARIOS_ERROR = 'obtener_destinatarios_error';
export const CARGANDO_DESTINATARIOS = 'cargando_destinatarios';

// Detalle
export const OBTENER_DESTINATARIO = 'obtener_destinatario';
export const OBTENER_DESTINATARIO_EXITOSO = 'obtener_destinatario_exitoso';
export const OBTENER_DESTINATARIO_ERROR = 'obtener_destinatario_error';
export const GUARDAR_DESTINATARIO = 'guardar_destinatario';
export const GUARDAR_DESTINATARIO_EXITOSO = 'guardar_destinatario_exitoso';
export const GUARDAR_DESTINATARIO_ERROR = 'guardar_destinatario_error';
export const GUARDANDO_DESTINATARIO = 'guardando_destinatario';
export const ELIMINAR_DESTINATARIO = 'eliminar_destinatario';
export const ELIMINAR_DESTINATARIO_EXITOSO = 'eliminar_destinatario_exitoso';
export const ELIMINAR_DESTINATARIO_ERROR = 'eliminar_destinatario_error';
