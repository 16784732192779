import { put, call, all, fork, takeEvery } from 'redux-saga/effects';

import {
  OBTENER_REPORTE_CAMPANIAS, EXPORTAR_REPORTE_CAMPANIAS
} from '../../constants/ActionTypes/Reportes';
import {
  REPORTE_MENSAJES
} from '../../constants/Urls/Mensajes';
import {
  obtenerReporteExitoso,
  obtenerReporteError,
  exportarReporteExitoso,
  exportarReporteError
} from '../../actions/Reportes/Campanias';
import { httpFetch } from '../../utils/Http/Fetch';


const obtenerReporteCampaniasApi = async (filtros) => {
  let esc = encodeURIComponent;
  let params = Object.keys(filtros).map(filtro => esc(filtro) + '=' + esc(filtros[filtro])).join('&');

  return httpFetch(`${REPORTE_MENSAJES}?${params}`, 'GET')
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};

const exportarReporteCampaniasApi = async (filtros) => {
  let esc = encodeURIComponent;
  let params = Object.keys(filtros).map(filtro => esc(filtro) + '=' + esc(filtros[filtro])).join('&');

  return httpFetch(`${REPORTE_MENSAJES}?${params}`, 'GET', null, {'Accept': 'application/xlsx', 'Content-Type': 'application/xlsx'})
    .then(response => response)
    .then(response => response.blob())
    .then(blob => blob)
    .catch((error) => {
      throw error;
    });
};



function* obtenerReporteCampanias(action) {
  try {
    const { filtros } = action.payload;
    const reporte = yield call(obtenerReporteCampaniasApi, filtros);

    yield put(obtenerReporteExitoso({mensajes: reporte}));
  } catch (error) {
    yield put(obtenerReporteError(error.message));
  }
}

function* exportarReporteCampanias(action) {
  try {
    const { filtros } = action.payload;
    const archivo = yield call(exportarReporteCampaniasApi, filtros);
    const mensaje = 'Reporte generado correctamente';

    yield put(exportarReporteExitoso({archivo, mensaje}));
  } catch (error) {
    yield put(exportarReporteError(error.message));
  }
}


export function* obtenerReporteWatcher() {
  yield takeEvery (OBTENER_REPORTE_CAMPANIAS, obtenerReporteCampanias);
}

export function* exportarReporteWatcher() {
  yield takeEvery (EXPORTAR_REPORTE_CAMPANIAS, exportarReporteCampanias);
}

export default function* rootSaga() {
  yield all ([
    fork (obtenerReporteWatcher),
    fork (exportarReporteWatcher)
  ]);
}
