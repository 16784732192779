import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faFolder,
  faFileAlt,
  faCircle
} from '@fortawesome/free-regular-svg-icons';


library.add(
  faFolder,
  faFileAlt,
  faCircle
);
