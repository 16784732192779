import { put, call, all, fork, takeEvery } from 'redux-saga/effects';

import { sprintf } from 'sprintf-js';

import {
  OBTENER_CAMPANIA,
  GUARDAR_CAMPANIA,
  ELIMINAR_CAMPANIA,
  OBTENER_LISTAS_DESTINATARIOS,
  OBTENER_MENSAJES
} from '../../constants/ActionTypes/Campanias';
import { CAMPANIAS_URL, CAMPANIA_URL } from '../../constants/Urls/Campanias';
import { MENSAJES_URL } from '../../constants/Urls/Mensajes';
import { LISTAS_DESTINATARIOS_URL } from '../../constants/Urls/ListasDestinatarios';
import {
  obtenerCampaniaExitoso,
  obtenerCampaniaError,
  guardarCampaniaExitoso,
  guardarCampaniaError,
  eliminarCampaniaExitoso,
  eliminarCampaniaError,
  obtenerListasDestinatariosExitoso,
  obtenerListasDestinatariosError,
  obtenerMensajesExitoso,
  obtenerMensajesError
} from '../../actions/Campanias/Detalle';
import { httpFetch } from '../../utils/Http/Fetch';
import { setUnidades } from '../../actions/Configuracion';


const obtenerCampaniaApi = async (uuid) => {
  const url = sprintf(CAMPANIA_URL, { uuid });

  return httpFetch(url, 'GET')
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};

const guardarCampaniaApi = async (datos) => {
  const verbo_http = datos.uuid ? 'PUT' : 'POST';
  let url = CAMPANIAS_URL;

  if (datos.uuid) {
    url = sprintf(CAMPANIA_URL, {
      uuid: datos.uuid
    });
  }

  return httpFetch(url, verbo_http, datos)
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};

const eliminarCampaniaApi = async (uuid) => {
  const url = sprintf(CAMPANIA_URL, {uuid});

  return httpFetch(url, 'DELETE')
    .catch((error) => {
      throw error;
    });
};

const obtenerListasDestinatariosApi = async () => {
  return httpFetch(`${LISTAS_DESTINATARIOS_URL}`, 'GET')
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};

const obtenerMensajesApi = async () => {
  return httpFetch(`${MENSAJES_URL}`, 'GET')
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};


function* obtenerCampania(action) {
  try {
    yield all([
      call(obtenerListasDestinatarios),
      call(obtenerMensajes)
    ]);

    const campania = yield call(obtenerCampaniaApi, action.payload);

    if (typeof campania.saldo !== 'undefined') {
      yield put(setUnidades(campania.saldo));
    }

    yield put(obtenerCampaniaExitoso(campania));
  } catch (error) {
    yield put(obtenerCampaniaError(error.message));
  }
}

function* guardarCampania(action) {
  try {
    const destinatario = yield call(guardarCampaniaApi, action.payload);
    const accion = action.payload.uuid ? 'actualizada' : 'creada';
    const mensaje = `Campaña ${accion} correctamente.`;

    if (typeof destinatario.saldo !== 'undefined') {
      yield put(setUnidades(destinatario.saldo));
    }

    yield put(guardarCampaniaExitoso({destinatario, mensaje}));
  } catch (error) {
    yield put(guardarCampaniaError(error.message));
  }
}

function* eliminarCampania(action) {
  try {
    yield call(eliminarCampaniaApi, action.payload);
    const mensaje = 'Campaña eliminada correctamente.';

    yield put(eliminarCampaniaExitoso({ mensaje }));
  } catch (error) {
    yield put(eliminarCampaniaError(error.message));
  }
}

function* obtenerListasDestinatarios() {
  try {
    const listas = yield call(obtenerListasDestinatariosApi);
    let listas_opciones = listas.map((lista) => {
      return {value: lista.uuid, label: `${lista.nombre} (${lista.cantidad_destinatarios} destinatario(s))`, ...lista};
    });
    listas_opciones.unshift({value: '', label: 'Seleccione una opción', columnas_destinatarios: []});

    yield put(obtenerListasDestinatariosExitoso(listas_opciones));
  } catch (error) {
    yield put(obtenerListasDestinatariosError(error.message));
  }
}

function* obtenerMensajes() {
  try {
    const mensajes = yield call(obtenerMensajesApi);
    let mensajes_opciones = mensajes.map((mensaje) => {
      return {value: mensaje.uuid, label: mensaje.nombre, ...mensaje};
    });
    mensajes_opciones.unshift({value: '', label: 'Seleccione una opción'});

    yield put(obtenerMensajesExitoso(mensajes_opciones));
  } catch (error) {
    yield put(obtenerMensajesError(error.message));
  }
}


export function* obtenerCampaniaWatcher() {
  yield takeEvery (OBTENER_CAMPANIA, obtenerCampania);
}

export function* guardarCampaniaWatcher() {
  yield takeEvery (GUARDAR_CAMPANIA, guardarCampania);
}

export function* eliminarCampaniaWatcher() {
  yield takeEvery (ELIMINAR_CAMPANIA, eliminarCampania);
}

export function* obtenerListasDestinatariosWatcher() {
  yield takeEvery (OBTENER_LISTAS_DESTINATARIOS, obtenerListasDestinatarios);
}

export function* obtenerMensajesWatcher() {
  yield takeEvery (OBTENER_MENSAJES, obtenerMensajes);
}

export default function* rootSaga() {
  yield all ([
    fork (guardarCampaniaWatcher),
    fork (obtenerCampaniaWatcher),
    fork (eliminarCampaniaWatcher),
    fork (obtenerListasDestinatariosWatcher),
    fork (obtenerMensajesWatcher)
  ]);
}
