import {
  OBTENER_CLIENTE,
  OBTENER_CLIENTE_EXITOSO,
  OBTENER_CLIENTE_ERROR,
  GUARDAR_CLIENTE,
  GUARDAR_CLIENTE_EXITOSO,
  GUARDAR_CLIENTE_ERROR,
  GUARDANDO_CLIENTE,
  LIMPIAR_CLIENTE,
  ELIMINAR_CLIENTE,
  ELIMINAR_CLIENTE_EXITOSO,
  ELIMINAR_CLIENTE_ERROR,
  OBTENER_CIUDADES,
  OBTENER_CIUDADES_EXITOSO,
  OBTENER_CIUDADES_ERROR
} from '../../constants/ActionTypes/Clientes';


export const obtenerCliente = (uuid) => {
  return {
    type: OBTENER_CLIENTE,
    payload: uuid
  };
};

export const obtenerClienteExitoso = (cuenta) => {
  return {
    type: OBTENER_CLIENTE_EXITOSO,
    payload: cuenta
  };
};

export const obtenerClienteError = (mensaje) => {
  return {
    type: OBTENER_CLIENTE_ERROR,
    payload: mensaje
  };
};

export const guardarCliente = (datos) => {
  return {
    type: GUARDAR_CLIENTE,
    payload: datos
  };
};

export const guardarClienteExitoso = (cliente) => {
  return {
    type: GUARDAR_CLIENTE_EXITOSO,
    payload: cliente
  };
};

export const guardarClienteError = (mensaje) => {
  return {
    type: GUARDAR_CLIENTE_ERROR,
    payload: mensaje
  };
};

export const guardandoCliente = (cargando) => {
  return {
    type: GUARDANDO_CLIENTE,
    payload: cargando
  };
};

export const limpiarCliente = () => {
  return {
    type: LIMPIAR_CLIENTE
  };
};

export const obtenerCiudades = (filtros) => {
  return {
    type: OBTENER_CIUDADES,
    payload: filtros
  };
};

export const obtenerCiudadesExitoso = (ciudades) => {
  return {
    type: OBTENER_CIUDADES_EXITOSO,
    payload: ciudades
  };
};

export const obtenerCiudadesError = (mensaje) => {
  return {
    type: OBTENER_CIUDADES_ERROR,
    payload: mensaje
  };
};

export const eliminarCliente = (uuid) => {
  return {
    type: ELIMINAR_CLIENTE,
    payload: uuid
  };
};

export const eliminarClienteExitoso = (mensaje) => {
  return {
    type: ELIMINAR_CLIENTE_EXITOSO,
    payload: mensaje
  };
};

export const eliminarClienteError = (mensaje) => {
  return {
    type: ELIMINAR_CLIENTE_ERROR,
    payload: mensaje
  };
};
