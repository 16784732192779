import React from 'react';

import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import UIDataTable from '../../../UI/DataTable/DataTable';


const ReportesCampaniasListar = (props) => (
  <div className="app-module">
    <div className="module-box">
      <div className="module-box-header">
        <div className="module-box-header-inner">
          <div className="module-box-header-right">
            <Button color="info" className="jr-btn" onClick={props.obtenerCampanias}>
              <FontAwesomeIcon icon="sync" size="lg" className="mr-2" />
              <span>Actualizar</span>
            </Button>
          </div>
        </div>
      </div>
      <div className="module-box-content">

        <UIDataTable
          data={props.campanias}
          manual
          filterable={true}
          filtered={props.filtrado}
          pages={props.paginas}
          page={props.paginacion.pagina}
          pageSize={props.paginacion.tamano_pagina}
          onPageChange={props.cambioPagina}
          onPageSizeChange={props.cambioTamanoPagina}
          onFilteredChange={props.cambioFiltro}
          columns={[
            {
              Header: 'Nombre',
              accessor: 'search',
              Cell: (row) => row.original.campania.nombre
            },
            {
              Header: 'Usuario',
              accessor: 'usuario',
              filterable: false,
              Cell: (row) => `${row.original.usuario.first_name} ${row.original.usuario.last_name}`
            },
            {
              Header: 'Mensaje',
              filterable: false,
              Cell: (row) => row.original.campania.mensaje ? row.original.campania.mensaje.nombre : null
            },
            {
              Header: 'Lista',
              filterable: false,
              Cell: (row) => row.original.campania.lista ?
                `${row.original.campania.lista.nombre} (${row.original.campania.lista.cantidad_destinatarios} destinatario(s))`
                :
                null
            },
          ]}
          getTrProps={(state, rowInfo) => ({
            onClick: () => {
              props.reporteCampania(rowInfo.original.uuid);
            }
          })}
          noDataText="No se ha encontrando ninguna campaña"
          rowsText="campañas"
          loading={props.cargando}
        />
      </div>
    </div>
  </div>
);


export default ReportesCampaniasListar;
