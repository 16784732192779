export const TIPO_PERSONA_NATURAL = 'PN';
export const TIPO_PERSONA_JURIDICA = 'PJ';

export const TIPO_PERSONA_NATURAL_LABEL = 'Natural';
export const TIPO_PERSONA_JURIDICA_LABEL = 'Jurídica';

export const TIPOS_PERSONA = [
  {label: TIPO_PERSONA_NATURAL_LABEL, value: TIPO_PERSONA_NATURAL},
  {label: TIPO_PERSONA_JURIDICA_LABEL, value: TIPO_PERSONA_JURIDICA}
];
