import {
  OBTENER_ENCUESTAS,
  OBTENER_ENCUESTAS_EXITOSO,
  OBTENER_ENCUESTAS_ERROR,
  CARGANDO_ENCUESTAS,
  OBTENER_ENCUESTAS_ENVIADAS,
  OBTENER_ENCUESTAS_ENVIADAS_EXITOSO,
  OBTENER_ENCUESTAS_ENVIADAS_ERROR,
  CARGANDO_ENCUESTAS_ENVIADAS
} from '../../constants/ActionTypes/Encuestas';


export const obtenerEncuestas = (params) => {
  return {
    type: OBTENER_ENCUESTAS,
    payload: params
  };
};

export const obtenerEncuestasExitoso = (destinatarios) => {
  return {
    type: OBTENER_ENCUESTAS_EXITOSO,
    payload: destinatarios
  };
};

export const obtenerEncuestasError = (mensaje) => {
  return {
    type: OBTENER_ENCUESTAS_ERROR,
    payload: mensaje
  };
};

export const cargandoEncuestas = (cargando) => {
  return {
    type: CARGANDO_ENCUESTAS,
    payload: cargando
  };
};

export const obtenerEncuestasEnviadas = (params) => {
  return {
    type: OBTENER_ENCUESTAS_ENVIADAS,
    payload: params
  };
};

export const obtenerEncuestasEnviadasExitoso = (encuestas) => {
  return {
    type: OBTENER_ENCUESTAS_ENVIADAS_EXITOSO,
    payload: encuestas
  };
};

export const obtenerEncuestasEnviadasError = (mensaje) => {
  return {
    type: OBTENER_ENCUESTAS_ENVIADAS_ERROR,
    payload: mensaje
  };
};

export const cargandoEncuestasEnviadas = (cargando) => {
  return {
    type: CARGANDO_ENCUESTAS_ENVIADAS,
    payload: cargando
  };
};
