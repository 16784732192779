import { put, call, all, fork, takeEvery } from 'redux-saga/effects';

import { sprintf } from 'sprintf-js';

import {
  OBTENER_DESTINATARIO,
  GUARDAR_DESTINATARIO,
  ELIMINAR_DESTINATARIO
} from '../../constants/ActionTypes/Destinatarios';
import {
  DESTINATARIOS_URL,
  DESTINATARIO_URL
} from '../../constants/Urls/Destinatarios';
import {
  obtenerDestinatarioExitoso,
  obtenerDestinatarioError,
  guardarDestinatarioExitoso,
  guardarDestinatarioError,
  eliminarDestinatarioExitoso,
  eliminarDestinatarioError
} from '../../actions/Destinatarios/Detalle';
import { httpFetch } from '../../utils/Http/Fetch';
import {setUnidades} from "../../actions/Configuracion";


const obtenerDestinatarioApi = async (uuid) => {
  const url = sprintf(DESTINATARIO_URL, { uuid });

  return httpFetch(url, 'GET')
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};

const guardarDestinatarioApi = async (datos) => {
  const verbo_http = datos.uuid ? 'PUT' : 'POST';
  let url = DESTINATARIOS_URL;

  if (datos.uuid) {
    url = sprintf(DESTINATARIO_URL, {
      uuid: datos.uuid
    });
  }

  return httpFetch(url, verbo_http, datos)
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};

const eliminarDestinatarioApi = async (uuid) => {
  const url = sprintf(DESTINATARIO_URL, {uuid});

  return httpFetch(url, 'DELETE')
    .catch((error) => {
      throw error;
    });
};


function* obtenerDestinatario(action) {
  try {
    const destinatario = yield call(obtenerDestinatarioApi, action.payload);

    if (typeof destinatario.saldo !== 'undefined') {
      yield put(setUnidades(destinatario.saldo));
    }

    yield put(obtenerDestinatarioExitoso(destinatario));
  } catch (error) {
    yield put(obtenerDestinatarioError(error.message));
  }
}

function* guardarDestinatario(action) {
  try {
    const destinatario = yield call(guardarDestinatarioApi, action.payload);
    const accion = action.payload.uuid ? 'actualizado' : 'creado';
    const mensaje = `Destinatario ${accion} correctamente.`;

    if (typeof destinatario.saldo !== 'undefined') {
      yield put(setUnidades(destinatario.saldo));
    }

    yield put(guardarDestinatarioExitoso({destinatario, mensaje}));
  } catch (error) {
    yield put(guardarDestinatarioError(error.message));
  }
}

function* eliminarDestinatario(action) {
  try {
    yield call(eliminarDestinatarioApi, action.payload);
    const mensaje = 'Destinatario eliminado correctamente.';

    yield put(eliminarDestinatarioExitoso({ mensaje }));
  } catch (error) {
    yield put(eliminarDestinatarioError(error.message));
  }
}


export function* obtenerDestinatarioWatcher() {
  yield takeEvery (OBTENER_DESTINATARIO, obtenerDestinatario);
}

export function* guardarDestinatarioWatcher() {
  yield takeEvery (GUARDAR_DESTINATARIO, guardarDestinatario);
}

export function* eliminarDestinatarioWatcher() {
  yield takeEvery (ELIMINAR_DESTINATARIO, eliminarDestinatario);
}

export default function* rootSaga() {
  yield all ([
    fork (guardarDestinatarioWatcher),
    fork (obtenerDestinatarioWatcher),
    fork (eliminarDestinatarioWatcher)
  ]);
}
