import React from 'react';
import { NavLink } from 'react-router-dom';

import { Breadcrumb as BTBreadcrumb, BreadcrumbItem } from 'reactstrap';


const Breadcrumb = (props) => (
  <div className="page-heading d-sm-flex justify-content-sm-between align-items-sm-center">
    <BTBreadcrumb tag="nav">
      {props.breadcrumbs.map((breadcrumb) => (
        <BreadcrumbItem key={breadcrumb.key}>
          <NavLink to={breadcrumb.props.match.url}>
            {breadcrumb}
          </NavLink>
        </BreadcrumbItem>
      ))}
    </BTBreadcrumb>
  </div>
);


export default Breadcrumb;
