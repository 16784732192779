import { put, call, all, fork, takeEvery } from 'redux-saga/effects';

import { sprintf } from 'sprintf-js';

import {
  OBTENER_REPORTE_ENCUESTAS, OBTENER_REPORTE_ENCUESTAS_RESULTADO, EXPORTAR_REPORTE_ENCUESTAS
} from '../../constants/ActionTypes/Reportes';
import {
  ENCUESTAS_RESULTADOS_URL, ENCUESTAS_RESULTADOS_EXPORTAR_URL, ENCUESTAS_RESULTADOS_RESULTADO_URL
} from '../../constants/Urls/Encuestas';
import {
  obtenerReporteExitoso,
  obtenerReporteError,
  obtenerReporteResultadoExitoso,
  obtenerReporteResultadoError,
  exportarReporteExitoso,
  exportarReporteError
} from '../../actions/Reportes/Encuestas';
import { httpFetch } from '../../utils/Http/Fetch';


const obtenerReporteEncuestasApi = async (filtros, encuesta) => {
  const url = sprintf(ENCUESTAS_RESULTADOS_URL, { uuid_encuesta: encuesta });

  let esc = encodeURIComponent;
  let params = Object.keys(filtros).map(filtro => esc(filtro) + '=' + esc(filtros[filtro])).join('&');

  return httpFetch(`${url}?${params}`, 'GET')
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};

const obtenerReporteEncuestasResultadoApi = async (encuesta, resultado) => {
  const url = sprintf(ENCUESTAS_RESULTADOS_RESULTADO_URL, { uuid_encuesta: encuesta, uuid_resultado: resultado });

  return httpFetch(url, 'GET')
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};

const exportarReporteEncuestasApi = async (filtros, encuesta) => {
  const url = sprintf(ENCUESTAS_RESULTADOS_EXPORTAR_URL, { uuid_encuesta: encuesta });

  let esc = encodeURIComponent;
  let params = Object.keys(filtros).map(filtro => esc(filtro) + '=' + esc(filtros[filtro])).join('&');

  return httpFetch(`${url}?${params}`,
    'GET', null, {'Accept': 'application/xlsx', 'Content-Type': 'application/xlsx'}
  )
    .then(response => response)
    .then(response => response.blob())
    .then(blob => blob)
    .catch((error) => {
      throw error;
    });
};



function* obtenerReporteEncuestas(action) {
  try {
    const { filtros, encuesta } = action.payload;
    const reporte = yield call(obtenerReporteEncuestasApi, filtros, encuesta);

    yield put(obtenerReporteExitoso({resultados: reporte}));
  } catch (error) {
    yield put(obtenerReporteError(error.message));
  }
}

function* obtenerReporteEncuestasResultado(action) {
  try {
    const { encuesta, resultado } = action.payload;
    const reporte = yield call(obtenerReporteEncuestasResultadoApi, encuesta, resultado);

    yield put(obtenerReporteResultadoExitoso({resultado: reporte}));
  } catch (error) {
    yield put(obtenerReporteResultadoError(error.message));
  }
}

function* exportarReporteEncuestas(action) {
  try {
    const { filtros, encuesta } = action.payload;
    const archivo = yield call(exportarReporteEncuestasApi, filtros, encuesta);
    const mensaje = 'Reporte generado correctamente';

    yield put(exportarReporteExitoso({archivo, mensaje}));
  } catch (error) {
    yield put(exportarReporteError(error.message));
  }
}


export function* obtenerReporteWatcher() {
  yield takeEvery (OBTENER_REPORTE_ENCUESTAS, obtenerReporteEncuestas);
}

export function* obtenerReporteResultadoWatcher() {
  yield takeEvery (OBTENER_REPORTE_ENCUESTAS_RESULTADO, obtenerReporteEncuestasResultado);
}

export function* exportarReporteWatcher() {
  yield takeEvery (EXPORTAR_REPORTE_ENCUESTAS, exportarReporteEncuestas);
}

export default function* rootSaga() {
  yield all ([
    fork (obtenerReporteWatcher),
    fork (obtenerReporteResultadoWatcher),
    fork (exportarReporteWatcher)
  ]);
}
