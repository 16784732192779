import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { toast } from 'react-toastify';

import DobleViaClavesListar from './Listar';
import { obtenerClaves, cargandoClaves } from '../../../../actions/DobleVia/Claves/Listar';
import { setTituloPagina } from '../../../../actions/Configuracion';
import { USUARIO_SESION } from '../../../../constants/Configuracion';


class DobleViaClavesListarContainer extends Component {
  constructor(props) {
    super(props);

    const usuario = JSON.parse(localStorage.getItem(USUARIO_SESION));
    this.state = {
      usuario,
      paginacion: {
        pagina: 0,
        tamano_pagina: 20
      },
      filtrado: []
    };
  }

  handleCambioPagina = (pagina) => {
    this.setState({
      paginacion: {
        ...this.state.paginacion,
        pagina
      }
    });
  };

  handleCambioTamanoPagina = (tamano_pagina, pagina) => {
    this.setState({
      paginacion: {
        tamano_pagina,
        pagina
      }
    });
  };

  handleCambioFiltro = (filtrado) => {
    this.setState({
      paginacion: {
        ...this.state.paginacion,
        pagina: 0
      },
      filtrado
    });
  };

  handleObtenerClaves = () => {
    const { pagina, tamano_pagina } = this.state.paginacion;

    let filtros = {pagina: pagina + 1, tamano_pagina};
    if (typeof this.state.filtrado !== 'undefined') {
      this.state.filtrado.map((filtro) => filtros[filtro.id] = filtro.value);
    }

    this.props.actions.cargandoClaves(true);
    this.props.actions.obtenerClaves({ filtros });
  };

  handleEditarClave = (id_clave) => {
    this.props.history.push('claves/detalle/' + id_clave);
  };

  componentDidMount() {
    this.props.actions.setTituloPagina({titulo: 'Claves doble vía', icono: 'tags'});
    this.handleObtenerClaves();
  }

  componentDidUpdate(props, state) {
    if (this.props.error) {
      if (this.props.mensaje) {
        toast.error(this.props.mensaje);
      }

      if (this.props.cargando) {
        this.props.actions.cargandoClaves(false);
      }
    }

    if ((state.filtrado !== this.state.filtrado) || (state.paginacion !== this.state.paginacion)) {
      this.handleObtenerClaves();
    }
  }

  render() {
    return (
      <DobleViaClavesListar
        usuario={this.state.usuario}
        claves={this.props.claves}
        cargando={this.props.cargando}
        paginas={this.props.paginas}
        paginacion={this.state.paginacion}
        filtrado={this.state.filtrado}
        obtenerClaves={this.handleObtenerClaves}
        editarClave={this.handleEditarClave}
        cambioPagina={this.handleCambioPagina}
        cambioTamanoPagina={this.handleCambioTamanoPagina}
        cambioFiltro={this.handleCambioFiltro}
      />
    );
  }
}


const mapStateToProps = ({ listarDobleViaClaves }) => {
  return { ...listarDobleViaClaves };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      setTituloPagina,
      obtenerClaves,
      cargandoClaves
    }, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DobleViaClavesListarContainer));
