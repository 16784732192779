import React from 'react';

import { Button, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import UIDataTable from '../../../UI/DataTable/DataTable';


const ReportesEncuestasListar = (props) => (
  <div className="app-module">
    <div className="module-box">
      <div className="module-box-header">
        <div className="module-box-header-inner">
          <div className="module-box-header-right">
            <Button color="info" className="jr-btn" onClick={props.obtenerEncuestas}>
              <FontAwesomeIcon icon="sync" size="lg" className="mr-2" />
              <span>Actualizar</span>
            </Button>
          </div>
        </div>
      </div>
      <div className="module-box-content">
        <UIDataTable
          data={props.encuestas}
          manual
          filterable={true}
          filtered={props.filtrado}
          pages={props.paginas}
          page={props.paginacion.pagina}
          pageSize={props.paginacion.tamano_pagina}
          onPageChange={props.cambioPagina}
          onPageSizeChange={props.cambioTamanoPagina}
          onFilteredChange={props.cambioFiltro}
          columns={[
            {
              Header: 'Encuesta',
              accessor: 'search',
              orderable: false,
              Cell: (row) => (
                row.original.encuesta ?
                  <span>
                    {row.original.encuesta.nombre}
                    <Badge color="info" pill className="ml-1">
                      {`${row.original.respuestas} respuestas`}
                    </Badge>
                  </span>
                  :
                  null
              )
            },
            {
              Header: 'Campaña',
              accessor: 'campania.nombre',
              orderable: false,
              filterable: false,
            },
            {
              Header: 'Usuario',
              accessor: 'usuario.nombre',
              orderable: false,
              filterable: false,
              Cell: (row) => (
                row.original.usuario ? `${row.original.usuario.first_name} ${row.original.usuario.last_name}` : null
              )
            },
            {
              Header: 'Mensaje',
              orderable: false,
              filterable: false,
              Cell: (row) => (
                row.original.campania && row.original.campania.mensaje ? row.original.campania.mensaje.nombre : null
              )
            },
            {
              Header: 'Lista',
              orderable: false,
              filterable: false,
              Cell: (row) => (
                row.original.campania && row.original.campania.lista ?
                  `${row.original.campania.lista.nombre} (${row.original.campania.lista.cantidad_destinatarios} destinatario(s))`
                  :
                  null
              )
            }
          ]}
          getTrProps={(state, rowInfo) => ({
            onClick: () => {
              props.reporteEncuesta(rowInfo.original.encuesta.uuid);
            }
          })}
          noDataText="No se ha encontrando ninguna encuesta enviada"
          rowsText="encuestas enviadas"
          loading={props.cargando}
        />
      </div>
    </div>
  </div>
);


export default ReportesEncuestasListar;
