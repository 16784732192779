
// Campanias
export const OBTENER_REPORTE_CAMPANIAS = 'obtener_reporte_campanias';
export const OBTENER_REPORTE_CAMPANIAS_EXITOSO = 'obtener_reporte_campanias_exitoso';
export const OBTENER_REPORTE_CAMPANIAS_ERROR = 'obtener_reporte_campanias_error';
export const CARGANDO_REPORTE_CAMPANIAS = 'cargando_reporte_campanias';
export const EXPORTAR_REPORTE_CAMPANIAS = 'exportar_reporte_campanias';
export const EXPORTAR_REPORTE_CAMPANIAS_EXITOSO = 'exportar_reporte_campanias_exitoso';
export const EXPORTAR_REPORTE_CAMPANIAS_ERROR = 'exportar_reporte_campanias_error';

// Mensajes
export const OBTENER_REPORTE_MENSAJES = 'obtener_reporte_mensajes';
export const OBTENER_REPORTE_MENSAJES_EXITOSO = 'obtener_reporte_mensajes_exitoso';
export const OBTENER_REPORTE_MENSAJES_ERROR = 'obtener_reporte_mensajes_error';
export const CARGANDO_REPORTE_MENSAJES = 'cargando_reporte_mensajes';
export const EXPORTAR_REPORTE_MENSAJES = 'exportar_reporte_mensajes';
export const EXPORTAR_REPORTE_MENSAJES_EXITOSO = 'exportar_reporte_mensajes_exitoso';
export const EXPORTAR_REPORTE_MENSAJES_ERROR = 'exportar_reporte_mensajes_error';

// Encuestas
export const OBTENER_REPORTE_ENCUESTAS = 'obtener_reporte_encuestas';
export const OBTENER_REPORTE_ENCUESTAS_EXITOSO = 'obtener_reporte_encuestas_exitoso';
export const OBTENER_REPORTE_ENCUESTAS_ERROR = 'obtener_reporte_encuestas_error';
export const CARGANDO_REPORTE_ENCUESTAS = 'cargando_reporte_encuestas';
export const OBTENER_REPORTE_ENCUESTAS_RESULTADO = 'obtener_reporte_encuestas_resultado';
export const OBTENER_REPORTE_ENCUESTAS_RESULTADO_EXITOSO = 'obtener_reporte_encuestas_resultado_exitoso';
export const OBTENER_REPORTE_ENCUESTAS_RESULTADO_ERROR = 'obtener_reporte_encuestas_resultado_error';
export const CARGANDO_REPORTE_ENCUESTAS_RESULTADO = 'cargando_reporte_encuestas_resultado';
export const EXPORTAR_REPORTE_ENCUESTAS = 'exportar_reporte_encuestas';
export const EXPORTAR_REPORTE_ENCUESTAS_EXITOSO = 'exportar_reporte_encuestas_exitoso';
export const EXPORTAR_REPORTE_ENCUESTAS_ERROR = 'exportar_reporte_encuestas_error';
