import * as Yup from 'yup';

import {
  CAMPO_REQUERIDO,
  CAMPO_LONGITUD_MAXIMA
} from '../../../../constants/Forms/Validacion';


export const claveSchema = Yup.object().shape({
  nombre: Yup.string()
    .required(CAMPO_REQUERIDO)
    .max(6, CAMPO_LONGITUD_MAXIMA),

  descripcion: Yup.string()
    .required(CAMPO_REQUERIDO),

  mensaje: Yup.string()
    .max(160, CAMPO_LONGITUD_MAXIMA)
    .notRequired()
});
