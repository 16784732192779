import React from 'react';

import { Button, Row, Col } from 'reactstrap';
import { Formik, Form } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WithOutContext as ReactTags } from 'react-tag-input';

import UIFormsCampoFormInput from '../../../UI/Forms/CampoForm/Input';
import UIFormsCampoFormSelect from '../../../UI/Forms/CampoForm/Select';
import { CODIGOS_TECLADO_ENTER } from '../../../../constants/Mensajes/CodigosTeclado';
import {
  TIPO_RESPUESTA_NUMERICO,
  TIPO_RESPUESTA_SELECCION,
  TIPO_RESPUESTA_SELECCION_MULTIPLE
} from '../../../../constants/Encuestas/TiposRespuestaPregunta';
import { preguntaSchema } from './Schema';


const EncuestasPreguntasPreguntaDetalle = (props) => (
  <Formik
    enableReinitialize
    onSubmit={props.handleGuardarPregunta}
    initialValues={props.valoresIniciales}
    validationSchema={preguntaSchema}
    validateOnChange
  >
    {formikProps => (
      <Form>
        <Row>
          <Col sm={12}>
            <UIFormsCampoFormInput
              id="pregunta"
              type="text"
              name="pregunta"
              label="Pregunta"
              labelClassName="requerido"
              placeholder=""
              autoComplete="off"
              error={formikProps.errors.pregunta ? formikProps.errors.pregunta : null}
              touched={formikProps.touched.pregunta ? formikProps.touched.pregunta : null}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <UIFormsCampoFormInput
              id="descripcion"
              type="textarea"
              name="descripcion"
              label="Descripción"
              placeholder=""
              autoComplete="off"
              error={formikProps.errors.descripcion ? formikProps.errors.descripcion : null}
              touched={formikProps.touched.descripcion ? formikProps.touched.descripcion : null}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <UIFormsCampoFormInput
              id="orden"
              type="number"
              name="orden"
              label="Orden"
              autoComplete="off"
              error={formikProps.errors.orden ? formikProps.errors.orden : null}
              touched={formikProps.touched.orden ? formikProps.touched.orden : null}
            />
          </Col>
          <Col sm={6}>
            <UIFormsCampoFormSelect
              id="obligatoria"
              name="obligatoria"
              label="Obligatoria"
              labelClassName="requerido"
              autoComplete="off"
              formikProps={formikProps}
              placeholder="Seleccione una opción"
              className="padding-0"
              options={props.OBLIGATORIA_OPCIONES}
              onChange={(e) => {
                formikProps.setFieldValue('obligatoria', e);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <UIFormsCampoFormSelect
              id="tipo"
              name="tipo"
              label="Tipo de respuesta"
              labelClassName="requerido"
              autoComplete="off"
              formikProps={formikProps}
              placeholder="Seleccione una opción"
              className="padding-0"
              options={props.TIPOS_RESPUESTA_PREGUNTA}
              onChange={(e) => {
                formikProps.setFieldValue('tipo', e);
              }}
            />
          </Col>
        </Row>

        {
          formikProps.values.tipo && formikProps.values.tipo.value === TIPO_RESPUESTA_NUMERICO &&
            <Row>
              <Col sm={12}>
                <hr />
                <h4>Configuración del campo de respuesta</h4>
                <Row>
                  <Col sm={6}>
                    <UIFormsCampoFormInput
                      id="minimo"
                      type="number"
                      name="minimo"
                      label="Mínimo"
                      placeholder=""
                      autoComplete="off"
                      error={formikProps.errors.minimo ? formikProps.errors.minimo : null}
                      touched={formikProps.touched.minimo ? formikProps.touched.minimo : null}
                    />
                  </Col>
                  <Col sm={6}>
                    <UIFormsCampoFormInput
                      id="maximo"
                      type="number"
                      name="maximo"
                      label="Máximo"
                      placeholder=""
                      autoComplete="off"
                      error={formikProps.errors.maximo ? formikProps.errors.maximo : null}
                      touched={formikProps.touched.maximo ? formikProps.touched.maximo : null}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
        }

        {
          formikProps.values.tipo
            && [TIPO_RESPUESTA_SELECCION, TIPO_RESPUESTA_SELECCION_MULTIPLE].includes(formikProps.values.tipo.value) &&
            <Row>
              <Col sm={12}>
                <hr />
                <h4>Configuración del campo de respuesta</h4>

                <label className="requerido" htmlFor="opciones">
                  Opciones de respuesta válida
                </label>
                <ReactTags
                  classNames={{
                    tags: 'h2',
                    tag: 'badge badge-pill badge-light',
                    tagInput: 'form-group',
                    tagInputField: 'form-control form-control-lg'
                  }}
                  placeholder="Agregue las opciones válidas separadas por coma"
                  name="opciones"
                  allowDragDrop={false}
                  inputFieldPosition="top"
                  tags={props.opcionesValidas}
                  delimiters={CODIGOS_TECLADO_ENTER}
                  handleDelete={props.handleEliminarOpcionValida}
                  handleAddition={props.handleAgregarOpcionValida}
                  handleInputBlur={(opcion) => props.handleAgregarOpcionValida({id: opcion, text: opcion})}
                />
              </Col>
            </Row>
        }

        <Row>
          <Col md={12}>
            <hr />
            <div className="text-center">
              <Button color="success" className="jr-btn" type="submit" disabled={props.guardando}>
                {
                  props.guardando ?
                    <FontAwesomeIcon icon="circle-notch" size="lg" spin className="mr-2" />
                    :
                    <FontAwesomeIcon icon="check" size="lg" className="mr-2" />
                }
                Guardar pregunta
              </Button>

              <Button color="link" className="jr-btn" type="button" onClick={props.handleCancelar}>
                Cancelar
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    )}
  </Formik>
);


export default EncuestasPreguntasPreguntaDetalle;
