import React from 'react';

import UIBreadcrumbContainer from '../../../components/UI/Breadcrumb/BreadcrumbContainer';
import ReportesEncuestasResultadoContainer from '../../../components/Reportes/Encuestas/Resultado/ResultadoContainer';


const ScreensReportesEncuestasResultado = () => (
  <div className="animated slideInUpTiny animation-duration-3">

    <UIBreadcrumbContainer></UIBreadcrumbContainer>

    <ReportesEncuestasResultadoContainer />
  </div>
);


export default ScreensReportesEncuestasResultado;
