import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import _ from 'lodash';
import { toast } from 'react-toastify';

import { setTituloPagina } from '../../../actions/Configuracion';
import EnviarSaldo from './EnviarSaldo';
import {
  enviarSaldo,
  enviandoSaldo,
  limpiarEnvioSaldo,
  obtenerClientes
} from '../../../actions/Transacciones/EnviarSaldo';


class EnviarSaldoContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      enviar_saldo: {
        cliente: '',
        unidades: '',
        unidades_confirmacion: '',
        observaciones: '',
      }
    };
  }

  limpiarEnvioSaldo = () => {
    this.setState({
      enviar_saldo: {
        cliente: '',
        unidades: '',
        unidades_confirmacion: '',
        observaciones: '',
      }
    });
  };

  cargarClientes = (termino) => {
    let filtros = {search: termino};
    this.props.actions.obtenerClientes({ filtros });
  };

  handleSubmit = (values, { resetForm }) => {
    let datos = _.cloneDeep(values);
    datos.cliente = datos.cliente.value;

    this.props.actions.enviandoSaldo(true);
    this.props.actions.enviarSaldo(datos);
    resetForm(this.state.enviar_saldo);
  };

  componentDidMount() {
    this.props.actions.setTituloPagina({titulo: 'Enviar saldo', icono: 'hand-holding-usd'});
  }

  componentDidUpdate() {
    // Verificar si existió algún error
    if (this.props.error) {
      if (this.props.mensaje) {
        toast.error(this.props.mensaje);
      }

      if (this.props.guardando) {
        this.props.actions.enviandoSaldo(false);
      }

    // Verificar si se envió el saldo correctamente
    } else if (this.props.guardado_exitoso) {
      toast.success(this.props.mensaje);
      this.limpiarEnvioSaldo();
      this.props.actions.limpiarEnvioSaldo();
    }
  }

  render() {
    return (
      <EnviarSaldo
        handleSubmit={this.handleSubmit}
        valoresIniciales={this.state.enviar_saldo}
        guardando={this.props.guardando}
        clientes={this.props.clientes}
        cargarClientes={this.cargarClientes}
      />
    );
  }
}


const mapStateToProps = ({ enviarSaldo }) => {
  return { ...enviarSaldo };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      setTituloPagina,
      enviarSaldo,
      enviandoSaldo,
      limpiarEnvioSaldo,
      obtenerClientes
    }, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EnviarSaldoContainer));
