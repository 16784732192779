import {
  OBTENER_PERFIL,
  OBTENER_PERFIL_EXITOSO,
  OBTENER_PERFIL_ERROR,
  GUARDAR_PERFIL,
  GUARDAR_PERFIL_EXITOSO,
  GUARDAR_PERFIL_ERROR,
  GUARDANDO_PERFIL
} from '../../constants/ActionTypes/Seguridad';


export const obtenerPerfil = (uuid) => {
  return {
    type: OBTENER_PERFIL,
    payload: uuid
  };
};

export const obtenerPerfilExitoso = (perfil) => {
  return {
    type: OBTENER_PERFIL_EXITOSO,
    payload: perfil
  };
};

export const obtenerPerfilError = (mensaje) => {
  return {
    type: OBTENER_PERFIL_ERROR,
    payload: mensaje
  };
};

export const guardarPerfil = (datos) => {
  return {
    type: GUARDAR_PERFIL,
    payload: datos
  };
};

export const guardarPerfilExitoso = (perfil) => {
  return {
    type: GUARDAR_PERFIL_EXITOSO,
    payload: perfil
  };
};

export const guardarPerfilError = (mensaje) => {
  return {
    type: GUARDAR_PERFIL_ERROR,
    payload: mensaje
  };
};

export const guardandoPerfil = (cargando) => {
  return {
    type: GUARDANDO_PERFIL,
    payload: cargando
  };
};
