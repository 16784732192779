import {
  OBTENER_CLIENTE_EXITOSO,
  OBTENER_CLIENTE_ERROR,
  GUARDAR_CLIENTE_EXITOSO,
  GUARDAR_CLIENTE_ERROR,
  GUARDANDO_CLIENTE,
  LIMPIAR_CLIENTE,
  ELIMINAR_CLIENTE_EXITOSO,
  ELIMINAR_CLIENTE_ERROR,
  OBTENER_CIUDADES_EXITOSO,
  OBTENER_CIUDADES_ERROR
} from '../../constants/ActionTypes/Clientes';


const clientesInicial = {
  cliente: {},
  ciudades: [],
  error: false,
  mensaje: null,
  guardando: false,
  editando: false,
  eliminando: false,
  guardado_exitoso: false
};

const detalleClientes = (state=clientesInicial, action) => {
  switch (action.type) {

  case OBTENER_CLIENTE_EXITOSO: {
    return {
      ...clientesInicial,
      cliente: action.payload,
      editando: true
    };
  }

  case OBTENER_CLIENTE_ERROR: {
    return {
      ...clientesInicial,
      error: true,
      mensaje: action.payload,
      editando: true
    };
  }

  case GUARDAR_CLIENTE_EXITOSO: {
    return {
      ...clientesInicial,
      cliente: action.payload.cliente,
      mensaje: action.payload.mensaje,
      guardado_exitoso: true
    };
  }

  case GUARDAR_CLIENTE_ERROR: {
    return {
      ...clientesInicial,
      error: true,
      mensaje: action.payload
    };
  }

  case GUARDANDO_CLIENTE: {
    return {
      ...clientesInicial,
      guardando: action.payload
    };
  }

  case LIMPIAR_CLIENTE: {
    return {
      ...clientesInicial
    };
  }

  case ELIMINAR_CLIENTE_EXITOSO: {
    return {
      ...clientesInicial
    };
  }

  case ELIMINAR_CLIENTE_ERROR: {
    return {
      ...clientesInicial,
      error: true,
      mensaje: action.payload
    };
  }

  case OBTENER_CIUDADES_EXITOSO: {
    return {
      ...clientesInicial,
      ciudades: action.payload
    };
  }

  case OBTENER_CIUDADES_ERROR: {
    return {
      ...clientesInicial,
      error: true,
      mensaje: action.payload
    };
  }

  default:
    return state;
  }
};


export default detalleClientes;
