export const TIPO_RESPUESTA_TEXTO_LIBRE = 'TL';
export const TIPO_RESPUESTA_NUMERICO = 'NM';
export const TIPO_RESPUESTA_SELECCION = 'SL';
export const TIPO_RESPUESTA_SELECCION_MULTIPLE = 'SM';

export const TIPO_RESPUESTA_TEXTO_LIBRE_LABEL = 'Texto Libre';
export const TIPO_RESPUESTA_NUMERICO_LABEL = 'Numerico';
export const TIPO_RESPUESTA_SELECCION_LABEL = 'Selección única';
export const TIPO_RESPUESTA_SELECCION_MULTIPLE_LABEL = 'Selección múltiple';


export const TIPOS_RESPUESTA_PREGUNTA = [
  {label: TIPO_RESPUESTA_TEXTO_LIBRE_LABEL, value: TIPO_RESPUESTA_TEXTO_LIBRE},
  {label: TIPO_RESPUESTA_NUMERICO_LABEL, value: TIPO_RESPUESTA_NUMERICO},
  {label: TIPO_RESPUESTA_SELECCION_LABEL, value: TIPO_RESPUESTA_SELECCION},
  {label: TIPO_RESPUESTA_SELECCION_MULTIPLE_LABEL, value: TIPO_RESPUESTA_SELECCION_MULTIPLE}
];
