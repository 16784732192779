import {
  OBTENER_DESTINATARIO,
  OBTENER_DESTINATARIO_EXITOSO,
  OBTENER_DESTINATARIO_ERROR,
  GUARDAR_DESTINATARIO,
  GUARDAR_DESTINATARIO_EXITOSO,
  GUARDAR_DESTINATARIO_ERROR,
  GUARDANDO_DESTINATARIO,
  ELIMINAR_DESTINATARIO,
  ELIMINAR_DESTINATARIO_EXITOSO,
  ELIMINAR_DESTINATARIO_ERROR
} from '../../constants/ActionTypes/Destinatarios';


export const obtenerDestinatario = (uuid) => {
  return {
    type: OBTENER_DESTINATARIO,
    payload: uuid
  };
};

export const obtenerDestinatarioExitoso = (destinatario) => {
  return {
    type: OBTENER_DESTINATARIO_EXITOSO,
    payload: destinatario
  };
};

export const obtenerDestinatarioError = (mensaje) => {
  return {
    type: OBTENER_DESTINATARIO_ERROR,
    payload: mensaje
  };
};

export const guardarDestinatario = (datos) => {
  return {
    type: GUARDAR_DESTINATARIO,
    payload: datos
  };
};

export const guardarDestinatarioExitoso = (destinatario) => {
  return {
    type: GUARDAR_DESTINATARIO_EXITOSO,
    payload: destinatario
  };
};

export const guardarDestinatarioError = (mensaje) => {
  return {
    type: GUARDAR_DESTINATARIO_ERROR,
    payload: mensaje
  };
};

export const guardandoDestinatario = (cargando) => {
  return {
    type: GUARDANDO_DESTINATARIO,
    payload: cargando
  };
};

export const eliminarDestinatario = (uuid) => {
  return {
    type: ELIMINAR_DESTINATARIO,
    payload: uuid
  };
};

export const eliminarDestinatarioExitoso = (mensaje) => {
  return {
    type: ELIMINAR_DESTINATARIO_EXITOSO,
    payload: mensaje
  };
};

export const eliminarDestinatarioError = (mensaje) => {
  return {
    type: ELIMINAR_DESTINATARIO_ERROR,
    payload: mensaje
  };
};
