import { put, call, all, fork, takeEvery } from 'redux-saga/effects';

import { OBTENER_LISTAS_DESTINATARIOS } from '../../constants/ActionTypes/ListasDestinatarios';
import { LISTAS_DESTINATARIOS_URL } from '../../constants/Urls/ListasDestinatarios';
import {
  obtenerListasDestinatariosExitoso,
  obtenerListasDestinatariosError
} from '../../actions/ListasDestinatarios/Listar';
import { httpFetch } from '../../utils/Http/Fetch';
import { setUnidades } from '../../actions/Configuracion';


const obtenerListasDestinatariosApi = async (filtros) => {
  let esc = encodeURIComponent;
  let params = Object.keys(filtros).map(filtro => esc(filtro) + '=' + esc(filtros[filtro])).join('&');

  return httpFetch(`${LISTAS_DESTINATARIOS_URL}?${params}`, 'GET')
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};

function* obtenerListasDestinatarios(action) {
  try {
    const { filtros } = action.payload;
    const listas = yield call(obtenerListasDestinatariosApi, filtros);
    const paginas = Math.ceil(listas.cantidad / filtros.tamano_pagina);

    if (typeof listas.saldo !== 'undefined') {
      yield put(setUnidades(listas.saldo));
    }

    yield put(obtenerListasDestinatariosExitoso({listas: listas.resultado, cantidad: listas.cantidad, paginas}));
  } catch (error) {
    yield put(obtenerListasDestinatariosError(error.message));
  }
}

export function* obtenerListasDestinatariosWatcher() {
  yield takeEvery (OBTENER_LISTAS_DESTINATARIOS, obtenerListasDestinatarios);
}

export default function* rootSaga() {
  yield all ([
    fork (obtenerListasDestinatariosWatcher)
  ]);
}
