import React from 'react';
// import PropTypes from 'prop-types';

import { MentionsInput, Mention } from 'react-mentions';
import { DropTarget } from 'react-dnd';


const campoTarget = {
  drop(props, monitor) {
    const item = monitor.getItem();
    const value = `${props.value}[${item.id}] `;

    props.onChange({ target: { value } });
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  };
}


const UIFormsCampoFormMensajeCampo = (props) => {
  const { connectDropTarget } = props;

  return connectDropTarget(
    <span>
      <MentionsInput
        className="gosms_mentions"
        markup="[__id__]"
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.onChange}
        readOnly={props.readOnly}
      >
        <Mention
          trigger="@"
          data={props.columnas}
          appendSpaceOnAdd={true}
          style={{ backgroundColor: '#8ec4ed' }}
        />
      </MentionsInput>
    </span>
  );
};


export default DropTarget('campoLista', campoTarget, collect)(UIFormsCampoFormMensajeCampo);
