import { put, call, all, fork, takeEvery } from 'redux-saga/effects';

import { sprintf } from 'sprintf-js';

import {
  OBTENER_ARCHIVO,
  GUARDAR_ARCHIVO,
  ELIMINAR_ARCHIVO
} from '../../constants/ActionTypes/Galeria';
import {
  GALERIA_ARCHIVOS_URL,
  GALERIA_ARCHIVO_URL
} from '../../constants/Urls/Galeria';
import {
  obtenerArchivoExitoso,
  obtenerArchivoError,
  guardarArchivoExitoso,
  guardarArchivoError,
  eliminarArchivoExitoso,
  eliminarArchivoError
} from '../../actions/Galeria/Detalle';
import { httpFetch } from '../../utils/Http/Fetch';
import { setUnidades } from '../../actions/Configuracion';


const obtenerArchivoApi = async (uuid) => {
  const url = sprintf(GALERIA_ARCHIVO_URL, { uuid });

  return httpFetch(url, 'GET')
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};

const guardarArchivoApi = async (datos) => {
  const verbo_http = datos.uuid ? 'PATCH' : 'POST';
  let url = GALERIA_ARCHIVOS_URL;

  if (datos.uuid) {
    url = sprintf(GALERIA_ARCHIVO_URL, {
      uuid: datos.uuid
    });
  }

  return httpFetch(url, verbo_http, datos)
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};

const eliminarArchivoApi = async (uuid) => {
  const url = sprintf(GALERIA_ARCHIVO_URL, {uuid});

  return httpFetch(url, 'DELETE')
    .catch((error) => {
      throw error;
    });
};


function* obtenerArchivo(action) {
  try {
    const destinatario = yield call(obtenerArchivoApi, action.payload);

    if (typeof destinatario.saldo !== 'undefined') {
      yield put(setUnidades(destinatario.saldo));
    }

    yield put(obtenerArchivoExitoso(destinatario));
  } catch (error) {
    yield put(obtenerArchivoError(error.message));
  }
}

function* guardarArchivo(action) {
  try {
    const archivo = yield call(guardarArchivoApi, action.payload);
    const accion = action.payload.uuid ? 'actualizado' : 'creado';
    const mensaje = `Archivo ${accion} correctamente.`;

    if (typeof archivo.saldo !== 'undefined') {
      yield put(setUnidades(archivo.saldo));
    }

    yield put(guardarArchivoExitoso({archivo, mensaje}));
  } catch (error) {
    yield put(guardarArchivoError(error.message));
  }
}

function* eliminarArchivo(action) {
  try {
    yield call(eliminarArchivoApi, action.payload);
    const mensaje = 'Archivo eliminado correctamente.';

    yield put(eliminarArchivoExitoso({ mensaje }));
  } catch (error) {
    yield put(eliminarArchivoError(error.message));
  }
}


export function* obtenerArchivoWatcher() {
  yield takeEvery (OBTENER_ARCHIVO, obtenerArchivo);
}

export function* guardarArchivoWatcher() {
  yield takeEvery (GUARDAR_ARCHIVO, guardarArchivo);
}

export function* eliminarArchivoWatcher() {
  yield takeEvery (ELIMINAR_ARCHIVO, eliminarArchivo);
}

export default function* rootSaga() {
  yield all ([
    fork (guardarArchivoWatcher),
    fork (obtenerArchivoWatcher),
    fork (eliminarArchivoWatcher)
  ]);
}
