import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ScreensReportesCampaniasListar from '../../../screens/Reportes/Campanias/CampaniasListar';
import ScreensReportesCampaniasDetalle from '../../../screens/Reportes/Campanias/CampaniasDetalle';


const ReportesCampanias = (props) => (
  <div className="app-wrapper">
    <Switch>
      <Route exact path={`${props.match.url}/`} component={ScreensReportesCampaniasListar}/>
      <Route path={`${props.match.url}/:uuid_campania`} component={ScreensReportesCampaniasDetalle} />
    </Switch>
  </div>
);


export default ReportesCampanias;
