
import {
  OBTENER_ENCUESTA_PREGUNTAS,
  OBTENER_ENCUESTA_PREGUNTAS_EXITOSO,
  OBTENER_ENCUESTA_PREGUNTAS_ERROR,
  CARGANDO_ENCUESTA_PREGUNTAS,
  OBTENER_ENCUESTA_PREGUNTA,
  OBTENER_ENCUESTA_PREGUNTA_EXITOSO,
  OBTENER_ENCUESTA_PREGUNTA_ERROR,
  GUARDAR_ENCUESTA_PREGUNTA,
  GUARDAR_ENCUESTA_PREGUNTA_EXITOSO,
  GUARDAR_ENCUESTA_PREGUNTA_ERROR,
  GUARDANDO_ENCUESTA_PREGUNTA,
  ELIMINAR_ENCUESTA_PREGUNTA,
  ELIMINAR_ENCUESTA_PREGUNTA_EXITOSO,
  ELIMINAR_ENCUESTA_PREGUNTA_ERROR,
  ELIMINANDO_ENCUESTA_PREGUNTA,
  EDITANDO_ENCUESTA_PREGUNTA
} from '../../constants/ActionTypes/Encuestas';


export const obtenerEncuestaPreguntas = (encuesta) => {
  return {
    type: OBTENER_ENCUESTA_PREGUNTAS,
    payload: encuesta
  };
};

export const obtenerEncuestaPreguntasExitoso = (preguntas) => {
  return {
    type: OBTENER_ENCUESTA_PREGUNTAS_EXITOSO,
    payload: preguntas
  };
};

export const obtenerEncuestaPreguntasError = (mensaje) => {
  return {
    type: OBTENER_ENCUESTA_PREGUNTAS_ERROR,
    payload: mensaje
  };
};

export const cargandoEncuestaPreguntas = (cargando) => {
  return {
    type: CARGANDO_ENCUESTA_PREGUNTAS,
    payload: cargando
  };
};

export const obtenerEncuestaPregunta = (uuid_encuesta, uuid) => {
  return {
    type: OBTENER_ENCUESTA_PREGUNTA,
    payload: { uuid_encuesta, uuid }
  };
};

export const obtenerEncuestaPreguntaExitoso = (pregunta) => {
  return {
    type: OBTENER_ENCUESTA_PREGUNTA_EXITOSO,
    payload: pregunta
  };
};

export const obtenerEncuestaPreguntaError = (mensaje) => {
  return {
    type: OBTENER_ENCUESTA_PREGUNTA_ERROR,
    payload: mensaje
  };
};

export const guardarEncuestaPregunta = (datos, encuesta) => {
  return {
    type: GUARDAR_ENCUESTA_PREGUNTA,
    payload: { datos, encuesta }
  };
};

export const guardarEncuestaPreguntaExitoso = (pregunta) => {
  return {
    type: GUARDAR_ENCUESTA_PREGUNTA_EXITOSO,
    payload: pregunta
  };
};

export const guardarEncuestaPreguntaError = (mensaje) => {
  return {
    type: GUARDAR_ENCUESTA_PREGUNTA_ERROR,
    payload: mensaje
  };
};

export const guardandoEncuestaPregunta = (cargando) => {
  return {
    type: GUARDANDO_ENCUESTA_PREGUNTA,
    payload: cargando
  };
};

export const eliminarEncuestaPregunta = (uuid) => {
  return {
    type: ELIMINAR_ENCUESTA_PREGUNTA,
    payload: uuid
  };
};

export const eliminarEncuestaPreguntaExitoso = (mensaje) => {
  return {
    type: ELIMINAR_ENCUESTA_PREGUNTA_EXITOSO,
    payload: mensaje
  };
};

export const eliminarEncuestaPreguntaError = (mensaje) => {
  return {
    type: ELIMINAR_ENCUESTA_PREGUNTA_ERROR,
    payload: mensaje
  };
};

export const eliminandoEncuestaPregunta = (eliminando) => {
  return {
    type: ELIMINANDO_ENCUESTA_PREGUNTA,
    payload: eliminando
  };
};

export const editandoEncuestaPregunta = (editando) => {
  return {
    type: EDITANDO_ENCUESTA_PREGUNTA,
    payload: editando
  };
};
