import React from 'react';


import UIBreadcrumbContainer from '../../../components/UI/Breadcrumb/BreadcrumbContainer';
import ReportesMensajesListarContainer from '../../../components/Reportes/Mensajes/Listar/ListarContainer';


const ScreensReportesMensajes = () => (
  <div className="animated slideInUpTiny animation-duration-3">

    <UIBreadcrumbContainer></UIBreadcrumbContainer>

    <ReportesMensajesListarContainer />
  </div>
);


export default ScreensReportesMensajes;
