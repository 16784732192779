import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { setTituloPagina } from '../../../actions/Configuracion';
import SeguridadTerminosCondicionesDetalle from './Detalle';


class SeguridadTerminosCondicionesDetalleContainer extends Component {

  componentDidMount() {
    this.props.actions.setTituloPagina({titulo: 'Términos y condiciones', icono: 'info-circle'});
  }

  render() {
    return (
      <SeguridadTerminosCondicionesDetalle />
    );
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      setTituloPagina
    }, dispatch)
  };
};

export default connect(null, mapDispatchToProps)(SeguridadTerminosCondicionesDetalleContainer);
