import { put, call, all, fork, takeEvery } from 'redux-saga/effects';

import { sprintf } from 'sprintf-js';

import {
  OBTENER_ENCUESTA,
  GUARDAR_ENCUESTA,
  ELIMINAR_ENCUESTA
} from '../../constants/ActionTypes/Encuestas';
import {
  ENCUESTAS_URL,
  ENCUESTA_URL
} from '../../constants/Urls/Encuestas';
import {
  obtenerEncuestaExitoso,
  obtenerEncuestaError,
  guardarEncuestaExitoso,
  guardarEncuestaError,
  eliminarEncuestaExitoso,
  eliminarEncuestaError
} from '../../actions/Encuestas/Detalle';
import { httpFetch } from '../../utils/Http/Fetch';
import { setUnidades } from '../../actions/Configuracion';


const obtenerEncuestaApi = async (uuid) => {
  const url = sprintf(ENCUESTA_URL, { uuid });

  return httpFetch(url, 'GET')
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};

const guardarEncuestaApi = async (datos) => {
  const verbo_http = datos.uuid ? 'PUT' : 'POST';
  let url = ENCUESTAS_URL;

  if (datos.uuid) {
    url = sprintf(ENCUESTA_URL, {
      uuid: datos.uuid
    });
  }

  return httpFetch(url, verbo_http, datos)
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};

const eliminarEncuestaApi = async (uuid) => {
  const url = sprintf(ENCUESTA_URL, {uuid});

  return httpFetch(url, 'DELETE')
    .catch((error) => {
      throw error;
    });
};


function* obtenerEncuesta(action) {
  try {
    const destinatario = yield call(obtenerEncuestaApi, action.payload);

    if (typeof destinatario.saldo !== 'undefined') {
      yield put(setUnidades(destinatario.saldo));
    }

    yield put(obtenerEncuestaExitoso(destinatario));
  } catch (error) {
    yield put(obtenerEncuestaError(error.message));
  }
}

function* guardarEncuesta(action) {
  try {
    const destinatario = yield call(guardarEncuestaApi, action.payload);
    const accion = action.payload.uuid ? 'actualizada' : 'creada';
    const mensaje = `Encuesta ${accion} correctamente.`;

    if (typeof destinatario.saldo !== 'undefined') {
      yield put(setUnidades(destinatario.saldo));
    }

    yield put(guardarEncuestaExitoso({destinatario, mensaje}));
  } catch (error) {
    yield put(guardarEncuestaError(error.message));
  }
}

function* eliminarEncuesta(action) {
  try {
    yield call(eliminarEncuestaApi, action.payload);
    const mensaje = 'Encuesta eliminada correctamente.';

    yield put(eliminarEncuestaExitoso({ mensaje }));
  } catch (error) {
    yield put(eliminarEncuestaError(error.message));
  }
}


export function* obtenerEncuestaWatcher() {
  yield takeEvery (OBTENER_ENCUESTA, obtenerEncuesta);
}

export function* guardarEncuestaWatcher() {
  yield takeEvery (GUARDAR_ENCUESTA, guardarEncuesta);
}

export function* eliminarEncuestaWatcher() {
  yield takeEvery (ELIMINAR_ENCUESTA, eliminarEncuesta);
}

export default function* rootSaga() {
  yield all ([
    fork (guardarEncuestaWatcher),
    fork (obtenerEncuestaWatcher),
    fork (eliminarEncuestaWatcher)
  ]);
}
