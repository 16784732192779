import { put, call, all, fork, takeEvery } from 'redux-saga/effects';

import { OBTENER_CAMPANIAS } from '../../constants/ActionTypes/Campanias';
import { CAMPANIAS_URL } from '../../constants/Urls/Campanias';
import { obtenerCampaniasExitoso, obtenerCampaniasError } from '../../actions/Campanias/Listar';
import { httpFetch } from '../../utils/Http/Fetch';
import { setUnidades } from '../../actions/Configuracion';


const obtenerCampaniasApi = async (filtros) => {
  let esc = encodeURIComponent;
  let params = Object.keys(filtros).map(filtro => esc(filtro) + '=' + esc(filtros[filtro])).join('&');

  return httpFetch(`${CAMPANIAS_URL}?${params}`, 'GET')
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};


function* obtenerCampanias(action) {
  try {
    const { filtros } = action.payload;
    const campanias = yield call(obtenerCampaniasApi, filtros);
    const paginas = Math.ceil(campanias.cantidad / filtros.tamano_pagina);

    if (typeof campanias.saldo !== 'undefined') {
      yield put(setUnidades(campanias.saldo));
    }

    yield put(obtenerCampaniasExitoso({campanias: campanias.resultado, cantidad: campanias.cantidad, paginas}));
  } catch (error) {
    yield put(obtenerCampaniasError(error.message));
  }
}


export function* obtenerCampaniasWatcher() {
  yield takeEvery (OBTENER_CAMPANIAS, obtenerCampanias);
}

export default function* rootSaga() {
  yield all ([
    fork (obtenerCampaniasWatcher)
  ]);
}
