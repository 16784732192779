import React from 'react';

import { Row } from 'reactstrap';

import UIBreadcrumbContainer from '../../components/UI/Breadcrumb/BreadcrumbContainer';
import UICajaContenido from '../../components/UI/CajaContenido/CajaContenido';
import UsuariosDetalleContainer from '../../components/Usuarios/Detalle/DetalleContainer';


const ScreensUsuariosDetalle = () => (
  <div className="animated slideInUpTiny animation-duration-3">
    <UIBreadcrumbContainer></UIBreadcrumbContainer>

    <div className="app-module">
      <div className="module-box">
        <Row>
          <UICajaContenido
            estiloCaja="border-radius-0"
            estilos="col-lg-12"
          >
            <UsuariosDetalleContainer />
          </UICajaContenido>
        </Row>
      </div>
    </div>
  </div>
);


export default ScreensUsuariosDetalle;
