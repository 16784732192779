import {
  OBTENER_CAMPANIA_PROGRAMADA_EXITOSO,
  OBTENER_CAMPANIA_PROGRAMADA_ERROR,
  ENVIAR_CAMPANIA_EXITOSO,
  ENVIAR_CAMPANIA_ERROR,
  ENVIANDO_CAMPANIA,
  CANCELAR_CAMPANIA_PROGRAMADA_EXITOSO,
  CANCELAR_CAMPANIA_PROGRAMADA_ERROR,
  OBTENER_CAMPANIAS_EXITOSO,
  OBTENER_CAMPANIAS_ERROR,
  CARGANDO_CAMPANIAS
} from '../../constants/ActionTypes/EnviarCampanias';


const campaniasInicial = {
  campania: {},
  campanias: [],
  error: false,
  mensaje: null,
  enviando: false,
  editando: false,
  eliminando: false,
  guardado_exitoso: false,
  cargando_campanias: false,
};

const detalleCampanias = (state=campaniasInicial, action) => {
  switch (action.type) {

  case OBTENER_CAMPANIA_PROGRAMADA_EXITOSO: {
    return {
      ...campaniasInicial,
      campania: action.payload,
      campanias: state.campanias,
      editando: true
    };
  }

  case OBTENER_CAMPANIA_PROGRAMADA_ERROR: {
    return {
      ...campaniasInicial,
      error: true,
      mensaje: action.payload,
      editando: true
    };
  }

  case ENVIAR_CAMPANIA_EXITOSO: {
    return {
      ...campaniasInicial,
      campania: action.payload.campania,
      mensaje: action.payload.mensaje,
      guardado_exitoso: true
    };
  }

  case ENVIAR_CAMPANIA_ERROR: {
    return {
      ...campaniasInicial,
      campanias: state.campanias,
      error: true,
      mensaje: action.payload
    };
  }

  case ENVIANDO_CAMPANIA: {
    return {
      ...campaniasInicial,
      campanias: state.campanias,
      enviando: action.payload
    };
  }

  case CANCELAR_CAMPANIA_PROGRAMADA_EXITOSO: {
    return {
      ...campaniasInicial,
      mensaje: action.payload.mensaje,
      editando: true,
      eliminando: true
    };
  }

  case CANCELAR_CAMPANIA_PROGRAMADA_ERROR: {
    return {
      ...campaniasInicial,
      campanias: state.campanias,
      error: true,
      mensaje: action.payload
    };
  }

  case OBTENER_CAMPANIAS_EXITOSO: {
    return {
      ...campaniasInicial,
      campania: state.campania,
      campanias: action.payload,
    };
  }

  case OBTENER_CAMPANIAS_ERROR: {
    return {
      ...campaniasInicial,
      error: true,
      campania: state.campania,
      mensaje: action.payload,
    };
  }

  case CARGANDO_CAMPANIAS: {
    return {
      ...campaniasInicial,
      campania: state.campania,
      campanias: state.campanias,
      cargando_campanias: action.payload
    };
  }

  default:
    return state;
  }
};


export default detalleCampanias;
