import {
  OBTENER_GALERIA,
  OBTENER_GALERIA_EXITOSO,
  OBTENER_GALERIA_ERROR,
  CARGANDO_GALERIA
} from '../../constants/ActionTypes/Galeria';


export const obtenerGaleria = (params) => {
  return {
    type: OBTENER_GALERIA,
    payload: params
  };
};

export const obtenerGaleriaExitoso = (galeria) => {
  return {
    type: OBTENER_GALERIA_EXITOSO,
    payload: galeria
  };
};

export const obtenerGaleriaError = (mensaje) => {
  return {
    type: OBTENER_GALERIA_ERROR,
    payload: mensaje
  };
};

export const cargandoGaleria = (cargando) => {
  return {
    type: CARGANDO_GALERIA,
    payload: cargando
  };
};
