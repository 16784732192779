import {
  OBTENER_MENSAJES,
  OBTENER_MENSAJES_EXITOSO,
  OBTENER_MENSAJES_ERROR,
  CARGANDO_MENSAJES
} from '../../constants/ActionTypes/Mensajes';


export const obtenerMensajes = (params) => {
  return {
    type: OBTENER_MENSAJES,
    payload: params
  };
};

export const obtenerMensajesExitoso = (mensajes) => {
  return {
    type: OBTENER_MENSAJES_EXITOSO,
    payload: mensajes
  };
};

export const obtenerMensajesError = (mensaje) => {
  return {
    type: OBTENER_MENSAJES_ERROR,
    payload: mensaje
  };
};

export const cargandoMensajes = (cargando) => {
  return {
    type: CARGANDO_MENSAJES,
    payload: cargando
  };
};
