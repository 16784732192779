import React from 'react';
import { Route, Redirect } from 'react-router-dom';


const RutaRestringida = ({component: Component, usuario, ...resto}) => (
  <Route
    {...resto}
    render={
      props =>
        usuario
          ? <Component {...props} />
          : <Redirect
            to={{
              pathname: '/login',
              state: {from: props.location}
            }}
          />}
  />
);


export default RutaRestringida;
