
export const CODIGO_TECLADO_COMA = 188;
export const CODIGO_TECLADO_PUNTO_Y_COMA = 186;
export const CODIGO_TECLADO_ENTER = 13;
export const CODIGO_TECLADO_TAB = 9;
export const CODIGO_TECLADO_ESPACIO = 32;

export const CODIGOS_TECLADO = [
  CODIGO_TECLADO_COMA,
  CODIGO_TECLADO_PUNTO_Y_COMA,
  CODIGO_TECLADO_ENTER,
  CODIGO_TECLADO_TAB,
  CODIGO_TECLADO_ESPACIO
];

export const CODIGOS_TECLADO_ENTER = [
  CODIGO_TECLADO_ENTER,
  CODIGO_TECLADO_TAB
];
