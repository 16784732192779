import React from 'react';
// import PropTypes from 'prop-types';

import { Field } from 'formik';
import Select from 'react-select';
import {
  FormGroup,
  Label
} from 'reactstrap';


const UIFormsCampoFormSelect = (props) => (
  <FormGroup className={props.groupClassName}>
    {
      props.label ?
        <Label for={props.id} className={props.labelClassName}>{props.label}</Label>
        :
        null
    }

    <Field
      name={props.name}
      render={({ field }) => (
        <Select
          {...field}
          isDisabled={props.isDisabled}
          options={props.options}
          className={`form-control form-control-lg ${props.className} ` + (props.error ? 'is-invalid' : '')}
          placeholder={props.placeholder}
          onChange={props.onChange ? props.onChange : (value) => props.formikProps.setFieldValue(props.name, value)}
          onBlur={() => props.formikProps.setFieldTouched(props.name, true)}
        />
      )}
    />

    {
      props.error ?
        <div className="invalid-tooltip">{props.error}</div>
        :
        null
    }
  </FormGroup>
);


UIFormsCampoFormSelect.defaultProps = {
  className: ''
};

export default UIFormsCampoFormSelect;
