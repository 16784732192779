import React from 'react';
import { NavLink } from 'react-router-dom';

import { Col, Row, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Form } from 'formik';

import { ESTADOS_USUARIO } from '../../../constants/Usuarios/Estados';
import UIFormsCampoFormInput from '../../UI/Forms/CampoForm/Input';
import UIFormsCampoFormSelect from '../../UI/Forms/CampoForm/Select';
import { usuarioSchema } from './Schema';


const UsuariosDetalle = (props) => (
  <Formik
    enableReinitialize
    initialValues={props.valoresIniciales}
    onSubmit={props.handleSubmit}
    validationSchema={usuarioSchema}
    validateOnChange
  >
    {formikProps => (
      <Form>
        <Row>
          <Col sm={12}>
            <Row form>
              <Col md={4}>
                <UIFormsCampoFormInput
                  id="numero_documento"
                  type="number"
                  name="numero_documento"
                  label="Número de documento"
                  labelClassName="requerido"
                  placeholder=""
                  autoComplete="off"
                  error={formikProps.errors.numero_documento ? formikProps.errors.numero_documento : null}
                  touched={formikProps.touched.numero_documento ? formikProps.touched.numero_documento : null}
                />
              </Col>
              <Col md={4}>
                <UIFormsCampoFormInput
                  id="first_name"
                  type="text"
                  name="first_name"
                  label="Nombres"
                  labelClassName="requerido"
                  placeholder=""
                  autoComplete="off"
                  error={formikProps.errors.first_name ? formikProps.errors.first_name : null}
                  touched={formikProps.touched.first_name ? formikProps.touched.first_name : null}
                />
              </Col>
              <Col md={4}>
                <UIFormsCampoFormInput
                  id="last_name"
                  type="text"
                  name="last_name"
                  label="Apellidos"
                  placeholder=""
                  autoComplete="off"
                  error={formikProps.errors.last_name ? formikProps.errors.last_name : null}
                  touched={formikProps.touched.last_name ? formikProps.touched.last_name : null}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Row form>
              <Col md={4}>
                <UIFormsCampoFormInput
                  id="email"
                  type="email"
                  name="email"
                  label="Correo electrónico"
                  labelClassName="requerido"
                  placeholder=""
                  autoComplete="off"
                  error={formikProps.errors.email ? formikProps.errors.email : null}
                  touched={formikProps.touched.email ? formikProps.touched.email : null}
                />
              </Col>
              <Col md={4}>
                <UIFormsCampoFormSelect
                  id="activo"
                  name="activo"
                  label="Estado"
                  labelClassName="requerido"
                  autoComplete="off"
                  formikProps={formikProps}
                  placeholder="Seleccione una opción"
                  className="padding-0"
                  options={ESTADOS_USUARIO}
                />
              </Col>
              {
                formikProps.values.uuid &&
                <Col md={4}>
                  <div className="form-group">
                    <label></label>
                    <div className="text-center">
                      <Button
                        color="info"
                        className="jr-btn"
                        type="button"
                        onClick={() => props.handleGenerarClave(formikProps.values.uuid)}>
                        Generar nueva clave
                      </Button>
                    </div>
                  </div>
                </Col>
              }
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <hr/>
            <div className="text-center">
              <Button color="success" className="jr-btn" type="submit" disabled={props.guardando}>
                {
                  props.guardando ?
                    <FontAwesomeIcon icon="circle-notch" size="lg" spin className="mr-2" />
                    :
                    <FontAwesomeIcon icon="check" size="lg" className="mr-2" />
                }
                Guardar usuario
              </Button>
              <NavLink className="jr-btn btn btn-default" to='/usuarios'>
                <span>Regresar al listado</span>
              </NavLink>

              {
                formikProps.values.uuid &&
                <Button
                  color="danger"
                  className="jr-btn fa-pull-right"
                  type="button"
                  onClick={() => props.handleEliminar(formikProps.values.uuid)}>
                  <FontAwesomeIcon icon="ban" size="lg" className="mr-2"/>
                  Eliminar usuario
                </Button>
              }
            </div>
          </Col>
        </Row>
      </Form>
    )}
  </Formik>
);


export default UsuariosDetalle;
