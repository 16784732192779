import { put, call, all, fork, takeEvery } from 'redux-saga/effects';

import { sprintf } from 'sprintf-js';

import {
  OBTENER_USUARIO,
  GUARDAR_USUARIO,
  ELIMINAR_USUARIO,
  GENERAR_CLAVE_USUARIO
} from '../../constants/ActionTypes/Usuarios';
import {
  USUARIOS_URL,
  USUARIO_URL,
  GENERAR_CLAVE_URL
} from '../../constants/Urls/Usuarios';
import {
  obtenerUsuarioExitoso,
  obtenerUsuarioError,
  guardarUsuarioExitoso,
  guardarUsuarioError,
  eliminarUsuarioExitoso,
  eliminarUsuarioError,
  generarClaveUsuarioExitoso,
  generarClaveUsuarioError
} from '../../actions/Usuarios/Detalle';
import { httpFetch } from '../../utils/Http/Fetch';
import {setUnidades} from "../../actions/Configuracion";


const obtenerUsuarioApi = async (uuid) => {
  const url = sprintf(USUARIO_URL, { uuid });

  return httpFetch(url, 'GET')
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};

const guardarUsuarioApi = async (datos) => {
  const verbo_http = datos.uuid ? 'PUT' : 'POST';
  let url = USUARIOS_URL;

  if (datos.uuid) {
    url = sprintf(USUARIO_URL, {
      uuid: datos.uuid
    });
  }

  return httpFetch(url, verbo_http, datos)
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};

const eliminarUsuarioApi = async (uuid) => {
  const url = sprintf(USUARIO_URL, {uuid});

  return httpFetch(url, 'DELETE')
    .catch((error) => {
      throw error;
    });
};

const generarClaveUsuarioApi = async (uuid) => {
  const url = sprintf(GENERAR_CLAVE_URL, {uuid});

  return httpFetch(url, 'GET')
    .catch((error) => {
      throw error;
    });
};


function* obtenerUsuario(action) {
  try {
    const usuario = yield call(obtenerUsuarioApi, action.payload);

    if (typeof usuario.saldo !== 'undefined') {
      yield put(setUnidades(usuario.saldo));
    }
    yield put(obtenerUsuarioExitoso(usuario));
  } catch (error) {
    yield put(obtenerUsuarioError(error.message));
  }
}

function* guardarUsuario(action) {
  try {
    const usuario = yield call(guardarUsuarioApi, action.payload);
    const accion = action.payload.uuid ? 'actualizado' : 'creado';
    const mensaje = `Usuario ${accion} correctamente.`;

    if (typeof usuario.saldo !== 'undefined') {
      yield put(setUnidades(usuario.saldo));
    }

    yield put(guardarUsuarioExitoso({usuario, mensaje}));
  } catch (error) {
    yield put(guardarUsuarioError(error.message));
  }
}

function* eliminarUsuario(action) {
  try {
    yield call(eliminarUsuarioApi, action.payload);
    const mensaje = 'Usuario eliminado correctamente.';

    yield put(eliminarUsuarioExitoso({ mensaje }));
  } catch (error) {
    yield put(eliminarUsuarioError(error.message));
  }
}

function* generarClaveUsuario(action) {
  try {
    yield call(generarClaveUsuarioApi, action.payload);
    const mensaje = 'Se ha generado una nueva clave para el usuario.';

    yield put(generarClaveUsuarioExitoso({ mensaje }));
  } catch (error) {
    yield put(generarClaveUsuarioError(error.message));
  }
}


export function* obtenerUsuarioWatcher() {
  yield takeEvery (OBTENER_USUARIO, obtenerUsuario);
}

export function* guardarUsuarioWatcher() {
  yield takeEvery (GUARDAR_USUARIO, guardarUsuario);
}

export function* eliminarUsuarioWatcher() {
  yield takeEvery (ELIMINAR_USUARIO, eliminarUsuario);
}

export function* generarClaveUsuarioWatcher() {
  yield takeEvery (GENERAR_CLAVE_USUARIO, generarClaveUsuario);
}

export default function* rootSaga() {
  yield all ([
    fork (guardarUsuarioWatcher),
    fork (obtenerUsuarioWatcher),
    fork (eliminarUsuarioWatcher),
    fork (generarClaveUsuarioWatcher),
  ]);
}
