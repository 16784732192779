import {
  OBTENER_ENCUESTA_PREGUNTA_EXITOSO,
  OBTENER_ENCUESTA_PREGUNTA_ERROR,
  GUARDAR_ENCUESTA_PREGUNTA_EXITOSO,
  GUARDAR_ENCUESTA_PREGUNTA_ERROR,
  GUARDANDO_ENCUESTA_PREGUNTA,
  ELIMINAR_ENCUESTA_PREGUNTA_EXITOSO,
  ELIMINAR_ENCUESTA_PREGUNTA_ERROR,
  ELIMINANDO_ENCUESTA_PREGUNTA,
  EDITANDO_ENCUESTA_PREGUNTA
} from '../../../constants/ActionTypes/Encuestas';


const encuestaPreguntaInicial = {
  pregunta: {},
  error: false,
  mensaje: null,
  guardando: false,
  editando: false,
  eliminando: false,
  guardado_exitoso: false
};

const detalleEncuestaPregunta = (state=encuestaPreguntaInicial, action) => {
  switch (action.type) {

  case OBTENER_ENCUESTA_PREGUNTA_EXITOSO: {
    return {
      ...encuestaPreguntaInicial,
      pregunta: action.payload,
      editando: true
    };
  }

  case OBTENER_ENCUESTA_PREGUNTA_ERROR: {
    return {
      ...encuestaPreguntaInicial,
      error: true,
      mensaje: action.payload,
      editando: true
    };
  }

  case GUARDAR_ENCUESTA_PREGUNTA_EXITOSO: {
    return {
      ...encuestaPreguntaInicial,
      pregunta: action.payload.pregunta,
      mensaje: action.payload.mensaje,
      guardado_exitoso: true
    };
  }

  case GUARDAR_ENCUESTA_PREGUNTA_ERROR: {
    return {
      ...encuestaPreguntaInicial,
      error: true,
      mensaje: action.payload
    };
  }

  case GUARDANDO_ENCUESTA_PREGUNTA: {
    return {
      ...encuestaPreguntaInicial,
      guardando: action.payload
    };
  }

  case ELIMINAR_ENCUESTA_PREGUNTA_EXITOSO: {
    return {
      ...encuestaPreguntaInicial,
      mensaje: action.payload.mensaje,
      editando: true,
      eliminando: true
    };
  }

  case ELIMINAR_ENCUESTA_PREGUNTA_ERROR: {
    return {
      ...encuestaPreguntaInicial,
      error: true,
      mensaje: action.payload
    };
  }

  case ELIMINANDO_ENCUESTA_PREGUNTA: {
    return {
      ...encuestaPreguntaInicial,
      pregunta: state.payload,
      eliminando: action.payload
    };
  }

  case EDITANDO_ENCUESTA_PREGUNTA: {
    return {
      ...encuestaPreguntaInicial,
      pregunta: state.payload,
      editando: action.payload
    };
  }

  default:
    return state;
  }
};


export default detalleEncuestaPregunta;
