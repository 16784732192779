import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ScreensUsuariosListar from '../../screens/Usuarios/UsuariosListar';
import ScreensUsuariosDetalle from '../../screens/Usuarios/UsuariosDetalle';


const Usuarios = (props) => (
  <div className="app-wrapper">
    <Switch>
      <Route exact path={`${props.match.url}/`} component={ScreensUsuariosListar}/>
      <Route path={`${props.match.url}/detalle/:uuid_usuario`} component={ScreensUsuariosDetalle}/>
      <Route path={`${props.match.url}/detalle`} component={ScreensUsuariosDetalle}/>
    </Switch>
  </div>
);


export default Usuarios;
