import { put, call, all, fork, takeEvery } from 'redux-saga/effects';

import { OBTENER_CLAVES } from '../../../constants/ActionTypes/Claves';
import { DOBLE_VIAS_URL } from '../../../constants/Urls/Clientes';
import { obtenerClavesExitoso, obtenerClavesError } from '../../../actions/DobleVia/Claves/Listar';
import { httpFetch } from '../../../utils/Http/Fetch';
import { setUnidades } from '../../../actions/Configuracion';


const obtenerClavesApi = async (filtros) => {
  let esc = encodeURIComponent;
  let params = Object.keys(filtros).map(filtro => esc(filtro) + '=' + esc(filtros[filtro])).join('&');

  return httpFetch(`${DOBLE_VIAS_URL}?${params}`, 'GET')
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};


function* obtenerClaves(action) {
  try {
    const { filtros } = action.payload;
    const claves = yield call(obtenerClavesApi, filtros);
    const paginas = Math.ceil(claves.cantidad / filtros.tamano_pagina);

    if (typeof claves.saldo !== 'undefined') {
      yield put(setUnidades(claves.saldo));
    }

    yield put(obtenerClavesExitoso({claves: claves.resultado, cantidad: claves.cantidad, paginas}));
  } catch (error) {
    yield put(obtenerClavesError(error.message));
  }
}


export function* obtenerClavesWatcher() {
  yield takeEvery (OBTENER_CLAVES, obtenerClaves);
}

export default function* rootSaga() {
  yield all ([
    fork (obtenerClavesWatcher)
  ]);
}
