import React from 'react';

import UIBreadcrumbContainer from '../../../components/UI/Breadcrumb/BreadcrumbContainer';
import ReportesCampaniasDetalleContainer from '../../../components/Reportes/Campanias/Detalle/DetalleContainer';


const ScreensReportesCampanias = () => (
  <div className="animated slideInUpTiny animation-duration-3">

    <UIBreadcrumbContainer></UIBreadcrumbContainer>

    <ReportesCampaniasDetalleContainer />
  </div>
);


export default ScreensReportesCampanias;
