import * as Yup from 'yup';

import {
  CAMPO_EMAIL,
  CAMPO_REQUERIDO,
  CAMPO_NUMERO_POSITIVO,
  CAMPO_NUMERO,
  CAMPO_LONGITUD_MAXIMA
} from '../../../constants/Forms/Validacion';
import { TIPO_PERSONA_NATURAL } from '../../../constants/Clientes/TiposPersona';

export const clienteSchema = Yup.object().shape({
  uuid: Yup.string()
    .notRequired(),

  nombres: Yup.string()
    .required(CAMPO_REQUERIDO),

  apellidos: Yup.string()
    .when('tipo_persona', (tipo_persona, schema) => {
      return tipo_persona.value === TIPO_PERSONA_NATURAL ?
        schema.required(CAMPO_REQUERIDO)
        :
        schema.notRequired();
    }),

  tipo_persona: Yup.object({
    label: Yup.string().required(CAMPO_REQUERIDO),
    value: Yup.string().required(CAMPO_REQUERIDO)
  }),

  tipo_documento: Yup.object({
    label: Yup.string().required(CAMPO_REQUERIDO),
    value: Yup.string().required(CAMPO_REQUERIDO)
  }),

  numero_documento: Yup.number()
    .typeError(CAMPO_NUMERO)
    .positive(CAMPO_NUMERO_POSITIVO)
    .required(CAMPO_REQUERIDO),

  email: Yup.string()
    .email(CAMPO_EMAIL)
    .when('uuid', (uuid, schema) => {
      return uuid ?
        schema.notRequired()
        :
        schema.required(CAMPO_REQUERIDO);
    }),

  celular: Yup.string()
    .max(10, CAMPO_LONGITUD_MAXIMA)
    .required(CAMPO_REQUERIDO),

  ciudad: Yup.object({
    label: Yup.string().required(CAMPO_REQUERIDO),
    value: Yup.string().required(CAMPO_REQUERIDO)
  }),

  direccion: Yup.string()
    .required(CAMPO_REQUERIDO),

  precio_formateado: Yup.string()
    .typeError(CAMPO_NUMERO)
    .required(CAMPO_REQUERIDO),
});
