import {
  OBTENER_CAMPANIAS_PROGRAMADAS,
  OBTENER_CAMPANIAS_PROGRAMADAS_EXITOSO,
  OBTENER_CAMPANIAS_PROGRAMADAS_ERROR,
  CARGANDO_CAMPANIAS_PROGRAMADAS
} from '../../constants/ActionTypes/EnviarCampanias';


export const obtenerCampaniasProgramadas = (params) => {
  return {
    type: OBTENER_CAMPANIAS_PROGRAMADAS,
    payload: params
  };
};

export const obtenerCampaniasProgramadasExitoso = (campanias) => {
  return {
    type: OBTENER_CAMPANIAS_PROGRAMADAS_EXITOSO,
    payload: campanias
  };
};

export const obtenerCampaniasProgramadasError = (mensaje) => {
  return {
    type: OBTENER_CAMPANIAS_PROGRAMADAS_ERROR,
    payload: mensaje
  };
};

export const cargandoCampaniasProgramadas = (cargando) => {
  return {
    type: CARGANDO_CAMPANIAS_PROGRAMADAS,
    payload: cargando
  };
};
