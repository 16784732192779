import React from 'react';
import { NavLink } from 'react-router-dom';

import { Col, Row, Button, Badge, FormText } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Form } from 'formik';
import moment from 'moment';
// import * as Survey from 'survey-react';

import UIFormsCampoFormInput from '../../UI/Forms/CampoForm/Input';
import { encuestaSchema } from './Schema';


const EncuestasDetalle = (props) => (
  <Formik
    enableReinitialize
    initialValues={props.valoresIniciales}
    onSubmit={props.handleSubmit}
    validationSchema={encuestaSchema}
    validateOnChange
  >
    {formikProps => (
      <Form>
        <Row>
          <Col sm={12}>
            <Row form>
              <Col md={6}>
                <UIFormsCampoFormInput
                  id="nombre"
                  type="text"
                  name="nombre"
                  label="Nombre"
                  labelClassName="requerido"
                  placeholder=""
                  autoComplete="off"
                  error={formikProps.errors.nombre ? formikProps.errors.nombre : null}
                  touched={formikProps.touched.nombre ? formikProps.touched.nombre : null}
                />
                {
                  formikProps.values.uuid &&
                    <FormText color="muted">
                      URL Corto <b>{formikProps.values.url_corto.url_corto}</b>.
                    </FormText>
                }
              </Col>
              <Col md={6}>
                <UIFormsCampoFormInput
                  id="tiempo_validez"
                  type="number"
                  name="tiempo_validez"
                  label="Tiempo de validez (en días)"
                  labelClassName="requerido"
                  groupClassName="mb-0"
                  placeholder=""
                  autoComplete="off"
                  error={formikProps.errors.tiempo_validez ? formikProps.errors.tiempo_validez : null}
                  touched={formikProps.touched.tiempo_validez ? formikProps.touched.tiempo_validez : null}
                />
                {
                  formikProps.values.uuid &&
                    <FormText color="muted">
                      {
                        formikProps.values.url_corto.vencido ?
                          <span>
                            Venció el <b>{moment(formikProps.values.url_corto.fecha_vencimiento).format('LL')}</b>.
                            <Badge color="danger" pill className="ml-1">VENCIDO</Badge>
                          </span>
                          :
                          <span>
                            Vence el <b>{moment(formikProps.values.url_corto.fecha_vencimiento).format('LL')}</b>.
                            <Badge color="success" pill className="ml-1">SIN VENCER</Badge>
                          </span>
                      }
                    </FormText>
                }
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <UIFormsCampoFormInput
                  type="textarea"
                  id="descripcion"
                  name="descripcion"
                  label="Descripción"
                  autoComplete="off"
                  formikProps={formikProps}
                  className="padding-0"
                  error={formikProps.errors.descripcion ? formikProps.errors.descripcion : null}
                  touched={formikProps.touched.descripcion ? formikProps.touched.descripcion : null}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <hr/>
            <div className="text-center">
              {
                formikProps.values.uuid &&
                  <NavLink
                    className="jr-btn btn btn-primary fa-pull-left"
                    to={`/encuestas/detalle/${formikProps.values.uuid}/preguntas`}
                  >
                    <FontAwesomeIcon icon="question-circle" size="lg" className="mr-2" />
                    Preguntas
                  </NavLink>
              }

              <Button color="success" className="jr-btn" type="submit" disabled={props.guardando}>
                {
                  props.guardando ?
                    <FontAwesomeIcon icon="circle-notch" size="lg" spin className="mr-2" />
                    :
                    <FontAwesomeIcon icon="check" size="lg" className="mr-2" />
                }
                Guardar encuesta
              </Button>

              <NavLink className="jr-btn btn btn-default" to='/encuestas'>
                <span>Regresar al listado</span>
              </NavLink>

              {
                formikProps.values.uuid &&
                <Button
                  color="danger"
                  className="jr-btn fa-pull-right"
                  type="button"
                  onClick={() => props.handleEliminar(formikProps.values.uuid)}>
                  <FontAwesomeIcon icon="ban" size="lg" className="mr-2"/>
                  Eliminar encuesta
                </Button>
              }
            </div>
          </Col>
        </Row>
      </Form>
    )}
  </Formik>
);


export default EncuestasDetalle;
