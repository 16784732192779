import React from 'react';
import { NavLink } from 'react-router-dom';

import { Button, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NumberFormat from 'react-number-format';

import UIDataTable from '../../UI/DataTable/DataTable';


const CuentasListar = (props) => (
  <div className="app-module">
    <div className="module-box">
      <div className="module-box-header">
        <div className="module-box-header-inner">
          <div className="module-box-header-right">
            <Button color="info" className="jr-btn" onClick={props.obtenerClientes}>
              <FontAwesomeIcon icon="sync" size="lg" className="mr-2" />
              <span>Actualizar</span>
            </Button>

            <NavLink className="jr-btn btn btn-primary" to='/clientes/detalle'>
              <FontAwesomeIcon icon="plus-circle" size="lg" className="mr-2" />
              <span>Crear cliente</span>
            </NavLink>
          </div>
        </div>
      </div>
      <div className="module-box-content">

        <UIDataTable
          data={props.clientes}
          manual
          filterable={true}
          filtered={props.filtrado}
          pages={props.paginas}
          page={props.paginacion.pagina}
          pageSize={props.paginacion.tamano_pagina}
          onPageChange={props.cambioPagina}
          onPageSizeChange={props.cambioTamanoPagina}
          onFilteredChange={props.cambioFiltro}
          columns={[
            {
              Header: 'Número de documento',
              accessor: 'numero_documento',
              Cell: (row) => (
                `${row.original.tipo_documento} ${row.original.numero_documento}`
              )
            },
            {
              Header: 'Nombre completo',
              accessor: 'search',
              Cell: (row) => (
                `${row.original.nombres} ${row.original.apellidos}`
              )
            },
            {
              Header: 'Ciudad',
              accessor: 'ciudad',
              filterable: false,
              Cell: (row) => (
                `${row.original.ciudad.display_name}`
              )
            },
            {
              Header: 'Precio de venta',
              accessor: 'precio_venta',
              filterable: false,
              Cell: (row) => (
                <NumberFormat
                  value={row.original.precio_venta}
                  displayType="text"
                  decimalScale={0}
                  prefix=" $"
                  thousandSeparator=","
                />
              )
            },
            {
              Header: 'Saldo actual',
              accessor: 'saldo',
              filterable: false,
              Cell: (row) => (
                <span className={row.original.saldo <= 50 ? 'text-danger' : ''}>
                  {row.original.saldo}
                </span>
              )
            },
            {
              Header: 'Saldo anterior',
              accessor: 'saldo_anterior',
              filterable: false,
              Cell: (row) => (
                <span>
                  {row.original.saldo_anterior}
                  <div className="fa-pull-right">
                    {
                      row.original.variacion > 0 ?
                        <Badge color="success" pill>
                          + {row.original.variacion}%
                        </Badge>
                        :
                        <Badge color="danger" pill>
                          - {row.original.variacion}%
                        </Badge>
                    }
                  </div>
                </span>
              )
            }
          ]}
          getTrProps={(state, rowInfo) => ({
            onClick: () => {
              props.editarCliente(rowInfo.original.uuid);
            }
          })}
          noDataText="No se ha encontrando ningún cliente"
          rowsText="clientes"
          loading={props.cargando}
        />
      </div>
    </div>
  </div>
);


export default CuentasListar;
