import { put, call, all, fork, takeEvery } from 'redux-saga/effects';

import { OBTENER_ENCUESTAS, OBTENER_ENCUESTAS_ENVIADAS } from '../../constants/ActionTypes/Encuestas';
import { ENCUESTAS_URL, ENCUESTAS_ENVIADAS_URL } from '../../constants/Urls/Encuestas';
import {
  obtenerEncuestasExitoso,
  obtenerEncuestasEnviadasExitoso,
  obtenerEncuestasError,
  obtenerEncuestasEnviadasError
} from '../../actions/Encuestas/Listar';
import { httpFetch } from '../../utils/Http/Fetch';
import { setUnidades } from '../../actions/Configuracion';


const obtenerEncuestasApi = async (filtros) => {
  let esc = encodeURIComponent;
  let params = Object.keys(filtros).map(filtro => esc(filtro) + '=' + esc(filtros[filtro])).join('&');

  return httpFetch(`${ENCUESTAS_URL}?${params}`, 'GET')
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};

const obtenerEncuestasEnviadasApi = async (filtros) => {
  let esc = encodeURIComponent;
  let params = Object.keys(filtros).map(filtro => esc(filtro) + '=' + esc(filtros[filtro])).join('&');

  return httpFetch(`${ENCUESTAS_ENVIADAS_URL}?${params}`, 'GET')
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};

function* obtenerEncuestas(action) {
  try {
    const { filtros } = action.payload;
    const encuestas = yield call(obtenerEncuestasApi, filtros);
    const paginas = Math.ceil(encuestas.cantidad / filtros.tamano_pagina);

    if (typeof encuestas.saldo !== 'undefined') {
      yield put(setUnidades(encuestas.saldo));
    }

    yield put(obtenerEncuestasExitoso({encuestas: encuestas.resultado, cantidad: encuestas.cantidad, paginas}));
  } catch (error) {
    yield put(obtenerEncuestasError(error.message));
  }
}

function* obtenerEncuestasEnviadas(action) {
  try {
    const { filtros } = action.payload;
    const encuestas = yield call(obtenerEncuestasEnviadasApi, filtros);
    const paginas = Math.ceil(encuestas.cantidad / filtros.tamano_pagina);

    if (typeof encuestas.saldo !== 'undefined') {
      yield put(setUnidades(encuestas.saldo));
    }

    yield put(obtenerEncuestasEnviadasExitoso({encuestas: encuestas.resultado, cantidad: encuestas.cantidad, paginas}));
  } catch (error) {
    yield put(obtenerEncuestasEnviadasError(error.message));
  }
}

export function* obtenerEncuestasWatcher() {
  yield takeEvery (OBTENER_ENCUESTAS, obtenerEncuestas);
}

export function* obtenerEncuestasEnviadasWatcher() {
  yield takeEvery (OBTENER_ENCUESTAS_ENVIADAS, obtenerEncuestasEnviadas);
}

export default function* rootSaga() {
  yield all ([
    fork (obtenerEncuestasWatcher),
    fork (obtenerEncuestasEnviadasWatcher)
  ]);
}
