import React from 'react';
// import PropTypes from 'prop-types';

import { FormGroup, Label, Card, CardText, Alert, FormText } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import UIFormsCampoFormMensajeCampo from './Campo';
import UIFormsCampoFormMensajeCampoLista from './CampoLista';


const UIFormsCampoFormMensaje = (props) => (
  <div>
    {
      props.columnas === false ?
        null
        :
        <Card body className="mb-3">
          <CardText>
            Estos son los campos de la lista disponibles para incluir en el mensaje, los puede usar <b>digitando
            "@"</b> en el campo de texto del mensaje y seleccionando el campo deseado o <b>arrastrandolo</b> hasta el
            campo de texto.
          </CardText>
          {
            props.columnas.length ?
              <CardText>
                {
                  props.columnas.map((columna, index) => (
                    <UIFormsCampoFormMensajeCampoLista
                      key={index}
                      nombre={columna.display}
                    />
                  ))
                }
              </CardText>
              :
              <Alert color="info">
                <FontAwesomeIcon icon="info-circle" size="lg" className="mr-2" />
                Por favor seleccione una lista de destinatarios.
              </Alert>
          }
        </Card>
    }

    <FormGroup>
      {
        props.label ?
          <Label for={props.id} className={props.labelClassName}>{props.label}</Label>
          :
          null
      }

      <UIFormsCampoFormMensajeCampo
        value={props.formikProps.values[props.name]}
        placeholder={props.placeholder}
        readOnly={props.readOnly}
        onChange={(e) => props.formikProps.setFieldValue(props.name, e.target.value)}
        columnas={props.columnas}
      />

      <FormText>
        {
          props.formikProps.values[props.name] ?
            props.formikProps.values[props.name].length
            :
            0
        } caracteres de {props.longitudMaxima}
      </FormText>

      {
        props.formikProps.values[props.name] && props.formikProps.values[props.name].length > props.longitudMaxima ?
          <Alert color="danger">
            <FontAwesomeIcon icon="info-circle" size="lg" className="mr-2" />
            {props.formikProps.errors[props.name]}
          </Alert>
          :
          null
      }
    </FormGroup>
  </div>
);


UIFormsCampoFormMensaje.defaultProps = {
  className: '',
  labelClassName: ''
};

export default UIFormsCampoFormMensaje;
