import {
  OBTENER_REPORTE_MENSAJES_EXITOSO,
  OBTENER_REPORTE_MENSAJES_ERROR,
  CARGANDO_REPORTE_MENSAJES,
  EXPORTAR_REPORTE_MENSAJES_EXITOSO,
  EXPORTAR_REPORTE_MENSAJES_ERROR
} from '../../constants/ActionTypes/Reportes';


const mensajesInicial = {
  archivo: null,
  mensajes: [],
  cantidad: 0,
  paginas: 1,
  error: false,
  mensaje: null,
  cargando: false,
  graficar: false
};

const reporteMensajes = (state=mensajesInicial, action) => {
  switch (action.type) {

  case OBTENER_REPORTE_MENSAJES_EXITOSO: {
    return {
      ...mensajesInicial,
      ...action.payload
    };
  }

  case OBTENER_REPORTE_MENSAJES_ERROR: {
    return {
      ...mensajesInicial,
      error: true,
      mensaje: action.payload
    };
  }

  case CARGANDO_REPORTE_MENSAJES: {
    return {
      ...mensajesInicial,
      mensajes: state.mensajes,
      cantidad: state.cantidad,
      paginas: state.paginas,
      cargando: action.payload
    };
  }

  case EXPORTAR_REPORTE_MENSAJES_EXITOSO: {
    return {
      ...mensajesInicial,
      mensajes: state.mensajes,
      cantidad: state.cantidad,
      paginas: state.paginas,
      ...action.payload
    };
  }

  case EXPORTAR_REPORTE_MENSAJES_ERROR: {
    return {
      ...mensajesInicial,
      mensajes: state.mensajes,
      cantidad: state.cantidad,
      paginas: state.paginas,
      error: true,
      mensaje: action.payload
    };
  }

  default:
    return state;
  }
};


export default reporteMensajes;
