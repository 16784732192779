import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ScreensSeguridadPerfilDetalle from '../../screens/Seguridad/Perfil/PerfilDetalle';
import ScreensSeguridadTerminosCondiciones from '../../screens/Seguridad/TerminosCondiciones/TerminosCondiciones';


const Seguridad = (props) => (
  <div className="app-wrapper">
    <Switch>
      <Route path={`${props.match.url}/perfil`} component={ScreensSeguridadPerfilDetalle}/>
      <Route path={`${props.match.url}/terminos`} component={ScreensSeguridadTerminosCondiciones}/>
    </Switch>
  </div>
);


export default Seguridad;
