import React from 'react';

import UIBreadcrumbContainer from '../../components/UI/Breadcrumb/BreadcrumbContainer';
import GaleriaListarContainer from '../../components/Galeria/Listar/ListarContainer';

const ScreensGaleriaListar = () => (
  <div className="animated slideInUpTiny animation-duration-3">

    <UIBreadcrumbContainer></UIBreadcrumbContainer>

    <GaleriaListarContainer />
  </div>
);


export default ScreensGaleriaListar;
