import { put, call, all, fork, takeEvery } from 'redux-saga/effects';

import { OBTENER_MENSAJES } from '../../constants/ActionTypes/Mensajes';
import { MENSAJES_URL } from '../../constants/Urls/Mensajes';
import { obtenerMensajesExitoso, obtenerMensajesError } from '../../actions/Mensajes/Listar';
import { httpFetch } from '../../utils/Http/Fetch';
import {setUnidades} from '../../actions/Configuracion';


const obtenerMensajesApi = async (filtros) => {
  let esc = encodeURIComponent;
  let params = Object.keys(filtros).map(filtro => esc(filtro) + '=' + esc(filtros[filtro])).join('&');

  return httpFetch(`${MENSAJES_URL}?${params}`, 'GET')
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};


function* obtenerMensajes(action) {
  try {
    const { filtros } = action.payload;
    const mensajes = yield call(obtenerMensajesApi, filtros);
    const paginas = Math.ceil(mensajes.cantidad / filtros.tamano_pagina);

    if (typeof mensajes.saldo !== 'undefined') {
      yield put(setUnidades(mensajes.saldo));
    }

    yield put(obtenerMensajesExitoso({mensajes: mensajes.resultado, cantidad: mensajes.cantidad, paginas}));
  } catch (error) {
    yield put(obtenerMensajesError(error.message));
  }
}


export function* obtenerMensajesWatcher() {
  yield takeEvery (OBTENER_MENSAJES, obtenerMensajes);
}

export default function* rootSaga() {
  yield all ([
    fork (obtenerMensajesWatcher)
  ]);
}
