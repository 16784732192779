import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { toast } from 'react-toastify';

import MensajesListar from './Listar';
import { obtenerMensajes, cargandoMensajes } from '../../../actions/Mensajes/Listar';
import { setTituloPagina } from '../../../actions/Configuracion';


class MensajesListarContainer extends Component {
  constructor (props) {
    super(props);

    this.state = {
      paginacion: {
        pagina: 0,
        tamano_pagina: 20
      },
      filtrado: []
    };
  }

  handleCambioPagina = (pagina) => {
    this.setState({
      paginacion: {
        ...this.state.paginacion,
        pagina
      }
    });
  };

  handleCambioTamanoPagina = (tamano_pagina, pagina) => {
    this.setState({
      paginacion: {
        tamano_pagina,
        pagina
      }
    });
  };

  handleCambioFiltro = (filtrado) => {
    this.setState({
      paginacion: {
        ...this.state.paginacion,
        pagina: 0
      },
      filtrado
    });
  };

  handleObtenerMensajes = () => {
    const { pagina, tamano_pagina } = this.state.paginacion;

    let filtros = {pagina: pagina + 1, tamano_pagina};
    if (typeof this.state.filtrado !== 'undefined') {
      this.state.filtrado.map((filtro) => filtros[filtro.id] = filtro.value);
    }

    this.props.actions.cargandoMensajes(true);
    this.props.actions.obtenerMensajes({ filtros });
  };

  handleEditarMensaje = (id_mensaje) => {
    this.props.history.push('mensajes/detalle/' + id_mensaje);
  };

  componentDidMount() {
    this.props.actions.setTituloPagina({titulo: 'Mensajes', icono: 'comments'});
    this.handleObtenerMensajes();
  }

  componentDidUpdate(props, state) {
    if (this.props.error) {
      if (this.props.mensaje) {
        toast.error(this.props.mensaje);
      }

      if (this.props.cargando) {
        this.props.actions.cargandoMensajes(false);
      }
    }

    if ((state.filtrado !== this.state.filtrado) || (state.paginacion !== this.state.paginacion)) {
      this.handleObtenerMensajes();
    }
  }

  render() {
    return (
      <MensajesListar
        mensajes={this.props.mensajes}
        cargando={this.props.cargando}
        paginas={this.props.paginas}
        paginacion={this.state.paginacion}
        filtrado={this.state.filtrado}
        obtenerMensajes={this.handleObtenerMensajes}
        editarMensaje={this.handleEditarMensaje}
        cambioPagina={this.handleCambioPagina}
        cambioTamanoPagina={this.handleCambioTamanoPagina}
        cambioFiltro={this.handleCambioFiltro}
      />
    );
  }
}


const mapStateToProps = ({ listarMensajes }) => {
  return { ...listarMensajes };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      setTituloPagina,
      obtenerMensajes,
      cargandoMensajes
    }, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MensajesListarContainer));
