import { put, call, all, fork, takeEvery } from 'redux-saga/effects';

import {
  OBTENER_RESPUESTAS_CLAVE,
  RESPUESTAS_OBTENER_CLAVES,
  EXPORTAR_RESPUESTAS_CLAVE
} from '../../../constants/ActionTypes/Claves';
import { DOBLE_VIA_RESPUESTAS_URL } from '../../../constants/Urls/Mensajes';
import { DOBLE_VIAS_URL } from '../../../constants/Urls/Clientes';
import {
  obtenerRespuestasClaveExitoso,
  obtenerRespuestasClaveError,
  obtenerClavesExitoso,
  obtenerClavesError,
  exportarReporteExitoso,
  exportarReporteError
} from '../../../actions/DobleVia/Respuestas/Listar';
import { httpFetch } from '../../../utils/Http/Fetch';
import { setUnidades } from '../../../actions/Configuracion';


const obtenerRespuestasClaveApi = async (filtros) => {
  let esc = encodeURIComponent;
  let params = Object.keys(filtros).map(filtro => esc(filtro) + '=' + esc(filtros[filtro])).join('&');

  return httpFetch(`${DOBLE_VIA_RESPUESTAS_URL}?${params}`, 'GET')
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};

const obtenerClavesApi = async () => {
  return httpFetch(`${DOBLE_VIAS_URL}`, 'GET')
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};

const exportarReporteRespuestasApi = async (filtros) => {
  let esc = encodeURIComponent;
  let params = Object.keys(filtros).map(filtro => esc(filtro) + '=' + esc(filtros[filtro])).join('&');

  return httpFetch(`${DOBLE_VIA_RESPUESTAS_URL}?${params}`, 'GET', null, {'Accept': 'application/xlsx', 'Content-Type': 'application/xlsx'})
    .then(response => response)
    .then(response => response.blob())
    .then(blob => blob)
    .catch((error) => {
      throw error;
    });
};


function* obtenerRespuestasClave(action) {
  try {
    const { filtros } = action.payload;
    const respuestas = yield call(obtenerRespuestasClaveApi, filtros);
    const paginas = Math.ceil(respuestas.cantidad / filtros.tamano_pagina);

    if (typeof respuestas.saldo !== 'undefined') {
      yield put(setUnidades(respuestas.saldo));
    }

    yield put(obtenerRespuestasClaveExitoso({respuestas: respuestas.resultado, cantidad: respuestas.cantidad, paginas}));
  } catch (error) {
    yield put(obtenerRespuestasClaveError(error.message));
  }
}

function* obtenerClaves() {
  try {
    const claves = yield call(obtenerClavesApi);

    yield put(obtenerClavesExitoso(claves));
  } catch (error) {
    yield put(obtenerClavesError(error.message));
  }
}

function* exportarReporteRespuespas(action) {
  try {
    const { filtros } = action.payload;
    const archivo = yield call(exportarReporteRespuestasApi, filtros);
    const mensaje = 'Reporte generado correctamente';

    yield put(exportarReporteExitoso({archivo, mensaje}));
  } catch (error) {
    yield put(exportarReporteError(error.message));
  }
}


export function* obtenerRespuestasClaveWatcher() {
  yield takeEvery (OBTENER_RESPUESTAS_CLAVE, obtenerRespuestasClave);
  yield takeEvery (RESPUESTAS_OBTENER_CLAVES, obtenerClaves);
}

export function* exportarReporteWatcher() {
  yield takeEvery (EXPORTAR_RESPUESTAS_CLAVE, exportarReporteRespuespas);
}

export default function* rootSaga() {
  yield all ([
    fork (obtenerRespuestasClaveWatcher),
    fork (exportarReporteWatcher)
  ]);
}
