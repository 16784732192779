import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { toast } from 'react-toastify';
import _ from 'lodash';
import moment from 'moment';

import {
  enviarMensaje,
  enviandoMensaje,
  obtenerMensajes,
  obtenerListasDestinatarios,
  limpiarEnvioMensaje
} from '../../../actions/Mensajes/Enviar';
import { HORA_ENVIO_MINIMA, HORA_ENVIO_MAXIMA } from '../../../constants/Mensajes/HorasEnvio';
import { mostrarModal, ocultarModal } from '../../../actions/Modal';
import { setTituloPagina } from '../../../actions/Configuracion';
import MensajesEnviar from './Enviar';


class MensajesEnviarContainer extends Component {

  constructor(props) {
    super(props);

    this.horas_limite = {
      minima: moment().set({hour: HORA_ENVIO_MINIMA, minute: 0}).format('hh:mm A'),
      maxima: moment().set({hour: HORA_ENVIO_MAXIMA, minute: 0}).format('hh:mm A')
    };

    this.state = {
      programar_envio: false,
      destinatarios: [],
      columnas: [],
      mensaje_html: '',
      mensaje: {
        destinatarios: [],
        lista: '',
        mensaje: '',
        texto_mensaje: '',
        fecha_inicio: moment().add(1, 'days').toDate(),
        fecha_inicio_str: moment().add(1, 'days').format('ll'),
        fecha_finalizacion: moment().add(2, 'days').toDate(),
        fecha_finalizacion_str: moment().add(2, 'days').format('ll'),
        hora_inicio: moment().add(1, 'days'),
        hora_inicio_str: moment().add(1, 'days').format('hh:mm A'),
        hora_finalizacion: moment().add(1, 'days'),
        hora_finalizacion_str: moment().add(1, 'days').format('hh:mm A')
      }
    };
  }

  agregarDestinatario = (destinatario) => {
    if (destinatario.id) {
      if (!isNaN(destinatario.id)) {
        if (destinatario.id.length === 10) {
          this.setState({
            destinatarios: [
              ...this.state.destinatarios,
              destinatario
            ]
          });
        } else {
          toast.error('El número de destinatario debe ser de 10 dígitos.');
        }
      } else {
        toast.error('Por favor ingrese un número de destinatario válido.');
      }
    }
  };

  eliminarDestinatario = (i) => {
    let destinatarios = _.isUndefined(i) ? [] : this.state.destinatarios.filter((_, index) => index !== i);

    this.setState({
      destinatarios
    });
  };

  toggleProgramarEnvio = () => {
    this.setState({
      programar_envio: !this.state.programar_envio
    });
  };

  setColumnasMensaje = (columnas_mensaje) => {
    let columnas = columnas_mensaje.map((columna) => {
      return {
        'id': columna.toUpperCase(),
        'display': columna.toUpperCase()
      };
    });

    this.setState({
      columnas
    });
  };

  getHorasNoEnvio = () => {
    let horas = [];
    for (let i=0; i<=23; i++) {
      if (i < HORA_ENVIO_MINIMA || i > HORA_ENVIO_MAXIMA) {
        horas.push(i);
      }
    }

    return horas;
  };

  validarHorasEnvio = ({ fecha_inicio, hora_inicio, fecha_finalizacion, hora_finalizacion }) => {
    let horas_validas = {
      valido: false
    };

    if (fecha_inicio && hora_inicio && fecha_finalizacion && hora_finalizacion) {
      let ahora = moment();
      let inicio = moment(fecha_inicio);
      let fin = moment(fecha_finalizacion);

      let fecha_inicio_minima = inicio.clone();
      fecha_inicio_minima.set({hour: HORA_ENVIO_MINIMA, minute: 0});
      inicio.set({hour: hora_inicio.hour(), minute: hora_inicio.minute()});

      let fecha_inicio_maxima = inicio.clone();
      fecha_inicio_maxima.set({hour: HORA_ENVIO_MAXIMA, minute: 0});

      let fecha_fin_minima = fin.clone();
      fecha_fin_minima.set({hour: HORA_ENVIO_MINIMA, minute: 0});
      fin.set({hour: hora_finalizacion.hour(), minute: hora_finalizacion.minute()});

      let fecha_fin_maxima = fin.clone();
      fecha_fin_maxima.set({hour: HORA_ENVIO_MAXIMA, minute: 0});

      if (inicio.isAfter(ahora)) {

        if (inicio.isBetween(fecha_inicio_minima, fecha_inicio_maxima) && fin.isBetween(fecha_fin_minima, fecha_fin_maxima)) {

          if (inicio.isBefore(fin)) {
            horas_validas.valido = true;
            horas_validas.fecha_inicio = inicio;
            horas_validas.fecha_finalizacion = fin;
          } else {
            toast.error('Por favor ingrese una fecha y hora de inicio anterior a la final.');
          }

        } else {
          toast.error(`Por favor ingrese una hora de envío válida.
            Entre las ${this.horas_limite.minima} y las ${this.horas_limite.maxima}.`);
        }

      } else {
        toast.error('Por favor ingrese una hora de envío futura.');
      }
    } else {
      if (fecha_inicio && fecha_finalizacion) {
        toast.error('Por favor ingrese la fecha y hora de inicio y finalización.');
      }
    }

    return horas_validas;
  };

  handleSubmit = (values) => {
    let enviar_mensaje = false;
    let datos = {texto_mensaje: values.texto_mensaje};

    if (this.state.destinatarios.length) {
      datos.destinatarios = this.state.destinatarios.map((destinatario) => destinatario.id);
    }

    if (values.mensaje) {
      datos.mensaje = values.mensaje.uuid;
    }

    if (values.lista) {
      datos.lista = values.lista.uuid;
    }

    if (datos.destinatarios || datos.lista) {
      if (this.state.programar_envio) {
        let horas_validas = this.validarHorasEnvio(values);
        if (horas_validas.valido) {
          datos.fecha_inicio = horas_validas.fecha_inicio.toISOString(true);
          datos.fecha_finalizacion = horas_validas.fecha_finalizacion.toISOString(true);

          enviar_mensaje = true;
        }
      } else {
        enviar_mensaje = true;
      }
    } else {
      toast.error('Por favor ingrese un número de destinatario o seleccione una lista.');
    }

    if (enviar_mensaje) {
      this.props.actions.enviandoMensaje(true);
      this.props.actions.enviarMensaje(datos);
    }
  };

  componentDidMount() {
    this.props.actions.setTituloPagina({titulo: 'Enviar mensaje', icono: 'sms'});

    if (this.props.match.params.uuid_mensaje) {
      this.props.actions.obtenerMensaje(this.props.match.params.uuid_mensaje);
    } else {
      this.props.actions.obtenerListasDestinatarios();
      this.props.actions.obtenerMensajes();
    }
  }

  componentDidUpdate() {
    // Verificar si existió algún error
    if (this.props.error) {
      if (this.props.mensaje_) {
        toast.error(this.props.mensaje_);
      }

      if (this.props.enviando) {
        this.props.actions.enviandoMensaje(false);
      }

    // Verificar si es creación de un nuevo mensaje
    } else if (this.props.envio_exitoso) {
      toast.success(this.props.mensaje_);
      this.props.actions.limpiarEnvioMensaje();
    }
  }

  render() {
    return (
      <MensajesEnviar
        handleSubmit={this.handleSubmit}
        valoresIniciales={this.state.mensaje}
        enviando={this.props.enviando}
        listasDestinatarios={this.props.listas}
        mensajes={this.props.mensajes}
        programarEnvio={this.state.programar_envio}
        toggleProgramarEnvio={this.toggleProgramarEnvio}
        setColumnasMensaje={this.setColumnasMensaje}
        columnasMensaje={this.state.columnas}
        destinatarios={this.state.destinatarios}
        agregarDestinatario={this.agregarDestinatario}
        eliminarDestinatario={this.eliminarDestinatario}
        horasNoEnvio={this.getHorasNoEnvio}
        horasLimite={this.horas_limite}
      />
    );
  }
}


const mapStateToProps = ({ enviarMensaje }) => {
  return { ...enviarMensaje };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      setTituloPagina,
      mostrarModal,
      ocultarModal,
      enviarMensaje,
      enviandoMensaje,
      obtenerListasDestinatarios,
      obtenerMensajes,
      limpiarEnvioMensaje
    }, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MensajesEnviarContainer));
