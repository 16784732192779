import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { toast } from 'react-toastify';

import ListasDestinatariosListar from './Listar';
import { obtenerListasDestinatarios, cargandoListasDestinatarios } from '../../../actions/ListasDestinatarios/Listar';
import { setTituloPagina } from '../../../actions/Configuracion';


class ListasDestinatariosListarContainer extends Component {
  constructor (props) {
    super(props);

    this.state = {
      paginacion: {
        pagina: 0,
        tamano_pagina: 20
      },
      filtrado: []
    };
  }

  handleCambioPagina = (pagina) => {
    this.setState({
      paginacion: {
        ...this.state.paginacion,
        pagina
      }
    });
  };

  handleCambioTamanoPagina = (tamano_pagina, pagina) => {
    this.setState({
      paginacion: {
        tamano_pagina,
        pagina
      }
    });
  };

  handleCambioFiltro = (filtrado) => {
    this.setState({
      paginacion: {
        ...this.state.paginacion,
        pagina: 0
      },
      filtrado
    });
  };

  handleObtenerListasDestinatarios = () => {
    const { pagina, tamano_pagina } = this.state.paginacion;

    let filtros = {pagina: pagina + 1, tamano_pagina};
    if (typeof this.state.filtrado !== 'undefined') {
      this.state.filtrado.map((filtro) => filtros[filtro.id] = filtro.value);
    }

    this.props.actions.cargandoListasDestinatarios(true);
    this.props.actions.obtenerListasDestinatarios({ filtros });
  };

  handleEditarListaDestinatarios = (id_lista) => {
    this.props.history.push('listas/detalle/' + id_lista);
  };

  componentDidMount() {
    this.props.actions.setTituloPagina({titulo: 'Listas de destinatarios', icono: 'list-ol'});
    this.handleObtenerListasDestinatarios();
  }

  componentDidUpdate(props, state) {
    if (this.props.error) {
      if (this.props.mensaje) {
        toast.error(this.props.mensaje);
      }

      if (this.props.cargando) {
        this.props.actions.cargandoListasDestinatarios(false);
      }
    }

    if ((state.filtrado !== this.state.filtrado) || (state.paginacion !== this.state.paginacion)) {
      this.handleObtenerListasDestinatarios();
    }
  }

  render() {
    return (
      <ListasDestinatariosListar
        listas={this.props.listas}
        cargando={this.props.cargando}
        paginas={this.props.paginas}
        paginacion={this.state.paginacion}
        filtrado={this.state.filtrado}
        obtenerListasDestinatarios={this.handleObtenerListasDestinatarios}
        editarListaDestinatarios={this.handleEditarListaDestinatarios}
        cambioPagina={this.handleCambioPagina}
        cambioTamanoPagina={this.handleCambioTamanoPagina}
        cambioFiltro={this.handleCambioFiltro}
      />
    );
  }
}


const mapStateToProps = ({ listarListasDestinatarios }) => {
  return { ...listarListasDestinatarios };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      setTituloPagina,
      obtenerListasDestinatarios,
      cargandoListasDestinatarios
    }, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListasDestinatariosListarContainer));
