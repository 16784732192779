import React from 'react';

import UIBreadcrumbContainer from '../../components/UI/Breadcrumb/BreadcrumbContainer';
import ClientesListarContainer from '../../components/Clientes/Listar/ListarContainer';

const ScreensClientesListar = () => (
  <div className="animated slideInUpTiny animation-duration-3">

    <UIBreadcrumbContainer></UIBreadcrumbContainer>

    <ClientesListarContainer />
  </div>
);


export default ScreensClientesListar;
