import {
  OBTENER_REPORTE_ENCUESTAS_EXITOSO,
  OBTENER_REPORTE_ENCUESTAS_ERROR,
  CARGANDO_REPORTE_ENCUESTAS,
  EXPORTAR_REPORTE_ENCUESTAS_EXITOSO,
  EXPORTAR_REPORTE_ENCUESTAS_ERROR
} from '../../constants/ActionTypes/Reportes';


const resultadosInicial = {
  archivo: null,
  resultados: [],
  cantidad: 0,
  paginas: 1,
  error: false,
  mensaje: null,
  cargando: false,
  graficar: false
};

const reporteEncuestas = (state=resultadosInicial, action) => {
  switch (action.type) {

  case OBTENER_REPORTE_ENCUESTAS_EXITOSO: {
    return {
      ...resultadosInicial,
      ...action.payload
    };
  }

  case OBTENER_REPORTE_ENCUESTAS_ERROR: {
    return {
      ...resultadosInicial,
      error: true,
      mensaje: action.payload
    };
  }

  case CARGANDO_REPORTE_ENCUESTAS: {
    return {
      ...resultadosInicial,
      resultados: state.resultados,
      cantidad: state.cantidad,
      paginas: state.paginas,
      cargando: action.payload
    };
  }

  case EXPORTAR_REPORTE_ENCUESTAS_EXITOSO: {
    return {
      ...resultadosInicial,
      resultados: state.resultados,
      cantidad: state.cantidad,
      paginas: state.paginas,
      ...action.payload
    };
  }

  case EXPORTAR_REPORTE_ENCUESTAS_ERROR: {
    return {
      ...resultadosInicial,
      resultados: state.resultados,
      cantidad: state.cantidad,
      paginas: state.paginas,
      error: true,
      mensaje: action.payload
    };
  }

  default:
    return state;
  }
};


export default reporteEncuestas;
