import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ScreensDestinatariosListar from '../../screens/Destinatarios/DestinatariosListar';
import ScreensDestinatariosDetalle from '../../screens/Destinatarios/DestinatariosDetalle';


const Destinatarios = (props) => (
  <div className="app-wrapper">
    <Switch>
      <Route exact path={`${props.match.url}/`} component={ScreensDestinatariosListar}/>
      <Route path={`${props.match.url}/detalle/:uuid_destinatario`} component={ScreensDestinatariosDetalle} />
      <Route path={`${props.match.url}/detalle`} component={ScreensDestinatariosDetalle}/>
    </Switch>
  </div>
);


export default Destinatarios;
