import * as Yup from 'yup';

import {
  CAMPO_REQUERIDO,
  CAMPO_LONGITUD_MAXIMA
} from '../../../constants/Forms/Validacion';


export const destinatarioSchema = Yup.object().shape({
  nombres: Yup.string()
    .required(CAMPO_REQUERIDO),

  numero: Yup.string()
    .max(10, CAMPO_LONGITUD_MAXIMA)
    .required(CAMPO_REQUERIDO),
});
