import { put, call, all, fork, takeEvery } from 'redux-saga/effects';

import { OBTENER_CLIENTES } from '../../constants/ActionTypes/Clientes';
import { CLIENTES_URL } from '../../constants/Urls/Clientes';
import { obtenerClientesExitoso, obtenerClientesError } from '../../actions/Clientes/Listar';
import { httpFetch } from '../../utils/Http/Fetch';


const obtenerClientesApi = async (filtros) => {
  let esc = encodeURIComponent;
  let params = Object.keys(filtros).map(filtro => esc(filtro) + '=' + esc(filtros[filtro])).join('&');

  return httpFetch(`${CLIENTES_URL}?${params}`, 'GET')
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};

function* obtenerClientes(action) {
  try {
    const { filtros } = action.payload;
    const clientes = yield call(obtenerClientesApi, filtros);
    const paginas = Math.ceil(clientes.cantidad / filtros.tamano_pagina);

    yield put(obtenerClientesExitoso({clientes: clientes.resultado, cantidad: clientes.cantidad, paginas}));
  } catch (error) {
    yield put(obtenerClientesError(error.message));
  }
}

export function* obtenerClientesWatcher() {
  yield takeEvery (OBTENER_CLIENTES, obtenerClientes);
}

export default function* rootSaga() {
  yield all ([
    fork (obtenerClientesWatcher)
  ]);
}
