import {
  ENVIAR_MENSAJE_EXITOSO,
  ENVIAR_MENSAJE_ERROR,
  ENVIANDO_MENSAJE,
  ENVIAR_OBTENER_MENSAJES_EXITOSO,
  ENVIAR_OBTENER_MENSAJES_ERROR,
  ENVIAR_CARGANDO_MENSAJES,
  ENVIAR_OBTENER_LISTAS_DESTINATARIOS_EXITOSO,
  ENVIAR_OBTENER_LISTAS_DESTINATARIOS_ERROR,
  ENVIAR_CARGANDO_LISTAS_DESTINATARIOS,
  ENVIAR_LIMPIAR_ENVIO_MENSAJE
} from '../../constants/ActionTypes/Mensajes';


const enviarMensajeInicial = {
  mensaje: {},
  listas: [],
  mensajes: [],
  error: false,
  mensaje_: null,
  enviando: false,
  envio_exitoso: false,
  cargando_listas: false,
  cargando_mensajes: false
};

const enviarMensaje = (state=enviarMensajeInicial, action) => {
  switch (action.type) {

  case ENVIAR_MENSAJE_EXITOSO: {
    return {
      ...enviarMensajeInicial,
      mensaje: action.payload.mensaje,
      mensaje_: action.payload.mensaje_,
      envio_exitoso: true
    };
  }

  case ENVIAR_MENSAJE_ERROR: {
    return {
      ...enviarMensajeInicial,
      listas: state.listas,
      mensajes: state.mensajes,
      error: true,
      mensaje_: action.payload
    };
  }

  case ENVIANDO_MENSAJE: {
    return {
      ...enviarMensajeInicial,
      listas: state.listas,
      mensajes: state.mensajes,
      enviando: action.payload
    };
  }

  case ENVIAR_OBTENER_LISTAS_DESTINATARIOS_EXITOSO: {
    return {
      ...enviarMensajeInicial,
      mensaje: state.mensaje,
      listas: action.payload,
      mensajes: state.mensajes,
    };
  }

  case ENVIAR_OBTENER_LISTAS_DESTINATARIOS_ERROR: {
    return {
      ...enviarMensajeInicial,
      error: true,
      mensaje: state.mensaje,
      mensajes: state.mensajes,
      mensaje_: action.payload,
    };
  }

  case ENVIAR_CARGANDO_LISTAS_DESTINATARIOS: {
    return {
      ...enviarMensajeInicial,
      mensaje: state.mensaje,
      listas: state.listas,
      mensajes: state.mensajes,
      cargando_listas: action.payload
    };
  }

  case ENVIAR_OBTENER_MENSAJES_EXITOSO: {
    return {
      ...enviarMensajeInicial,
      mensaje: state.mensaje,
      listas: state.listas,
      mensajes: action.payload,
    };
  }

  case ENVIAR_OBTENER_MENSAJES_ERROR: {
    return {
      ...enviarMensajeInicial,
      error: true,
      mensaje: state.mensaje,
      listas: state.listas,
      mensaje_: action.payload,
    };
  }

  case ENVIAR_CARGANDO_MENSAJES: {
    return {
      ...enviarMensajeInicial,
      mensaje: state.mensaje,
      listas: state.listas,
      mensajes: state.mensajes,
      cargando_mensajes: action.payload
    };
  }

  case ENVIAR_LIMPIAR_ENVIO_MENSAJE: {
    return {
      ...enviarMensajeInicial,
      listas: state.listas,
      mensajes: state.mensajes,
    };
  }

  default:
    return state;
  }
};


export default enviarMensaje;
