import {
  OBTENER_CAMPANIA,
  OBTENER_CAMPANIA_EXITOSO,
  OBTENER_CAMPANIA_ERROR,
  GUARDAR_CAMPANIA,
  GUARDAR_CAMPANIA_EXITOSO,
  GUARDAR_CAMPANIA_ERROR,
  GUARDANDO_CAMPANIA,
  ELIMINAR_CAMPANIA,
  ELIMINAR_CAMPANIA_EXITOSO,
  ELIMINAR_CAMPANIA_ERROR,
  OBTENER_LISTAS_DESTINATARIOS,
  OBTENER_LISTAS_DESTINATARIOS_EXITOSO,
  OBTENER_LISTAS_DESTINATARIOS_ERROR,
  CARGANDO_LISTAS_DESTINATARIOS,
  OBTENER_MENSAJES,
  OBTENER_MENSAJES_EXITOSO,
  OBTENER_MENSAJES_ERROR,
  CARGANDO_MENSAJES
} from '../../constants/ActionTypes/Campanias';


export const obtenerCampania = (uuid) => {
  return {
    type: OBTENER_CAMPANIA,
    payload: uuid
  };
};

export const obtenerCampaniaExitoso = (mensaje) => {
  return {
    type: OBTENER_CAMPANIA_EXITOSO,
    payload: mensaje
  };
};

export const obtenerCampaniaError = (mensaje) => {
  return {
    type: OBTENER_CAMPANIA_ERROR,
    payload: mensaje
  };
};

export const guardarCampania = (datos) => {
  return {
    type: GUARDAR_CAMPANIA,
    payload: datos
  };
};

export const guardarCampaniaExitoso = (mensaje) => {
  return {
    type: GUARDAR_CAMPANIA_EXITOSO,
    payload: mensaje
  };
};

export const guardarCampaniaError = (mensaje) => {
  return {
    type: GUARDAR_CAMPANIA_ERROR,
    payload: mensaje
  };
};

export const guardandoCampania = (cargando) => {
  return {
    type: GUARDANDO_CAMPANIA,
    payload: cargando
  };
};

export const eliminarCampania = (uuid) => {
  return {
    type: ELIMINAR_CAMPANIA,
    payload: uuid
  };
};

export const eliminarCampaniaExitoso = (mensaje) => {
  return {
    type: ELIMINAR_CAMPANIA_EXITOSO,
    payload: mensaje
  };
};

export const eliminarCampaniaError = (mensaje) => {
  return {
    type: ELIMINAR_CAMPANIA_ERROR,
    payload: mensaje
  };
};

export const obtenerListasDestinatarios = () => {
  return {
    type: OBTENER_LISTAS_DESTINATARIOS
  };
};

export const obtenerListasDestinatariosExitoso = (destinatarios) => {
  return {
    type: OBTENER_LISTAS_DESTINATARIOS_EXITOSO,
    payload: destinatarios
  };
};

export const obtenerListasDestinatariosError = (mensaje) => {
  return {
    type: OBTENER_LISTAS_DESTINATARIOS_ERROR,
    payload: mensaje
  };
};

export const cargandoListasDestinatarios = (cargando) => {
  return {
    type: CARGANDO_LISTAS_DESTINATARIOS,
    payload: cargando
  };
};

export const obtenerMensajes = () => {
  return {
    type: OBTENER_MENSAJES
  };
};

export const obtenerMensajesExitoso = (destinatarios) => {
  return {
    type: OBTENER_MENSAJES_EXITOSO,
    payload: destinatarios
  };
};

export const obtenerMensajesError = (mensaje) => {
  return {
    type: OBTENER_MENSAJES_ERROR,
    payload: mensaje
  };
};

export const cargandoMensajes = (cargando) => {
  return {
    type: CARGANDO_MENSAJES,
    payload: cargando
  };
};
