import React from 'react';

import UIBreadcrumbContainer from '../../components/UI/Breadcrumb/BreadcrumbContainer';
import MensajesListarContainer from '../../components/Mensajes/Listar/ListarContainer';

const ScreensMensajesListar = () => (
  <div className="animated slideInUpTiny animation-duration-3">

    <UIBreadcrumbContainer></UIBreadcrumbContainer>

    <MensajesListarContainer />
  </div>
);


export default ScreensMensajesListar;
