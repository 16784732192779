export const TOGGLE_NAVEGACION_COLAPSADA = 'toggle_collapse_menu';
export const TIPO_DRAWER = 'drawer_type';
export const DRAWER_FIJO = 'fixed_drawer';
export const DRAWER_COLAPSADO = 'collapsible';
export const MINI_DRAWER = 'mini_drawer';
export const COLOR_TEMA = 'theme_color';
export const ANCHO_VENTANA = 'window-width';
export const CAMBIAR_DIRECCION = 'change-direction';

export const CAMBIAR_ESTILO_NAVEGACION = 'change-navigation-style';
export const NAVEGACION_HORIZONTAL = 'horizontal_navigation';
export const NAVEGACION_VERTICAL = 'vertical_navigation';

export const POSICION_MENU_HORIZONTAL = 'horizontal_menu_position';
export const ENCIMA_DE_CABECERA = 'above_the_header';
export const DENTRO_DE_CABECERA = 'inside_the_header';
export const DEBAJO_DE_CABECERA = 'below_the_header';
export const TITULO_PAGINA = 'titulo_pagina';
export const UNIDADES = 'unidades';
