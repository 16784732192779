export const TIPO_MENSAJE_NORMAL = 'SMS';
export const TIPO_MENSAJE_NORMAL_LABEL = 'MENSAJE DE TEXTO (SMS)';
export const TIPO_MENSAJE_MULTIMEDIA = 'MMS';
export const TIPO_MENSAJE_MULTIMEDIA_LABEL = 'MENSAJE MULTIMEDIA (MMS)';
export const TIPO_MENSAJE_ENCUESTA = 'ENCUESTA';
export const TIPO_MENSAJE_ENCUESTA_LABEL = 'ENCUESTA WEB';

export const TIPOS_MENSAJE = [
  {label: TIPO_MENSAJE_NORMAL_LABEL, value: TIPO_MENSAJE_NORMAL},
  {label: TIPO_MENSAJE_MULTIMEDIA_LABEL, value: TIPO_MENSAJE_MULTIMEDIA},
  {label: TIPO_MENSAJE_ENCUESTA_LABEL, value: TIPO_MENSAJE_ENCUESTA}
];
