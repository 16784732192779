import {
  OBTENER_USUARIO,
  OBTENER_USUARIO_EXITOSO,
  OBTENER_USUARIO_ERROR,
  GUARDAR_USUARIO,
  GUARDAR_USUARIO_EXITOSO,
  GUARDAR_USUARIO_ERROR,
  GUARDANDO_USUARIO,
  LIMPIAR_USUARIO,
  ELIMINAR_USUARIO,
  ELIMINAR_USUARIO_EXITOSO,
  ELIMINAR_USUARIO_ERROR,
  GENERAR_CLAVE_USUARIO,
  GENERAR_CLAVE_USUARIO_EXITOSO,
  GENERAR_CLAVE_USUARIO_ERROR
} from '../../constants/ActionTypes/Usuarios';


export const obtenerUsuario = (uuid) => {
  return {
    type: OBTENER_USUARIO,
    payload: uuid
  };
};

export const obtenerUsuarioExitoso = (cuenta) => {
  return {
    type: OBTENER_USUARIO_EXITOSO,
    payload: cuenta
  };
};

export const obtenerUsuarioError = (mensaje) => {
  return {
    type: OBTENER_USUARIO_ERROR,
    payload: mensaje
  };
};

export const guardarUsuario = (datos) => {
  return {
    type: GUARDAR_USUARIO,
    payload: datos
  };
};

export const guardarUsuarioExitoso = (usuario) => {
  return {
    type: GUARDAR_USUARIO_EXITOSO,
    payload: usuario
  };
};

export const guardarUsuarioError = (mensaje) => {
  return {
    type: GUARDAR_USUARIO_ERROR,
    payload: mensaje
  };
};

export const guardandoUsuario = (cargando) => {
  return {
    type: GUARDANDO_USUARIO,
    payload: cargando
  };
};

export const limpiarUsuario = () => {
  return {
    type: LIMPIAR_USUARIO
  };
};

export const eliminarUsuario = (uuid) => {
  return {
    type: ELIMINAR_USUARIO,
    payload: uuid
  };
};

export const eliminarUsuarioExitoso = (mensaje) => {
  return {
    type: ELIMINAR_USUARIO_EXITOSO,
    payload: mensaje
  };
};

export const eliminarUsuarioError = (mensaje) => {
  return {
    type: ELIMINAR_USUARIO_ERROR,
    payload: mensaje
  };
};

export const generarClaveUsuario = (uuid) => {
  return {
    type: GENERAR_CLAVE_USUARIO,
    payload: uuid
  };
};

export const generarClaveUsuarioExitoso = (mensaje) => {
  return {
    type: GENERAR_CLAVE_USUARIO_EXITOSO,
    payload: mensaje
  };
};

export const generarClaveUsuarioError = (mensaje) => {
  return {
    type: GENERAR_CLAVE_USUARIO_ERROR,
    payload: mensaje
  };
};
