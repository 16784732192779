import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { toast } from 'react-toastify';
import { sprintf } from 'sprintf-js';
import moment from 'moment';

import {
  obtenerReporte,
  cargandoReporte,
  exportarReporte
} from '../../../../actions/Reportes/Campanias';
import { setTituloPagina } from '../../../../actions/Configuracion';
import ReportesCampaniasDetalle from './Detalle';
import { ESTADOS_CAMPANIA } from '../../../../constants/EnviarCampanias/Estados';


class ReportesCampaniasContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      datos: []
    };
  }

  descargarReporte = () => {
    toast.success(this.props.mensaje);
    const archivo = new Blob([this.props.archivo], {type: 'application/xlsx'});
    let url_archivo = URL.createObjectURL(archivo);

    // Simula descarga del archivo
    let link_archivo = document.createElement('a');
    link_archivo.href = url_archivo;
    link_archivo.download = sprintf('reporte_campañas_enviadas_%(fecha)s.xlsx', { fecha: moment().format('MMDDYYYY') });
    link_archivo.click();

    // Limpia descarga
    link_archivo.remove();
    URL.revokeObjectURL(url_archivo);
  };

  calcularGrafica = () => {
    let datos = [];

    for (let index in this.props.mensajes) {
      let mensaje = this.props.mensajes[index];
      let estado = ESTADOS_CAMPANIA.find((estado) => estado.value === mensaje.estado);
      let estado_label = estado ? estado.label.toUpperCase() : 'DESCONOCIDO';

      let existe = datos.find((dato) => dato.name === estado_label);
      if (existe) {
        existe.value++;
        datos[estado_label] = existe;
      } else {
        datos.push({
          name: estado_label,
          estado: estado ? estado.value : '',
          color: estado ? estado.color : '#CCC',
          value: 1,
        });
      }
    }

    this.setState({
      datos
    });
  };

  handleExportarReporte = () => {
    let filtros = {campania_enviada__uuid: this.props.match.params.uuid_campania};

    if (typeof this.state.filtered !== 'undefined') {
      this.state.filtered.map((filtro) => filtros[filtro.id] = filtro.value);
    }

    this.props.actions.cargandoReporte(true);
    this.props.actions.exportarReporte({ filtros });
  };

  handleObtenerReporte = (state) => {
    let filtros = {campania_enviada__uuid: this.props.match.params.uuid_campania};
    if (state && typeof state.filtered !== 'undefined') {
      state.filtered.map((filtro) => filtros[filtro.id] = filtro.value);
    }

    this.props.actions.cargandoReporte(true);
    this.props.actions.obtenerReporte({ filtros });
  };

  componentDidMount() {
    this.props.actions.setTituloPagina({titulo: 'Reporte de campañas enviadas', icono: 'chart-pie'});

    if (this.props.match.params.uuid_campania) {
      this.handleObtenerReporte();
    }
  }

  componentDidUpdate(props) {
    // Verificar si existió algún error
    if (this.props.error) {
      if (this.props.mensaje) {
        toast.error(this.props.mensaje);
      }

      if (this.props.cargando) {
        this.props.actions.cargandoReporte(false);
      }
    } else if (props.mensajes !== this.props.mensajes) {
      this.calcularGrafica();
    } else if (this.props.archivo) {
      this.descargarReporte();
    }
  }

  render() {
    return (
      <ReportesCampaniasDetalle
        obtenerReporte={this.handleObtenerReporte}
        exportarReporte={this.handleExportarReporte}
        mensajes={this.props.mensajes}
        cargando={this.props.cargando}
        datos={this.state.datos}
      />
    );
  }
}


const mapStateToProps = ({ reporteCampanias }) => {
  return { ...reporteCampanias };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      setTituloPagina,
      obtenerReporte,
      cargandoReporte,
      exportarReporte
    }, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportesCampaniasContainer));
