import {
  OBTENER_CAMPANIAS,
  OBTENER_CAMPANIAS_EXITOSO,
  OBTENER_CAMPANIAS_ERROR,
  CARGANDO_CAMPANIAS
} from '../../constants/ActionTypes/Campanias';


export const obtenerCampanias = (params) => {
  return {
    type: OBTENER_CAMPANIAS,
    payload: params
  };
};

export const obtenerCampaniasExitoso = (campanias) => {
  return {
    type: OBTENER_CAMPANIAS_EXITOSO,
    payload: campanias
  };
};

export const obtenerCampaniasError = (mensaje) => {
  return {
    type: OBTENER_CAMPANIAS_ERROR,
    payload: mensaje
  };
};

export const cargandoCampanias = (cargando) => {
  return {
    type: CARGANDO_CAMPANIAS,
    payload: cargando
  };
};
