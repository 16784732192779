import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ScreensReportesMensajesListar from '../../../screens/Reportes/Mensajes/MensajesListar';


const ReportesMensajes = (props) => (
  <div className="app-wrapper">
    <Switch>
      <Route exact path={`${props.match.url}/`} component={ScreensReportesMensajesListar}/>
    </Switch>
  </div>
);


export default ReportesMensajes;
