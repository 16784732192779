import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { toast } from 'react-toastify';

import { obtenerEncuesta } from '../../../actions/Encuestas/Detalle';
import { obtenerEncuestaPreguntas, cargandoEncuestaPreguntas } from '../../../actions/Encuestas/Preguntas';
import { setTituloPagina } from '../../../actions/Configuracion';
import EncuestasVistaPrevia from './VistaPrevia';


class EncuestasVistaPreviaContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      encuesta: {
        nombre: '',
        descripcion: '',
        tiempo_validez: 1
      }
    };
  }

  cargarEncuesta = (encuesta) => {
    this.setState({
      encuesta: {
        ...encuesta,
        tiempo_validez: encuesta.url_corto.tiempo_validez
      },
    });

    this.props.actions.cargandoEncuestaPreguntas(true);
    this.props.actions.obtenerEncuestaPreguntas(encuesta.uuid);
  };

  componentDidMount() {
    this.props.actions.setTituloPagina({titulo: 'Vista Previa', icono: 'eye'});

    if (this.props.match.params.uuid_encuesta) {
      this.props.actions.obtenerEncuesta(this.props.match.params.uuid_encuesta);
    }
  }

  componentDidUpdate(props) {
    // Verificar si existió algún error
    if (this.props.detalleEncuestas.error || this.props.listarEncuestaPreguntas.error) {
      if (this.props.detalleEncuestas.mensaje || this.props.listarEncuestaPreguntas.mensaje) {
        toast.error(this.props.detalleEncuestas.mensaje || this.props.listarEncuestaPreguntas.mensaje);
      }
    // Verificar si es una edición de encuestas
    } else if (this.props.detalleEncuestas.editando) {
      if (props.detalleEncuestas.encuesta !== this.props.detalleEncuestas.encuesta) {
        this.cargarEncuesta(this.props.detalleEncuestas.encuesta);
      }
    }
  }

  render() {
    return (
      <EncuestasVistaPrevia
        encuesta={this.state.encuesta}
        preguntas={this.props.listarEncuestaPreguntas.preguntas}
        cargandoPreguntas={this.props.listarEncuestaPreguntas.cargando}
      />
    );
  }
}


const mapStateToProps = ({ detalleEncuestas, listarEncuestaPreguntas }) => {
  return { detalleEncuestas, listarEncuestaPreguntas };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      setTituloPagina,
      obtenerEncuesta,
      obtenerEncuestaPreguntas,
      cargandoEncuestaPreguntas
    }, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EncuestasVistaPreviaContainer));
