
// Enviar Saldo
export const ENVIAR_SALDO = 'enviar_saldo';
export const ENVIAR_SALDO_EXITOSO = 'enviar_saldo_exitoso';
export const ENVIAR_SALDO_ERROR = 'enviar_saldo_error';
export const ENVIANDO_SALDO = 'enviando_saldo';
export const LIMPIAR_ENVIO_SALDO = 'limpiar_envio_saldo';
export const OBTENER_CLIENTES = 'enviar_saldo_obtener_clientes';
export const OBTENER_CLIENTES_EXITOSO = 'enviar_saldo_obtener_clientes_exitoso';
export const OBTENER_CLIENTES_ERROR = 'enviar_saldo_obtener_clientes_error';
