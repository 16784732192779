import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ScreensReportesEncuestasListar from '../../../screens/Reportes/Encuestas/EncuestasListar';
import ScreensReportesEncuestasDetalle from '../../../screens/Reportes/Encuestas/EncuestasDetalle';
import ScreensReportesEncuestasResultado from '../../../screens/Reportes/Encuestas/EncuestasResultado';


const ReportesEncuestas = (props) => (
  <div className="app-wrapper">
    <Switch>
      <Route exact path={`${props.match.url}/`} component={ScreensReportesEncuestasListar}/>
      <Route path={`${props.match.url}/:uuid_encuesta/resultado/:uuid_resultado`} component={ScreensReportesEncuestasResultado} />
      <Route path={`${props.match.url}/:uuid_encuesta`} component={ScreensReportesEncuestasDetalle} />
    </Switch>
  </div>
);


export default ReportesEncuestas;
