import React from 'react';

import UIBreadcrumbContainer from '../../components/UI/Breadcrumb/BreadcrumbContainer';
import ListasDestinatariosListarContainer from '../../components/ListasDestinatarios/Listar/ListarContainer';

const ScreensListasDestinatariosListar = () => (
  <div className="animated slideInUpTiny animation-duration-3">

    <UIBreadcrumbContainer></UIBreadcrumbContainer>

    <ListasDestinatariosListarContainer />
  </div>
);


export default ScreensListasDestinatariosListar;
