import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import BarraLateralContenido from './Contenido';
import { USUARIO_SESION } from '../../../../constants/Configuracion';


class BarraLateralContenidoContainer extends Component {
  constructor(props) {
    super(props);

    const usuario = JSON.parse(localStorage.getItem(USUARIO_SESION));
    this.state = {
      usuario
    };
  }

  componentDidMount() {
    const that = this;
    const pathname = `#${this.props.history.location.pathname}`;// get current path

    const subMenuLi = document.querySelectorAll('.sub-menu > li');
    for (let i = 0; i < subMenuLi.length; i++) {
      subMenuLi[i].onclick = function () {
        // event.stopPropagation();
      };
    }

    const menuLi = document.getElementsByClassName('menu');
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function () {
        for (let j = 0; j < menuLi.length; j++) {
          const parentLi = that.closest(this, 'li');
          if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains('open'))) {
            menuLi[j].classList.remove('open');
          }
        }
        this.classList.toggle('open');
        // event.stopPropagation();
      };
    }

    const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      } else {
        this.closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) {}
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(
        function (fn) {
          if (typeof document.body[fn] === 'function') {
            matchesFn = fn;
            return true;
          }
          return false;
        }
      );

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {}

    return null;
  }

  render() {
    return (
      <BarraLateralContenido
        usuario={this.state.usuario}
      />
    );
  }
}

export default withRouter(BarraLateralContenidoContainer);
