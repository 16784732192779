import React from 'react';

import UIBreadcrumbContainer from '../../components/UI/Breadcrumb/BreadcrumbContainer';
import CampaniasListarContainer from '../../components/Campanias/Listar/ListarContainer';

const ScreensCampaniasListar = () => (
  <div className="animated slideInUpTiny animation-duration-3">

    <UIBreadcrumbContainer></UIBreadcrumbContainer>

    <CampaniasListarContainer />
  </div>
);


export default ScreensCampaniasListar;
