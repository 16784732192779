import React from 'react';
// import { NavLink } from 'react-router-dom';

const LayoutCabeceraMenu = (props) => (
  <div className="app-main-menu d-none d-md-block">
    <ul className="navbar-nav navbar-nav-mega">

      {/*<li className="nav-item">*/}
        {/*<a >*/}
          {/*Logística*/}
        {/*</a>*/}
        {/*<ul className="sub-menu">*/}

          {/*<li className="nav-item">*/}
            {/*<a >Logística</a>*/}
            {/*<ul className="sub-menu">*/}
              {/*<li>*/}
                {/*<NavLink to="/app/widgets">*/}
                  {/*<i className="zmdi zmdi-widgets zmdi-hc-fw"/>*/}
                  {/*<span className="nav-text">Requisiciones</span>*/}
                {/*</NavLink>*/}
              {/*</li>*/}
            {/*</ul>*/}
          {/*</li>*/}
          {/**/}
        {/*</ul>*/}
      {/*</li>*/}

    </ul>
  </div>
);

export default LayoutCabeceraMenu;
