import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Route, Switch, BrowserRouter } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import HTML5Backend from 'react-dnd-html5-backend';
import {DragDropContextProvider} from 'react-dnd';

import { setUrlInicial } from './actions/Autenticacion';
import LayoutBaseContainer from './components/Layout/Base/BaseContainer';
import RutaRestringida from './utils/RutaRestringida';
import ScreensAutenticacionLogin from './screens/Autenticacion/AutenticacionLogin';
import './utils/FontAwesome/LibrarySolid';
import './utils/FontAwesome/LibraryRegular';

import 'react-toastify/dist/ReactToastify.css';
import 'rmc-drawer/assets/index.css';
import './styles/bootstrap.scss';
import './styles/app.scss';
import './styles/loader.scss';


class App extends Component {
  componentDidMount() {
    document.body.classList.add(this.props.colorTema);
  }

  render() {
    return (
      <div className="app-main">
        <DragDropContextProvider backend={HTML5Backend}>
          <BrowserRouter>
            <Switch>
              <Route path="/login" component={ScreensAutenticacionLogin} />

              <RutaRestringida
                path="/"
                component={LayoutBaseContainer}
                usuario={this.props.usuario}
              />
            </Switch>
          </BrowserRouter>

          <ToastContainer
            position='top-center'
            autoClose={3000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          />
        </DragDropContextProvider>
      </div>
    );
  }
}


const mapStateToProps = ({ configs, autenticacion }) => {
  const { usuario, urlInicial } = autenticacion;
  const { colorTema } = configs;
  return { usuario, urlInicial, colorTema };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ setUrlInicial }, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
