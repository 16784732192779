import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import uuid from 'uuid';
import { toast } from 'react-toastify';

import { obtenerEncuesta, editandoEncuesta } from '../../../actions/Encuestas/Detalle';
import {
  obtenerEncuestaPreguntas,
  cargandoEncuestaPreguntas,
  eliminarEncuestaPregunta,
  eliminandoEncuestaPregunta
} from '../../../actions/Encuestas/Preguntas';
import { mostrarModal, ocultarModal } from '../../../actions/Modal';
import { setTituloPagina } from '../../../actions/Configuracion';
import EncuestaPreguntas from './Preguntas';
import EncuestasPreguntasPreguntaDetalleContainer from './Pregunta/DetalleContainer';


class PreguntasDetalleContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      encuesta: {
        nombre: '',
        descripcion: ''
      },
      preguntasColapsadas: {}
    };
  }

  cargarEncuesta = (encuesta) => {
    this.setState({
      encuesta
    });

    this.props.actions.ocultarModal();
    this.props.actions.editandoEncuesta(false);
    this.props.actions.eliminandoEncuestaPregunta(false);
    this.props.actions.cargandoEncuestaPreguntas(true);
    this.props.actions.obtenerEncuestaPreguntas(encuesta.uuid);
  };

  cargarPreguntasColapsadas = () => {
    let colapsados = {};
    this.props.listarEncuestaPreguntas.preguntas.forEach((pregunta) => {
      colapsados[pregunta.uuid] = false;
    });

    this.setState({
      preguntasColapsadas: colapsados
    });
  };

  handleColapsarPregunta = (pregunta) => {
    let colapsados = this.state.preguntasColapsadas;
    colapsados[pregunta.uuid] = !colapsados[pregunta.uuid];

    this.setState({
      preguntasColapsadas: colapsados
    });
  }

  handleEditarPregunta = (pregunta = {}) => {
    const modal_uuid = uuid.v4();
    this.props.actions.mostrarModal({
      uuid: modal_uuid,
      componente: EncuestasPreguntasPreguntaDetalleContainer,
      componenteProps: {
        modal_uuid,
        encuesta: this.state.encuesta,
        pregunta
      },
      atributos: {
        titulo: 'Agregar nueva pregunta',
        textoBotonAceptar: 'Guardar',
        estatico: 'static',
        tamano: 'lg',
        teclado: false,
        cerrarDesdeCabecera: false
      }
    });
  }

  handleEliminarPregunta = (pregunta) => {
    this.props.actions.mostrarModal({
      uuid: uuid.v4(),
      atributos: {
        titulo: 'Eliminar pregunta',
        mensaje: '¿Realmente desea eliminar este pregunta?',
        colorBotonAceptar: 'danger',
        textoBotonAceptar: 'Eliminar pregunta',
        nota: 'Esta acción no se puede deshacer',
        colorNota: 'danger',
        tamano: 'sm',
        aceptar: () => this.props.actions.eliminarEncuestaPregunta(pregunta.uuid)
      }
    });
  }

  componentDidMount() {
    this.props.actions.setTituloPagina({titulo: 'Preguntas', icono: 'question-circle'});

    if (this.props.match.params.uuid_encuesta) {
      this.props.actions.obtenerEncuesta(this.props.match.params.uuid_encuesta);
    }
  }

  componentDidUpdate(props) {
    // Verificar si existió algún error
    if (this.props.detalleEncuestas.error || this.props.listarEncuestaPreguntas.error) {
      if (this.props.detalleEncuestas.mensaje || this.props.listarEncuestaPreguntas.mensaje) {
        toast.error(this.props.detalleEncuestas.mensaje || this.props.listarEncuestaPreguntas.mensaje);
      }
    // Verificar si es una edición de encuestas
    } else if (this.props.detalleEncuestas.editando) {
      if (props.detalleEncuestas.encuesta !== this.props.detalleEncuestas.encuesta) {
        this.cargarEncuesta(this.props.detalleEncuestas.encuesta);
      }
    // 
    } else if (this.props.detalleEncuestaPreguntas.editando) {
      if (this.props.detalleEncuestaPreguntas.eliminando) {
        toast.success('Pregunta eliminada correctamente.');
        this.cargarEncuesta(this.props.detalleEncuestas.encuesta);
      }
    } else if (this.props.listarEncuestaPreguntas.preguntas !== props.listarEncuestaPreguntas.preguntas) {
      this.cargarPreguntasColapsadas();
    }
  }

  render() {
    return (
      <EncuestaPreguntas
        encuesta={this.state.encuesta}
        preguntas={this.props.listarEncuestaPreguntas.preguntas}
        cargandoPreguntas={this.props.listarEncuestaPreguntas.cargando}
        handleEditarPregunta={this.handleEditarPregunta}
        handleColapsarPregunta={this.handleColapsarPregunta}
        handleEliminarPregunta={this.handleEliminarPregunta}
        preguntasColapsadas={this.state.preguntasColapsadas}
      />
    );
  }
}


const mapStateToProps = ({ detalleEncuestas, listarEncuestaPreguntas, detalleEncuestaPreguntas }) => {
  return { detalleEncuestas, listarEncuestaPreguntas, detalleEncuestaPreguntas };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      setTituloPagina,
      mostrarModal,
      ocultarModal,
      obtenerEncuesta,
      editandoEncuesta,
      obtenerEncuestaPreguntas,
      cargandoEncuestaPreguntas,
      eliminarEncuestaPregunta,
      eliminandoEncuestaPregunta
    }, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PreguntasDetalleContainer));
