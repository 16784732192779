import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { toast } from 'react-toastify';
import { sprintf } from 'sprintf-js';
import moment from 'moment';

import DobleViaRespuestasListar from './Listar';
import {
  obtenerRespuestasClave,
  obtenerClaves,
  cargandoRespuestasClave,
  exportarReporte
} from '../../../../actions/DobleVia/Respuestas/Listar';
import { setTituloPagina } from '../../../../actions/Configuracion';


class DobleViaRespuestasListarContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paginacion: {
        pagina: 0,
        tamano_pagina: 20
      },
      fecha_desde: moment().subtract(1, 'days').toDate(),
      fecha_hasta: moment().toDate(),
      filtrado: []
    };
  }

  descargarReporte = () => {
    toast.success(this.props.mensaje);
    const archivo = new Blob([this.props.archivo], {type: 'application/xlsx'});
    let url_archivo = URL.createObjectURL(archivo);

    // Simula descarga del archivo
    let link_archivo = document.createElement('a');
    link_archivo.href = url_archivo;
    link_archivo.download = sprintf('reporte_respuestas_doble_via_%(fecha)s.xlsx', { fecha: moment().format('MMDDYYYY') });
    link_archivo.click();

    // Limpia descarga
    link_archivo.remove();
    URL.revokeObjectURL(url_archivo);
  };

  validarRangoFecha = () => {
    let fechas_validas = {
      valido: false
    };

    if (this.state.fecha_desde && this.state.fecha_hasta) {
      let inicio = moment(this.state.fecha_desde);
      let fin = moment(this.state.fecha_hasta);

      inicio.startOf('day');
      fin.add(1, 'days').startOf('day');

      fechas_validas.valido = true;
      fechas_validas.fecha_inicio = inicio;
      fechas_validas.fecha_fin = fin;
    }

    return fechas_validas;
  };

  handleCambioPagina = (pagina) => {
    this.setState({
      paginacion: {
        ...this.state.paginacion,
        pagina
      }
    });
  };

  handleCambioTamanoPagina = (tamano_pagina, pagina) => {
    this.setState({
      paginacion: {
        tamano_pagina,
        pagina
      }
    });
  };

  handleCambioFiltro = (filtrado) => {
    this.setState({
      paginacion: {
        ...this.state.paginacion,
        pagina: 0
      },
      filtrado
    });
  };

  handleExportarReporte = () => {
    let fechas_validas = this.validarRangoFecha();

    if (fechas_validas.valido) {
      const { pagina, tamano_pagina } = this.state.paginacion;

      let filtros = {
        pagina: pagina + 1,
        tamano_pagina,
        fecha_inicio: fechas_validas.fecha_inicio.toISOString(true),
        fecha_fin: fechas_validas.fecha_fin.toISOString(true)
      };
      if (typeof this.state.filtrado !== 'undefined') {
        this.state.filtrado.map((filtro) => filtros[filtro.id] = filtro.value);
      }

      this.props.actions.cargandoRespuestasClave(true);
      this.props.actions.exportarReporte({ filtros });
    } else {
      toast.error('Por favor ingrese un rango de fechas válido.');
    }
  };

  handleDesdeChange = (fecha_desde) => {
    this.setState({ fecha_desde });
  };

  handleHastaChange = (fecha_hasta) => {
    this.setState({ fecha_hasta });
  };

  handleObtenerRespuestasClave = () => {
    let fechas_validas = this.validarRangoFecha();

    if (fechas_validas.valido) {
      const { pagina, tamano_pagina } = this.state.paginacion;

      let filtros = {
        pagina: pagina + 1,
        tamano_pagina,
        fecha_inicio: fechas_validas.fecha_inicio.toISOString(true),
        fecha_fin: fechas_validas.fecha_fin.toISOString(true)
      };
      if (typeof this.state.filtrado !== 'undefined') {
        this.state.filtrado.map((filtro) => filtros[filtro.id] = filtro.value);
      }

      this.props.actions.cargandoRespuestasClave(true);
      this.props.actions.obtenerRespuestasClave({ filtros });
    } else {
      toast.error('Por favor ingrese un rango de fechas válido.');
    }
  };

  componentDidMount() {
    this.props.actions.setTituloPagina({titulo: 'Respuestas de claves doble vía', icono: 'undo'});
    this.props.actions.obtenerClaves();
    this.handleObtenerRespuestasClave();
  }

  componentDidUpdate(props, state) {
    if (this.props.error) {
      if (this.props.mensaje) {
        toast.error(this.props.mensaje);
      }

      if (this.props.cargando) {
        this.props.actions.cargandoRespuestasClave(false);
      }
    } else if (this.props.archivo) {
      this.descargarReporte();
    }

    if ((state.filtrado !== this.state.filtrado) || (state.paginacion !== this.state.paginacion)) {
      this.handleObtenerRespuestasClave();
    }
  }

  render() {
    return (
      <DobleViaRespuestasListar
        respuestas={this.props.respuestas}
        claves={this.props.claves}
        cargando={this.props.cargando}
        paginas={this.props.paginas}
        paginacion={this.state.paginacion}
        filtrado={this.state.filtrado}
        exportarReporte={this.handleExportarReporte}
        obtenerRespuestasClave={this.handleObtenerRespuestasClave}
        cambioPagina={this.handleCambioPagina}
        cambioTamanoPagina={this.handleCambioTamanoPagina}
        cambioFiltro={this.handleCambioFiltro}
        fechaDesde={this.state.fecha_desde}
        fechaHasta={this.state.fecha_hasta}
        handleDesdeChange={this.handleDesdeChange}
        handleHastaChange={this.handleHastaChange}
      />
    );
  }
}


const mapStateToProps = ({ listarDobleViaRespuestasClave }) => {
  return { ...listarDobleViaRespuestasClave };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      setTituloPagina,
      obtenerRespuestasClave,
      obtenerClaves,
      cargandoRespuestasClave,
      exportarReporte
    }, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DobleViaRespuestasListarContainer));
