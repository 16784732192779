import React from 'react';
// import { Link } from 'react-router-dom';

import { Button } from 'reactstrap';
import { Formik, Form } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import UIFormsCampoFormInput from '../../UI/Forms/CampoForm/Input';
import { loginSchema } from './Schema';


const AutenticacionLogin = (props) => (
  <Formik
    initialValues={props.valoresIniciales}
    onSubmit={props.iniciarSesion}
    validationSchema={loginSchema}
    validateOnChange
  >
    {formikProps => (
      <Form>
        <div className="form-group mb-3">
          <UIFormsCampoFormInput
            id="email"
            type="email"
            name="email"
            placeholder="Correo electrónico"
            autoComplete="off"
            error={formikProps.errors.email ? formikProps.errors.email : null}
            touched={formikProps.touched.email ? formikProps.touched.email : null}
          />
        </div>

        <div className="form-group mb-3">
          <UIFormsCampoFormInput
            id="password"
            type="password"
            name="clave"
            placeholder="Contraseña"
            autoComplete="off"
            error={formikProps.errors.clave ? formikProps.errors.clave : null}
            touched={formikProps.touched.clave ? formikProps.touched.clave : null}
          />
        </div>

        <div className="mb-3 d-flex align-items-center justify-content-between">
          <Button color="primary" className="text-uppercase" type="submit" disabled={props.autenticando}>
            {
              props.autenticando ?
                <FontAwesomeIcon icon="circle-notch" size="lg" spin className="mr-2" />
                :
                null
            }
            Ingresar
          </Button>

          {/*<Link to="/signup">*/}
          {/*Olvidó la contraseña?*/}
          {/*</Link>*/}
        </div>
      </Form>
    )
    }
  </Formik>
);

export default AutenticacionLogin;
