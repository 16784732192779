
// Listar
export const OBTENER_USUARIOS = 'obtener_usuarios';
export const OBTENER_USUARIOS_EXITOSO = 'obtener_usuarios_exitoso';
export const OBTENER_USUARIOS_ERROR = 'obtener_usuarios_error';
export const CARGANDO_USUARIOS = 'cargando_usuarios';

// Detalle
export const OBTENER_USUARIO = 'obtener_usuario';
export const OBTENER_USUARIO_EXITOSO = 'obtener_usuario_exitoso';
export const OBTENER_USUARIO_ERROR = 'obtener_usuario_error';
export const GUARDAR_USUARIO = 'guardar_usuario';
export const GUARDAR_USUARIO_EXITOSO = 'guardar_usuario_exitoso';
export const GUARDAR_USUARIO_ERROR = 'guardar_usuario_error';
export const GUARDANDO_USUARIO = 'guardando_usuario';
export const ELIMINAR_USUARIO = 'eliminar_usuario';
export const ELIMINAR_USUARIO_EXITOSO = 'eliminar_usuario_exitoso';
export const ELIMINAR_USUARIO_ERROR = 'eliminar_usuario_error';
export const LIMPIAR_USUARIO = 'limpiar_usuario';
export const GENERAR_CLAVE_USUARIO = 'generar_clave_usuario';
export const GENERAR_CLAVE_USUARIO_EXITOSO = 'generar_clave_usuario_exitoso';
export const GENERAR_CLAVE_USUARIO_ERROR = 'generar_clave_usuario_error';

// Clientes
export const OBTENER_USUARIOS_CLIENTES = 'obtener_usuarios_clientes';
export const OBTENER_USUARIOS_CLIENTES_EXITOSO = 'obtener_usuarios_clientes_exitoso';
export const OBTENER_USUARIOS_CLIENTES_ERROR = 'obtener_usuarios_clientes_error';
