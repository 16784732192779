import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { toast } from 'react-toastify';

import UsuariosListar from './Listar';
import { USUARIO_SESION } from '../../../constants/Configuracion';
import { obtenerUsuarios, cargandoUsuarios, obtenerClientes } from '../../../actions/Usuarios/Listar';
import { setTituloPagina } from '../../../actions/Configuracion';
import { USUARIO_TIPO_ADMIN } from '../../../constants/Usuarios/Tipos';


class UsuariosListarContainer extends Component {
  constructor(props) {
    super(props);

    const usuario = JSON.parse(localStorage.getItem(USUARIO_SESION));
    this.state = {
      usuario,
      paginacion: {
        pagina: 0,
        tamano_pagina: 20
      },
      filtrado: []
    };
  }

  handleCambioPagina = (pagina) => {
    this.setState({
      paginacion: {
        ...this.state.paginacion,
        pagina
      }
    });
  }

  handleCambioTamanoPagina = (tamano_pagina, pagina) => {
    this.setState({
      paginacion: {
        tamano_pagina,
        pagina
      }
    });
  }

  handleCambioFiltro = (filtrado) => {
    this.setState({
      paginacion: {
        ...this.state.paginacion,
        pagina: 0
      },
      filtrado
    });
  }

  handleObtenerUsuarios = () => {
    const { pagina, tamano_pagina } = this.state.paginacion;

    let filtros = {pagina: pagina + 1, tamano_pagina};
    if (typeof this.state.filtrado !== 'undefined') {
      this.state.filtrado.map((filtro) => filtros[filtro.id] = filtro.value);
    }

    this.props.actions.cargandoUsuarios(true);
    this.props.actions.obtenerUsuarios({ filtros });
  };

  handleEditarCliente = (id_usuario) => {
    this.props.history.push('usuarios/detalle/' + id_usuario);
  };

  componentDidMount() {
    this.props.actions.setTituloPagina({titulo: 'Usuarios', icono: 'users'});
    this.handleObtenerUsuarios();

    if (this.state.usuario.tipo === USUARIO_TIPO_ADMIN) {
      this.props.actions.obtenerClientes();
    }
  }

  componentDidUpdate(props, state) {
    if (this.props.error) {
      if (this.props.mensaje) {
        toast.error(this.props.mensaje);
      }

      if (this.props.cargando) {
        this.props.actions.cargandoUsuarios(false);
      }
    }

    if ((state.filtrado !== this.state.filtrado) || (state.paginacion !== this.state.paginacion)) {
      this.handleObtenerUsuarios();
    }
  }

  render() {
    return (
      <UsuariosListar
        usuario={this.state.usuario}
        usuarios={this.props.usuarios}
        clientes={this.props.clientes}
        cargando={this.props.cargando}
        paginas={this.props.paginas}
        paginacion={this.state.paginacion}
        filtrado={this.state.filtrado}
        obtenerUsuarios={this.handleObtenerUsuarios}
        editarCliente={this.handleEditarCliente}
        cambioPagina={this.handleCambioPagina}
        cambioTamanoPagina={this.handleCambioTamanoPagina}
        cambioFiltro={this.handleCambioFiltro}
      />
    );
  }
}


const mapStateToProps = ({ listarUsuarios }) => {
  return { ...listarUsuarios };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      setTituloPagina,
      obtenerUsuarios,
      cargandoUsuarios,
      obtenerClientes
    }, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsuariosListarContainer));
