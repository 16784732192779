import React from 'react';
// import PropTypes from 'prop-types';

import DayPicker, { DateUtils } from 'react-day-picker';
import MomentLocaleUtils  from 'react-day-picker/moment';
import 'moment/locale/es';

import { Row, Col } from 'reactstrap';

import 'react-day-picker/lib/style.css';


const UIFormsCampoFormFecha = (props) => (
  <Row>
    <Col sm={12}>
      <div className="text-center">
        <DayPicker
          className="Selectable"
          numberOfMonths={props.numberOfMonths}
          disabledDays={props.disabledDays}
          selectedDays={props.selectedDays}
          modifiers={props.modifiers}
          locale="es"
          localeUtils={MomentLocaleUtils}
          onDayClick={(dia) => {
            if (props.modifiers) {
              const rango = DateUtils.addDayToRange(dia, {from: props.modifiers.start, to: props.modifiers.end});
              props.onDayClick(rango);
            } else {
              props.onDayClick(dia);
            }
          }}
        />
      </div>
    </Col>
  </Row>
);


UIFormsCampoFormFecha.defaultProps = {
  className: '',
  labelClassName: ''
};

export default UIFormsCampoFormFecha;
