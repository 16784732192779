import React from 'react';

import { Col, Row, Button, Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Form } from 'formik';

import UIFormsCampoFormInput from '../../UI/Forms/CampoForm/Input';
import { perfilSchema } from './Schema';


const PerfilDetalle = (props) => (
  <Formik
    enableReinitialize
    initialValues={props.valoresIniciales}
    onSubmit={props.guardarPerfil}
    validationSchema={perfilSchema}
    validateOnChange
  >
    {formikProps => (
      <Form>
        <Row>
          <Col sm={12}>
            <Row form>
              <Col md={12}>
                <UIFormsCampoFormInput
                  id="email"
                  type="email"
                  name="email"
                  label="Correo electrónico"
                  labelClassName="requerido"
                  autoComplete="off"
                  error={formikProps.errors.email ? formikProps.errors.email : null}
                  touched={formikProps.touched.email ? formikProps.touched.email : null}
                />
              </Col>
            </Row>
            <Row form>
              <Col md={4}>
                <UIFormsCampoFormInput
                  id="actual_password"
                  type="password"
                  name="actual_password"
                  label="Contraseña actual"
                  placeholder="Ingrese la contraseña actual"
                  autoComplete="off"
                  error={formikProps.errors.actual_password ? formikProps.errors.actual_password : null}
                  touched={formikProps.touched.actual_password ? formikProps.touched.actual_password : null}
                />
              </Col>
              <Col md={4}>
                <UIFormsCampoFormInput
                  id="nuevo_password"
                  type="password"
                  name="nuevo_password"
                  label="Contraseña nueva"
                  placeholder="Esta será su nueva contraseña"
                  autoComplete="off"
                  error={formikProps.errors.nuevo_password ? formikProps.errors.nuevo_password : null}
                  touched={formikProps.touched.nuevo_password ? formikProps.touched.nuevo_password : null}
                />
              </Col>
              <Col md={4}>
                <UIFormsCampoFormInput
                  id="nuevo_password_confirmacion"
                  type="password"
                  name="nuevo_password_confirmacion"
                  label="Confirmación de contraseña nueva"
                  placeholder="Confirme la nueva contraseña"
                  autoComplete="off"
                  error={formikProps.errors.nuevo_password_confirmacion ? formikProps.errors.nuevo_password_confirmacion : null}
                  touched={formikProps.touched.nuevo_password_confirmacion ? formikProps.touched.nuevo_password_confirmacion : null}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Alert color="info" className="animated slideInUpTiny animation-duration-3">
                  <h4 className="alert-heading">
                    <FontAwesomeIcon icon="exclamation-circle" size="lg" className="mr-2"/>
                    <b>Información</b>
                  </h4>
                  <p className="mb-0">
                    Una vez guarde los cambios, tendrá que iniciar sesión nuevamente.
                  </p>
                </Alert>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <hr />
            <div className="text-center">
              <Button color="success" className="jr-btn" type="submit" disabled={props.guardando}>
                {
                  props.guardando ?
                    <FontAwesomeIcon icon="circle-notch" size="lg" spin className="mr-2" />
                    :
                    <FontAwesomeIcon icon="check" size="lg" className="mr-2" />
                }
                Guardar cambios
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    )}
  </Formik>
);


export default PerfilDetalle;
