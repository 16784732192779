import { put, call, all, fork, takeEvery } from 'redux-saga/effects';

import { sprintf } from 'sprintf-js';

import {
  OBTENER_CLAVE,
  GUARDAR_CLAVE,
  ELIMINAR_CLAVE
} from '../../../constants/ActionTypes/Claves';
import { DOBLE_VIAS_URL, DOBLE_VIA_URL } from '../../../constants/Urls/Clientes';
import {
  obtenerClaveExitoso,
  obtenerClaveError,
  guardarClaveExitoso,
  guardarClaveError,
  eliminarClaveExitoso,
  eliminarClaveError
} from '../../../actions/DobleVia/Claves/Detalle';
import { httpFetch } from '../../../utils/Http/Fetch';
import { setUnidades } from '../../../actions/Configuracion';


const obtenerClaveApi = async (uuid) => {
  const url = sprintf(DOBLE_VIA_URL, { uuid });

  return httpFetch(url, 'GET')
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};

const guardarClaveApi = async (datos) => {
  const verbo_http = datos.uuid ? 'PUT' : 'POST';
  let url = DOBLE_VIAS_URL;

  if (datos.uuid) {
    url = sprintf(DOBLE_VIA_URL, {
      uuid: datos.uuid
    });
  }

  return httpFetch(url, verbo_http, datos, {'Content-Type': 'application/json'})
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};

const eliminarClaveApi = async (uuid) => {
  const url = sprintf(DOBLE_VIA_URL, {uuid});

  return httpFetch(url, 'DELETE')
    .catch((error) => {
      throw error;
    });
};


function* obtenerClave(action) {
  try {
    const clave = yield call(obtenerClaveApi, action.payload);

    if (typeof clave.saldo !== 'undefined') {
      yield put(setUnidades(clave.saldo));
    }

    yield put(obtenerClaveExitoso(clave));
  } catch (error) {
    yield put(obtenerClaveError(error.message));
  }
}

function* guardarClave(action) {
  try {
    const clave = yield call(guardarClaveApi, action.payload);
    const accion = action.payload.uuid ? 'actualizada' : 'creada';
    const mensaje = `Clave doble vía ${accion} correctamente.`;

    if (typeof clave.saldo !== 'undefined') {
      yield put(setUnidades(clave.saldo));
    }

    yield put(guardarClaveExitoso({clave, mensaje}));
  } catch (error) {
    yield put(guardarClaveError(error.message));
  }
}

function* eliminarClave(action) {
  try {
    yield call(eliminarClaveApi, action.payload);
    const mensaje = 'Clave doble vía eliminada correctamente.';

    yield put(eliminarClaveExitoso({ mensaje }));
  } catch (error) {
    yield put(eliminarClaveError(error.message));
  }
}


export function* obtenerClaveWatcher() {
  yield takeEvery (OBTENER_CLAVE, obtenerClave);
}

export function* guardarClaveWatcher() {
  yield takeEvery (GUARDAR_CLAVE, guardarClave);
}

export function* eliminarClaveWatcher() {
  yield takeEvery (ELIMINAR_CLAVE, eliminarClave);
}

export default function* rootSaga() {
  yield all ([
    fork (guardarClaveWatcher),
    fork (obtenerClaveWatcher),
    fork (eliminarClaveWatcher)
  ]);
}
