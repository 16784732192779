import {all} from 'redux-saga/effects';

import autenticacionSaga from './Autenticacion';
import listarClientes from './Clientes/Listar';
import detalleClientes from './Clientes/Detalle';
import listarUsuarios from './Usuarios/Listar';
import detalleUsuarios from './Usuarios/Detalle';
import listarDestinatarios from './Destinatarios/Listar';
import detalleDestinatarios from './Destinatarios/Detalle';
import listarListasDestinatarios from './ListasDestinatarios/Listar';
import detalleListasDestinatarios from './ListasDestinatarios/Detalle';
import listarMensajes from './Mensajes/Listar';
import detalleMensajes from './Mensajes/Detalle';
import enviarMensaje from './Mensajes/Enviar';
import listarCampanias from './Campanias/Listar';
import detalleCampanias from './Campanias/Detalle';
import listarCampaniasProgramadas from './EnviarCampanias/Listar';
import detalleCampaniasProgramadas from './EnviarCampanias/Detalle';
import listarDobleViaClaves from './DobleVia/Claves/Listar';
import detalleDobleViaClaves from './DobleVia/Claves/Detalle';
import listarEncuestas from './Encuestas/Listar';
import detalleEncuestas from './Encuestas/Detalle';
import listarEncuestaPreguntas from './Encuestas/Preguntas/Listar';
import detalleEncuestaPreguntas from './Encuestas/Preguntas/Detalle';
import listarDobleViaRespuestasClave from './DobleVia/Respuestas/Listar';
import transaccionesEnviarsaldo from './Transacciones/EnviarSaldo';
import reporteCampanias from './Reportes/Campanias';
import reporteMensajes from './Reportes/Mensajes';
import reporteEncuestas from './Reportes/Encuestas';
import listarGaleria from './Galeria/Listar';
import detalleGaleria from './Galeria/Detalle';


export default function* rootSaga() {
  yield all([
    autenticacionSaga(),
    listarClientes(),
    detalleClientes(),
    listarDestinatarios(),
    detalleDestinatarios(),
    listarListasDestinatarios(),
    detalleListasDestinatarios(),
    listarMensajes(),
    detalleMensajes(),
    enviarMensaje(),
    listarCampanias(),
    listarCampaniasProgramadas(),
    detalleCampaniasProgramadas(),
    detalleCampanias(),
    listarUsuarios(),
    detalleUsuarios(),
    listarDobleViaClaves(),
    detalleDobleViaClaves(),
    listarDobleViaRespuestasClave(),
    listarEncuestas(),
    detalleEncuestas(),
    listarEncuestaPreguntas(),
    detalleEncuestaPreguntas(),
    reporteCampanias(),
    reporteMensajes(),
    reporteEncuestas(),
    transaccionesEnviarsaldo(),
    listarGaleria(),
    detalleGaleria()
  ]);
}
