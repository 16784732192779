import React from 'react';

import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';
import moment from 'moment';

import UIDataTable from '../../../UI/DataTable/DataTable';


const DobleViaRespuestasListar = (props) => (
  <div className="app-module">
    <div className="module-box">
      <div className="module-box-header">
        <div className="module-box-header-inner">
          <div className="module-box-header-right">
            <div className="InputFromTo">
              <DayPickerInput
                value={props.fechaDesde}
                placeholder="Desde"
                format="LL"
                formatDate={formatDate}
                parseDate={parseDate}
                dayPickerProps={{
                  locale: 'es',
                  localeUtils: MomentLocaleUtils,
                  selectedDays: [props.fechaDesde, { from: props.fechaDesde, to: props.fechaHasta }],
                  disabledDays: { after: props.fechaHasta },
                  toMonth: props.fechaHasta,
                  modifiers: { start: props.fechaDesde, end: props.fechaHasta },
                  numberOfMonths: 2
                }}
                inputProps={{
                  className: 'form-control form-control-lg'
                }}
                onDayChange={props.handleDesdeChange}
              />
              {' '}—{' '}
              <span className="InputFromTo-to">
                <DayPickerInput
                  value={props.fechaHasta}
                  placeholder="Hasta"
                  format="LL"
                  formatDate={formatDate}
                  parseDate={parseDate}
                  dayPickerProps={{
                    locale: 'es',
                    localeUtils: MomentLocaleUtils,
                    selectedDays: [props.fechaDesde, { from: props.fechaDesde, to: props.fechaHasta }],
                    disabledDays: { before: props.fechaDesde },
                    month: props.fechaDesde,
                    fromMonth: props.fechaDesde,
                    modifiers: { start: props.fechaDesde, end: props.fechaHasta },
                    numberOfMonths: 2
                  }}
                  inputProps={{
                    className: 'form-control form-control-lg'
                  }}
                  onDayChange={props.handleHastaChange}
                />
              </span>
            </div>
            <Button color="info" className="jr-btn my-0 ml-2" onClick={props.obtenerRespuestasClave}>
              <FontAwesomeIcon icon="sync" size="lg" className="mr-2" />
              <span>Actualizar</span>
            </Button>
            <Button
              color="success"
              className="jr-btn my-0 ml-2"
              onClick={props.exportarReporte}
              disabled={props.cargando || !props.respuestas.length}>
              <FontAwesomeIcon icon="file-excel" size="lg" className="mr-2" />
              <span>Exportar a Excel</span>
            </Button>
          </div>
        </div>
      </div>
      <div className="module-box-content">

        <UIDataTable
          data={props.respuestas}
          manual
          filterable={true}
          filtered={props.filtrado}
          pages={props.paginas}
          page={props.paginacion.pagina}
          pageSize={props.paginacion.tamano_pagina}
          onPageChange={props.cambioPagina}
          onPageSizeChange={props.cambioTamanoPagina}
          onFilteredChange={props.cambioFiltro}
          columns={[
            {
              Header: 'Clave',
              accessor: 'clave__uuid',
              Cell: (row) => row.original.clave.nombre,
              Filter: ({ filter, onChange }) => (
                <select
                  className="form-control form-control-sm"
                  onChange={(event) => onChange(event.target.value)}
                  value={filter ? filter.value : ''}
                >
                  <option value="">Todos</option>
                  {
                    props.claves.map((clave, index) => (
                      <option key={index} value={clave.uuid}>{clave.nombre}</option>
                    ))
                  }
                </select>
              )
            },
            {
              Header: 'Remitente',
              accessor: 'search',
              Cell: (row) => row.original.remitente
            },
            {
              Header: 'Mensaje',
              accessor: 'mensaje',
              filterable: false
            },
            {
              Header: 'Fecha',
              accessor: 'created',
              filterable: false,
              Cell: (row) => `${moment(row.original.created).format('L')} ${moment(row.original.created).format('hh:mm A')}`
            }
          ]}
          noDataText="No se ha encontrando ninguna respuesta"
          rowsText="respuestas"
          loading={props.cargando}
        />
      </div>
    </div>
  </div>
);


export default DobleViaRespuestasListar;
