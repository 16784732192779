export const ORIGEN_DESTINATARIOS_LISTADO = 'CO';
export const ORIGEN_DESTINATARIOS_ARCHIVO = 'AR';

export const ORIGEN_DESTINATARIOS_LISTADO_LABEL = 'Listado de destinatarios';
export const ORIGEN_DESTINATARIOS_ARCHIVO_LABEL = 'Archivo plano (TXT o CSV)';

export const ORIGEN_DESTINATARIOS = [
  {label: ORIGEN_DESTINATARIOS_LISTADO_LABEL, value: ORIGEN_DESTINATARIOS_LISTADO},
  {label: ORIGEN_DESTINATARIOS_ARCHIVO_LABEL, value: ORIGEN_DESTINATARIOS_ARCHIVO}
];
