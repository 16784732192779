import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ScreensDobleViaClavesListar from '../../../screens/DobleVia/Claves/ClavesListar';
import ScreensDobleViaClavesDetalle from '../../../screens/DobleVia/Claves/ClavesDetalle';


const DobleViaClaves = (props) => (
  <div>
    <Switch>
      <Route exact path={`${props.match.url}/`} component={ScreensDobleViaClavesListar}/>
      <Route path={`${props.match.url}/detalle/:uuid_clave`} component={ScreensDobleViaClavesDetalle} />
      <Route path={`${props.match.url}/detalle`} component={ScreensDobleViaClavesDetalle}/>
    </Switch>
  </div>
);


export default DobleViaClaves;
