import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ScreensEncuestasListar from '../../screens/Encuestas/EncuestasListar';
import ScreensEncuestasDetalle from '../../screens/Encuestas/EncuestasDetalle';
import ScreensEncuestasPreguntas from '../../screens/Encuestas/EncuestasPreguntas';
import ScreensEncuestasVistaPrevia from '../../screens/Encuestas/EncuestasVistaPrevia';


const Encuestas = (props) => (
  <div className="app-wrapper">
    <Switch>
      <Route exact path={`${props.match.url}/`} component={ScreensEncuestasListar}/>
      <Route path={`${props.match.url}/detalle/:uuid_encuesta/vista_previa`} component={ScreensEncuestasVistaPrevia} />
      <Route path={`${props.match.url}/detalle/:uuid_encuesta/preguntas`} component={ScreensEncuestasPreguntas} />
      <Route path={`${props.match.url}/detalle/:uuid_encuesta`} component={ScreensEncuestasDetalle} />
      <Route path={`${props.match.url}/detalle`} component={ScreensEncuestasDetalle}/>
    </Switch>
  </div>
);


export default Encuestas;
