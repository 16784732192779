import {
  OBTENER_LISTA_DESTINATARIOS_EXITOSO,
  OBTENER_LISTA_DESTINATARIOS_ERROR,
  GUARDAR_LISTA_DESTINATARIOS_EXITOSO,
  GUARDAR_LISTA_DESTINATARIOS_ERROR,
  GUARDANDO_LISTA_DESTINATARIOS,
  ELIMINAR_LISTA_DESTINATARIOS_EXITOSO,
  ELIMINAR_LISTA_DESTINATARIOS_ERROR,
  ERROR_LISTA_DESTINATARIOS,
  OBTENER_DESTINATARIOS_EXITOSO,
  OBTENER_DESTINATARIOS_ERROR,
  CARGANDO_DESTINATARIOS
} from '../../constants/ActionTypes/ListasDestinatarios';


const listaDestinatariosInicial = {
  lista: {},
  destinatarios: [],
  cantidad: 0,
  paginas: 1,
  error: false,
  mensaje: null,
  guardando: false,
  editando: false,
  eliminando: false,
  guardado_exitoso: false,
  cargando_destinatarios: false
};

const detalleListaDestinatarios = (state=listaDestinatariosInicial, action) => {
  switch (action.type) {

  case OBTENER_LISTA_DESTINATARIOS_EXITOSO: {
    return {
      ...listaDestinatariosInicial,
      lista: action.payload,
      editando: true
    };
  }

  case OBTENER_LISTA_DESTINATARIOS_ERROR: {
    return {
      ...listaDestinatariosInicial,
      error: true,
      mensaje: action.payload,
      editando: true
    };
  }

  case GUARDAR_LISTA_DESTINATARIOS_EXITOSO: {
    return {
      ...listaDestinatariosInicial,
      lista: action.payload.lista,
      mensaje: action.payload.mensaje,
      guardado_exitoso: true
    };
  }

  case GUARDAR_LISTA_DESTINATARIOS_ERROR: {
    return {
      ...listaDestinatariosInicial,
      error: true,
      destinatarios: state.destinatarios,
      cantidad: state.cantidad,
      paginas: state.paginas,
      mensaje: action.payload
    };
  }

  case GUARDANDO_LISTA_DESTINATARIOS: {
    return {
      ...listaDestinatariosInicial,
      destinatarios: state.destinatarios,
      cantidad: state.cantidad,
      paginas: state.paginas,
      guardando: action.payload
    };
  }

  case ELIMINAR_LISTA_DESTINATARIOS_EXITOSO: {
    return {
      ...listaDestinatariosInicial,
      mensaje: action.payload.mensaje,
      editando: true,
      eliminando: true
    };
  }

  case ELIMINAR_LISTA_DESTINATARIOS_ERROR: {
    return {
      ...listaDestinatariosInicial,
      error: true,
      destinatarios: state.destinatarios,
      cantidad: state.cantidad,
      paginas: state.paginas,
      mensaje: action.payload
    };
  }

  case ERROR_LISTA_DESTINATARIOS: {
    return {
      ...listaDestinatariosInicial,
      error: action.payload
    };
  }

  case OBTENER_DESTINATARIOS_EXITOSO: {
    return {
      ...listaDestinatariosInicial,
      ...action.payload,
      lista: state.lista,
    };
  }

  case OBTENER_DESTINATARIOS_ERROR: {
    return {
      ...listaDestinatariosInicial,
      error: true,
      lista: state.lista,
      mensaje: action.payload,
    };
  }

  case CARGANDO_DESTINATARIOS: {
    return {
      ...listaDestinatariosInicial,
      lista: state.lista,
      destinatarios: state.destinatarios,
      cargando_destinatarios: action.payload
    };
  }

  default:
    return state;
  }
};


export default detalleListaDestinatarios;
