import * as Yup from 'yup';

import {
  CAMPO_REQUERIDO,
  CAMPO_EMAIL,
  CAMPO_COINCIDE,
  CAMPO_NUMERO_MINIMO,
  CAMPO_ALFANUMERICO
} from '../../../constants/Forms/Validacion';


export const perfilSchema = Yup.object().shape({
  email: Yup.string()
    .email(CAMPO_EMAIL)
    .required(CAMPO_REQUERIDO),

  actual_password: Yup.string()
    .notRequired(),

  nuevo_password: Yup.string()
    .notRequired()
    .min(8, CAMPO_NUMERO_MINIMO)
    .matches(/^\d*[a-zA-Z][a-zA-Z0-9]*$/, CAMPO_ALFANUMERICO),

  nuevo_password_confirmacion: Yup.string()
    .oneOf([Yup.ref('nuevo_password'), null], CAMPO_COINCIDE)
    .when('nuevo_password', (nuevo_password, schema) => {
      return nuevo_password ?
        schema.required(CAMPO_REQUERIDO)
        :
        schema;
    })
});
