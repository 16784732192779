import React from 'react';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { DRAWER_FIJO, DRAWER_COLAPSADO } from '../../../constants/ActionTypes/Configuracion';
import { toggleNavegacionColapsada } from '../../../actions/Configuracion';
import { cerrarSesionUsuario } from '../../../actions/Autenticacion';
import LayoutCabecera from './Cabecera';
import { USUARIO_SESION } from '../../../constants/Configuracion';


class Cabecera extends React.Component {

  constructor(props) {
    super(props);

    const usuario = JSON.parse(localStorage.getItem(USUARIO_SESION));
    this.state = {
      notificaciones: false,
      infoUsuario: false,
      usuario
    };
  }

  toggleNotificaciones = () => {
    this.setState({
      notificaciones: !this.state.notificaciones
    });
  };

  toggleInfoUsuario = () => {
    this.setState({
      infoUsuario: !this.state.infoUsuario
    });
  };

  toggleNavegacionColapsada = () => {
    const val = !this.props.navegacionColapsada;
    this.props.actions.toggleNavegacionColapsada(val);
  };

  handleCerrarSesion = () => {
    this.props.actions.cerrarSesionUsuario();
  };

  render() {
    const estiloDrawer = this.props.tipoDrawer.includes(DRAWER_FIJO) ?
      'd-flex d-xl-none' : this.props.tipoDrawer.includes(DRAWER_COLAPSADO) ?
        'd-flex' : 'd-none';

    return (
      <LayoutCabecera
        estiloNavegacion={this.props.estiloNavegacion}
        estiloDrawer={estiloDrawer}
        posicionNavegacionHorizontal={this.props.posicionNavegacionHorizontal}
        tituloPagina={this.props.tituloPagina}
        unidades={this.props.unidades}
        usuario={this.state.usuario}
        infoUsuario={this.state.infoUsuario}
        notificaciones={this.state.notificaciones}
        toggleInfoUsuario={this.toggleInfoUsuario}
        toggleNotificaciones={this.toggleNotificaciones}
        toggleNavegacionColapsada={this.toggleNavegacionColapsada}
        handleCerrarSesion={this.handleCerrarSesion}
      />
    );
  }
}


const mapStateToProps = ({ configs }) => {
  const { tipoDrawer, estiloNavegacion, navegacionColapsada, posicionNavegacionHorizontal, tituloPagina, unidades } = configs;
  return { tipoDrawer, estiloNavegacion, navegacionColapsada, posicionNavegacionHorizontal, tituloPagina, unidades };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ toggleNavegacionColapsada, cerrarSesionUsuario }, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Cabecera));
