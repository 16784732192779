import {
  CAMBIAR_ESTILO_NAVEGACION,
  TIPO_DRAWER,
  DRAWER_FIJO,
  POSICION_MENU_HORIZONTAL,
  DENTRO_DE_CABECERA,
  COLOR_TEMA,
  TOGGLE_NAVEGACION_COLAPSADA,
  NAVEGACION_VERTICAL,
  ANCHO_VENTANA,
  TITULO_PAGINA,
  UNIDADES
} from '../constants/ActionTypes/Configuracion';
import { DARK_BLUE } from '../constants/ColoresTema';

const initialSettings = {
  navegacionColapsada: false,
  tipoDrawer: DRAWER_FIJO,
  colorTema: DARK_BLUE,
  ancho: window.innerWidth,
  estiloNavegacion: NAVEGACION_VERTICAL,
  posicionNavegacionHorizontal: DENTRO_DE_CABECERA,
  tituloPagina: '',
  unidades: 0
};

const configuracion = (state=initialSettings, action) => {
  switch (action.type) {

  case '@@router/LOCATION_CHANGE':
    return {
      ...state,
      navegacionColapsada: false
    };
  case TOGGLE_NAVEGACION_COLAPSADA:
    return {
      ...state,
      navegacionColapsada: action.navegacionColapsada
    };
  case TIPO_DRAWER:
    return {
      ...state,
      tipoDrawer: action.tipoDrawer
    };
  case ANCHO_VENTANA:
    return {
      ...state,
      ancho: action.ancho
    };
  case COLOR_TEMA:
    return {
      ...state,
      darkTheme: false,
      colorTema: action.color
    };
  case CAMBIAR_ESTILO_NAVEGACION:
    return {
      ...state,
      estiloNavegacion: action.payload
    };
  case POSICION_MENU_HORIZONTAL:
    return {
      ...state,
      posicionNavegacionHorizontal: action.payload
    };
  case TITULO_PAGINA:
    return {
      ...state,
      tituloPagina: action.payload
    };
  case UNIDADES:
    return {
      ...state,
      unidades: action.payload
    };
  default:
    return state;

  }
};

export default configuracion;
