import { put, call, all, fork, takeEvery } from 'redux-saga/effects';

import { sprintf } from 'sprintf-js';

import {
  OBTENER_MENSAJE,
  GUARDAR_MENSAJE,
  ELIMINAR_MENSAJE,
  OBTENER_LISTAS_DESTINATARIOS,
  OBTENER_GALERIA,
  OBTENER_ENCUESTAS
} from '../../constants/ActionTypes/Mensajes';
import { MENSAJES_URL, MENSAJE_URL } from '../../constants/Urls/Mensajes';
import { LISTAS_DESTINATARIOS_URL } from '../../constants/Urls/ListasDestinatarios';
import { ENCUESTAS_URL } from '../../constants/Urls/Encuestas';
import { GALERIA_ARCHIVOS_URL } from '../../constants/Urls/Galeria';
import {
  obtenerMensajeExitoso,
  obtenerMensajeError,
  guardarMensajeExitoso,
  guardarMensajeError,
  eliminarMensajeExitoso,
  eliminarMensajeError,
  obtenerListasDestinatariosExitoso,
  obtenerListasDestinatariosError,
  obtenerGaleriaExitoso,
  obtenerGaleriaError,
  obtenerEncuestasExitoso,
  obtenerEncuestasError
} from '../../actions/Mensajes/Detalle';
import { httpFetch } from '../../utils/Http/Fetch';
import { setUnidades } from '../../actions/Configuracion';


const obtenerMensajeApi = async (uuid) => {
  const url = sprintf(MENSAJE_URL, { uuid });

  return httpFetch(url, 'GET')
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};

const guardarMensajeApi = async (datos) => {
  const verbo_http = datos.uuid ? 'PUT' : 'POST';
  let url = MENSAJES_URL;

  if (datos.uuid) {
    url = sprintf(MENSAJE_URL, {
      uuid: datos.uuid
    });
  }

  return httpFetch(url, verbo_http, datos)
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};

const eliminarMensajeApi = async (uuid) => {
  const url = sprintf(MENSAJE_URL, {uuid});

  return httpFetch(url, 'DELETE')
    .catch((error) => {
      throw error;
    });
};

const obtenerListasDestinatariosApi = async () => {
  return httpFetch(`${LISTAS_DESTINATARIOS_URL}`, 'GET')
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};

const obtenerGaleriaApi = async () => {
  return httpFetch(`${GALERIA_ARCHIVOS_URL}`, 'GET')
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};

const obtenerEncuestasApi = async () => {
  return httpFetch(`${ENCUESTAS_URL}`, 'GET')
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};


function* obtenerMensaje(action) {
  try {
    yield call(obtenerListasDestinatariosApi);

    const mensaje = yield call(obtenerMensajeApi, action.payload);

    if (typeof mensaje.saldo !== 'undefined') {
      yield put(setUnidades(mensaje.saldo));
    }

    yield put(obtenerMensajeExitoso(mensaje));
  } catch (error) {
    yield put(obtenerMensajeError(error.message));
  }
}

function* guardarMensaje(action) {
  try {
    const mensaje = yield call(guardarMensajeApi, action.payload);
    const accion = action.payload.uuid ? 'actualizado' : 'creado';
    const mensaje_ = `Mensaje ${accion} correctamente.`;

    yield put(guardarMensajeExitoso({mensaje, mensaje_}));
  } catch (error) {
    yield put(guardarMensajeError(error.message));
  }
}

function* eliminarMensaje(action) {
  try {
    yield call(eliminarMensajeApi, action.payload);
    const mensaje = 'Mensaje eliminado correctamente.';

    yield put(eliminarMensajeExitoso({ mensaje }));
  } catch (error) {
    yield put(eliminarMensajeError(error.message));
  }
}

function* obtenerListasDestinatarios() {
  try {
    const listas = yield call(obtenerListasDestinatariosApi);
    let listas_opciones = listas.map((lista) => {
      return {value: lista.uuid, label: `${lista.nombre} (${lista.cantidad_destinatarios} destinatario(s))`, ...lista};
    });
    listas_opciones.unshift({value: '', label: 'Seleccione una opción', columnas_destinatarios: []});

    yield put(obtenerListasDestinatariosExitoso(listas_opciones));
  } catch (error) {
    yield put(obtenerListasDestinatariosError(error.message));
  }
}

function* obtenerGaleria() {
  try {
    const galeria = yield call(obtenerGaleriaApi);
    let galeria_opciones = galeria.map((archivo) => {
      return {value: archivo.uuid, label: `${archivo.nombre} (${archivo.tipo_archivo})`, ...archivo};
    });
    galeria_opciones.unshift({value: '', label: 'Seleccione una opción'});

    yield put(obtenerGaleriaExitoso(galeria_opciones));
  } catch (error) {
    yield put(obtenerGaleriaError(error.message));
  }
}

function* obtenerEncuestas() {
  try {
    const encuestas = yield call(obtenerEncuestasApi);
    let encuestas_opciones = encuestas.map((encuesta) => {
      return {value: encuesta.uuid, label: encuesta.nombre, ...encuesta};
    });
    encuestas_opciones.unshift({value: '', label: 'Seleccione una opción'});

    yield put(obtenerEncuestasExitoso(encuestas_opciones));
  } catch (error) {
    yield put(obtenerEncuestasError(error.message));
  }
}


export function* obtenerMensajeWatcher() {
  yield takeEvery (OBTENER_MENSAJE, obtenerMensaje);
}

export function* guardarMensajeWatcher() {
  yield takeEvery (GUARDAR_MENSAJE, guardarMensaje);
}

export function* eliminarMensajeWatcher() {
  yield takeEvery (ELIMINAR_MENSAJE, eliminarMensaje);
}

export function* obtenerListasDestinatariosWatcher() {
  yield takeEvery (OBTENER_LISTAS_DESTINATARIOS, obtenerListasDestinatarios);
}

export function* obtenerGaleriaWatcher() {
  yield takeEvery (OBTENER_GALERIA, obtenerGaleria);
}

export function* obtenerEncuestasWatcher() {
  yield takeEvery (OBTENER_ENCUESTAS, obtenerEncuestas);
}

export default function* rootSaga() {
  yield all ([
    fork (guardarMensajeWatcher),
    fork (obtenerMensajeWatcher),
    fork (eliminarMensajeWatcher),
    fork (obtenerListasDestinatariosWatcher),
    fork (obtenerGaleriaWatcher),
    fork (obtenerEncuestasWatcher)
  ]);
}
