import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ScreensDobleViaRespuestasListar from '../../../screens/DobleVia/Respuestas/RespuestasListar';


const DobleViaRespuestas = (props) => (
  <div>
    <Switch>
      <Route exact path={`${props.match.url}/`} component={ScreensDobleViaRespuestasListar}/>
    </Switch>
  </div>
);


export default DobleViaRespuestas;
