export const TIPO_DOCUMENTO_CEDULA = 'CC';
export const TIPO_DOCUMENTO_PASAPORTE = 'PS';
export const TIPO_DOCUMENTO_CEDULA_EXTRANJERIA = 'CE';
export const TIPO_DOCUMENTO_NIT = 'NT';

export const TIPO_DOCUMENTO_CEDULA_LABEL = 'Cédula de ciudadania';
export const TIPO_DOCUMENTO_PASAPORTE_LABEL = 'Pasaporte';
export const TIPO_DOCUMENTO_CEDULA_EXTRANJERIA_LABEL = 'Cédula de extranjería';
export const TIPO_DOCUMENTO_NIT_LABEL = 'NIT';

export const TIPOS_DOCUMENTO = [
  {label: TIPO_DOCUMENTO_CEDULA_LABEL, value: TIPO_DOCUMENTO_CEDULA},
  {label: TIPO_DOCUMENTO_PASAPORTE_LABEL, value: TIPO_DOCUMENTO_PASAPORTE},
  {label: TIPO_DOCUMENTO_CEDULA_EXTRANJERIA_LABEL, value: TIPO_DOCUMENTO_CEDULA_EXTRANJERIA},
  {label: TIPO_DOCUMENTO_NIT_LABEL, value: TIPO_DOCUMENTO_NIT}
];

export const TIPOS_DOCUMENTO_NATURAL = [
  {label: TIPO_DOCUMENTO_CEDULA_LABEL, value: TIPO_DOCUMENTO_CEDULA},
  {label: TIPO_DOCUMENTO_PASAPORTE_LABEL, value: TIPO_DOCUMENTO_PASAPORTE},
  {label: TIPO_DOCUMENTO_CEDULA_EXTRANJERIA_LABEL, value: TIPO_DOCUMENTO_CEDULA_EXTRANJERIA}
];

export const TIPOS_DOCUMENTO_JURIDICA = [
  {label: TIPO_DOCUMENTO_NIT_LABEL, value: TIPO_DOCUMENTO_NIT}
];
