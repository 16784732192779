import React from 'react';
import { NavLink } from 'react-router-dom';

import { Col, Row, Button, FormText, FormGroup, Label, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Form } from 'formik';
import moment from 'moment';

import UIFormsCampoFormInput from '../../UI/Forms/CampoForm/Input';
import { archivoSchema } from './Schema';


const DestinatariosDetalle = (props) => (
  <Formik
    enableReinitialize
    initialValues={props.valoresIniciales}
    onSubmit={props.handleSubmit}
    validationSchema={archivoSchema}
    validateOnChange
  >
    {formikProps => (
      <Form>
        <Row>
          <Col sm={12}>
            <Row form>
              <Col md={6}>
                <UIFormsCampoFormInput
                  id="nombre"
                  type="text"
                  name="nombre"
                  label="Nombre"
                  labelClassName="requerido"
                  placeholder=""
                  autoComplete="off"
                  error={formikProps.errors.nombre ? formikProps.errors.nombre : null}
                  touched={formikProps.touched.nombre ? formikProps.touched.nombre : null}
                />
              </Col>
              <Col md={6}>
                <UIFormsCampoFormInput
                  id="tiempo_validez"
                  type="number"
                  name="tiempo_validez"
                  label="Tiempo de validez (en días)"
                  labelClassName="requerido"
                  groupClassName="mb-0"
                  placeholder=""
                  autoComplete="off"
                  error={formikProps.errors.tiempo_validez ? formikProps.errors.tiempo_validez : null}
                  touched={formikProps.touched.tiempo_validez ? formikProps.touched.tiempo_validez : null}
                />
                {
                  formikProps.values.uuid &&
                    <FormText color="muted">
                      {
                        formikProps.values.url_corto.vencido ?
                          <span>
                            Venció el <b>{moment(formikProps.values.url_corto.fecha_vencimiento).format('LL')}</b>.
                            <Badge color="danger" pill className="ml-1">VENCIDO</Badge>
                          </span>
                          :
                          <span>
                            Vence el <b>{moment(formikProps.values.url_corto.fecha_vencimiento).format('LL')}</b>.
                            <Badge color="success" pill className="ml-1">SIN VENCER</Badge>
                          </span>
                      }
                    </FormText>
                }
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Row form>
              {
                formikProps.values.uuid ?
                  <Col sm="6">
                    <FormGroup className="mb-0">
                      <Label>Archivo cargado</Label>
                      <div className="form-control form-control-lg">
                        Archivo tipo {formikProps.values.tipo_archivo.toLowerCase()}.
                        <a href={formikProps.values.archivo} target="_BLANK" rel="noopener noreferrer" className="ml-1">
                          Descargar archivo.
                        </a>
                      </div>
                    </FormGroup>
                    <FormText color="muted">
                      URL Corto <b>{formikProps.values.url_corto.url_corto}</b>.
                    </FormText>
                  </Col>
                  :
                  <Col sm="6">
                    <div className="form-group">
                      <label className="requerido" htmlFor="archivo">Seleccione el archivo que desea cargar</label>
                      <input
                        id="archivo"
                        className="form-control"
                        name="archivo"
                        type="file"
                        accept="image/*, application/pdf"
                        onChange={(e) => {
                          formikProps.setFieldValue('archivo', e.currentTarget.files[0]);
                        }}
                      />
                      <FormText color="muted">
                        Seleccione un archivo de tipo <b>PNG</b>, <b>JPG</b>, <b>JPEG</b> o <b>PDF</b>.
                      </FormText>
                    </div>
                  </Col>
              }
              <Col md={6}>
                <UIFormsCampoFormInput
                  id="url_destino"
                  type="text"
                  name="url_destino"
                  label="URL destino"
                  placeholder="Ejemplo: https://go-sms.co"
                  autoComplete="off"
                  error={formikProps.errors.url_destino ? formikProps.errors.url_destino : null}
                  touched={formikProps.touched.url_destino ? formikProps.touched.url_destino : null}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <hr/>
            <div className="text-center">
              <Button color="success" className="jr-btn" type="submit" disabled={props.guardando}>
                {
                  props.guardando ?
                    <FontAwesomeIcon icon="circle-notch" size="lg" spin className="mr-2" />
                    :
                    <FontAwesomeIcon icon="check" size="lg" className="mr-2" />
                }
                Guardar archivo
              </Button>

              <NavLink className="jr-btn btn btn-default" to='/galeria'>
                <span>Regresar al listado</span>
              </NavLink>

              {
                formikProps.values.uuid &&
                <Button
                  color="danger"
                  className="jr-btn fa-pull-right"
                  type="button"
                  onClick={() => props.handleEliminar(formikProps.values.uuid)}>
                  <FontAwesomeIcon icon="ban" size="lg" className="mr-2"/>
                  Eliminar archivo
                </Button>
              }
            </div>
          </Col>
        </Row>
      </Form>
    )}
  </Formik>
);


export default DestinatariosDetalle;
