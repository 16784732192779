import * as Yup from 'yup';

import {
  CAMPO_REQUERIDO,
  CAMPO_LONGITUD_MAXIMA
} from '../../../constants/Forms/Validacion';


export const enviarMensajeSchema = Yup.object().shape({
  destinatarios: Yup.string()
    .notRequired(),

  lista: Yup.object({
    label: Yup.string(),
    value: Yup.string()
  })
    .nullable(),

  texto_mensaje: Yup.string()
    .max(160, CAMPO_LONGITUD_MAXIMA)
    .required(CAMPO_REQUERIDO),
});
