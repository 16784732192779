import React from 'react';
import { Route, Switch } from 'react-router-dom';

import DobleViaClaves from './Claves';
import DobleViaRespuestas from './Respuestas';


const DobleVia = (props) => (
  <div className="app-wrapper">
    <Switch>
      <Route path={`${props.match.url}/claves`} component={DobleViaClaves}/>
      <Route path={`${props.match.url}/respuestas`} component={DobleViaRespuestas}/>
    </Switch>
  </div>
);


export default DobleVia;
