import { put, call, all, fork, takeEvery } from 'redux-saga/effects';

import {
  ENVIAR_MENSAJE,
  ENVIAR_OBTENER_MENSAJES,
  ENVIAR_OBTENER_LISTAS_DESTINATARIOS
} from '../../constants/ActionTypes/Mensajes';
import { MENSAJES_URL, ENVIAR_MENSAJE_URL } from '../../constants/Urls/Mensajes';
import { LISTAS_DESTINATARIOS_URL } from '../../constants/Urls/ListasDestinatarios';
import {
  enviarMensajeExitoso,
  enviarMensajeError,
  obtenerMensajesExitoso,
  obtenerMensajesError,
  obtenerListasDestinatariosExitoso,
  obtenerListasDestinatariosError
} from '../../actions/Mensajes/Enviar';
import { httpFetch } from '../../utils/Http/Fetch';
import { setUnidades } from '../../actions/Configuracion';


const enviarMensajeApi = async (datos) => {
  let url = ENVIAR_MENSAJE_URL;

  return httpFetch(url, 'POST', datos, {'Content-Type': 'application/json'})
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};

const obtenerMensajesApi = async () => {
  return httpFetch(`${MENSAJES_URL}?only_raw=true`, 'GET')
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};

const obtenerListasDestinatariosApi = async () => {
  return httpFetch(`${LISTAS_DESTINATARIOS_URL}`, 'GET')
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};


function* enviarMensaje(action) {
  try {
    const mensaje = yield call(enviarMensajeApi, action.payload);

    let accion = action.payload.fecha_inicio && action.payload.fecha_finalizacion ? 'programado' : 'enviado';
    const mensaje_ = `Mensaje ${accion} exitosamente.`;

    if (typeof mensaje.saldo !== 'undefined') {
      yield put(setUnidades(mensaje.saldo));
    }

    yield put(enviarMensajeExitoso({mensaje, mensaje_}));
  } catch (error) {
    yield put(enviarMensajeError(error.message));
  }
}

function* obtenerMensajes() {
  try {
    const mensajes = yield call(obtenerMensajesApi);
    let mensajes_opciones = mensajes.map((mensaje) => {
      return {value: mensaje.uuid, label: mensaje.nombre, ...mensaje};
    });
    mensajes_opciones.unshift({value: '', label: 'Seleccione una opción'});

    yield put(obtenerMensajesExitoso(mensajes_opciones));
  } catch (error) {
    yield put(obtenerMensajesError(error.message));
  }
}

function* obtenerListasDestinatarios() {
  try {
    const listas = yield call(obtenerListasDestinatariosApi);
    let listas_opciones = listas.map((lista) => {
      return {value: lista.uuid, label: `${lista.nombre} (${lista.cantidad_destinatarios} destinatario(s))`, ...lista};
    });
    listas_opciones.unshift({value: '', label: 'Seleccione una opción', columnas_destinatarios: []});

    yield put(obtenerListasDestinatariosExitoso(listas_opciones));
  } catch (error) {
    yield put(obtenerListasDestinatariosError(error.message));
  }
}


export function* enviarMensajeWatcher() {
  yield takeEvery (ENVIAR_MENSAJE, enviarMensaje);
}

export function* obtenerMensajesWatcher() {
  yield takeEvery (ENVIAR_OBTENER_MENSAJES, obtenerMensajes);
}

export function* obtenerListasDestinatariosWatcher() {
  yield takeEvery (ENVIAR_OBTENER_LISTAS_DESTINATARIOS, obtenerListasDestinatarios);
}

export default function* rootSaga() {
  yield all ([
    fork (enviarMensajeWatcher),
    fork (obtenerMensajesWatcher),
    fork (obtenerListasDestinatariosWatcher)
  ]);
}
