import React from 'react';


import UIBreadcrumbContainer from '../../../components/UI/Breadcrumb/BreadcrumbContainer';
import ReportesCampaniasListarContainer from '../../../components/Reportes/Campanias/Listar/ListarContainer';


const ScreensReportesCampanias = () => (
  <div className="animated slideInUpTiny animation-duration-3">

    <UIBreadcrumbContainer></UIBreadcrumbContainer>

    <ReportesCampaniasListarContainer />
  </div>
);


export default ScreensReportesCampanias;
