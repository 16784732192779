import React from 'react';

import { Button, Row, Col, Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import moment from 'moment';

import UIDataTable from '../../../UI/DataTable/DataTable';
import { ESTADOS_CAMPANIA } from '../../../../constants/EnviarCampanias/Estados';
import { TIPOS_MENSAJE } from '../../../../constants/Mensajes/Tipos';

import 'react-day-picker/lib/style.css';


const ReportesMensajesListar = (props) => (
  <div className="app-module">
    <div className="module-box">
      <div className="module-box-header">
        <div className="module-box-header-inner">
          <div className="module-box-header-right">
            <div className="InputFromTo">
              <DayPickerInput
                value={props.fechaDesde}
                placeholder="Desde"
                format="LL"
                formatDate={formatDate}
                parseDate={parseDate}
                dayPickerProps={{
                  locale: 'es',
                  localeUtils: MomentLocaleUtils,
                  selectedDays: [props.fechaDesde, { from: props.fechaDesde, to: props.fechaHasta }],
                  disabledDays: { after: props.fechaHasta },
                  toMonth: props.fechaHasta,
                  modifiers: { start: props.fechaDesde, end: props.fechaHasta },
                  numberOfMonths: 2
                }}
                inputProps={{
                  className: 'form-control form-control-lg'
                }}
                onDayChange={props.handleDesdeChange}
              />
              {' '}—{' '}
              <span className="InputFromTo-to">
                <DayPickerInput
                  value={props.fechaHasta}
                  placeholder="Hasta"
                  format="LL"
                  formatDate={formatDate}
                  parseDate={parseDate}
                  dayPickerProps={{
                    locale: 'es',
                    localeUtils: MomentLocaleUtils,
                    selectedDays: [props.fechaDesde, { from: props.fechaDesde, to: props.fechaHasta }],
                    disabledDays: { before: props.fechaDesde },
                    month: props.fechaDesde,
                    fromMonth: props.fechaDesde,
                    modifiers: { start: props.fechaDesde, end: props.fechaHasta },
                    numberOfMonths: 2
                  }}
                  inputProps={{
                    className: 'form-control form-control-lg'
                  }}
                  onDayChange={props.handleHastaChange}
                />
              </span>
            </div>
            <Button color="info" className="jr-btn my-0 ml-2" onClick={props.obtenerReporte}>
              <FontAwesomeIcon icon="sync" size="lg" className="mr-2" />
              <span>Actualizar</span>
            </Button>
            <Button
              color="success"
              className="jr-btn my-0 ml-2"
              onClick={props.exportarReporte}
              disabled={props.cargando || !props.datos.length}>
              <FontAwesomeIcon icon="file-excel" size="lg" className="mr-2" />
              <span>Exportar a Excel</span>
            </Button>
          </div>
        </div>
      </div>
      <div className="module-box-content">

        <Row>
          <Col md={12}>
            <div className="height-300 m-auto">
              {
                props.cargando &&
                  <Alert color="light" className="text-center">
                    <FontAwesomeIcon icon="sync" spin size="lg" className="mr-2" />
                    Cargando reporte...
                  </Alert>
              }

              {
                !props.cargando && !props.datos.length &&
                  <Alert color="info" className="text-center">
                    <FontAwesomeIcon icon="info-circle" size="lg" className="mr-2" />
                    No se ha econtrado ningún mensaje
                  </Alert>
              }
              <ResponsiveContainer>
                <PieChart>
                  <Pie
                    data={props.datos}
                    dataKey="value"
                    nameKey="name"
                    fill="#2196F3"
                    innerRadius={30}
                    minAngle={10}
                    labelLine={false}
                    label={true}>
                    {
                      props.datos.map((entry, index) => <Cell key={index} fill={entry.color} />)
                    }
                  </Pie>
                  <Tooltip/>
                </PieChart>
              </ResponsiveContainer>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <UIDataTable
              data={props.mensajes}
              filterable={true}
              columns={[
                {
                  Header: 'Fecha',
                  filterable: false,
                  accessor: 'created',
                  Cell: (row) => row.original.created ?
                    `${moment(row.original.created).format('L')} ${moment(row.original.created).format('hh:mm A')}`
                    :
                    null
                },
                {
                  Header: 'Número',
                  accessor: 'numero'
                },
                {
                  Header: 'Estado',
                  accessor: 'estado',
                  Cell: (row) => {
                    let estado = ESTADOS_CAMPANIA.find((estado) => estado.value === row.original.estado);

                    return estado ? estado.label : 'Desconocido';
                  },
                  Filter: ({ filter, onChange }) => (
                    <select
                      className="form-control form-control-sm"
                      onChange={(event) => onChange(event.target.value)}
                      value={filter ? filter.value : ''}
                    >
                      <option value="">Todos</option>
                      {
                        ESTADOS_CAMPANIA.map((estado, index) => (
                          <option key={index} value={estado.value}>{estado.label}</option>
                        ))
                      }
                    </select>
                  )
                },
                {
                  Header: 'Tipo',
                  accessor: 'tipo',
                  Cell: (row) => row.original.tipo.toUpperCase(),
                  Filter: ({ filter, onChange }) => (
                    <select
                      className="form-control form-control-sm"
                      onChange={(event) => onChange(event.target.value)}
                      value={filter ? filter.value : ''}
                    >
                      <option value="">Todos</option>
                      {
                        TIPOS_MENSAJE.map((estado, index) => (
                          <option key={index} value={estado.value.toLowerCase()}>{estado.label}</option>
                        ))
                      }
                    </select>
                  )
                },
                {
                  Header: 'Mensaje',
                  filterable: false,
                  accessor: 'mensaje'
                },
                {
                  Header: 'Observaciones',
                  filterable: false,
                  accessor: 'observaciones'
                }
              ]}
              noDataText="No se ha encontrando ningún mensaje"
              rowsText="mensajes"
              loading={props.cargando}
            />
          </Col>
        </Row>
      </div>
    </div>
  </div>
);


export default ReportesMensajesListar;
