import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { toast } from 'react-toastify';

import { inicioSesionUsuario, setAutenticando } from '../../../actions/Autenticacion';
import AutenticacionLogin from './Login';


class AutenticacionLoginContainer extends Component {
  valoresIniciales = {
    email: '',
    clave: ''
  };

  iniciarSesion = (values) => {
    this.props.actions.setAutenticando(true);

    let { email, clave } = values;
    this.props.actions.inicioSesionUsuario({ email, clave });
  };

  componentDidUpdate(props) {
    if (this.props.error) {
      if (this.props.mensaje) {
        toast.error(this.props.mensaje);
      }

      if (this.props.autenticando) {
        this.props.actions.setAutenticando(false);
      }
    } else if (props.autenticando && this.props.usuario !== null) {
      toast.success(this.props.mensaje);
      this.props.history.push('/');
    }
  }

  render() {
    return (
      <AutenticacionLogin
        iniciarSesion={this.iniciarSesion}
        valoresIniciales={this.valoresIniciales}
        autenticando={this.props.autenticando}
      />

    );
  }
}


const mapStateToProps = ({ autenticacion }) => {
  const { urlInicial, usuario, autenticando, error, mensaje } = autenticacion;
  return { urlInicial, usuario, autenticando, error, mensaje };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ inicioSesionUsuario, setAutenticando }, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AutenticacionLoginContainer));
