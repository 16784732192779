import React from 'react';

import { Link } from 'react-router-dom';

import AutenticacionLoginContainer from '../../components/Autenticacion/Login/LoginContainer';

import logo from '../../images/gosms-logo.png';


const AutenticacionLogin = () => (
  <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
    <div className="app-login-main-content">

      <div className="app-logo-content d-flex align-items-center justify-content-center">
        <Link className="logo-lg" to="/" title="Makiltech CRM">
          {/* <img src="http://via.placeholder.com/177x65" alt="Makiltech CRM" title="Makiltech CRM" /> */}
          <img src={logo} alt="Go SMS" title="Go SMS" width={170}/>
        </Link>
      </div>

      <div className="app-login-content">
        <div className="app-login-header mb-4">
          <h1>Ingresa con tu cuenta</h1>
        </div>

        <div className="app-login-form">

          <AutenticacionLoginContainer />

        </div>
      </div>
    </div>
  </div>
);


export default AutenticacionLogin;
