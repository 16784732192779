import React from 'react';
import { NavLink } from 'react-router-dom';

import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import UIDataTable from '../../UI/DataTable/DataTable';
import {
  ORIGEN_DESTINATARIOS_ARCHIVO,
  ORIGEN_DESTINATARIOS_ARCHIVO_LABEL,
  ORIGEN_DESTINATARIOS_LISTADO_LABEL
} from '../../../constants/ListasDestinatarios/Origenes';


const ListasDestinatariosListar = (props) => (
  <div className="app-module">
    <div className="module-box">
      <div className="module-box-header">
        <div className="module-box-header-inner">
          <div className="module-box-header-right">
            <Button color="info" className="jr-btn" onClick={props.obtenerListasDestinatarios}>
              <FontAwesomeIcon icon="sync" size="lg" className="mr-2" />
              <span>Actualizar</span>
            </Button>

            <NavLink className="jr-btn btn btn-primary" to='/listas/detalle'>
              <FontAwesomeIcon icon="plus-circle" size="lg" className="mr-2" />
              <span>Crear lista</span>
            </NavLink>
          </div>
        </div>
      </div>
      <div className="module-box-content">

        <UIDataTable
          data={props.listas}
          manual
          filterable={true}
          filtered={props.filtrado}
          pages={props.paginas}
          page={props.paginacion.pagina}
          pageSize={props.paginacion.tamano_pagina}
          onPageChange={props.cambioPagina}
          onPageSizeChange={props.cambioTamanoPagina}
          onFilteredChange={props.cambioFiltro}
          columns={[
            {
              Header: 'Nombre de la lista',
              accessor: 'search',
              Cell: (row) => row.original.nombre
            },
            {
              Header: 'Origen de los destinatarios',
              accessor: 'origen',
              filterable: false,
              Cell: (row) => (
                row.original.origen === ORIGEN_DESTINATARIOS_ARCHIVO ?
                  ORIGEN_DESTINATARIOS_ARCHIVO_LABEL
                  :
                  ORIGEN_DESTINATARIOS_LISTADO_LABEL
              )
            },
            {
              Header: 'Cantidad de destinatarios',
              accessor: 'cantidad_destinatarios',
              filterable: false,
            }
          ]}
          getTrProps={(state, rowInfo) => ({
            onClick: () => {
              props.editarListaDestinatarios(rowInfo.original.uuid);
            }
          })}
          noDataText="No se ha encontrando ninguna lista de destinatarios"
          rowsText="listas"
          loading={props.cargando}
        />
      </div>
    </div>
  </div>
);


export default ListasDestinatariosListar;
