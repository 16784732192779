import React from 'react';
import { NavLink } from 'react-router-dom';

import { Col, Row, Button, Collapse, Card } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Form } from 'formik';
import { WithOutContext as ReactTags } from 'react-tag-input';

import UIFormsCampoFormInput from '../../../UI/Forms/CampoForm/Input';
import UIFormsCampoFormMensaje from '../../../UI/Forms/CampoForm/Mensaje/Mensaje';
import { claveSchema } from './Schema';
import { CODIGOS_TECLADO } from '../../../../constants/Mensajes/CodigosTeclado';


const MensajesDetalle = (props) => (
  <Formik
    enableReinitialize
    initialValues={props.valoresIniciales}
    onSubmit={props.handleSubmit}
    validationSchema={claveSchema}
    validateOnChange
  >
    {formikProps => (
      <Form>
        <Row>
          <Col sm={12}>
            <Row form>
              <Col md={12}>
                <UIFormsCampoFormInput
                  id="nombre"
                  type="text"
                  name="nombre"
                  label="Código doble vía"
                  labelClassName="requerido"
                  className="text-center text-uppercase"
                  placeholder="Ingrese un código de 6 caracteres para usar en mensajes doble vía"
                  autoComplete="off"
                  error={formikProps.errors.nombre ? formikProps.errors.nombre : null}
                  touched={formikProps.touched.nombre ? formikProps.touched.nombre : null}
                />
              </Col>
            </Row>
            <Row form>
              <Col md={12}>
                <UIFormsCampoFormInput
                  id="descripcion"
                  type="textarea"
                  name="descripcion"
                  label="Descripción"
                  labelClassName="requerido"
                  placeholder=""
                  autoComplete="off"
                  error={formikProps.errors.descripcion ? formikProps.errors.descripcion : null}
                  touched={formikProps.touched.descripcion ? formikProps.touched.descripcion : null}
                />
              </Col>
            </Row>
          </Col>
          <Col sm={12}>
            <Button color="light" className="jr-btn" type="button" onClick={props.toggleProcedimiento}>
              {
                props.procedimiento.activo ?
                  <FontAwesomeIcon icon={'check-circle'} size="lg" className="mr-2" />
                  :
                  <FontAwesomeIcon icon={['far', 'circle']} size="lg" className="mr-2" />
              }
              Programar procedimiento
            </Button>
            <Collapse isOpen={props.procedimiento.activo}>
              <Row>
                <Col md={12}>
                  <Card body>
                    <Row form>
                      <Col md={12}>
                        <label htmlFor="destinatarios">Tipo de procedimiento</label>
                        <p><b className="text-uppercase">Envío de mensaje de confirmación</b></p>
                      </Col>
                    </Row>
                    <Row form>
                      <Col md={12}>
                        <label htmlFor="destinatarios">Administrador(es) de confirmaciones</label>
                        <ReactTags
                          classNames={{
                            tags: 'h2',
                            tag: 'badge badge-pill badge-light',
                            tagInput: 'form-group',
                            tagInputField: 'form-control form-control-lg'
                          }}
                          placeholder="Agregue los números de destinatarios separados por coma"
                          name="destinatarios"
                          id="destinatarios"
                          allowDragDrop={false}
                          inline={false}
                          tags={props.destinatarios}
                          delimiters={CODIGOS_TECLADO}
                          handleDelete={props.eliminarDestinatario}
                          handleAddition={props.agregarDestinatario}
                          handleInputBlur={(destinatario) => props.agregarDestinatario({id: destinatario, text: destinatario})}
                        />
                      </Col>
                    </Row>
                    <Row form>
                      <Col md={12}>
                        <UIFormsCampoFormMensaje
                          id="mensaje"
                          name="mensaje"
                          label="Escribir mensaje de respuesta"
                          labelClassName="requerido"
                          formikProps={formikProps}
                          placeholder="Dígite el mensaje que sea enviar."
                          longitudMaxima={160}
                          columnas={false}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Collapse>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <hr/>
            <div className="text-center">
              <Button color="success" className="jr-btn" type="submit" disabled={props.guardando}>
                {
                  props.guardando ?
                    <FontAwesomeIcon icon="circle-notch" size="lg" spin className="mr-2" />
                    :
                    <FontAwesomeIcon icon="check" size="lg" className="mr-2" />
                }
                Guardar clave
              </Button>

              <NavLink className="jr-btn btn btn-default" to='/doble_via/claves'>
                <span>Regresar al listado</span>
              </NavLink>

              {
                formikProps.values.uuid &&
                <Button
                  color="danger"
                  className="jr-btn fa-pull-right"
                  type="button"
                  onClick={() => props.handleEliminar(formikProps.values.uuid)}>
                  <FontAwesomeIcon icon="ban" size="lg" className="mr-2"/>
                  Eliminar clave
                </Button>
              }
            </div>
          </Col>
        </Row>
      </Form>
    )}
  </Formik>
);


export default MensajesDetalle;
