import { put, call, all, fork, takeEvery } from 'redux-saga/effects';

import { ENVIAR_SALDO, OBTENER_CLIENTES } from '../../constants/ActionTypes/Transacciones';
import { ENVIAR_SALDO_URL } from '../../constants/Urls/Transacciones';
import { CLIENTES_URL } from '../../constants/Urls/Clientes';
import {
  enviarSaldoExitoso,
  enviarSaldoError,
  obtenerClientesExitoso,
  obtenerClientesError
} from '../../actions/Transacciones/EnviarSaldo';
import { setUnidades } from '../../actions/Configuracion';
import { httpFetch } from '../../utils/Http/Fetch';


const enviarSaldoApi = async (datos) => {
  return httpFetch(`${ENVIAR_SALDO_URL}`, 'POST', datos)
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};

const obtenerClientesApi = async (filtros) => {
  let esc = encodeURIComponent;
  let params = Object.keys(filtros).map(filtro => esc(filtro) + '=' + esc(filtros[filtro])).join('&');

  return httpFetch(`${CLIENTES_URL}?${params}`, 'GET')
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};


function* enviarSaldo(action) {
  try {
    const mensaje = yield call(enviarSaldoApi, action.payload);

    if (typeof mensaje.saldo !== 'undefined') {
      yield put(setUnidades(mensaje.saldo));
    }

    yield put(enviarSaldoExitoso(mensaje.detail));
  } catch (error) {
    yield put(enviarSaldoError(error.message));
  }
}

function* obtenerClientes(action) {
  try {
    const { filtros } = action.payload;
    const clientes = yield call(obtenerClientesApi, filtros);
    let clientes_opciones = clientes.map((cliente) => {
      return {value: cliente.uuid, label: `${cliente.nombres} ${cliente.apellidos}`, ...cliente};
    });

    yield put(obtenerClientesExitoso(clientes_opciones));
  } catch (error) {
    yield put(obtenerClientesError(error.message));
  }
}


export function* enviarSaldoWatcher() {
  yield takeEvery (ENVIAR_SALDO, enviarSaldo);
}

export function* obtenerClientesWatcher() {
  yield takeEvery (OBTENER_CLIENTES, obtenerClientes);
}

export default function* rootSaga() {
  yield all ([
    fork (enviarSaldoWatcher),
    fork (obtenerClientesWatcher)
  ]);
}
