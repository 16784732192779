import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import _ from 'lodash';
import { toast } from 'react-toastify';

import {
  TIPOS_PERSONA,
  TIPO_PERSONA_NATURAL
} from '../../../constants/Clientes/TiposPersona';
import {
  TIPOS_DOCUMENTO,
  TIPOS_DOCUMENTO_NATURAL,
  TIPOS_DOCUMENTO_JURIDICA
} from '../../../constants/Clientes/TiposDocumento';
import {
  obtenerCliente,
  guardarCliente,
  guardandoCliente,
  obtenerCiudades
} from '../../../actions/Clientes/Detalle';
import { setTituloPagina } from '../../../actions/Configuracion';
import ClientesDetalle from './Detalle';


class ClientesDetalleContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tiposDocumento: TIPOS_DOCUMENTO_NATURAL,
      cliente: {
        nombres: '',
        apellidos: '',
        tipo_persona: TIPOS_PERSONA[0],
        tipo_documento: TIPOS_DOCUMENTO_NATURAL[0],
        numero_documento: '',
        email: '',
        celular: '',
        ciudad: '',
        precio_formateado: '',
        direccion: ''
      }
    };
  }

  setTiposDocumento = (tipoPersona) => {
    const tiposDocumento = (tipoPersona === TIPO_PERSONA_NATURAL) ? TIPOS_DOCUMENTO_NATURAL : TIPOS_DOCUMENTO_JURIDICA;
    this.setState({
      tiposDocumento
    });

    return tiposDocumento;
  };

  cargarCiudades = (termino) => {
    let filtros = {search: termino};
    this.props.actions.obtenerCiudades({ filtros });
  };

  cargarCliente = (cliente) => {
    cliente.tipo_persona = TIPOS_PERSONA.find(tipo => tipo.value === cliente.tipo_persona);
    cliente.tipo_documento = TIPOS_DOCUMENTO.find(tipo => tipo.value === cliente.tipo_documento);
    cliente.precio_formateado = _.clone(cliente.precio_venta);
    cliente.ciudad = {label: cliente.ciudad.display_name, value: cliente.ciudad.uuid};

    this.setState({
      cliente
    });
  };

  handleActualizacionCliente = () => {
    toast.success(this.props.mensaje);
    this.props.history.push('/clientes');
  };

  handleSubmit = (values) => {
    let datos = _.cloneDeep(values);

    datos.tipo_persona = values.tipo_persona.value;
    datos.tipo_documento = values.tipo_documento.value;
    datos.ciudad = values.ciudad.value;

    this.props.actions.guardandoCliente(true);
    this.props.actions.guardarCliente(datos);
  };

  componentDidMount() {
    this.props.actions.setTituloPagina({titulo: 'Clientes', icono: 'user-tie'});

    if (this.props.match.params.uuid_cliente) {
      this.props.actions.obtenerCliente(this.props.match.params.uuid_cliente);
    }
  }

  componentDidUpdate(props) {
    // Verificar si existió algún error
    if (this.props.error) {
      if (this.props.mensaje) {
        toast.error(this.props.mensaje);
      }

      if (this.props.guardando) {
        this.props.actions.guardandoCliente(false);
      }

    // Verificar si es una edición de clientes
    } else if (this.props.editando) {
      if (this.props.eliminando) {
        this.handleActualizacionCliente();
      } else if (props.cliente !== this.props.cliente) {
        this.cargarCliente(this.props.cliente);
      } else if (this.props.guardado_exitoso) {
        this.handleActualizacionCliente();
      }

    // Verificar si es creación de un nuevo cliente
    } else if (this.props.guardado_exitoso) {
      this.handleActualizacionCliente();
    }
  }

  render() {
    return (
      <ClientesDetalle
        handleSubmit={this.handleSubmit}
        valoresIniciales={this.state.cliente}
        setTiposDocumento={this.setTiposDocumento}
        tiposDocumento={this.state.tiposDocumento}
        guardando={this.props.guardando}
        ciudades={this.props.ciudades}
        cargarCiudades={this.cargarCiudades}
      />
    );
  }
}


const mapStateToProps = ({ detalleClientes }) => {
  return { ...detalleClientes };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      setTituloPagina,
      obtenerCliente,
      guardarCliente,
      guardandoCliente,
      obtenerCiudades
    }, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientesDetalleContainer));
