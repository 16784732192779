import React from 'react';
// import PropTypes from 'prop-types';

import TimePicker from 'rc-time-picker';
import {
  FormGroup,
  Label,
  FormFeedback
} from 'reactstrap';

import 'rc-time-picker/assets/index.css';


const UIFormsCampoFormHora = (props) => (
  <FormGroup>
    {
      props.label ?
        <Label for={props.id} className={props.labelClassName}>{props.label} <small>(Click para cambiar)</small></Label>
        :
        null
    }

    {
      props.group ?
        null
        :
        <span>
          <TimePicker
            className="gosms-time-picker"
            popupClassName="gosms-time-picker-popup"
            showSecond={false}
            use12Hours={true}
            inputReadOnly={true}
            defaultValue={props.defaultValue}
            allowEmpty={props.allowEmpty}
            onChange={props.onChange}
            minuteStep={props.minuteStep}
            disabledHours={props.disabledHours}
            value={props.value}
          />

          <FormFeedback tooltip>
            {props.error}
          </FormFeedback>
        </span>
    }
  </FormGroup>
);


UIFormsCampoFormHora.defaultProps = {
  className: '',
  labelClassName: ''
};

export default UIFormsCampoFormHora;
