import {
  OBTENER_DESTINATARIOS,
  OBTENER_DESTINATARIOS_EXITOSO,
  OBTENER_DESTINATARIOS_ERROR,
  CARGANDO_DESTINATARIOS
} from '../../constants/ActionTypes/Destinatarios';


export const obtenerDestinatarios = (params) => {
  return {
    type: OBTENER_DESTINATARIOS,
    payload: params
  };
};

export const obtenerDestinatariosExitoso = (destinatarios) => {
  return {
    type: OBTENER_DESTINATARIOS_EXITOSO,
    payload: destinatarios
  };
};

export const obtenerDestinatariosError = (mensaje) => {
  return {
    type: OBTENER_DESTINATARIOS_ERROR,
    payload: mensaje
  };
};

export const cargandoDestinatarios = (cargando) => {
  return {
    type: CARGANDO_DESTINATARIOS,
    payload: cargando
  };
};
