import * as Yup from 'yup';

import { CAMPO_REQUERIDO, CAMPO_EMAIL } from '../../../constants/Forms/Validacion';


export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email(CAMPO_EMAIL)
    .required(CAMPO_REQUERIDO),

  clave: Yup.string()
    .required(CAMPO_REQUERIDO)
});
