import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { toast } from 'react-toastify';
import _ from 'lodash';

import {
  obtenerEncuestaPreguntas,
  cargandoEncuestaPreguntas,
  obtenerEncuestaPregunta,
  guardarEncuestaPregunta,
  guardandoEncuestaPregunta,
  editandoEncuestaPregunta
} from '../../../../actions/Encuestas/Preguntas';
import { ocultarModal } from '../../../../actions/Modal';
import {
  TIPOS_RESPUESTA_PREGUNTA,
  TIPO_RESPUESTA_SELECCION,
  TIPO_RESPUESTA_SELECCION_MULTIPLE
} from '../../../../constants/Encuestas/TiposRespuestaPregunta';
import EncuestasPreguntasPreguntaDetalle from './Detalle';


class PreguntasDetalleContainer extends Component {

  constructor(props) {
    super(props);

    const OBLIGATORIA_OPCIONES = [
      {label: 'SI', value: true},
      {label: 'NO', value: false}
    ];

    this.state = {
      pregunta: {
        pregunta: '',
        descripcion: '',
        orden: '',
        obligatoria: OBLIGATORIA_OPCIONES[0],
        tipo: TIPOS_RESPUESTA_PREGUNTA[0],
        opciones: '',
        minimo: '',
        maximo: ''
      },
      opcionesValidas: [],
      OBLIGATORIA_OPCIONES: OBLIGATORIA_OPCIONES,
      TIPOS_RESPUESTA_PREGUNTA: TIPOS_RESPUESTA_PREGUNTA
    };
  }

  cargarPregunta = (cargarPregunta) => {
    let opcionesValidas = [];
    let pregunta = cargarPregunta;
    pregunta.minimo = pregunta.minimo ? pregunta.minimo : '';
    pregunta.maximo = pregunta.maximo ? pregunta.maximo : '';

    if (pregunta.opciones) {
      opcionesValidas = pregunta.opciones.split(',').map((opcion) => ({id: opcion, text: opcion}));
    }

    pregunta.obligatoria = this.state.OBLIGATORIA_OPCIONES.find(
      (opcion) => opcion.value === cargarPregunta.obligatoria
    );

    pregunta.tipo = this.state.TIPOS_RESPUESTA_PREGUNTA.find(
      (opcion) => opcion.value === cargarPregunta.tipo
    );

    this.setState({
      pregunta,
      opcionesValidas
    });
  };

  handleGuardarPregunta = (values) => {
    let guardar = true;
    let datos = values;
    datos.obligatoria = values.obligatoria.value;
    datos.tipo = values.tipo.value;

    if (datos.tipo === TIPO_RESPUESTA_SELECCION || datos.tipo === TIPO_RESPUESTA_SELECCION_MULTIPLE) {
      if (this.state.opcionesValidas.length) {
        datos.opciones = this.state.opcionesValidas.map(opcion => opcion.text).join(',');
      } else {
        toast.error('Por favor ingrese al menos una opción válida.');
        guardar = false;
      }
    }

    if (guardar) {
      this.props.actions.guardandoEncuestaPregunta(true);
      this.props.actions.guardarEncuestaPregunta(datos, this.props.encuesta.uuid);
    }
  };

  handleActualizacionPreguntas = () => {
    this.props.actions.cargandoEncuestaPreguntas(true);
    this.props.actions.obtenerEncuestaPreguntas(this.props.encuesta.uuid);
    this.props.actions.ocultarModal();
  };

  handleAgregarOpcionValida = (opcion) => {
    if (opcion.id) {
      this.props.actions.editandoEncuestaPregunta(false);
      this.setState({
        opcionesValidas: [
          ...this.state.opcionesValidas,
          opcion
        ]
      });
    }
  };

  handleEliminarOpcionValida = (i) => {
    let opcionesValidas = _.isUndefined(i) ? [] : this.state.opcionesValidas.filter((_, index) => index !== i);

    this.setState({
      opcionesValidas
    });
  };

  handleCancelar = () => {
    this.props.actions.ocultarModal(this.props.modal_uuid);
  };

  componentDidMount() {
    if (this.props.encuesta.uuid && this.props.pregunta.uuid) {
      this.props.actions.obtenerEncuestaPregunta(this.props.encuesta.uuid, this.props.pregunta.uuid);
    }
  }

  componentDidUpdate(props) {
    // Verificar si existió algún error
    if (this.props.detalleEncuestaPreguntas.error) {
      if (this.props.detalleEncuestaPreguntas.mensaje) {
        toast.error(this.props.detalleEncuestaPreguntas.mensaje);
      }

      if (this.props.detalleEncuestaPreguntas.guardando) {
        this.props.actions.guardandoEncuesta(false);
      }
    // Verificar si es una edición de encuestas
    } else if (this.props.detalleEncuestaPreguntas.editando) {
      if (props.detalleEncuestaPreguntas.pregunta !== this.props.detalleEncuestaPreguntas.pregunta) {
        this.cargarPregunta(this.props.detalleEncuestaPreguntas.pregunta);
      } else if (this.props.detalleEncuestaPreguntas.guardado_exitoso) {
        this.handleActualizacionPreguntas();
      }
    // Verificar si es creación de un nuevo encuesta
    } else if (this.props.detalleEncuestaPreguntas.guardado_exitoso) {
      this.handleActualizacionPreguntas();
    }
  }

  render() {
    return (
      <EncuestasPreguntasPreguntaDetalle
        valoresIniciales={this.state.pregunta}
        opcionesValidas={this.state.opcionesValidas}
        guardando={this.props.detalleEncuestaPreguntas.guardando}
        handleGuardarPregunta={this.handleGuardarPregunta}
        handleAgregarOpcionValida={this.handleAgregarOpcionValida}
        handleEliminarOpcionValida={this.handleEliminarOpcionValida}
        handleCancelar={this.handleCancelar}
        OBLIGATORIA_OPCIONES={this.state.OBLIGATORIA_OPCIONES}
        TIPOS_RESPUESTA_PREGUNTA={this.state.TIPOS_RESPUESTA_PREGUNTA}
      />
    );
  }
}


const mapStateToProps = ({ detalleEncuestaPreguntas }) => {
  return { detalleEncuestaPreguntas };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      ocultarModal,
      obtenerEncuestaPreguntas,
      cargandoEncuestaPreguntas,
      obtenerEncuestaPregunta,
      guardarEncuestaPregunta,
      guardandoEncuestaPregunta,
      editandoEncuestaPregunta
    }, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PreguntasDetalleContainer));
