import React from 'react';
import { NavLink } from 'react-router-dom';

import { Col, Row, FormGroup, Label, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Form } from 'formik';
import moment from 'moment';

import {
  TIPO_RESPUESTA_TEXTO_LIBRE,
  TIPO_RESPUESTA_NUMERICO,
  TIPO_RESPUESTA_SELECCION,
  TIPO_RESPUESTA_SELECCION_MULTIPLE
} from '../../../constants/Encuestas/TiposRespuestaPregunta';
import UIFormsCampoFormInput from '../../UI/Forms/CampoForm/Input';
import UIFormsCampoFormSelect from '../../UI/Forms/CampoForm/Select';


const EncuestasVistaPrevia = (props) => (
  <Formik>
    {formikProps => (
      <Form>
        <Row>
          <Col md={12}>
            <Row>
              <Col md={12}>
                {
                  props.encuesta.uuid &&
                    <div>
                      <h2 className="font-weight-bold pb-0">
                        {props.encuesta.nombre} <small>Vence el <b>{moment(props.encuesta.url_corto.fecha_vencimiento).format('LL')}</b>.</small>
                      </h2>
                      <h4>{props.encuesta.descripcion}</h4>
                      <hr />
                    </div>
                }
              </Col>
            </Row>
            <Row>
              {
                props.preguntas.length > 0 ? 
                  props.preguntas.map((pregunta, index) => (
                    <Col md={12} key={index}>
                      {
                        pregunta.tipo === TIPO_RESPUESTA_TEXTO_LIBRE &&
                          <Row className="mb-4">
                            <Col md={12}>
                              <UIFormsCampoFormInput
                                id={`pregunta_${index}`}
                                type="text"
                                name={`pregunta_${index}`}
                                label={pregunta.pregunta}
                                labelClassName={pregunta.obligatoria ? 'requerido' : ''}
                                groupClassName="mb-0"
                                placeholder=""
                                autoComplete="off"
                              />
                              {
                                pregunta.descripcion &&
                                  <div>
                                    <FontAwesomeIcon icon="info-circle" className="mr-1" />
                                    {pregunta.descripcion}
                                  </div>
                              }
                            </Col>
                          </Row>
                      }

                      {
                        pregunta.tipo === TIPO_RESPUESTA_NUMERICO &&
                          <Row className="mb-4">
                            <Col md={12}>
                              <UIFormsCampoFormInput
                                id={`pregunta_${index}`}
                                type="number"
                                min={pregunta.minimo}
                                max={pregunta.maximo}
                                name={`pregunta_${index}`}
                                label={pregunta.pregunta}
                                labelClassName={pregunta.obligatoria ? 'requerido' : ''}
                                groupClassName="mb-0"
                                autoComplete="off"
                              />
                              {
                                pregunta.descripcion &&
                                  <div>
                                    <FontAwesomeIcon icon="info-circle" className="mr-1" />
                                    {pregunta.descripcion}
                                  </div>
                              }
                            </Col>
                          </Row>
                      }

                      {
                        pregunta.tipo === TIPO_RESPUESTA_SELECCION &&
                          <Row className="mb-4">
                            <Col md={12}>
                              <UIFormsCampoFormSelect
                                id={`pregunta_${index}`}
                                name={`pregunta_${index}`}
                                label={pregunta.pregunta}
                                labelClassName={pregunta.obligatoria ? 'requerido' : ''}
                                autoComplete="off"
                                className="p-0"
                                groupClassName="mb-0"
                                formikProps={formikProps}
                                options={
                                  pregunta.opciones
                                    .split(',')
                                    .map(
                                      (opcion) => ({
                                        label: opcion,
                                        value: opcion.toUpperCase()
                                      })
                                    )
                                }
                              />
                              {
                                pregunta.descripcion &&
                                  <div>
                                    <FontAwesomeIcon icon="info-circle" className="mr-1" />
                                    {pregunta.descripcion}
                                  </div>
                              }
                            </Col>
                          </Row>
                      }

{
                        pregunta.tipo === TIPO_RESPUESTA_SELECCION_MULTIPLE &&
                          <Row className="mb-4">
                            <Col md={12}>
                              <FormGroup>
                                <p className="mb-0">
                                  <Label className="requerido">
                                    {pregunta.pregunta}
                                  </Label>
                                </p>
                                {
                                  pregunta.opciones.split(',').map((opcion) =>
                                    <FormGroup check inline>
                                      <Label check>
                                        <Input type="checkbox" value={opcion} /> {opcion.toUpperCase()}
                                      </Label>
                                    </FormGroup>
                                  )
                                }
                              </FormGroup>
                              {
                                pregunta.descripcion &&
                                  <div>
                                    <FontAwesomeIcon icon="info-circle" className="mr-1" />
                                    {pregunta.descripcion}
                                  </div>
                              }
                            </Col>
                          </Row>
                      }
                    </Col>
                  ))
                  :
                  null
              }
            </Row>
            <Row>
              <Col md={12}>
                <hr/>
                <div className="text-center">
                  <NavLink className="jr-btn btn btn-default" to={`/encuestas/detalle/${props.encuesta.uuid}/preguntas`}>
                    <span>Regresar a la encuesta</span>
                  </NavLink>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    )}
  </Formik>
);


export default EncuestasVistaPrevia;
