import * as Yup from 'yup';

import {
  CAMPO_REQUERIDO,
  CAMPO_NUMERO_MINIMO
} from '../../../constants/Forms/Validacion';


export const encuestaSchema = Yup.object().shape({
  nombre: Yup.string()
    .required(CAMPO_REQUERIDO),

  descripcion: Yup.string()
    .notRequired(),

  tiempo_validez: Yup.number()
    .min(1, CAMPO_NUMERO_MINIMO)
    .required(CAMPO_REQUERIDO),
});
