import React from 'react';
import { NavLink } from 'react-router-dom';

import { Col, Row, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Form } from 'formik';

import UIFormsCampoFormInput from '../../UI/Forms/CampoForm/Input';
import { destinatarioSchema } from './Schema';


const DestinatariosDetalle = (props) => (
  <Formik
    enableReinitialize
    initialValues={props.valoresIniciales}
    onSubmit={props.handleSubmit}
    validationSchema={destinatarioSchema}
    validateOnChange
  >
    {formikProps => (
      <Form>
        <Row>
          <Col sm={12}>
            <Row form>
              <Col md={6}>
                <UIFormsCampoFormInput
                  id="nombres"
                  type="text"
                  name="nombres"
                  label="Nombres"
                  labelClassName="requerido"
                  placeholder=""
                  autoComplete="off"
                  error={formikProps.errors.nombres ? formikProps.errors.nombres : null}
                  touched={formikProps.touched.nombres ? formikProps.touched.nombres : null}
                />
              </Col>
              <Col md={6}>
                <UIFormsCampoFormInput
                  id="numero"
                  type="number"
                  name="numero"
                  label="Número"
                  labelClassName="requerido"
                  placeholder=""
                  autoComplete="off"
                  error={formikProps.errors.numero ? formikProps.errors.numero : null}
                  touched={formikProps.touched.numero ? formikProps.touched.numero : null}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <hr/>
            <div className="text-center">
              <Button color="success" className="jr-btn" type="submit" disabled={props.guardando}>
                {
                  props.guardando ?
                    <FontAwesomeIcon icon="circle-notch" size="lg" spin className="mr-2" />
                    :
                    <FontAwesomeIcon icon="check" size="lg" className="mr-2" />
                }
                Guardar destinatario
              </Button>

              <NavLink className="jr-btn btn btn-default" to='/destinatarios'>
                <span>Regresar al listado</span>
              </NavLink>

              {
                formikProps.values.uuid &&
                <Button
                  color="danger"
                  className="jr-btn fa-pull-right"
                  type="button"
                  onClick={() => props.handleEliminar(formikProps.values.uuid)}>
                  <FontAwesomeIcon icon="ban" size="lg" className="mr-2"/>
                  Eliminar destinatario
                </Button>
              }
            </div>
          </Col>
        </Row>
      </Form>
    )}
  </Formik>
);


export default DestinatariosDetalle;
