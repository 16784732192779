import React from 'react';

import { Row } from 'reactstrap';

import UICajaContenido from '../../../components/UI/CajaContenido/CajaContenido';
import UIBreadcrumbContainer from '../../../components/UI/Breadcrumb/BreadcrumbContainer';
import SeguridadPerfilDetalleContainer from '../../../components/Seguridad/Perfil/DetalleContainer';


const ScreensPerfilDetalle = () => (
  <div className="animated slideInUpTiny animation-duration-3">
    <UIBreadcrumbContainer></UIBreadcrumbContainer>

    <div className="app-module">
      <div className="module-box">
        <Row>
          <UICajaContenido
            encabezado="Mi perfil"
            estiloCaja="border-radius-0"
            estilos="col-lg-12"
          >
            <SeguridadPerfilDetalleContainer />
          </UICajaContenido>
        </Row>
      </div>
    </div>
  </div>
);


export default ScreensPerfilDetalle;
