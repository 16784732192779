import { put, call, all, fork, takeEvery } from 'redux-saga/effects';

import { OBTENER_USUARIOS, OBTENER_USUARIOS_CLIENTES } from '../../constants/ActionTypes/Usuarios';
import { USUARIOS_URL } from '../../constants/Urls/Usuarios';
import { CLIENTES_URL } from '../../constants/Urls/Clientes';
import {
  obtenerUsuariosExitoso,
  obtenerUsuariosError,
  obtenerClientesExitoso,
  obtenerClientesError
} from '../../actions/Usuarios/Listar';
import { setUnidades } from '../../actions/Configuracion';
import { httpFetch } from '../../utils/Http/Fetch';


const obtenerUsuariosApi = async (filtros) => {
  let esc = encodeURIComponent;
  let params = Object.keys(filtros).map(filtro => esc(filtro) + '=' + esc(filtros[filtro])).join('&');

  return httpFetch(`${USUARIOS_URL}?${params}`, 'GET')
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};

const obtenerClientesApi = async () => {
  return httpFetch(`${CLIENTES_URL}`, 'GET')
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};


function* obtenerUsuarios(action) {
  try {
    const { filtros } = action.payload;
    const usuarios = yield call(obtenerUsuariosApi, filtros);
    const paginas = Math.ceil(usuarios.cantidad / filtros.tamano_pagina);

    if (typeof usuarios.saldo !== 'undefined') {
      yield put(setUnidades(usuarios.saldo));
    }

    yield put(obtenerUsuariosExitoso({usuarios: usuarios.resultado, cantidad: usuarios.cantidad, paginas}));
  } catch (error) {
    yield put(obtenerUsuariosError(error.message));
  }
}

function* obtenerClientes() {
  try {
    const clientes = yield call(obtenerClientesApi);
    let clientes_opciones = clientes.map((cliente) => {
      return {value: cliente.uuid, label: `${cliente.nombres} ${cliente.apellidos}`};
    });

    yield put(obtenerClientesExitoso(clientes_opciones));
  } catch (error) {
    yield put(obtenerClientesError(error.message));
  }
}


export function* obtenerUsuariosWatcher() {
  yield takeEvery (OBTENER_USUARIOS, obtenerUsuarios);
}

export function* obtenerClientesWatcher() {
  yield takeEvery (OBTENER_USUARIOS_CLIENTES, obtenerClientes);
}

export default function* rootSaga() {
  yield all ([
    fork (obtenerUsuariosWatcher),
    fork (obtenerClientesWatcher)
  ]);
}
