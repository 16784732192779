import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ScreensListasDestinatariosListar from '../../screens/ListasDestinatarios/ListasDestinatariosListar';
import ScreensListasDestinatariosDetalle from '../../screens/ListasDestinatarios/ListasDestinatariosDetalle';


const ListasDestinatarios = (props) => (
  <div className="app-wrapper">
    <Switch>
      <Route exact path={`${props.match.url}/`} component={ScreensListasDestinatariosListar} />
      <Route path={`${props.match.url}/detalle/:uuid_lista`} component={ScreensListasDestinatariosDetalle} />
      <Route path={`${props.match.url}/detalle`} component={ScreensListasDestinatariosDetalle} />
    </Switch>
  </div>
);


export default ListasDestinatarios;
