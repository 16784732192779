import React from 'react';
import { NavLink } from 'react-router-dom';

import { Col, Row, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Form } from 'formik';

import UIFormsCampoFormInput from '../../UI/Forms/CampoForm/Input';
import UIFormsCampoFormSelect from '../../UI/Forms/CampoForm/Select';
import UIFormsCampoFormMensaje from '../../UI/Forms/CampoForm/Mensaje/Mensaje';
import { mensajeSchema } from './Schema';


const MensajesDetalle = (props) => (
  <Formik
    enableReinitialize
    initialValues={props.valoresIniciales}
    onSubmit={props.handleSubmit}
    validationSchema={mensajeSchema}
    validateOnChange
  >
    {formikProps => (
      <Form>
        <Row>
          <Col sm={12}>
            <Row form>
              <Col md={6}>
                <UIFormsCampoFormInput
                  id="nombre"
                  type="text"
                  name="nombre"
                  label="Nombre"
                  labelClassName="requerido"
                  placeholder=""
                  autoComplete="off"
                  error={formikProps.errors.nombre ? formikProps.errors.nombre : null}
                  touched={formikProps.touched.nombre ? formikProps.touched.nombre : null}
                />
              </Col>
              <Col md={6}>
                <UIFormsCampoFormSelect
                  id="lista"
                  name="lista"
                  label="Lista de destinatarios"
                  labelClassName="requerido"
                  autoComplete="off"
                  formikProps={formikProps}
                  placeholder="Seleccione una opción"
                  className="padding-0"
                  options={props.listasDestinatarios}
                  error={formikProps.errors.lista ? formikProps.errors.lista.value : null}
                  onChange={(e) => {
                    props.setColumnasMensaje(e.columnas_destinatarios);
                    formikProps.setFieldValue('lista', e);
                  }}
                />
              </Col>
            </Row>

            <Row form>
              <Col md={12}>
                <UIFormsCampoFormSelect
                  id="mensaje"
                  name="mensaje"
                  label="Mensajes creados"
                  autoComplete="off"
                  formikProps={formikProps}
                  placeholder="Seleccione una opción"
                  className="padding-0"
                  options={props.mensajes}
                  onChange={(e) => {
                    formikProps.setFieldValue('texto_mensaje', e.mensaje ? e.mensaje : '');
                    formikProps.setFieldValue('mensaje', e);
                  }}
                />
              </Col>
            </Row>

            <Row form>
              <Col md={12}>
                <UIFormsCampoFormMensaje
                  id="texto_mensaje"
                  name="texto_mensaje"
                  label="Escribir mensaje"
                  labelClassName="requerido"
                  formikProps={formikProps}
                  placeholder="Puede usar los campos de la lista digitando '@' y seleccionando el campo deseado."
                  longitudMaxima={160}
                  columnas={!formikProps.values.mensaje || formikProps.values.mensaje.value === '' ? props.columnasMensaje : false}
                  readOnly={formikProps.values.mensaje && formikProps.values.mensaje.uuid}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <hr/>
            <div className="text-center">
              <Button color="success" className="jr-btn" type="submit" disabled={props.guardando}>
                {
                  props.guardando ?
                    <FontAwesomeIcon icon="circle-notch" size="lg" spin className="mr-2" />
                    :
                    <FontAwesomeIcon icon="check" size="lg" className="mr-2" />
                }
                Guardar campaña
              </Button>

              <NavLink className="jr-btn btn btn-default" to='/campanias'>
                <span>Regresar al listado</span>
              </NavLink>

              {
                formikProps.values.uuid &&
                <Button
                  color="danger"
                  className="jr-btn fa-pull-right"
                  type="button"
                  onClick={() => props.handleEliminar(formikProps.values.uuid)}>
                  <FontAwesomeIcon icon="ban" size="lg" className="mr-2"/>
                  Eliminar campaña
                </Button>
              }
            </div>
          </Col>
        </Row>
      </Form>
    )}
  </Formik>
);


export default MensajesDetalle;
