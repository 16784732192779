import { put, call, all, fork, takeEvery } from 'redux-saga/effects';

import { OBTENER_GALERIA } from '../../constants/ActionTypes/Galeria';
import { GALERIA_ARCHIVOS_URL } from '../../constants/Urls/Galeria';
import { obtenerGaleriaExitoso, obtenerGaleriaError } from '../../actions/Galeria/Listar';
import { httpFetch } from '../../utils/Http/Fetch';
import { setUnidades } from '../../actions/Configuracion';


const obtenerGaleriaApi = async (filtros) => {
  let esc = encodeURIComponent;
  let params = Object.keys(filtros).map(filtro => esc(filtro) + '=' + esc(filtros[filtro])).join('&');

  return httpFetch(`${GALERIA_ARCHIVOS_URL}?${params}`, 'GET')
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};

function* obtenerGaleria(action) {
  try {
    const { filtros } = action.payload;
    const galeria = yield call(obtenerGaleriaApi, filtros);
    const paginas = Math.ceil(galeria.cantidad / filtros.tamano_pagina);

    if (typeof galeria.saldo !== 'undefined') {
      yield put(setUnidades(galeria.saldo));
    }

    yield put(obtenerGaleriaExitoso({galeria: galeria.resultado, cantidad: galeria.cantidad, paginas}));
  } catch (error) {
    yield put(obtenerGaleriaError(error.message));
  }
}

export function* obtenerGaleriaWatcher() {
  yield takeEvery (OBTENER_GALERIA, obtenerGaleria);
}

export default function* rootSaga() {
  yield all ([
    fork (obtenerGaleriaWatcher)
  ]);
}
