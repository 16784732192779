import {
  OBTENER_CAMPANIAS_PROGRAMADAS_EXITOSO,
  OBTENER_CAMPANIAS_PROGRAMADAS_ERROR,
  CARGANDO_CAMPANIAS_PROGRAMADAS
} from '../../constants/ActionTypes/EnviarCampanias';


const campaniasProgramadasInicial = {
  campanias: [],
  cantidad: 0,
  paginas: 1,
  error: false,
  cargando: false,
  mensaje: null
};

const listarCampaniasProgramadas = (state=campaniasProgramadasInicial, action) => {
  switch (action.type) {

  case OBTENER_CAMPANIAS_PROGRAMADAS_EXITOSO: {
    return {
      ...campaniasProgramadasInicial,
      ...action.payload
    };
  }

  case OBTENER_CAMPANIAS_PROGRAMADAS_ERROR: {
    return {
      ...campaniasProgramadasInicial,
      error: true,
      mensaje: action.payload
    };
  }

  case CARGANDO_CAMPANIAS_PROGRAMADAS: {
    return {
      ...campaniasProgramadasInicial,
      cargando: action.payload
    };
  }

  default:
    return state;
  }
};


export default listarCampaniasProgramadas;
