import React from 'react';

import {
  Row,
  Col,
  Alert,
  Button,
  Modal as BTModal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const Modal = (props) => {
  const {componente: Componente} = props;

  return (
    <BTModal
      isOpen={props.visible}
      toggle={() => props.handleToggle(props.uuid)}
      className={props.className}
      size={props.atributos.tamano}
      backdrop={props.atributos.estatico}
      keyboard={props.atributos.teclado}>
      <ModalHeader tag="div" className="sm-12" {...props.propsCabecera}>
        <h1>{props.atributos.titulo}</h1> <br/>
        <h2>
          {props.atributos.subtitulo}
        </h2>
      </ModalHeader>
      <ModalBody>
        {
          Componente ?
            <Componente {...props.componenteProps} />
            :
            <Row>
              <Col sm={12}>
                <h3>
                  {props.atributos.mensaje}
                </h3>

                <Alert color={props.atributos.colorNota}>
                  <h4 className="alert-heading">
                    <FontAwesomeIcon icon="exclamation-circle" size="lg" className="mr-2"/>
                    <b>Nota</b>
                  </h4>
                  <p className="mb-0">
                    {props.atributos.nota}
                  </p>
                </Alert>
              </Col>
            </Row>
        }
      </ModalBody>

      {
        Componente ?
          null
          :
          <ModalFooter>
            <Button
              color={props.atributos.colorBotonAceptar ? props.atributos.colorBotonAceptar : 'primary'}
              size="lg"
              onClick={props.atributos.aceptar}
            >
              {props.atributos.textoBotonAceptar ? props.atributos.textoBotonAceptar : 'Aceptar'}
            </Button>
            <Button
              color={props.atributos.colorBotonCancelar ? props.atributos.colorBotonCancelar : 'dark'}
              size="lg"
              onClick={() => props.handleToggle(props.uuid)}
            >
              {props.atributos.textoBotonCancelar ? props.atributos.textoBotonCancelar : 'Cancelar'}
            </Button>
          </ModalFooter>
      }
    </BTModal>
  );
};


export default Modal;
