import {
  OBTENER_USUARIOS_EXITOSO,
  OBTENER_USUARIOS_ERROR,
  CARGANDO_USUARIOS,
  OBTENER_USUARIOS_CLIENTES_EXITOSO,
  OBTENER_USUARIOS_CLIENTES_ERROR
} from '../../constants/ActionTypes/Usuarios';


const usuariosInicial = {
  usuarios: [],
  clientes: [],
  cantidad: 0,
  paginas: 1,
  error: false,
  cargando: false,
  mensaje: null
};

const listarUsuarios = (state=usuariosInicial, action) => {
  switch (action.type) {

  case OBTENER_USUARIOS_EXITOSO: {
    return {
      ...usuariosInicial,
      ...action.payload,
      clientes: state.clientes
    };
  }

  case OBTENER_USUARIOS_ERROR: {
    return {
      ...usuariosInicial,
      clientes: state.clientes,
      error: true,
      mensaje: action.payload
    };
  }

  case CARGANDO_USUARIOS: {
    return {
      ...usuariosInicial,
      clientes: state.clientes,
      cargando: action.payload
    };
  }

  case OBTENER_USUARIOS_CLIENTES_EXITOSO: {
    return {
      ...usuariosInicial,
      usuarios: state.usuarios,
      clientes: action.payload
    };
  }

  case OBTENER_USUARIOS_CLIENTES_ERROR: {
    return {
      ...usuariosInicial,
      usuarios: state.usuarios,
      error: true,
      mensaje: action.payload
    };
  }

  default:
    return state;
  }
};


export default listarUsuarios;
