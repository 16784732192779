import React, { Component } from 'react';
// import PropTypes from 'prop-types';

import { Field } from 'formik';
import Select from 'react-select';
import {
  FormGroup,
  Label
} from 'reactstrap';


class UIFormsCampoFormAutocompletar extends Component {
  constructor (props) {
    super(props);

    this.timeout = null;

    this.state = {
      cargando: false
    };
  };

  obtenerOpciones = (termino) => {
    if (termino !== '') {
      this.setState({cargando: true});

      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(() => {
        this.setState({cargando: false});

        this.props.onInputChange(termino);
      }, 500);
    }
  };

  onChange = (value) => {
    this.props.formikProps.setFieldValue(this.props.name, value);
  };

  onBlur = () => {
    this.props.formikProps.setFieldTouched(this.props.name, true);
  };

  getNoOptionsMessage = () => {
    return this.props.noOptionsMessage ? this.props.noOptionsMessage : 'No hay opciones';
  }

  getLoadingMessage = () => {
    return this.props.loadingMessage ? this.props.loadingMessage : 'Cargando...';
  }

  render() {
    return (
      <FormGroup>
        {
          typeof this.props.label === 'object' ?
            this.props.label
            :
            <Label for={this.props.id} className={this.props.labelClassName}>{this.props.label}</Label>
        }

        <Field
          name={this.props.name}
          render={({ field }) => (
            <Select
              {...field}
              className={`form-control form-control-lg ${this.props.className} ` + (this.props.error ? 'is-invalid' : '')}
              placeholder={this.props.placeholder}
              isDisabled={this.props.isDisabled}
              options={this.props.options}
              noOptionsMessage={this.getNoOptionsMessage}
              isLoading={this.state.cargando}
              loadingMessage={this.getLoadingMessage}
              filterOption={() => (true)}
              onChange={this.props.onChange ? this.props.onChange : this.onChange}
              onInputChange={this.props.onInputChange ? this.obtenerOpciones : undefined}
              onBlur={this.onBlur}
            />
          )}
        />

        {
          this.props.error ?
            <div className="invalid-tooltip">{this.props.error}</div>
            :
            null
        }
      </FormGroup>
    );
  }
}


UIFormsCampoFormAutocompletar.defaultProps = {
  className: ''
};

export default UIFormsCampoFormAutocompletar;
