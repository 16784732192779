import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import uuid from 'uuid';
import { toast } from 'react-toastify';
import _ from 'lodash';


import {
  obtenerMensaje,
  guardarMensaje,
  guardandoMensaje,
  eliminarMensaje,
  obtenerListasDestinatarios,
  obtenerGaleria,
  obtenerEncuestas
} from '../../../actions/Mensajes/Detalle';
import { TIPOS_MENSAJE, TIPO_MENSAJE_MULTIMEDIA, TIPO_MENSAJE_ENCUESTA } from '../../../constants/Mensajes/Tipos';
import { mostrarModal, ocultarModal } from '../../../actions/Modal';
import { setTituloPagina } from '../../../actions/Configuracion';
import MensajesDetalle from './Detalle';


class MensajesDetalleContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      columnas: [],
      mensaje_html: '',
      mensaje: {
        tipo: TIPOS_MENSAJE[0],
        nombre: '',
        lista: '',
        mensaje: ''
      }
    };
  }

  cargarMensaje = (mensaje) => {
    if (
      mensaje.lista
      || mensaje.tipo.toLowerCase() === TIPO_MENSAJE_MULTIMEDIA.toLowerCase()
      || mensaje.tipo.toLowerCase() === TIPO_MENSAJE_ENCUESTA.toLowerCase()
    ) {
      this.setColumnasMensaje(mensaje.url_corto, mensaje.lista, mensaje.tipo);

      if (mensaje.lista) {
        let lista  = this.props.listas.find((lista) => lista.uuid === mensaje.lista.uuid);
        mensaje.lista = lista;
      }

      if (mensaje.url_corto) {
        if (mensaje.tipo.toLowerCase() === TIPO_MENSAJE_MULTIMEDIA.toLowerCase()) {
          let archivo  = this.props.galeria.find((archivo) =>
            archivo.url_corto && archivo.url_corto.uuid === mensaje.url_corto.uuid
          );
          mensaje.archivo = archivo;
        } else if (mensaje.tipo.toLowerCase() === TIPO_MENSAJE_ENCUESTA.toLowerCase()) {
          let encuesta  = this.props.encuestas.find((encuesta) => 
            encuesta.url_corto && encuesta.url_corto.uuid === mensaje.url_corto.uuid
          );
          mensaje.encuesta = encuesta;
        }
      }
    }

    let tipo  = TIPOS_MENSAJE.find((tipo) => tipo.value.toLowerCase() === mensaje.tipo.toLowerCase());
    mensaje.tipo = tipo;

    this.setState({
      mensaje
    });
  };

  setColumnasMensaje = (recurso, lista, tipo) => {
    let columnas = [];
    if (lista && lista.uuid) {
      columnas = lista.columnas_destinatarios.map((columna) => {
        return {
          'id': columna.toUpperCase(),
          'display': columna.toUpperCase()
        };
      });  
    }

    if (tipo.toUpperCase() === TIPO_MENSAJE_MULTIMEDIA || tipo.toUpperCase() === TIPO_MENSAJE_ENCUESTA) {
      if (recurso && recurso.uuid) {
        columnas.push({
          'id': 'ENLACE',
          'display': 'ENLACE'
        });
      }
    }


    this.setState({
      columnas
    });
  };

  handleActualizacionMensaje = () => {
    toast.success(this.props.mensaje_);
    this.props.history.push('/mensajes');
  };

  handleEliminar = (mensaje_uuid) => {
    this.props.actions.mostrarModal({
      uuid: uuid.v4(),
      atributos: {
        titulo: 'Eliminar mensaje',
        mensaje: '¿Realmente desea eliminar este mensaje?',
        colorBotonAceptar: 'danger',
        textoBotonAceptar: 'Eliminar mensaje',
        nota: 'Esta acción no se puede deshacer',
        colorNota: 'danger',
        tamano: 'sm',
        aceptar: () => this.props.actions.eliminarMensaje(mensaje_uuid)
      }
    });
  };

  handleSubmit = (values) => {
    let error = false;
    let datos = _.cloneDeep(values);

    if (values.lista) {
      datos.lista = values.lista.uuid;
    } else {
      delete datos.lista;
    }

    if (values.tipo) {
      datos.tipo = values.tipo.value.toLowerCase();
    } else {
      delete datos.tipo;
    }

    if (values.tipo.value === TIPO_MENSAJE_MULTIMEDIA || values.tipo.value === TIPO_MENSAJE_ENCUESTA) {
      const recurso = values.archivo || values.encuesta;
      delete datos.archivo;
      delete datos.encuesta;

      if (recurso) {
        if (recurso.url_corto.vencido) {
          error = true;
          toast.error('El URL corto del mensaje esta vencido.');
        } else {
          datos['url_corto'] = recurso.url_corto.uuid;
        }
      }
    }

    if (!error) {
      this.props.actions.guardandoMensaje(true);
      this.props.actions.guardarMensaje(datos);
    }
  };

  componentDidMount() {
    this.props.actions.setTituloPagina({titulo: 'Mensajes', icono: 'comments'});
    this.props.actions.obtenerListasDestinatarios();
    this.props.actions.obtenerGaleria();
    this.props.actions.obtenerEncuestas();

    if (this.props.match.params.uuid_mensaje) {
      this.props.actions.obtenerMensaje(this.props.match.params.uuid_mensaje);
    }
  }

  componentDidUpdate(props) {
    // Verificar si existió algún error
    if (this.props.error) {
      if (this.props.mensaje_) {
        toast.error(this.props.mensaje_);
      }

      if (this.props.guardando) {
        this.props.actions.guardandoMensaje(false);
      }

    // Verificar si es una edición de mensajes
    } else if (this.props.editando) {
      if (this.props.eliminando) {
        this.props.actions.ocultarModal();
        this.handleActualizacionMensaje();
      } else if (props.mensaje !== this.props.mensaje) {
        this.cargarMensaje(this.props.mensaje);
      } else if (this.props.guardado_exitoso) {
        this.handleActualizacionMensaje();
      }

    // Verificar si es creación de un nuevo mensaje
    } else if (this.props.guardado_exitoso) {
      this.handleActualizacionMensaje();
    }
  }

  render() {
    return (
      <MensajesDetalle
        handleSubmit={this.handleSubmit}
        handleEliminar={this.handleEliminar}
        valoresIniciales={this.state.mensaje}
        guardando={this.props.guardando}
        listasDestinatarios={this.props.listas}
        galeria={this.props.galeria}
        encuestas={this.props.encuestas}
        setColumnasMensaje={this.setColumnasMensaje}
        columnasMensaje={this.state.columnas}
      />
    );
  }
}


const mapStateToProps = ({ detalleMensajes }) => {
  return { ...detalleMensajes };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      setTituloPagina,
      mostrarModal,
      ocultarModal,
      obtenerMensaje,
      guardarMensaje,
      guardandoMensaje,
      eliminarMensaje,
      obtenerListasDestinatarios,
      obtenerGaleria,
      obtenerEncuestas
    }, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MensajesDetalleContainer));
