import {
  OBTENER_CAMPANIA_PROGRAMADA,
  OBTENER_CAMPANIA_PROGRAMADA_EXITOSO,
  OBTENER_CAMPANIA_PROGRAMADA_ERROR,
  ENVIAR_CAMPANIA,
  ENVIAR_CAMPANIA_EXITOSO,
  ENVIAR_CAMPANIA_ERROR,
  ENVIANDO_CAMPANIA,
  CANCELAR_CAMPANIA_PROGRAMADA,
  CANCELAR_CAMPANIA_PROGRAMADA_EXITOSO,
  CANCELAR_CAMPANIA_PROGRAMADA_ERROR,
  OBTENER_CAMPANIAS,
  OBTENER_CAMPANIAS_EXITOSO,
  OBTENER_CAMPANIAS_ERROR,
  CARGANDO_CAMPANIAS,
} from '../../constants/ActionTypes/EnviarCampanias';


export const obtenerCampaniaProgramada = (uuid) => {
  return {
    type: OBTENER_CAMPANIA_PROGRAMADA,
    payload: uuid
  };
};

export const obtenerCampaniaProgramadaExitoso = (mensaje) => {
  return {
    type: OBTENER_CAMPANIA_PROGRAMADA_EXITOSO,
    payload: mensaje
  };
};

export const obtenerCampaniaProgramadaError = (mensaje) => {
  return {
    type: OBTENER_CAMPANIA_PROGRAMADA_ERROR,
    payload: mensaje
  };
};

export const enviarCampania = (datos) => {
  return {
    type: ENVIAR_CAMPANIA,
    payload: datos
  };
};

export const enviarCampaniaExitoso = (mensaje) => {
  return {
    type: ENVIAR_CAMPANIA_EXITOSO,
    payload: mensaje
  };
};

export const enviarCampaniaError = (mensaje) => {
  return {
    type: ENVIAR_CAMPANIA_ERROR,
    payload: mensaje
  };
};

export const enviandoCampania = (cargando) => {
  return {
    type: ENVIANDO_CAMPANIA,
    payload: cargando
  };
};

export const cancelarCampaniaProgramada = (uuid) => {
  return {
    type: CANCELAR_CAMPANIA_PROGRAMADA,
    payload: uuid
  };
};

export const cancelarCampaniaProgramadaExitoso = (mensaje) => {
  return {
    type: CANCELAR_CAMPANIA_PROGRAMADA_EXITOSO,
    payload: mensaje
  };
};

export const cancelarCampaniaProgramadaError = (mensaje) => {
  return {
    type: CANCELAR_CAMPANIA_PROGRAMADA_ERROR,
    payload: mensaje
  };
};

export const obtenerCampanias = () => {
  return {
    type: OBTENER_CAMPANIAS
  };
};

export const obtenerCampaniasExitoso = (campanias) => {
  return {
    type: OBTENER_CAMPANIAS_EXITOSO,
    payload: campanias
  };
};

export const obtenerCampaniasError = (mensaje) => {
  return {
    type: OBTENER_CAMPANIAS_ERROR,
    payload: mensaje
  };
};

export const cargandoCampanias = (cargando) => {
  return {
    type: CARGANDO_CAMPANIAS,
    payload: cargando
  };
};
