import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import uuid from 'uuid';
import { toast } from 'react-toastify';
// import * as Survey from 'survey-react';

import {
  obtenerEncuesta,
  guardarEncuesta,
  guardandoEncuesta,
  eliminarEncuesta
} from '../../../actions/Encuestas/Detalle';
import { mostrarModal, ocultarModal } from '../../../actions/Modal';
import { setTituloPagina } from '../../../actions/Configuracion';
import EncuestasDetalle from './Detalle';


class EncuestasDetalleContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      encuesta: {
        nombre: '',
        descripcion: '',
        tiempo_validez: 1
      }
    };
  }

  cargarEncuesta = (encuesta) => {
    this.setState({
      encuesta: {
        ...encuesta,
        tiempo_validez: encuesta.url_corto.tiempo_validez
      },
    });
  };

  handleActualizacionEncuesta = () => {
    toast.success(this.props.mensaje);
    this.props.history.push('/encuestas');
  };

  handleEliminar = (encuesta_uuid) => {
    this.props.actions.mostrarModal({
      uuid: uuid.v4(),
      atributos: {
        titulo: 'Eliminar encuesta',
        mensaje: '¿Realmente desea eliminar este encuesta?',
        colorBotonAceptar: 'danger',
        textoBotonAceptar: 'Eliminar encuesta',
        nota: 'Esta acción no se puede deshacer',
        colorNota: 'danger',
        tamano: 'sm',
        aceptar: () => this.props.actions.eliminarEncuesta(encuesta_uuid)
      }
    });
  };

  handleSubmit = (values) => {
    this.props.actions.guardandoEncuesta(true);
    this.props.actions.guardarEncuesta(values);
  };

  componentDidMount() {
    this.props.actions.setTituloPagina({titulo: 'Encuestas', icono: 'question-circle'});

    if (this.props.match.params.uuid_encuesta) {
      this.props.actions.obtenerEncuesta(this.props.match.params.uuid_encuesta);
    }
  }

  componentDidUpdate(props) {
    // Verificar si existió algún error
    if (this.props.error) {
      if (this.props.mensaje) {
        toast.error(this.props.mensaje);
      }

      if (this.props.guardando) {
        this.props.actions.guardandoEncuesta(false);
      }

    // Verificar si es una edición de encuestas
    } else if (this.props.editando) {
      if (this.props.eliminando) {
        this.props.actions.ocultarModal();
        this.handleActualizacionEncuesta();
      } else if (props.encuesta !== this.props.encuesta) {
        this.cargarEncuesta(this.props.encuesta);
      } else if (this.props.guardado_exitoso) {
        this.handleActualizacionEncuesta();
      }

    // Verificar si es creación de un nuevo encuesta
    } else if (this.props.guardado_exitoso) {
      this.handleActualizacionEncuesta();
    }
  }

  render() {
    return (
      <EncuestasDetalle
        handleSubmit={this.handleSubmit}
        handleEliminar={this.handleEliminar}
        valoresIniciales={this.state.encuesta}
        guardando={this.props.guardando}
      />
    );
  }
}


const mapStateToProps = ({ detalleEncuestas }) => {
  return { ...detalleEncuestas };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      setTituloPagina,
      mostrarModal,
      ocultarModal,
      obtenerEncuesta,
      guardarEncuesta,
      guardandoEncuesta,
      eliminarEncuesta
    }, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EncuestasDetalleContainer));
