import * as Yup from 'yup';

import {
  CAMPO_REQUERIDO,
  CAMPO_NUMERO_ENTERO
} from '../../../../constants/Forms/Validacion';
import {
    TIPO_RESPUESTA_NUMERICO,
    TIPO_RESPUESTA_SELECCION,
    TIPO_RESPUESTA_SELECCION_MULTIPLE
} from '../../../../constants/Encuestas/TiposRespuestaPregunta';


export const preguntaSchema = Yup.object().shape({
  pregunta: Yup.string()
    .required(CAMPO_REQUERIDO),

  descripcion: Yup.string()
    .notRequired(),

  orden: Yup.number()
    .integer(CAMPO_NUMERO_ENTERO)
    .notRequired(),

  tipo: Yup.object()
    .required(CAMPO_REQUERIDO),

  minimo: Yup.number()
    .when('tipo', {
      is: (tipo) => tipo.value === TIPO_RESPUESTA_NUMERICO,
      then: Yup.number().required(CAMPO_REQUERIDO),
      otherwise: Yup.number().notRequired()
    }),

  maximo: Yup.number()
    .when('tipo', {
      is: (tipo) => tipo.value === TIPO_RESPUESTA_NUMERICO,
      then: Yup.number().required(CAMPO_REQUERIDO),
      otherwise: Yup.number().notRequired()
    }),

  opciones: Yup.number()
    .when('tipo', {
      is: (tipo) => tipo.value === TIPO_RESPUESTA_SELECCION || tipo.value === TIPO_RESPUESTA_SELECCION_MULTIPLE,
      then: Yup.string().required(CAMPO_REQUERIDO),
      otherwise: Yup.string().notRequired()
    }),
  
  obligatoria: Yup.string()
    .required(CAMPO_REQUERIDO),
});
