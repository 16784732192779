import {
  OBTENER_CAMPANIAS_EXITOSO,
  OBTENER_CAMPANIAS_ERROR,
  CARGANDO_CAMPANIAS
} from '../../constants/ActionTypes/Campanias';


const campaniasInicial = {
  campanias: [],
  cantidad: 0,
  pagina: 1,
  error: false,
  cargando: false,
  mensaje: null
};

const listarCampanias = (state=campaniasInicial, action) => {
  switch (action.type) {

  case OBTENER_CAMPANIAS_EXITOSO: {
    return {
      ...campaniasInicial,
      ...action.payload
    };
  }

  case OBTENER_CAMPANIAS_ERROR: {
    return {
      ...campaniasInicial,
      error: true,
      mensaje: action.payload
    };
  }

  case CARGANDO_CAMPANIAS: {
    return {
      ...campaniasInicial,
      cargando: action.payload
    };
  }

  default:
    return state;
  }
};


export default listarCampanias;
