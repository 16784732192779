import React from 'react';

import { Col, Row } from 'reactstrap';


const SeguridadTerminosCondicionesDetalleContainer = () => (
  <Row>
    <Col md={12} className="text-justify">
      <h1>Términos y condiciones de uso</h1>
      <hr/>

      <h2>
        <small>
          <b>1.</b> Envío de SPAM. Entendiéndose como tal, el envío de 16 mensajes con el mismo contenido en el
          transcurso de 1 (un) minuto por parte de un mismo Usuario Origen, los cuales se detecten técnicamente.
        </small>
      </h2>

      <h2>
        <small>
          <b>2.</b> Envío de flooding. Entendiéndose como tal, el envío de más de 100 (cien) Mensajes Cortos a uno o
          más destinos en el transcurso de un (1) minuto por parte de un mismo Usuario Origen, los cuales se detecten
          técnicamente.
        </small>
      </h2>

      <h2>
        <small>
          <b>3.</b> Que los Proveedores de Contenidos de la Parte Remitente envíen a los Usuarios Destino de la Parte
          Receptora, Mensajes Cortos que contengan prefijos o códigos que puedan ser interpretados por el Equipo
          Terminal Móvil del Usuario Destino como imágenes, tonos o logos. Con el fin de que las Partes puedan tomar
          las medidas necesarias para llevar a cabo el filtro de este tipo de Mensajes Cortos, a continuación se
          detallan los encabezados que actualmente las Partes tienen detectados y no pueden estar contenidos en
          ninguna parte del mensaje:

          <ul className="mt-2">
            <li>{'//SCKL'}</li>
            <li>{'//SCK'}</li>
            <li>IMELODY</li>
            <li>L35</li>
            <li>VERSION:1.0+FORMAT:CLASS1.0</li>
          </ul>
        </small>
      </h2>

      <h2>
        <small>
          <b>4.</b> El envío por una Parte o por la Fuerza de Ventas de una Parte a los Usuarios Destino de la otra
          Parte, de cualquier tipo de Mensaje Corto que sugiera la contratación de los servicios de telecomunicaciones
          provistos por la otra parte, así como la venta de equipos terminales de cualquier naturaleza, sus accesorios
          o, cualquier otro servicio o bien que comercialice la Parte Receptora.
        </small>
      </h2>

      <h2>
        <small>
          <b>5.</b> Manipular, falsear o insertar información en alguno de los campos de los Mensajes Cortos
          intercambiados a través del protocolo SMPP.
        </small>
      </h2>

      <h2>
        <small>
          <b>6.</b> Realizar exploraciones en la arquitectura de cualesquiera de los elementos involucrados en el
          diagrama de Interoperabilidad de la otra Parte, con el fin de buscar y/o explotar fallas en la seguridad.
        </small>
      </h2>

      <h2>
        <small>
          <b>7.</b> Enviar cualquier tipo de mensaje que afecte la configuración/programación del Equipo Terminal
          Móvil del Usuario Destino.
        </small>
      </h2>

      <h2>
        <small>
          <b>8.</b> Enviar cualquier tipo de mensaje que pueda afectar, menoscabar o restringir la operación del
          Equipo Terminal Móvil del Usuario Destino (Código Malicioso).
        </small>
      </h2>

      <h2>
        <small>
          <b>9.</b> Enviar por el Enlace mensajes diferentes a los contemplados en el Contrato y sus Anexos para la
          prestación del Servicio de SMS sin el consentimiento por escrito de la otra Parte.
        </small>
      </h2>

    </Col>
  </Row>
);


export default SeguridadTerminosCondicionesDetalleContainer;
