import React from 'react';
import { NavLink } from 'react-router-dom';

import {
  Col, Row, Button, Card, CardHeader, NavLink as NavLinkBT,
  Nav, NavItem, TabContent, TabPane, Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Form } from 'formik';
import { Wizard, Steps, Step } from 'react-albus';

import UIFormsCampoFormInput from '../../UI/Forms/CampoForm/Input';
import UIFormsCampoFormSelect from '../../UI/Forms/CampoForm/Select';
import UIDataTable from '../../UI/DataTable/DataTable';
import {
  ORIGEN_DESTINATARIOS,
  ORIGEN_DESTINATARIOS_LISTADO,
  ORIGEN_DESTINATARIOS_ARCHIVO,
  ORIGEN_DESTINATARIOS_ARCHIVO_LABEL,
  ORIGEN_DESTINATARIOS_LISTADO_LABEL
} from '../../../constants/ListasDestinatarios/Origenes';
import { listaSchema } from './Schema';

import ejemplo_csv from '../../../images/formato_csv.jpg';
import archivo_ejemplo_csv from '../../../media/ejemplo_csv.csv';

import ejemplo_txt from '../../../images/formato_txt.jpg';
import archivo_ejemplo_txt from '../../../media/ejemplo_txt.txt';


const ListaDestinatariosDetalle = (props) => (
  <Formik
    enableReinitialize
    initialValues={props.valoresIniciales}
    onSubmit={props.handleSubmit}
    validationSchema={listaSchema}
    validateOnChange
  >
    {formikProps => (
      <Form>
        <Row>
          <Col sm={12}>
            <Row form>
              <Col md={6}>
                <UIFormsCampoFormInput
                  id="nombre"
                  type="text"
                  name="nombre"
                  label="Nombre de la lista"
                  labelClassName="requerido"
                  placeholder=""
                  autoComplete="off"
                  error={formikProps.errors.nombre ? formikProps.errors.nombre : null}
                  touched={formikProps.touched.nombre ? formikProps.touched.nombre : null}
                />
              </Col>
              <Col md={6}>
                {
                  !formikProps.values.uuid &&
                    <UIFormsCampoFormSelect
                      id="origen"
                      name="origen"
                      label="Origen de los destinatarios"
                      labelClassName="requerido"
                      autoComplete="off"
                      formikProps={formikProps}
                      placeholder="Seleccione una opción"
                      className="padding-0"
                      options={ORIGEN_DESTINATARIOS}
                      onChange={(e) => {
                        if (e.value === ORIGEN_DESTINATARIOS_LISTADO) {
                          props.obtenerDestinatarios();
                        }

                        formikProps.setFieldValue('origen', e);
                      }}
                    />
                }

                {
                  formikProps.values.uuid && (
                    <div className="form-group">
                      <label>Origen de los destinatarios</label>
                      <div className="form-control form-control-lg">
                        {
                          formikProps.values.origen.value === ORIGEN_DESTINATARIOS_ARCHIVO ?
                            ORIGEN_DESTINATARIOS_ARCHIVO_LABEL
                            :
                            ORIGEN_DESTINATARIOS_LISTADO_LABEL
                        }
                        <span className="ml-1">
                          (<b>Cantidad: </b> {formikProps.values.cantidad_destinatarios} destinatarios)
                        </span>
                      </div>
                    </div>
                  )
                }
              </Col>
            </Row>
          </Col>
        </Row>

        {
          formikProps.values.origen.value === ORIGEN_DESTINATARIOS_LISTADO && !formikProps.values.uuid &&
          <Row className="animated slideInUpTiny animation-duration-3">
            <Col md={12}>
              <p className="mt-3 pb-0 mb-0">
                {
                  props.destinatariosSeleccionados.length === 0 ?
                    'No ha seleccionado ningún destinatario.'
                    :
                    `Se agregarán ${props.destinatariosSeleccionados.length} destinatarios a la lista ${formikProps.values.nombre}`
                }
              </p>
              <UIDataTable
                data={props.destinatarios}
                manual
                filterable={true}
                filtered={props.filtrado}
                pages={props.paginas}
                page={props.paginacion.pagina}
                pageSize={props.paginacion.tamano_pagina}
                onPageChange={props.cambioPagina}
                onPageSizeChange={props.cambioTamanoPagina}
                onFilteredChange={props.cambioFiltro}
                columns={[
                  {
                    accessor: '',
                    width: 100,
                    Header: 'Seleccionar',
                    Cell: (row) => (
                      <div className="form-checkbox text-center">
                        <input
                          type="checkbox"
                          color="primary"
                          tabIndex="-1"
                          checked={props.destinatariosSeleccionados.indexOf(row.original.uuid) !== -1}
                          onChange={() => props.toggleDestinatario(row.original.uuid)}
                        />
                        <span className="check">
                          <i className="zmdi zmdi-check zmdi-hc-lg"/>
                        </span>
                      </div>
                    ),
                    Filter: () => (
                      <div className="form-checkbox text-center">
                        <input
                          type="checkbox"
                          color="primary"
                          tabIndex="-1"
                          checked={props.destinatarios_todos}
                          onChange={() => props.toggleDestinatarioTodos()}
                        />
                        <span className="check">
                          <i className="zmdi zmdi-check zmdi-hc-lg"/>
                        </span>
                      </div>
                    )
                  },
                  {
                    Header: 'Nombre',
                    accessor: 'nombres'
                  },
                  {
                    Header: 'Número',
                    accessor: 'numero'
                  }
                ]}
                getTrProps={(state, rowInfo) => ({
                  onClick: () => props.toggleDestinatario(rowInfo.original.uuid)
                })}
                noDataText="No se ha encontrando ningún destinatario"
                rowsText="destinatarios"
                loading={props.cargandoDestinatarios}
              />
            </Col>
          </Row>
        }

        {
          formikProps.values.origen.value === ORIGEN_DESTINATARIOS_ARCHIVO && !formikProps.values.uuid &&
          <Row className="animated slideInUpTiny animation-duration-3">
            <Col md={12}>
              <Card body>
                <Nav pills justified fill>
                  <NavItem>
                    <Alert color={props.origenArchivo.pasos.activo === '1' ? 'dark' : 'light'}>
                      <b>Descargar formato</b>
                    </Alert>
                  </NavItem>
                  <NavItem>
                    <Alert color={props.origenArchivo.pasos.activo === '2' ? 'dark' : 'light'}>
                      <b>Cargar archivo</b>
                    </Alert>
                  </NavItem>
                </Nav>
                <Wizard onNext={props.handleSiguientePaso}>
                  <TabContent activeTab={props.origenArchivo.pasos.activo}>
                    <Steps>
                      <Step
                        id="formato"
                        render={({ next }) => (
                          <TabPane tabId="1">
                            <Row>
                              <Col sm="12">
                                <hr />
                                <h2 className="font-weight-bold">Descargue el formato de ejemplo <br/>
                                  <small>
                                    Puede cargar destinatarios desde un archivo plano en formato <b>CSV</b> o <b>TXT</b>.

                                    <Alert color="info">
                                      <FontAwesomeIcon icon={'info-circle'} size="lg" className="mr-2" /> <b>Importante!</b> <br />
                                      <p className="mt-1">
                                        Puede incluir todas las columnas que necesite, teniendo en cuenta que la
                                        columna <b>numero</b> es obligatoria. A continuación encontrará un archivo de
                                        ejemplo de cada tipo disponible para descargar.
                                      </p>
                                    </Alert>
                                  </small>
                                </h2>

                                <Row>
                                  <Col md={6}>
                                    <Card>
                                      <CardHeader>Archivo CSV</CardHeader>

                                      <Alert color="dark" className="m-1">
                                        Tenga en cuenta que este tipo de archivo se debe guardar usando Excel con la extesión
                                        CSV (MS-DOS). Ponga los datos del contacto en cada línea, así:
                                      </Alert>

                                      <div className="text-center my-2">
                                        <img src={ejemplo_csv} className="img-thumbnail img-fluid" alt="..." />
                                      </div>

                                      <NavLinkBT href={archivo_ejemplo_csv} className="btn btn-info" download>
                                        <FontAwesomeIcon icon="file-download" size="lg" className="mr-2" />
                                        Descargar archivo CSV de ejemplo
                                      </NavLinkBT>
                                    </Card>
                                  </Col>
                                  <Col md={6}>
                                    <Card>
                                      <CardHeader>Archivo TXT</CardHeader>

                                      <Alert color="dark" className="m-1">
                                        Tenga en cuenta que el contenido de este archivo debe ser separado por comas (,) o
                                        punto y comas (;), poniendo los datos del contacto en cada línea, así:
                                      </Alert>

                                      <div className="text-center my-2">
                                        <img src={ejemplo_txt} className="img-thumbnail img-fluid" alt="..." />
                                      </div>

                                      <NavLinkBT href={archivo_ejemplo_txt} className="btn btn-info" download>
                                        <FontAwesomeIcon icon="file-download" size="lg" className="mr-2" />
                                        Descargar archivo TXT de ejemplo
                                      </NavLinkBT>
                                    </Card>
                                  </Col>
                                </Row>

                                <Button color="primary" className="jr-btn" type="button" onClick={next}>
                                  Continuar
                                </Button>
                              </Col>
                            </Row>
                          </TabPane>
                        )}
                      />

                      <Step
                        id="archivo"
                        render={({ step, push }) => (
                          <TabPane tabId="2">
                            <Row>
                              <Col sm="12">
                                <hr />
                                <h2 className="font-weight-bold">Cargue el archivo <br/>
                                  <small>
                                    Por favor haga click en el siguiente botón y seleccione el archivo a cargar desde su equipo.
                                  </small>
                                </h2>

                                <div className="form-group">
                                  <label htmlFor="archivo">Seleccione el archivo que desea cargar</label>
                                  <input
                                    id="archivo"
                                    className="form-control"
                                    name="archivo"
                                    type="file"
                                    onChange={(e) => {
                                      formikProps.setFieldValue('archivo', e.currentTarget.files[0]);
                                    }}
                                  />
                                </div>

                                <p>
                                  Después de seleccionar el archivo, puede dar click en el botón <b>Guardar Lista</b> para finalizar el proceso.
                                </p>

                                <Button color="dark" className="jr-btn" type="button" onClick={() => props.handleAnteriorPaso({step, push})}>
                                  Regresar
                                </Button>
                              </Col>
                            </Row>
                          </TabPane>

                        )}
                      />

                    </Steps>
                  </TabContent>
                </Wizard>
              </Card>
            </Col>
          </Row>
        }

        {
          formikProps.values.uuid &&
            <Row>
              <Col md={12}>
                <UIDataTable
                  data={props.destinatarios}
                  manual
                  filterable={true}
                  filtered={props.filtrado}
                  pages={props.paginas}
                  page={props.paginacion.pagina}
                  pageSize={props.paginacion.tamano_pagina}
                  onPageChange={props.cambioPagina}
                  onPageSizeChange={props.cambioTamanoPagina}
                  onFilteredChange={props.cambioFiltro}
                  columns={[
                    {
                      Header: 'Contenido de la lista',
                      accessor: 'search',
                      Cell: (row) => {
                        let texto_datos = '';
                        Object.keys(row.original.datos).forEach((dato, index, datos) => {
                          texto_datos += `${dato}: ${row.original.datos[dato]}`;
                          texto_datos += Object.is(datos.length - 1, index) ? '.' : ', ';
                        });

                        return texto_datos;
                      }
                    }
                  ]}
                  noDataText="No se ha encontrando ningún destinatario"
                  rowsText="destinatarios"
                  loading={props.cargandoDestinatarios}
                />
              </Col>
            </Row>
        }

        <Row>
          <Col md={12}>
            <hr/>
            <div className="text-center">
              <Button color="success" className="jr-btn" type="submit" disabled={props.guardando}>
                {
                  props.guardando ?
                    <FontAwesomeIcon icon="circle-notch" size="lg" spin className="mr-2" />
                    :
                    <FontAwesomeIcon icon="check" size="lg" className="mr-2" />
                }
                Guardar lista
              </Button>

              <NavLink className="jr-btn btn btn-default" to='/listas'>
                <span>Regresar al listado</span>
              </NavLink>

              {
                formikProps.values.uuid &&
                <Button
                  color="danger"
                  className="jr-btn fa-pull-right"
                  type="button"
                  onClick={() => props.handleEliminar(formikProps.values.uuid)}>
                  <FontAwesomeIcon icon="ban" size="lg" className="mr-2"/>
                  Eliminar lista
                </Button>
              }
            </div>
          </Col>
        </Row>
      </Form>
    )}
  </Formik>
);


export default ListaDestinatariosDetalle;
