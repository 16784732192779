import {
  OBTENER_ENCUESTAS_EXITOSO,
  OBTENER_ENCUESTAS_ENVIADAS_EXITOSO,
  OBTENER_ENCUESTAS_ERROR,
  OBTENER_ENCUESTAS_ENVIADAS_ERROR,
  CARGANDO_ENCUESTAS,
  CARGANDO_ENCUESTAS_ENVIADAS
} from '../../constants/ActionTypes/Encuestas';


const encuestasInicial = {
  encuestas: [],
  cantidad: 0,
  paginas: 1,
  error: false,
  cargando: false,
  mensaje: null
};

const listarEncuestas = (state=encuestasInicial, action) => {
  switch (action.type) {

  case OBTENER_ENCUESTAS_EXITOSO:
  case OBTENER_ENCUESTAS_ENVIADAS_EXITOSO: {
    return {
      ...encuestasInicial,
      ...action.payload
    };
  }

  case OBTENER_ENCUESTAS_ERROR:
  case OBTENER_ENCUESTAS_ENVIADAS_ERROR: {
    return {
      ...encuestasInicial,
      error: true,
      mensaje: action.payload
    };
  }

  case CARGANDO_ENCUESTAS:
  case CARGANDO_ENCUESTAS_ENVIADAS: {
    return {
      ...encuestasInicial,
      cargando: action.payload
    };
  }

  default:
    return state;
  }
};


export default listarEncuestas;
