import React from 'react';
import { NavLink } from 'react-router-dom';

import { Col, Row, Button, FormGroup, Label, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Form } from 'formik';

import UIFormsCampoFormInput from '../../UI/Forms/CampoForm/Input';
import UIFormsCampoFormSelect from '../../UI/Forms/CampoForm/Select';
import UIFormsCampoFormMensaje from '../../UI/Forms/CampoForm/Mensaje/Mensaje';
import { mensajeSchema } from './Schema';
import { TIPOS_MENSAJE, TIPO_MENSAJE_MULTIMEDIA, TIPO_MENSAJE_ENCUESTA } from '../../../constants/Mensajes/Tipos';


const MensajesDetalle = (props) => (
  <Formik
    enableReinitialize
    initialValues={props.valoresIniciales}
    onSubmit={props.handleSubmit}
    validationSchema={mensajeSchema}
    validateOnChange
  >
    {formikProps => (
      <Form>
        <Row>
          <Col sm={12}>
            <Row form>
              <Col md={6}>
                <UIFormsCampoFormInput
                  id="nombre"
                  type="text"
                  name="nombre"
                  label="Nombre"
                  labelClassName="requerido"
                  placeholder=""
                  autoComplete="off"
                  error={formikProps.errors.nombre ? formikProps.errors.nombre : null}
                  touched={formikProps.touched.nombre ? formikProps.touched.nombre : null}
                />
              </Col>
              <Col md={6}>
                <UIFormsCampoFormSelect
                  id="tipo"
                  name="tipo"
                  label="Tipo"
                  labelClassName="requerido"
                  autoComplete="off"
                  formikProps={formikProps}
                  placeholder="Seleccione una opción"
                  className="padding-0"
                  options={TIPOS_MENSAJE}
                  onChange={(e) => {
                    props.setColumnasMensaje(e, formikProps.values.lista, formikProps.values.tipo.value);
                    formikProps.setFieldValue('tipo', e);
                    formikProps.setFieldValue('archivo', '');
                    formikProps.setFieldValue('encuesta', '');
                  }}
                />
              </Col>
            </Row>
            {
              formikProps.values.tipo.value === TIPO_MENSAJE_MULTIMEDIA &&
                <Row form>
                  <Col md={6}>
                    <UIFormsCampoFormSelect
                      id="archivo"
                      name="archivo"
                      label="Archivo"
                      autoComplete="off"
                      formikProps={formikProps}
                      placeholder="Seleccione una opción"
                      className="padding-0"
                      options={props.galeria}
                      onChange={(e) => {
                        props.setColumnasMensaje(e, formikProps.values.lista, formikProps.values.tipo.value);
                        formikProps.setFieldValue('archivo', e);
                      }}
                    />
                  </Col>
                  <Col md={6}>
                    {
                      formikProps.values.archivo && formikProps.values.archivo.url_corto ?
                        <FormGroup>
                          <Label>URL corto</Label>
                          <div className="form-control form-control-lg">
                            <Row>
                              <Col>
                                {formikProps.values.archivo.url_corto.url_corto}

                                {
                                  formikProps.values.archivo.url_corto.vencido ?
                                    <Badge color="danger" className="ml-2 m-0" pill>VENCIDO</Badge>
                                    :
                                    <Badge color="success" className="ml-2 m-0" pill>SIN VENCER</Badge>
                                }
                              </Col>
                            </Row>
                          </div>
                        </FormGroup>
                        :
                        null
                    }
                  </Col>
                </Row>
            }
            {
              formikProps.values.tipo.value === TIPO_MENSAJE_ENCUESTA &&
                <Row form>
                  <Col md={6}>
                    <UIFormsCampoFormSelect
                      id="encuesta"
                      name="encuesta"
                      label="Encuesta"
                      autoComplete="off"
                      formikProps={formikProps}
                      placeholder="Seleccione una opción"
                      className="padding-0"
                      options={props.encuestas}
                      onChange={(e) => {
                        props.setColumnasMensaje(e, formikProps.values.lista, formikProps.values.tipo.value);
                        formikProps.setFieldValue('encuesta', e);
                      }}
                    />
                  </Col>
                  <Col md={6}>
                    {
                      formikProps.values.encuesta && formikProps.values.encuesta.url_corto ?
                        <FormGroup>
                          <Label>URL corto</Label>
                          <div className="form-control form-control-lg">
                            <Row>
                              <Col>
                                {formikProps.values.encuesta.url_corto.url_corto}

                                {
                                  formikProps.values.encuesta.url_corto.vencido ?
                                    <Badge color="danger" className="ml-2 m-0" pill>VENCIDO</Badge>
                                    :
                                    <Badge color="success" className="ml-2 m-0" pill>SIN VENCER</Badge>
                                }
                              </Col>
                            </Row>
                          </div>
                        </FormGroup>
                        :
                        null
                    }
                  </Col>
                </Row>
            }
            <Row form>
              <Col md={12}>
                <UIFormsCampoFormSelect
                  id="lista"
                  name="lista"
                  label="Lista de destinatarios"
                  autoComplete="off"
                  formikProps={formikProps}
                  placeholder="Seleccione una opción"
                  className="padding-0"
                  options={props.listasDestinatarios}
                  onChange={(e) => {
                    props.setColumnasMensaje(formikProps.values.archivo || formikProps.values.encuesta, e, formikProps.values.tipo.value);
                    formikProps.setFieldValue('lista', e);
                  }}
                />
              </Col>
            </Row>
            <Row form>
              <Col md={12}>
                <UIFormsCampoFormMensaje
                  id="mensaje"
                  name="mensaje"
                  label="Mensaje"
                  labelClassName="requerido"
                  formikProps={formikProps}
                  placeholder="Puede usar los campos de la lista digitando '@' y seleccionando el campo deseado."
                  longitudMaxima={160}
                  columnas={props.columnasMensaje}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <hr/>
            <div className="text-center">
              <Button color="success" className="jr-btn" type="submit" disabled={props.guardando}>
                {
                  props.guardando ?
                    <FontAwesomeIcon icon="circle-notch" size="lg" spin className="mr-2" />
                    :
                    <FontAwesomeIcon icon="check" size="lg" className="mr-2" />
                }
                Guardar mensaje
              </Button>

              <NavLink className="jr-btn btn btn-default" to='/mensajes'>
                <span>Regresar al listado</span>
              </NavLink>

              {
                formikProps.values.uuid &&
                <Button
                  color="danger"
                  className="jr-btn fa-pull-right"
                  type="button"
                  onClick={() => props.handleEliminar(formikProps.values.uuid)}>
                  <FontAwesomeIcon icon="ban" size="lg" className="mr-2"/>
                  Eliminar mensaje
                </Button>
              }
            </div>
          </Col>
        </Row>
      </Form>
    )}
  </Formik>
);


export default MensajesDetalle;
