import {
  ENVIAR_MENSAJE,
  ENVIAR_MENSAJE_EXITOSO,
  ENVIAR_MENSAJE_ERROR,
  ENVIANDO_MENSAJE,
  ENVIAR_OBTENER_MENSAJES,
  ENVIAR_OBTENER_MENSAJES_EXITOSO,
  ENVIAR_OBTENER_MENSAJES_ERROR,
  ENVIAR_CARGANDO_MENSAJES,
  ENVIAR_OBTENER_LISTAS_DESTINATARIOS,
  ENVIAR_OBTENER_LISTAS_DESTINATARIOS_EXITOSO,
  ENVIAR_OBTENER_LISTAS_DESTINATARIOS_ERROR,
  ENVIAR_CARGANDO_LISTAS_DESTINATARIOS,
  ENVIAR_LIMPIAR_ENVIO_MENSAJE
} from '../../constants/ActionTypes/Mensajes';


export const enviarMensaje = (datos) => {
  return {
    type: ENVIAR_MENSAJE,
    payload: datos
  };
};

export const enviarMensajeExitoso = (mensaje) => {
  return {
    type: ENVIAR_MENSAJE_EXITOSO,
    payload: mensaje
  };
};

export const enviarMensajeError = (mensaje) => {
  return {
    type: ENVIAR_MENSAJE_ERROR,
    payload: mensaje
  };
};

export const enviandoMensaje = (enviando) => {
  return {
    type: ENVIANDO_MENSAJE,
    payload: enviando
  };
};

export const obtenerMensajes = () => {
  return {
    type: ENVIAR_OBTENER_MENSAJES
  };
};

export const obtenerMensajesExitoso = (mensajes) => {
  return {
    type: ENVIAR_OBTENER_MENSAJES_EXITOSO,
    payload: mensajes
  };
};

export const obtenerMensajesError = (mensaje) => {
  return {
    type: ENVIAR_OBTENER_MENSAJES_ERROR,
    payload: mensaje
  };
};

export const cargandoMensajes = (cargando) => {
  return {
    type: ENVIAR_CARGANDO_MENSAJES,
    payload: cargando
  };
};

export const obtenerListasDestinatarios = () => {
  return {
    type: ENVIAR_OBTENER_LISTAS_DESTINATARIOS
  };
};

export const obtenerListasDestinatariosExitoso = (destinatarios) => {
  return {
    type: ENVIAR_OBTENER_LISTAS_DESTINATARIOS_EXITOSO,
    payload: destinatarios
  };
};

export const obtenerListasDestinatariosError = (mensaje) => {
  return {
    type: ENVIAR_OBTENER_LISTAS_DESTINATARIOS_ERROR,
    payload: mensaje
  };
};

export const cargandoListasDestinatarios = (cargando) => {
  return {
    type: ENVIAR_CARGANDO_LISTAS_DESTINATARIOS,
    payload: cargando
  };
};


export const limpiarEnvioMensaje = () => {
  return {
    type: ENVIAR_LIMPIAR_ENVIO_MENSAJE
  };
};

