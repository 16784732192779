import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ScreensGaleriaListar from '../../screens/Galeria/GaleriaListar';
import ScreensGaleriaDetalle from '../../screens/Galeria/GaleriaDetalle';


const Galeria = (props) => (
  <div className="app-wrapper">
    <Switch>
      <Route exact path={`${props.match.url}/`} component={ScreensGaleriaListar}/>
      <Route path={`${props.match.url}/detalle/:uuid_archivo`} component={ScreensGaleriaDetalle} />
      <Route path={`${props.match.url}/detalle`} component={ScreensGaleriaDetalle}/>
    </Switch>
  </div>
);


export default Galeria;
