import React from 'react';
import { NavLink } from 'react-router-dom';

import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NumberFormat from 'react-number-format';

import { NAVEGACION_HORIZONTAL, DENTRO_DE_CABECERA } from '../../../constants/ActionTypes/Configuracion';
import LayoutCabeceraMenuContainer from '../Cabecera/Menu/MenuContainer';
import { USUARIO_TIPO_ADMIN, USUARIO_TIPO_PRINCIPAL } from '../../../constants/Usuarios/Tipos';


import logo from '../../../images/gosms-logo.png';
import avatar from '../../../images/usuario.jpg';


const LayoutCabecera = (props) => (
  <div className="app-main-header">
    <div className="d-flex app-toolbar align-items-center">
      {
        props.estiloNavegacion === NAVEGACION_HORIZONTAL  ?
          <div className="app-logo-bl">
            <div className="d-block d-md-none">
              <span className="jr-menu-icon" onClick={props.toggleNavegacionColapsada}>
                <span className="menu-icon"/>
              </span>
            </div>
            <div className="app-logo pointer d-none d-md-block">
              {/* <img className="d-none d-lg-block" alt='...' src='http://via.placeholder.com/105x36'/> */}
              <img className="d-none d-lg-block" alt='...' src={logo}/>
              <img className="d-block d-lg-none mr-3" alt='...' src='http://via.placeholder.com/32x32'/>
            </div>
          </div>
          :
          <span className={`jr-menu-icon pointer ${props.estiloDrawer}`} onClick={props.toggleNavegacionColapsada}>
            <span className="menu-icon"/>
          </span>
      }

      {
        props.tituloPagina ?
          <h1 className="title pb-2 mb-sm-0">
            <FontAwesomeIcon icon={props.tituloPagina.icono} size="lg" className="mr-2" />
            <span>{props.tituloPagina.titulo}</span>
          </h1>
          :
          null
      }

      {/* <SearchBox styleName="d-none d-lg-block"
                  onChange={this.updateSearchText.bind(this)}
                  value={this.state.searchText}/> */}
      {
        (props.estiloNavegacion === NAVEGACION_HORIZONTAL &&
          props.posicionNavegacionHorizontal === DENTRO_DE_CABECERA) && <LayoutCabeceraMenuContainer />
      }

      <ul className="header-notifications list-inline ml-auto">
        <li className="list-inline-item">
          <div className="quick-menu">
            <h2 className="mb-0">
              {props.usuario.nombre_cliente} <br/>
              <small>Unidades disponibles: <b>
                <NumberFormat
                  value={props.unidades}
                  displayType="text"
                  decimalScale={0}
                  decimalSeparator=","
                  thousandSeparator="."
                />
              </b></small>
            </h2>
          </div>
        </li>

        <li className="list-inline-item">
          <div className="quick-menu">
            <NavLink to="/seguridad/terminos">
              <span className="icon-btn size-20 font-size-16">
                <i className="zmdi zmdi-info zmdi-hc-lg"/>
              </span>
            </NavLink>
          </div>
        </li>
        <li className="list-inline-item user-nav">
          <Dropdown
            className="quick-menu"
            isOpen={props.infoUsuario}
            toggle={props.toggleInfoUsuario.bind(this)}>

            <DropdownToggle
              className="d-inline-block"
              tag="span"
              data-toggle="dropdown">
              <img
                alt='...'
                src={avatar}
                className="pointer user-avatar size-30"/>
            </DropdownToggle>

            <DropdownMenu right>
              <div>
                <div className="user-profile">
                  <img className="user-avatar border-0 size-40" src={avatar} alt="User"/>
                  <div className="user-detail ml-2">
                    <h4 className="user-name mb-0">
                      {props.usuario.first_name}
                    </h4>
                    <small>
                      {
                        props.usuario.tipo === USUARIO_TIPO_ADMIN ?
                          'Administrador'
                          :
                          (
                            props.usuario.tipo === USUARIO_TIPO_PRINCIPAL ?
                              'Principal'
                              :
                              'Normal'
                          )
                      }
                    </small>
                  </div>
                </div>
                <NavLink to="/seguridad/perfil" className="dropdown-item text-muted">
                  <i className="zmdi zmdi-face zmdi-hc-fw mr-1"/> Perfil
                </NavLink>
                <span className="dropdown-item text-muted cursor-pointer" onClick={props.handleCerrarSesion}>
                  <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-1"/> Cerrar sesión
                </span>
              </div>
            </DropdownMenu>
          </Dropdown>
        </li>
      </ul>
    </div>
  </div>
);

export default LayoutCabecera;
