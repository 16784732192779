import {
  OBTENER_LISTAS_DESTINATARIOS,
  OBTENER_LISTAS_DESTINATARIOS_EXITOSO,
  OBTENER_LISTAS_DESTINATARIOS_ERROR,
  CARGANDO_LISTAS_DESTINATARIOS
} from '../../constants/ActionTypes/ListasDestinatarios';


export const obtenerListasDestinatarios = (params) => {
  return {
    type: OBTENER_LISTAS_DESTINATARIOS,
    payload: params
  };
};

export const obtenerListasDestinatariosExitoso = (listas) => {
  return {
    type: OBTENER_LISTAS_DESTINATARIOS_EXITOSO,
    payload: listas
  };
};

export const obtenerListasDestinatariosError = (mensaje) => {
  return {
    type: OBTENER_LISTAS_DESTINATARIOS_ERROR,
    payload: mensaje
  };
};

export const cargandoListasDestinatarios = (cargando) => {
  return {
    type: CARGANDO_LISTAS_DESTINATARIOS,
    payload: cargando
  };
};
