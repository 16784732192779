import {
  OBTENER_DESTINATARIO_EXITOSO,
  OBTENER_DESTINATARIO_ERROR,
  GUARDAR_DESTINATARIO_EXITOSO,
  GUARDAR_DESTINATARIO_ERROR,
  GUARDANDO_DESTINATARIO,
  ELIMINAR_DESTINATARIO_EXITOSO,
  ELIMINAR_DESTINATARIO_ERROR
} from '../../constants/ActionTypes/Destinatarios';


const destinatariosInicial = {
  destinatario: {},
  error: false,
  mensaje: null,
  guardando: false,
  editando: false,
  eliminando: false,
  guardado_exitoso: false
};

const detalleDestinatarios = (state=destinatariosInicial, action) => {
  switch (action.type) {

  case OBTENER_DESTINATARIO_EXITOSO: {
    return {
      ...destinatariosInicial,
      destinatario: action.payload,
      editando: true
    };
  }

  case OBTENER_DESTINATARIO_ERROR: {
    return {
      ...destinatariosInicial,
      error: true,
      mensaje: action.payload,
      editando: true
    };
  }

  case GUARDAR_DESTINATARIO_EXITOSO: {
    return {
      ...destinatariosInicial,
      destinatario: action.payload.destinatario,
      mensaje: action.payload.mensaje,
      guardado_exitoso: true
    };
  }

  case GUARDAR_DESTINATARIO_ERROR: {
    return {
      ...destinatariosInicial,
      error: true,
      mensaje: action.payload
    };
  }

  case GUARDANDO_DESTINATARIO: {
    return {
      ...destinatariosInicial,
      guardando: action.payload
    };
  }

  case ELIMINAR_DESTINATARIO_EXITOSO: {
    return {
      ...destinatariosInicial,
      mensaje: action.payload.mensaje,
      editando: true,
      eliminando: true
    };
  }

  case ELIMINAR_DESTINATARIO_ERROR: {
    return {
      ...destinatariosInicial,
      error: true,
      mensaje: action.payload
    };
  }

  default:
    return state;
  }
};


export default detalleDestinatarios;
