
// Listar
export const OBTENER_GALERIA = 'obtener_galeria';
export const OBTENER_GALERIA_EXITOSO = 'obtener_galeria_exitoso';
export const OBTENER_GALERIA_ERROR = 'obtener_galeria_error';
export const CARGANDO_GALERIA = 'cargando_galeria';

// Detalle
export const OBTENER_ARCHIVO = 'obtener_archivo';
export const OBTENER_ARCHIVO_EXITOSO = 'obtener_archivo_exitoso';
export const OBTENER_ARCHIVO_ERROR = 'obtener_archivo_error';
export const GUARDAR_ARCHIVO = 'guardar_archivo';
export const GUARDAR_ARCHIVO_EXITOSO = 'guardar_archivo_exitoso';
export const GUARDAR_ARCHIVO_ERROR = 'guardar_archivo_error';
export const GUARDANDO_ARCHIVO = 'guardando_archivo';
export const ELIMINAR_ARCHIVO = 'eliminar_archivo';
export const ELIMINAR_ARCHIVO_EXITOSO = 'eliminar_archivo_exitoso';
export const ELIMINAR_ARCHIVO_ERROR = 'eliminar_archivo_error';
