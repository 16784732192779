import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import UIBarraScroll from '../../../UI/BarraScroll/BarraScroll';
import {USUARIO_TIPO_ADMIN, USUARIO_TIPO_NORMAL, USUARIO_TIPO_PRINCIPAL} from '../../../../constants/Usuarios/Tipos';


const BarraLateralContenido = (props) => (
  <UIBarraScroll className="scrollbar" style={{height: 'calc(100vh - 70px)'}}>
    <ul className="nav-menu">

      <li className="nav-header">
        <span>Administración</span>
      </li>
      {
        props.usuario.tipo === USUARIO_TIPO_ADMIN &&
        <li className="menu no-arrow">
          <NavLink to="/clientes">
            <FontAwesomeIcon icon="user-tie" size="lg" className="mr-2" />
            <span className="nav-text">Clientes</span>
          </NavLink>
        </li>
      }

      {
        (props.usuario.tipo === USUARIO_TIPO_ADMIN || props.usuario.tipo === USUARIO_TIPO_PRINCIPAL) &&
        <li className="menu no-arrow">
          <NavLink to="/usuarios">
            <FontAwesomeIcon icon="users" size="lg" className="mr-2" />
            <span className="nav-text">Usuarios</span>
          </NavLink>
        </li>
      }

      {
        (props.usuario.tipo === USUARIO_TIPO_PRINCIPAL || props.usuario.tipo === USUARIO_TIPO_NORMAL) &&
        <li className="menu no-arrow">
          <NavLink to="/destinatarios">
            <FontAwesomeIcon icon="user-circle" size="lg" className="mr-2" />
            <span className="nav-text">Destinatarios</span>
          </NavLink>
        </li>
      }

      {
        (props.usuario.tipo === USUARIO_TIPO_PRINCIPAL || props.usuario.tipo === USUARIO_TIPO_NORMAL) &&
        <li className="menu no-arrow">
          <NavLink to="/galeria" className="">
            <FontAwesomeIcon icon="images" size="lg" className="mr-2" />
            <span className="nav-text">Galeria</span>
          </NavLink>
        </li>
      }

      {
        (props.usuario.tipo === USUARIO_TIPO_PRINCIPAL || props.usuario.tipo === USUARIO_TIPO_NORMAL) &&
        <li className="menu no-arrow">
          <NavLink to="/listas">
            <FontAwesomeIcon icon="list-ol" size="lg" className="mr-2" />
            <span className="nav-text">Listas</span>
          </NavLink>
        </li>
      }

      {
        (props.usuario.tipo === USUARIO_TIPO_PRINCIPAL || props.usuario.tipo === USUARIO_TIPO_NORMAL) &&
        <li className="menu no-arrow">
          <NavLink to="/mensajes">
            <FontAwesomeIcon icon="comments" size="lg" className="mr-2" />
            <span className="nav-text">Mensajes</span>
          </NavLink>
        </li>
      }

      {
        (props.usuario.tipo === USUARIO_TIPO_PRINCIPAL || props.usuario.tipo === USUARIO_TIPO_NORMAL) &&
        <li className="menu no-arrow">
          <NavLink to="/campanias">
            <FontAwesomeIcon icon="comments-dollar" size="lg" className="mr-2" />
            <span className="nav-text">Campañas</span>
          </NavLink>
        </li>
      }

      {
        (props.usuario.tipo === USUARIO_TIPO_PRINCIPAL || props.usuario.tipo === USUARIO_TIPO_NORMAL) &&
        <li className="menu no-arrow">
          <NavLink to="/doble_via/claves">
            <FontAwesomeIcon icon="exchange-alt" size="lg" className="mr-2" />
            <span className="nav-text">Doble vía</span>
          </NavLink>
        </li>
      }

      {
        (props.usuario.tipo === USUARIO_TIPO_PRINCIPAL || props.usuario.tipo === USUARIO_TIPO_NORMAL) &&
        <li className="menu no-arrow">
          <NavLink to="/encuestas">
            <FontAwesomeIcon icon="question-circle" size="lg" className="mr-2" />
            <span className="nav-text">Encuestas</span>
          </NavLink>
        </li>
      }

      <li className="nav-header">
        <span>Operaciones</span>
      </li>
      {
        props.usuario.tipo === USUARIO_TIPO_ADMIN &&
        <li className="menu no-arrow">
          <NavLink to="/transacciones/enviar_saldo">
            <FontAwesomeIcon icon="hand-holding-usd" size="lg" className="mr-2" />
            <span className="nav-text">Enviar saldo</span>
          </NavLink>
        </li>
      }

      {
        (props.usuario.tipo === USUARIO_TIPO_PRINCIPAL || props.usuario.tipo === USUARIO_TIPO_NORMAL) &&
        <li className="menu no-arrow">
          <NavLink to="/mensajes/enviar">
            <FontAwesomeIcon icon="sms" size="lg" className="mr-2" />
            <span className="nav-text">Enviar mensaje</span>
          </NavLink>
        </li>
      }

      {
        (props.usuario.tipo === USUARIO_TIPO_PRINCIPAL || props.usuario.tipo === USUARIO_TIPO_NORMAL) &&
        <li className="menu no-arrow">
          <NavLink to="/campanias/enviar">
            <FontAwesomeIcon icon="comment-dollar" size="lg" className="mr-2" />
            <span className="nav-text">Enviar campaña</span>
          </NavLink>
        </li>
      }

      <li className="nav-header">
        <span>Reportes</span>
      </li>
      <li className="menu no-arrow">
        <NavLink to="/reportes/mensajes">
          <FontAwesomeIcon icon="list-alt" size="lg" className="mr-2" />
          <span className="nav-text">Mensajes</span>
        </NavLink>
      </li>
      <li className="menu no-arrow">
        <NavLink to="/reportes/campanias">
          <FontAwesomeIcon icon="chart-pie" size="lg" className="mr-2" />
          <span className="nav-text">Campañas</span>
        </NavLink>
      </li>
      <li className="menu no-arrow">
        <NavLink to="/doble_via/respuestas">
          <FontAwesomeIcon icon="undo" size="lg" className="mr-2" />
          <span className="nav-text">Respuestas doble vía</span>
        </NavLink>
      </li>
      <li className="menu no-arrow">
        <NavLink to="/reportes/encuestas">
          <FontAwesomeIcon icon="chart-bar" size="lg" className="mr-2" />
          <span className="nav-text">Encuestas</span>
        </NavLink>
      </li>
    </ul>
  </UIBarraScroll>
);

export default withRouter(BarraLateralContenido);
