import * as Yup from 'yup';

import {
  CAMPO_REQUERIDO,
  CAMPO_NUMERO,
  CAMPO_COINCIDE
} from '../../../constants/Forms/Validacion';


export const enviarSaldoSchema = Yup.object().shape({
  cliente: Yup.object({
    label: Yup.string().required(CAMPO_REQUERIDO),
    value: Yup.string().required(CAMPO_REQUERIDO)
  }),

  unidades: Yup.string()
    .typeError(CAMPO_NUMERO)
    .required(CAMPO_REQUERIDO),

  unidades_confirmacion: Yup.string()
    .oneOf([Yup.ref('unidades'), null], CAMPO_COINCIDE)
    .when('unidades', (unidades, schema) => {
      return unidades ?
        schema.required(CAMPO_REQUERIDO)
        :
        schema;
    }),

  observaciones: Yup.string()
    .notRequired()
});
