import React from 'react';
import { Route, Switch } from 'react-router-dom';

import withBreadcrumbs from 'react-router-breadcrumbs-hoc';

import ScreensClientesListar from '../../screens/Clientes/ClientesListar';
import ScreensClientesDetalle from '../../screens/Clientes/ClientesDetalle';


const ClienteBreadcrumb = ({match}) => {
  // console.log(match);
};

// define some custom breadcrumbs for certain routes (optional)
const routes = [
  { path: '/clientes/detalle/:uuid_cliente', breadcrumb: ClienteBreadcrumb },
  { path: '/clientes', breadcrumb: 'Custom Example' },
];

const Clientes = (props) => (
  <div className="app-wrapper">
    <Switch>
      <Route exact path={`${props.match.url}/`} component={ScreensClientesListar}/>
      <Route
        path={`${props.match.url}/detalle/:uuid_cliente`}
        component={ScreensClientesDetalle}
        breadcrumb='hola'
      />
      <Route path={`${props.match.url}/detalle`} component={ScreensClientesDetalle}/>
    </Switch>
  </div>
);


export default withBreadcrumbs(routes)(Clientes);
