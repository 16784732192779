import React from 'react';

import UIBreadcrumbContainer from '../../components/UI/Breadcrumb/BreadcrumbContainer';
import EncuestasListarContainer from '../../components/Encuestas/Listar/ListarContainer';

const ScreensEncuestasListar = () => (
  <div className="animated slideInUpTiny animation-duration-3">

    <UIBreadcrumbContainer></UIBreadcrumbContainer>

    <EncuestasListarContainer />
  </div>
);


export default ScreensEncuestasListar;
