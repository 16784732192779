import {
  OBTENER_LISTAS_DESTINATARIOS_EXITOSO,
  OBTENER_LISTAS_DESTINATARIOS_ERROR,
  CARGANDO_LISTAS_DESTINATARIOS
} from '../../constants/ActionTypes/ListasDestinatarios';


const listasDestinatariosInicial = {
  listas: [],
  cantidad: 0,
  paginas: 1,
  error: false,
  cargando: false,
  mensaje: null
};

const listarListasDestinatarios = (state=listasDestinatariosInicial, action) => {
  switch (action.type) {

  case OBTENER_LISTAS_DESTINATARIOS_EXITOSO: {
    return {
      ...listasDestinatariosInicial,
      ...action.payload
    };
  }

  case OBTENER_LISTAS_DESTINATARIOS_ERROR: {
    return {
      ...listasDestinatariosInicial,
      error: true,
      mensaje: action.payload
    };
  }

  case CARGANDO_LISTAS_DESTINATARIOS: {
    return {
      ...listasDestinatariosInicial,
      cargando: action.payload
    };
  }

  default:
    return state;
  }
};


export default listarListasDestinatarios;
