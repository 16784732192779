import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { toast } from 'react-toastify';

import {
  obtenerEncuesta
} from '../../../../actions/Encuestas/Detalle';
import {
  obtenerReporteResultado,
  cargandoReporteResultado
} from '../../../../actions/Reportes/Encuestas';
import { setTituloPagina } from '../../../../actions/Configuracion';
import ReportesEncuestasResultadoDetalle from './Resultado';


class ReportesEncuestasResultadoContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleObtenerReporte = () => {
    this.props.actions.cargandoReporteResultado(true);

    this.props.actions.obtenerReporteResultado({
      encuesta: this.props.match.params.uuid_encuesta,
      resultado: this.props.match.params.uuid_resultado
    });
  };

  componentDidMount() {
    this.props.actions.setTituloPagina({titulo: 'Reporte de resultados de encuestas enviadas', icono: 'chart-bar'});

    if (this.props.match.params.uuid_encuesta && this.props.match.params.uuid_resultado) {
      this.props.actions.obtenerEncuesta(this.props.match.params.uuid_encuesta);
      this.handleObtenerReporte();
    }
  }

  componentDidUpdate() {
    // Verificar si existió algún error
    if (this.props.error) {
      if (this.props.mensaje) {
        toast.error(this.props.mensaje);
      }

      if (this.props.cargando) {
        this.props.actions.cargandoReporteResultado(false);
      }
    }
  }

  render() {
    return (
      <ReportesEncuestasResultadoDetalle
        obtenerReporte={this.handleObtenerReporte}
        encuesta={this.props.detalleEncuestas.encuesta}
        resultado={this.props.resultado}
        cargando={this.props.cargando}
      />
    );
  }
}


const mapStateToProps = ({ reporteEncuestasResultado, detalleEncuestas }) => {
  return { ...reporteEncuestasResultado, detalleEncuestas };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      setTituloPagina,
      obtenerEncuesta,
      obtenerReporteResultado,
      cargandoReporteResultado
    }, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportesEncuestasResultadoContainer));
