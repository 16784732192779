import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import uuid from 'uuid';
import { toast } from 'react-toastify';

import {
  obtenerArchivo,
  guardarArchivo,
  guardandoArchivo,
  eliminarArchivo
} from '../../../actions/Galeria/Detalle';
import { mostrarModal, ocultarModal } from '../../../actions/Modal';
import { setTituloPagina } from '../../../actions/Configuracion';
import GaleriaDetalle from './Detalle';


class GaleriaDetalleContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      archivo: {
        archivo: null,
        nombre: '',
        tiempo_validez: 1,
        url_destino: ''
      }
    };
  }

  cargarArchivo = (archivo) => {
    this.setState({
      archivo: {
        ...archivo,
        tiempo_validez: archivo.url_corto.tiempo_validez,
        url_destino: archivo.url_destino ? archivo.url_destino : ''
      }
    });
  };

  handleActualizacionArchivo = () => {
    toast.success(this.props.mensaje);
    this.props.history.push('/galeria');
  };

  handleEliminar = (archivo_uuid) => {
    this.props.actions.mostrarModal({
      uuid: uuid.v4(),
      atributos: {
        titulo: 'Eliminar archivo',
        mensaje: '¿Realmente desea eliminar este archivo?',
        colorBotonAceptar: 'danger',
        textoBotonAceptar: 'Eliminar archivo',
        nota: 'Esta acción no se puede deshacer',
        colorNota: 'danger',
        tamano: 'sm',
        aceptar: () => this.props.actions.eliminarArchivo(archivo_uuid)
      }
    });
  };

  handleSubmit = (values) => {
    if (values.archivo) {
      const archivo = {
        uuid: values.uuid,
        nombre: values.nombre,
        url_destino: values.url_destino,
        tiempo_validez: values.tiempo_validez
      };

      if (!values.uuid) {
        archivo.archivo = values.archivo;
      }

      this.props.actions.guardandoArchivo(true);
      this.props.actions.guardarArchivo(archivo);
    } else {
      toast.error('Debe seleccionar un archivo.');
    }
  };

  componentDidMount() {
    this.props.actions.setTituloPagina({titulo: 'Galeria de archivos', icono: 'images'});

    if (this.props.match.params.uuid_archivo) {
      this.props.actions.obtenerArchivo(this.props.match.params.uuid_archivo);
    }
  }

  componentDidUpdate(props) {
    // Verificar si existió algún error
    if (this.props.error) {
      if (this.props.mensaje) {
        toast.error(this.props.mensaje);
      }

      if (this.props.guardando) {
        this.props.actions.guardandoArchivo(false);
      }

    // Verificar si es una edición de galeria
    } else if (this.props.editando) {
      if (this.props.eliminando) {
        this.props.actions.ocultarModal();
        this.handleActualizacionArchivo();
      } else if (props.archivo !== this.props.archivo) {
        this.cargarArchivo(this.props.archivo);
      } else if (this.props.guardado_exitoso) {
        this.handleActualizacionArchivo();
      }

    // Verificar si es creación de un nuevo destinatario
    } else if (this.props.guardado_exitoso) {
      this.handleActualizacionArchivo();
    }
  }

  render() {
    return (
      <GaleriaDetalle
        handleSubmit={this.handleSubmit}
        handleEliminar={this.handleEliminar}
        valoresIniciales={this.state.archivo}
        guardando={this.props.guardando}
      />
    );
  }
}


const mapStateToProps = ({ detalleGaleria }) => {
  return { ...detalleGaleria };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      setTituloPagina,
      mostrarModal,
      ocultarModal,
      obtenerArchivo,
      guardarArchivo,
      guardandoArchivo,
      eliminarArchivo
    }, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GaleriaDetalleContainer));
