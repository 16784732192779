import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Alert } from 'reactstrap';

import {
  ENCIMA_DE_CABECERA,
  DEBAJO_DE_CABECERA,
  NAVEGACION_HORIZONTAL
} from '../../../constants/ActionTypes/Configuracion';
import LayoutBarraLateralContainer from '../BarraLateral/BarraLateralContainer';
import LayoutCabeceraContainer from '../Cabecera/CabeceraContainer';
import LayoutBarraSuperiorContainer from '../BarraSuperior/BarraSuperiorContainer';
import PiePagina from '../PiePagina/PiePagina';
import Clientes from '../../Clientes';
import Usuarios from '../../Usuarios';
import Destinatarios from '../../Destinatarios';
import ListasDestinatarios from '../../ListasDestinatarios';
import Seguridad from '../../Seguridad';
import Transacciones from '../../Transacciones';
import Mensajes from '../../Mensajes';
import Campanias from '../../Campanias';
import EnviarCampanias from '../../EnviarCampanias';
import DobleVia from '../../DobleVia';
import Reportes from '../../Reportes';
import Encuestas from '../../Encuestas';
import Galeria from '../../Galeria';


const LayoutBase = (props) => (
  <div className={`app-container ${props.estiloDrawer}`}>

    <LayoutBarraLateralContainer />

    <div className="app-main-container">
      <div className="app-header">
        {
          (props.estiloNavegacion === NAVEGACION_HORIZONTAL &&
            props.posicionNavegacionHorizontal === ENCIMA_DE_CABECERA) &&
              <LayoutBarraSuperiorContainer nombreEstilo="app-top-header"/>
        }

        <LayoutCabeceraContainer />

        {
          props.unidades <= 500 &&
          <Alert color="danger" className="m-0">
            <b>Atención!</b> Su saldo de mensajes esta próximo a terminarse.
            Por favor recargue para seguir usando el servicio.
          </Alert>
        }

        {
          (props.estiloNavegacion === NAVEGACION_HORIZONTAL &&
            props.posicionNavegacionHorizontal === DEBAJO_DE_CABECERA) &&
              <LayoutBarraSuperiorContainer />
        }
      </div>

      <main className="app-main-content-wrapper">
        <div className="app-main-content">
          <Switch>
            <Route path={`${props.match.url}seguridad`} component={Seguridad} />
            <Route path={`${props.match.url}clientes`} component={Clientes} />
            <Route path={`${props.match.url}usuarios`} component={Usuarios} />
            <Route path={`${props.match.url}destinatarios`} component={Destinatarios} />
            <Route path={`${props.match.url}listas`} component={ListasDestinatarios} />
            <Route path={`${props.match.url}mensajes`} component={Mensajes} />
            <Route path={`${props.match.url}campanias/enviar`} component={EnviarCampanias} />
            <Route path={`${props.match.url}campanias`} component={Campanias} />
            <Route path={`${props.match.url}transacciones`} component={Transacciones} />
            <Route path={`${props.match.url}doble_via`} component={DobleVia} />
            <Route path={`${props.match.url}reportes`} component={Reportes} />
            <Route path={`${props.match.url}encuestas`} component={Encuestas} />
            <Route path={`${props.match.url}galeria`} component={Galeria} />
            {/* <Route component={asyncComponent(() => import('components/Error404'))}/> */}
          </Switch>
        </div>

        <PiePagina />
      </main>
    </div>
  </div>
);


export default LayoutBase;
