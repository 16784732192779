import React, { Component } from 'react';

import withBreadcrumbs from 'react-router-breadcrumbs-hoc';

import Breadcrumb from './Breadcrumb';


// define some custom breadcrumbs for certain routes (optional)
const routes = [
  {path: '/', breadcrumb: 'Inicio'},
];

class BreadcrumbContainer extends Component {
  render() {
    return (
      <Breadcrumb
        breadcrumbs={this.props.breadcrumbs}
      />
    );
  }
}


export default withBreadcrumbs(routes)(BreadcrumbContainer);
