import React from 'react';

import { MINI_DRAWER } from '../../../../constants/ActionTypes/Configuracion';

import logo from '../../../../images/gosms-logo.png';
// import logo from '../../../../images/gosms-logo-completo.png';


const BarraLateralLogo = (props) => {

  const showMini = props.tipoDrawer.includes(MINI_DRAWER);

  return (
    <div className="sidebar-header d-flex align-items-center">
      {
        showMini ?
          <div className="mini-logo">
            <img className="mini-logo-img" alt='...' src='http://via.placeholder.com/32x32'/>
            {/* <img className="mini-logo-img-hover" alt='...' src='http://via.placeholder.com/105x36'/> */}
            <img className="mini-logo-img-hover" alt='...' src={logo} width={60}/> Go SMS
          </div>
          :
          <div>
            <h1 className="mb-0">
              <img alt='...' src={logo} width={60}/> <b className="ml-2 align-bottom">Go SMS</b>
            </h1>
          </div>
          // <img alt='...' src='http://via.placeholder.com/105x36'/>
      }
    </div>
  );
};

export default BarraLateralLogo;
