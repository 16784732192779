import {
  ENVIAR_SALDO_EXITOSO,
  ENVIAR_SALDO_ERROR,
  ENVIANDO_SALDO,
  OBTENER_CLIENTES_EXITOSO,
  OBTENER_CLIENTES_ERROR,
  LIMPIAR_ENVIO_SALDO
} from '../../constants/ActionTypes/Transacciones';


const enviarSaldoInicial = {
  enviarSaldo: {},
  clientes: [],
  error: false,
  guardando: false,
  mensaje: null,
  guardado_exitoso: false
};

const enviarSaldo = (state=enviarSaldoInicial, action) => {
  switch (action.type) {

  case ENVIAR_SALDO_EXITOSO: {
    return {
      ...enviarSaldoInicial,
      guardado_exitoso: true,
      mensaje: action.payload
    };
  }

  case ENVIAR_SALDO_ERROR: {
    return {
      ...enviarSaldoInicial,
      error: true,
      mensaje: action.payload
    };
  }

  case ENVIANDO_SALDO: {
    return {
      ...enviarSaldoInicial,
      guardando: action.payload
    };
  }

  case LIMPIAR_ENVIO_SALDO: {
    return {
      ...enviarSaldoInicial
    };
  }

  case OBTENER_CLIENTES_EXITOSO: {
    return {
      ...enviarSaldoInicial,
      enviarSaldo: state.enviarSaldo,
      clientes: action.payload
    };
  }

  case OBTENER_CLIENTES_ERROR: {
    return {
      ...enviarSaldoInicial,
      enviarSaldo: state.enviarSaldo,
      error: true,
      mensaje: action.payload
    };
  }

  default:
    return state;
  }
};


export default enviarSaldo;
