import React from 'react';
import { NavLink } from 'react-router-dom';

import { Button, Row, Col, Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ResponsiveContainer, PieChart, Pie, Tooltip, Cell } from 'recharts';
import moment from 'moment';

import UIDataTable from '../../../UI/DataTable/DataTable';
import { ESTADOS_CAMPANIA } from '../../../../constants/EnviarCampanias/Estados';
import { TIPOS_MENSAJE } from '../../../../constants/Mensajes/Tipos';


const ReportesCampaniasDetalle = (props) => (
  <div className="app-module">
    <div className="module-box">
      <div className="module-box-header">
        <div className="module-box-header-inner">
          <div className="module-box-header-right">
            <Button color="info" className="jr-btn" type="button" disabled={props.guardando} onClick={props.obtenerReporte}>
              {
                props.guardando ?
                  <FontAwesomeIcon icon="circle-notch" size="lg" spin className="mr-2" />
                  :
                  <FontAwesomeIcon icon="sync" size="lg" className="mr-2" />
              }
              Actualizar reporte
            </Button>
            <Button
              color="success"
              className="jr-btn fa-pull-right"
              onClick={props.exportarReporte}
              disabled={props.cargando || !props.datos.length}>
              <FontAwesomeIcon icon="file-excel" size="lg" className="mr-2" />
              <span>Exportar a Excel</span>
            </Button>
            <NavLink className="jr-btn btn btn-default" to='/reportes/campanias'>
              <span>Regresar al listado</span>
            </NavLink>
          </div>
        </div>
      </div>
      <div className="module-box-content pt-2">
        <Row>
          <Col md={12}>
            <h2 className="text-center mb-0">Mensajes enviados de la campaña <b></b></h2>
            <div className="height-300 m-auto">
              {
                props.cargando &&
                  <Alert color="light" className="text-center">
                    <FontAwesomeIcon icon="sync" spin size="lg" className="mr-2" />
                    Cargando reporte...
                  </Alert>
              }

              {
                !props.cargando && !props.datos.length &&
                  <Alert color="info" className="text-center">
                    <FontAwesomeIcon icon="info-circle" size="lg" className="mr-2" />
                    No se ha econtrado ningún mensaje
                  </Alert>
              }
              <ResponsiveContainer>
                <PieChart>
                  <Pie
                    data={props.datos}
                    dataKey="value"
                    nameKey="name"
                    fill="#2196F3"
                    innerRadius={30}
                    minAngle={10}
                    labelLine={false}
                    label={true}>
                    {
                      props.datos.map((entry, index) => <Cell key={index} fill={entry.color} />)
                    }
                  </Pie>
                  <Tooltip/>
                </PieChart>
              </ResponsiveContainer>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <UIDataTable
              data={props.mensajes}
              filterable={true}
              columns={[
                {
                  Header: 'Fecha',
                  filterable: false,
                  accessor: 'created',
                  Cell: (row) => row.original.created ?
                    `${moment(row.original.created).format('L')} ${moment(row.original.created).format('hh:mm A')}`
                    :
                    null
                },
                {
                  Header: 'Número',
                  accessor: 'numero'
                },
                {
                  Header: 'Estado',
                  accessor: 'estado',
                  Cell: (row) => {
                    let estado = ESTADOS_CAMPANIA.find((estado) => estado.value === row.original.estado);

                    return estado ? estado.label : 'Desconocido';
                  },
                  Filter: ({ filter, onChange }) => (
                    <select
                      className="form-control form-control-sm"
                      onChange={(event) => onChange(event.target.value)}
                      value={filter ? filter.value : ''}
                    >
                      <option value="">Todos</option>
                      {
                        ESTADOS_CAMPANIA.map((estado, index) => (
                          <option key={index} value={estado.value}>{estado.label}</option>
                        ))
                      }
                    </select>
                  )
                },
                {
                  Header: 'Tipo',
                  accessor: 'tipo',
                  Cell: (row) => row.original.tipo.toUpperCase(),
                  Filter: ({ filter, onChange }) => (
                    <select
                      className="form-control form-control-sm"
                      onChange={(event) => onChange(event.target.value)}
                      value={filter ? filter.value : ''}
                    >
                      <option value="">Todos</option>
                      {
                        TIPOS_MENSAJE.map((estado, index) => (
                          <option key={index} value={estado.value.toLowerCase()}>{estado.label}</option>
                        ))
                      }
                    </select>
                  )
                },
                {
                  Header: 'Mensaje',
                  filterable: false,
                  accessor: 'mensaje'
                },
                {
                  Header: 'Observaciones',
                  filterable: false,
                  accessor: 'observaciones'
                }
              ]}
              noDataText="No se ha encontrando ningún mensaje"
              rowsText="mensajes"
              loading={props.cargando}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <hr/>
            <div className="text-center">
              <Button color="info" className="jr-btn" type="button" disabled={props.guardando} onClick={props.obtenerReporte}>
                {
                  props.guardando ?
                    <FontAwesomeIcon icon="circle-notch" size="lg" spin className="mr-2" />
                    :
                    <FontAwesomeIcon icon="sync" size="lg" className="mr-2" />
                }
                Actualizar reporte
              </Button>

              <NavLink className="jr-btn btn btn-default" to='/reportes/campanias'>
                <span>Regresar al listado</span>
              </NavLink>

              <Button
                color="success"
                className="jr-btn fa-pull-right"
                onClick={props.exportarReporte}
                disabled={props.cargando || !props.datos.length}>
                <FontAwesomeIcon icon="file-excel" size="lg" className="mr-2" />
                <span>Exportar a Excel</span>
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  </div>
);


export default ReportesCampaniasDetalle;
