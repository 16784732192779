import React from 'react';
import { NavLink } from 'react-router-dom';

import { Button, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import UIDataTable from '../../UI/DataTable/DataTable';


const EncuestasListar = (props) => (
  <div className="app-module">
    <div className="module-box">
      <div className="module-box-header">
        <div className="module-box-header-inner">
          <div className="module-box-header-right">
            <Button color="info" className="jr-btn" onClick={props.obtenerEncuestas}>
              <FontAwesomeIcon icon="sync" size="lg" className="mr-2" />
              <span>Actualizar</span>
            </Button>

            <NavLink className="jr-btn btn btn-primary" to='/encuestas/detalle'>
              <FontAwesomeIcon icon="plus-circle" size="lg" className="mr-2" />
              <span>Crear encuesta</span>
            </NavLink>
          </div>
        </div>
      </div>
      <div className="module-box-content">

        <UIDataTable
          data={props.encuestas}
          manual
          filterable={true}
          filtered={props.filtrado}
          pages={props.paginas}
          page={props.paginacion.pagina}
          pageSize={props.paginacion.tamano_pagina}
          onPageChange={props.cambioPagina}
          onPageSizeChange={props.cambioTamanoPagina}
          onFilteredChange={props.cambioFiltro}
          columns={[
            {
              Header: 'Nombre',
              accessor: 'search',
              Cell: (row) => row.original.nombre ? row.original.nombre : null
            },
            {
              Header: 'URL Corto',
              accessor: 'url_corto.url_corto',
              filterable: false,
            },
            {
              Header: 'Fecha de vencimiento',
              accessor: 'numero',
              filterable: false,
              Cell: (row) => 
                row.original.url_corto ? moment(row.original.url_corto.fecha_vencimiento).format('LL') : null
            },
            {
              Header: 'Vencido',
              accessor: 'vencido',
              filterable: false,
              Cell: (row) => (
                row.original.url_corto && row.original.url_corto.vencido ?
                  <Badge color="danger" pill>VENCIDO</Badge>
                  :
                  <Badge color="success" pill>SIN VENCER</Badge>
              )
            }
          ]}
          getTrProps={(state, rowInfo) => ({
            onClick: () => {
              props.editarEncuesta(rowInfo.original.uuid);
            }
          })}
          noDataText="No se ha encontrando ninguna encuesta"
          rowsText="encuestas"
          loading={props.cargando}
        />
      </div>
    </div>
  </div>
);


export default EncuestasListar;
