import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { esIOS, esMovil } from 'react-device-detect';

import { DRAWER_FIJO, DRAWER_COLAPSADO } from '../../../constants/ActionTypes/Configuracion';
import LayoutBase from './Base';

import 'rmc-drawer/assets/index.css';
import '../../../styles/bootstrap.css';
import '../../../styles/app.css';
import ModalContainer from '../../UI/Modal/ModalContainer';
// import "react-toggle-switch/dist/css/switch.min.css";


class LayoutBaseContainer extends Component {

  componentDidMount() {
    document.body.classList.add(this.props.colorTema);
  }

  render() {
    const estiloDrawer = this.props.tipoDrawer.includes(DRAWER_FIJO) ?
      'fixed-drawer' :
      this.props.tipoDrawer.includes(DRAWER_COLAPSADO) ?
        'collapsible-drawer' :
        'mini-drawer';

    // set default height and overflow for iOS mobile Safari 10+ support.
    if (esIOS && esMovil) {
      document.body.classList.add('ios-mobile-view-height');
    } else if (document.body.classList.contains('ios-mobile-view-height')) {
      document.body.classList.remove('ios-mobile-view-height');
    }

    return (
      <div className="app-main">
        <LayoutBase
          match={this.props.match}
          location={this.props.location}
          unidades={this.props.unidades}
          estiloNavegacion={this.props.estiloNavegacion}
          posicionNavegacionHorizontal={this.props.posicionNavegacionHorizontal}
          estiloDrawer={estiloDrawer}
        />

        <ModalContainer />
      </div>
    );
  }
}

LayoutBaseContainer.propTypes = {
  colorTema: PropTypes.string
};

const mapStateToProps = ({ configs }) => {
  const { estiloNavegacion, tipoDrawer, posicionNavegacionHorizontal, colorTema, unidades } = configs;

  return { estiloNavegacion, tipoDrawer, posicionNavegacionHorizontal, colorTema, unidades };
};

export default withRouter(connect(mapStateToProps)(LayoutBaseContainer));
