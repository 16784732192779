import { put, call, all, fork, takeEvery } from 'redux-saga/effects';

import { sprintf } from 'sprintf-js';

import {
  OBTENER_CLIENTE,
  GUARDAR_CLIENTE,
  ELIMINAR_CLIENTE,
  OBTENER_CIUDADES
} from '../../constants/ActionTypes/Clientes';
import {
  CLIENTES_URL,
  CLIENTE_URL,
  CIUDADES_URL
} from '../../constants/Urls/Clientes';
import {
  obtenerClienteExitoso,
  obtenerClienteError,
  obtenerCiudadesExitoso,
  obtenerCiudadesError,
  guardarClienteExitoso,
  guardarClienteError,
  eliminarClienteExitoso,
  eliminarClienteError
} from '../../actions/Clientes/Detalle';
import { httpFetch } from '../../utils/Http/Fetch';


const obtenerClienteApi = async (uuid) => {
  const url = sprintf(CLIENTE_URL, { uuid });

  return httpFetch(url, 'GET')
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};

const guardarClienteApi = async (datos) => {
  const verbo_http = datos.uuid ? 'PUT' : 'POST';
  let url = CLIENTES_URL;

  if (datos.uuid) {
    url = sprintf(CLIENTE_URL, {
      uuid: datos.uuid
    });
  }

  return httpFetch(url, verbo_http, datos)
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};

const eliminarClienteApi = async (uuid) => {
  const url = sprintf(CLIENTE_URL, {uuid});

  return httpFetch(url, 'DELETE')
    .catch((error) => {
      throw error;
    });
};

const obtenerCiudadesApi = async (filtros) => {
  let esc = encodeURIComponent;
  let params = Object.keys(filtros).map(filtro => esc(filtro) + '=' + esc(filtros[filtro])).join('&');

  return httpFetch(`${CIUDADES_URL}?${params}`, 'GET')
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};


function* obtenerCliente(action) {
  try {
    const evento = yield call(obtenerClienteApi, action.payload);
    yield put(obtenerClienteExitoso(evento));
  } catch (error) {
    yield put(obtenerClienteError(error.message));
  }
}

function* guardarCliente(action) {
  try {
    const cliente = yield call(guardarClienteApi, action.payload);
    const accion = action.payload.uuid ? 'actualizado' : 'creado';
    const mensaje = `Cliente ${accion} correctamente.`;

    yield put(guardarClienteExitoso({cliente, mensaje}));
  } catch (error) {
    yield put(guardarClienteError(error.message));
  }
}

function* eliminarCliente(action) {
  try {
    yield call(eliminarClienteApi, action.payload);
    const mensaje = 'Cliente eliminado correctamente.';

    yield put(eliminarClienteExitoso({ mensaje }));
  } catch (error) {
    yield put(eliminarClienteError(error.message));
  }
}

function* obtenerCiudades(action) {
  try {
    const { filtros } = action.payload;
    const ciudades = yield call(obtenerCiudadesApi, filtros);
    let ciudades_opciones = ciudades.map((ciudad) => {
      return {value: ciudad.uuid, label: ciudad.display_name, ...ciudad};
    });

    yield put(obtenerCiudadesExitoso(ciudades_opciones));
  } catch (error) {
    yield put(obtenerCiudadesError(error.message));
  }
}

export function* obtenerClienteWatcher() {
  yield takeEvery (OBTENER_CLIENTE, obtenerCliente);
}

export function* guardarClienteWatcher() {
  yield takeEvery (GUARDAR_CLIENTE, guardarCliente);
}

export function* eliminarClienteWatcher() {
  yield takeEvery (ELIMINAR_CLIENTE, eliminarCliente);
}

export function* obtenerCiudadesWatcher() {
  yield takeEvery (OBTENER_CIUDADES, obtenerCiudades);
}

export default function* rootSaga() {
  yield all ([
    fork (guardarClienteWatcher),
    fork (obtenerClienteWatcher),
    fork (obtenerCiudadesWatcher),
    fork (eliminarClienteWatcher)
  ]);
}
