import {
  OBTENER_MENSAJES_EXITOSO,
  OBTENER_MENSAJES_ERROR,
  CARGANDO_MENSAJES
} from '../../constants/ActionTypes/Mensajes';


const mensajesInicial = {
  mensajes: [],
  cantidad: 0,
  paginas: 1,
  error: false,
  cargando: false,
  mensaje: null
};

const listarMensajes = (state=mensajesInicial, action) => {
  switch (action.type) {

  case OBTENER_MENSAJES_EXITOSO: {
    return {
      ...mensajesInicial,
      ...action.payload
    };
  }

  case OBTENER_MENSAJES_ERROR: {
    return {
      ...mensajesInicial,
      error: true,
      mensaje: action.payload
    };
  }

  case CARGANDO_MENSAJES: {
    return {
      ...mensajesInicial,
      cargando: action.payload
    };
  }

  default:
    return state;
  }
};


export default listarMensajes;
