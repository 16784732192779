import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ScreensMensajesListar from '../../screens/Mensajes/MensajesListar';
import ScreensMensajesDetalle from '../../screens/Mensajes/MensajesDetalle';
import ScreensMensajesEnviar from '../../screens/Mensajes/MensajesEnviar';


const Mensajes = (props) => (
  <div className="app-wrapper">
    <Switch>
      <Route exact path={`${props.match.url}/`} component={ScreensMensajesListar}/>
      <Route path={`${props.match.url}/detalle/:uuid_mensaje`} component={ScreensMensajesDetalle} />
      <Route path={`${props.match.url}/detalle`} component={ScreensMensajesDetalle}/>
      <Route path={`${props.match.url}/enviar`} component={ScreensMensajesEnviar}/>
    </Switch>
  </div>
);


export default Mensajes;
