import * as Yup from 'yup';

import {
  CAMPO_REQUERIDO,
  CAMPO_NUMERO_MINIMO,
  CAMPO_URL
} from '../../../constants/Forms/Validacion';


export const archivoSchema = Yup.object().shape({
  nombre: Yup.string()
    .required(CAMPO_REQUERIDO),

  tiempo_validez: Yup.number()
    .min(1, CAMPO_NUMERO_MINIMO)
    .required(CAMPO_REQUERIDO),

  url_destino: Yup.string()
    .url(CAMPO_URL)
    .nullable()
});
