export const ESTADO_CAMPANIA_ENVIADO = 'EN';
export const ESTADO_CAMPANIA_PENDIENTE = 'PE';
export const ESTADO_CAMPANIA_RECHAZADO = 'RE';
export const ESTADO_CAMPANIA_EXPIRADO = 'EX';
export const ESTADO_CAMPANIA_CANCELADO = 'CA';

export const ESTADO_CAMPANIA_ENVIADO_LABEL = 'Enviado';
export const ESTADO_CAMPANIA_PENDIENTE_LABEL = 'Pendiente';
export const ESTADO_CAMPANIA_RECHAZADO_LABEL = 'Rechazado';
export const ESTADO_CAMPANIA_EXPIRADO_LABEL = 'Expirado';
export const ESTADO_CAMPANIA_CANCELADO_LABEL = 'Cancelado';

export const ESTADOS_CAMPANIA = [
  {label: ESTADO_CAMPANIA_ENVIADO_LABEL, value: ESTADO_CAMPANIA_ENVIADO, color: '#4caf50'},
  {label: ESTADO_CAMPANIA_PENDIENTE_LABEL, value: ESTADO_CAMPANIA_PENDIENTE, color: '#00bcd4'},
  {label: ESTADO_CAMPANIA_RECHAZADO_LABEL, value: ESTADO_CAMPANIA_RECHAZADO, color: '#f44336'},
  {label: ESTADO_CAMPANIA_EXPIRADO_LABEL, value: ESTADO_CAMPANIA_EXPIRADO, color: '#ff9800'},
  {label: ESTADO_CAMPANIA_CANCELADO_LABEL, value: ESTADO_CAMPANIA_CANCELADO, color: '#343a40'}
];
