import {
  OBTENER_MENSAJE_EXITOSO,
  OBTENER_MENSAJE_ERROR,
  GUARDAR_MENSAJE_EXITOSO,
  GUARDAR_MENSAJE_ERROR,
  GUARDANDO_MENSAJE,
  ELIMINAR_MENSAJE_EXITOSO,
  ELIMINAR_MENSAJE_ERROR,
  OBTENER_LISTAS_DESTINATARIOS_EXITOSO,
  OBTENER_LISTAS_DESTINATARIOS_ERROR,
  CARGANDO_LISTAS_DESTINATARIOS,
  OBTENER_GALERIA_EXITOSO,
  OBTENER_GALERIA_ERROR,
  CARGANDO_GALERIA,
  OBTENER_ENCUESTAS_EXITOSO,
  OBTENER_ENCUESTAS_ERROR,
  CARGANDO_ENCUESTAS
} from '../../constants/ActionTypes/Mensajes';


const mensajesInicial = {
  mensaje: {},
  listas: [],
  galeria: [],
  encuestas: [],
  error: false,
  mensaje_: null,
  guardando: false,
  editando: false,
  eliminando: false,
  guardado_exitoso: false,
  cargando_listas: false,
  cargando_galeria: false,
  cargando_encuestas: false
};

const detalleMensajes = (state=mensajesInicial, action) => {
  switch (action.type) {

  case OBTENER_MENSAJE_EXITOSO: {
    return {
      ...mensajesInicial,
      listas: state.listas,
      galeria: state.galeria,
      encuestas: state.encuestas,
      mensaje: action.payload,
      editando: true
    };
  }

  case OBTENER_MENSAJE_ERROR: {
    return {
      ...mensajesInicial,
      error: true,
      mensaje_: action.payload,
      editando: true
    };
  }

  case GUARDAR_MENSAJE_EXITOSO: {
    return {
      ...mensajesInicial,
      mensaje: action.payload.mensaje,
      mensaje_: action.payload.mensaje_,
      guardado_exitoso: true
    };
  }

  case GUARDAR_MENSAJE_ERROR: {
    return {
      ...mensajesInicial,
      listas: state.listas,
      galeria: state.galeria,
      encuestas: state.encuestas,
      error: true,
      mensaje_: action.payload
    };
  }

  case GUARDANDO_MENSAJE: {
    return {
      ...mensajesInicial,
      listas: state.listas,
      galeria: state.galeria,
      encuestas: state.encuestas,
      guardando: action.payload
    };
  }

  case ELIMINAR_MENSAJE_EXITOSO: {
    return {
      ...mensajesInicial,
      mensaje_: action.payload.mensaje,
      editando: true,
      eliminando: true
    };
  }

  case ELIMINAR_MENSAJE_ERROR: {
    return {
      ...mensajesInicial,
      listas: state.listas,
      galeria: state.galeria,
      encuestas: state.encuestas,
      error: true,
      mensaje_: action.payload
    };
  }

  case OBTENER_LISTAS_DESTINATARIOS_EXITOSO: {
    return {
      ...mensajesInicial,
      mensaje: state.mensaje,
      galeria: state.galeria,
      encuestas: state.encuestas,
      listas: action.payload,
    };
  }

  case OBTENER_LISTAS_DESTINATARIOS_ERROR: {
    return {
      ...mensajesInicial,
      error: true,
      mensaje: state.mensaje,
      galeria: state.galeria,
      encuestas: state.encuestas,
      mensaje_: action.payload,
    };
  }

  case CARGANDO_LISTAS_DESTINATARIOS: {
    return {
      ...mensajesInicial,
      mensaje: state.mensaje,
      listas: state.listas,
      galeria: state.galeria,
      encuestas: state.encuestas,
      cargando_listas: action.payload
    };
  }

  case OBTENER_GALERIA_EXITOSO: {
    return {
      ...mensajesInicial,
      mensaje: state.mensaje,
      listas: state.listas,
      encuestas: state.encuestas,
      galeria: action.payload,
    };
  }

  case OBTENER_GALERIA_ERROR: {
    return {
      ...mensajesInicial,
      error: true,
      listas: state.listas,
      encuestas: state.encuestas,
      mensaje: state.mensaje,
      mensaje_: action.payload,
    };
  }

  case CARGANDO_GALERIA: {
    return {
      ...mensajesInicial,
      mensaje: state.mensaje,
      listas: state.listas,
      galeria: state.galeria,
      encuestas: state.encuestas,
      cargando_galeria: action.payload
    };
  }

  case OBTENER_ENCUESTAS_EXITOSO: {
    return {
      ...mensajesInicial,
      mensaje: state.mensaje,
      listas: state.listas,
      galeria: state.galeria,
      encuestas: action.payload,
    };
  }

  case OBTENER_ENCUESTAS_ERROR: {
    return {
      ...mensajesInicial,
      error: true,
      listas: state.listas,
      galeria: state.galeria,
      mensaje: state.mensaje,
      mensaje_: action.payload,
    };
  }

  case CARGANDO_ENCUESTAS: {
    return {
      ...mensajesInicial,
      mensaje: state.mensaje,
      listas: state.listas,
      galeria: state.galeria,
      encuestas: state.encuestas,
      cargando_encuestas: action.payload
    };
  }

  default:
    return state;
  }
};


export default detalleMensajes;
