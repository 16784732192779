import {
  OBTENER_CAMPANIA_EXITOSO,
  OBTENER_CAMPANIA_ERROR,
  GUARDAR_CAMPANIA_EXITOSO,
  GUARDAR_CAMPANIA_ERROR,
  GUARDANDO_CAMPANIA,
  ELIMINAR_CAMPANIA_EXITOSO,
  ELIMINAR_CAMPANIA_ERROR,
  OBTENER_LISTAS_DESTINATARIOS_EXITOSO,
  OBTENER_LISTAS_DESTINATARIOS_ERROR,
  CARGANDO_LISTAS_DESTINATARIOS,
  OBTENER_MENSAJES_EXITOSO,
  OBTENER_MENSAJES_ERROR,
  CARGANDO_MENSAJES
} from '../../constants/ActionTypes/Campanias';


const campaniasInicial = {
  campania: {},
  listas: [],
  mensajes: [],
  error: false,
  mensaje: null,
  guardando: false,
  editando: false,
  eliminando: false,
  guardado_exitoso: false,
  cargando_listas: false,
  cargando_mensajes: false
};

const detalleCampanias = (state=campaniasInicial, action) => {
  switch (action.type) {

  case OBTENER_CAMPANIA_EXITOSO: {
    return {
      ...campaniasInicial,
      campania: action.payload,
      listas: state.listas,
      mensajes: state.mensajes,
      editando: true
    };
  }

  case OBTENER_CAMPANIA_ERROR: {
    return {
      ...campaniasInicial,
      error: true,
      mensaje: action.payload,
      editando: true
    };
  }

  case GUARDAR_CAMPANIA_EXITOSO: {
    return {
      ...campaniasInicial,
      campania: action.payload.campania,
      mensaje: action.payload.mensaje,
      guardado_exitoso: true
    };
  }

  case GUARDAR_CAMPANIA_ERROR: {
    return {
      ...campaniasInicial,
      listas: state.listas,
      mensajes: state.mensajes,
      error: true,
      mensaje: action.payload
    };
  }

  case GUARDANDO_CAMPANIA: {
    return {
      ...campaniasInicial,
      listas: state.listas,
      mensajes: state.mensajes,
      guardando: action.payload
    };
  }

  case ELIMINAR_CAMPANIA_EXITOSO: {
    return {
      ...campaniasInicial,
      mensaje: action.payload.mensaje,
      editando: true,
      eliminando: true
    };
  }

  case ELIMINAR_CAMPANIA_ERROR: {
    return {
      ...campaniasInicial,
      listas: state.listas,
      mensajes: state.mensajes,
      error: true,
      mensaje: action.payload
    };
  }

  case OBTENER_LISTAS_DESTINATARIOS_EXITOSO: {
    return {
      ...campaniasInicial,
      campania: state.campania,
      listas: action.payload,
      mensajes: state.mensajes
    };
  }

  case OBTENER_LISTAS_DESTINATARIOS_ERROR: {
    return {
      ...campaniasInicial,
      error: true,
      campania: state.campania,
      mensajes: state.mensajes,
      mensaje: action.payload,
    };
  }

  case CARGANDO_LISTAS_DESTINATARIOS: {
    return {
      ...campaniasInicial,
      campania: state.campania,
      listas: state.listas,
      mensajes: state.mensajes,
      cargando_listas: action.payload
    };
  }

  case OBTENER_MENSAJES_EXITOSO: {
    return {
      ...campaniasInicial,
      campania: state.campania,
      listas: state.listas,
      mensajes: action.payload
    };
  }

  case OBTENER_MENSAJES_ERROR: {
    return {
      ...campaniasInicial,
      error: true,
      campania: state.campania,
      listas: state.listas,
      mensaje: action.payload,
    };
  }

  case CARGANDO_MENSAJES: {
    return {
      ...campaniasInicial,
      campania: state.campania,
      listas: state.listas,
      mensajes: state.mensajes,
      cargando_mensajes: action.payload
    };
  }

  default:
    return state;
  }
};


export default detalleCampanias;
