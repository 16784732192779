import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import _ from 'lodash';
import uuid from 'uuid';
import { toast } from 'react-toastify';

import {
  obtenerListaDestinatarios,
  guardarListaDestinatarios,
  guardandoListaDestinatarios,
  eliminarListaDestinatarios,
  errorListaDestinatarios,
  obtenerDestinatarios,
  cargandoDestinatarios,
} from '../../../actions/ListasDestinatarios/Detalle';
import { mostrarModal, ocultarModal } from '../../../actions/Modal';
import { setTituloPagina } from '../../../actions/Configuracion';
import { ORIGEN_DESTINATARIOS, ORIGEN_DESTINATARIOS_ARCHIVO } from '../../../constants/ListasDestinatarios/Origenes';
import ListaDestinatariosDetalle from './Detalle';


class ListaDestinatariosDetalleContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      destinatarios_todos: false,
      destinatarios_seleccionados: [],
      origen_archivo: {
        pasos: {
          activo: '1'
        }
      },
      lista: {
        nombre: '',
        origen: ORIGEN_DESTINATARIOS[0],
        archivo: null,
        destinatarios: []
      },
      paginacion: {
        pagina: 0,
        tamano_pagina: 20
      },
      filtrado: []
    };
  }

  cargarListaDestinatarios = (lista) => {
    lista.origen = ORIGEN_DESTINATARIOS.find(origen => origen.value === lista.origen);

    this.setState({
      lista
    });
  };

  toggleDestinatario = (destinatario) => {
    let destinatarios_seleccionados = this.state.destinatarios_seleccionados.slice(0);
    let destinatario_actual = destinatarios_seleccionados.indexOf(destinatario);

    if (destinatario_actual === -1) {
      destinatarios_seleccionados.push(destinatario);
    } else {
      destinatarios_seleccionados.splice(destinatario_actual, 1);
    }

    this.setState({
      destinatarios_seleccionados
    });
  };

  toggleDestinatarioTodos = () => {
    let destinatarios_seleccionados = [];

    if (!this.state.destinatarios_todos) {
      destinatarios_seleccionados = this.props.destinatarios.map((destinatario) => destinatario.uuid);
    }

    this.setState({
      destinatarios_todos: !this.state.destinatarios_todos,
      destinatarios_seleccionados
    });
  };

  handleObtenerDestinatarios = () => {
    const { pagina, tamano_pagina } = this.state.paginacion;

    let filtros = {pagina: pagina + 1, tamano_pagina};
    if (typeof this.state.filtrado !== 'undefined') {
      this.state.filtrado.map((filtro) => filtros[filtro.id] = filtro.value);
    }

    this.props.actions.cargandoDestinatarios(true);
    this.props.actions.obtenerDestinatarios({ filtros });
  };

  handleCambioPagina = (pagina) => {
    this.setState({
      paginacion: {
        ...this.state.paginacion,
        pagina
      }
    });
  };

  handleCambioTamanoPagina = (tamano_pagina, pagina) => {
    this.setState({
      paginacion: {
        tamano_pagina,
        pagina
      }
    });
  };

  handleCambioFiltro = (filtrado) => {
    this.setState({
      paginacion: {
        ...this.state.paginacion,
        pagina: 0
      },
      filtrado
    });
  };

  handleSiguientePaso = ({ step, push }) => {
    switch (step.id) {
    case 'formato':
      push('archivo');
      this.setState({
        ...this.state,
        origen_archivo: {
          pasos: {
            activo: '2'
          }
        }
      });
      break;

    default:
      push();
      break;
    }
  };

  handleAnteriorPaso = ({ step, push }) => {
    switch (step.id) {
    case 'archivo':
      push('formato');
      this.setState({
        ...this.state,
        origen_archivo: {
          pasos: {
            activo: '1'
          }
        }
      });
      break;

    default:
      push();
      break;
    }
  };

  handleActualizacionListaDestinatarios = () => {
    toast.success(this.props.mensaje);
    this.props.history.push('/listas');
  };

  handleEliminar = (lista_uuid) => {
    this.props.actions.mostrarModal({
      uuid: uuid.v4(),
      atributos: {
        titulo: 'Eliminar lista de destinatarios',
        mensaje: '¿Realmente desea eliminar esta lista de destinatarios?',
        colorBotonAceptar: 'danger',
        textoBotonAceptar: 'Eliminar lista',
        nota: 'Esta acción no se puede deshacer',
        colorNota: 'danger',
        tamano: 'sm',
        aceptar: () => this.props.actions.eliminarListaDestinatarios(lista_uuid)
      }
    });
  };

  handleSubmit = (values) => {
    let error = false;
    let datos = {};

    if (values.uuid) {
      datos = {uuid: values.uuid, nombre: values.nombre};
    } else {
      datos = _.cloneDeep(values);
      datos.origen = values.origen.value;

      if (datos.origen === ORIGEN_DESTINATARIOS_ARCHIVO) {
        if (!datos.archivo) {
          error = true;
          toast.error('Debe cargar un archivo.');
        }
      } else {
        datos.destinatarios = this.state.destinatarios_seleccionados;

        if (!datos.destinatarios.length) {
          error = true;
          toast.error('Debe seleccionar al menos un destinatario.');
        }
      }
    }

    if (!error) {
      this.props.actions.guardandoListaDestinatarios(true);
      this.props.actions.guardarListaDestinatarios(datos);
    }
  };

  componentDidMount() {
    this.props.actions.setTituloPagina({titulo: 'Lista de destinatarios', icono: 'list-ol'});

    if (this.props.match.params.uuid_lista) {
      this.props.actions.obtenerListaDestinatarios(this.props.match.params.uuid_lista);
      this.handleCambioFiltro([{id: 'lista__uuid', value: this.props.match.params.uuid_lista}]);
    } else {
      this.handleObtenerDestinatarios();
    }
  }

  componentDidUpdate(props, state) {
    // Verificar si existió algún error
    if (this.props.error) {
      if (this.props.mensaje) {
        toast.error(this.props.mensaje);
      }

      this.props.actions.errorListaDestinatarios(false);

    // Verificar si es una edición de listas
    } else if (this.props.editando) {
      if (this.props.eliminando) {
        this.props.actions.ocultarModal();
        this.handleActualizacionListaDestinatarios();
      } else if (props.lista !== this.props.lista) {
        this.cargarListaDestinatarios(this.props.lista);
      } else if (this.props.guardado_exitoso) {
        this.handleActualizacionListaDestinatarios();
      }

    // Verificar si es creación de un nuevo lista
    } else if (this.props.guardado_exitoso) {
      this.handleActualizacionListaDestinatarios();
    }

    if ((state.filtrado !== this.state.filtrado) || (state.paginacion !== this.state.paginacion)) {
      this.handleObtenerDestinatarios();
    }
  }

  render() {
    return (
      <ListaDestinatariosDetalle
        handleSubmit={this.handleSubmit}
        handleEliminar={this.handleEliminar}
        toggleDestinatario={this.toggleDestinatario}
        toggleDestinatarioTodos={this.toggleDestinatarioTodos}
        obtenerDestinatarios={this.handleObtenerDestinatarios}
        fuenteDestinatarios={ORIGEN_DESTINATARIOS}
        valoresIniciales={this.state.lista}
        destinatarios={this.props.destinatarios}
        destinatariosSeleccionados={this.state.destinatarios_seleccionados}
        destinatarios_todos={this.state.destinatarios_todos}
        cargandoDestinatarios={this.props.cargando_destinatarios}
        origenArchivo={this.state.origen_archivo}
        handleSiguientePaso={this.handleSiguientePaso}
        handleAnteriorPaso={this.handleAnteriorPaso}
        guardando={this.props.guardando}
        paginas={this.props.paginas}
        paginacion={this.state.paginacion}
        filtrado={this.state.filtrado}
        cambioPagina={this.handleCambioPagina}
        cambioTamanoPagina={this.handleCambioTamanoPagina}
        cambioFiltro={this.handleCambioFiltro}
      />
    );
  }
}


const mapStateToProps = ({ detalleListasDestinatarios }) => {
  return { ...detalleListasDestinatarios };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      setTituloPagina,
      mostrarModal,
      ocultarModal,
      obtenerListaDestinatarios,
      guardarListaDestinatarios,
      guardandoListaDestinatarios,
      eliminarListaDestinatarios,
      errorListaDestinatarios,
      obtenerDestinatarios,
      cargandoDestinatarios
    }, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListaDestinatariosDetalleContainer));
