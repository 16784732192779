import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  DRAWER_COLAPSADO,
  DRAWER_FIJO,
  NAVEGACION_HORIZONTAL
} from '../../../constants/ActionTypes/Configuracion';
import { toggleNavegacionColapsada, actualizarAnchoVentana } from '../../../actions/Configuracion';
import BarraLateral from './BarraLateral';


class BarraLateralContainer extends Component {

  constructor(props) {
    super(props);

    this.onToggleNavegacionColapsada = this.onToggleNavegacionColapsada.bind(this);
  }

  onToggleNavegacionColapsada = () => {
    const val = !this.props.navegacionColapsada;
    this.props.actions.toggleNavegacionColapsada(val);
  };

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.props.actions.actualizarAnchoVentana(window.innerWidth);
    });
  }

  render() {
    let anclado = true;
    let estiloDrawer = this.props.tipoDrawer.includes(DRAWER_FIJO) ?
      'd-xl-flex' : this.props.tipoDrawer.includes(DRAWER_COLAPSADO) ?
        '' : 'd-flex';

    if (this.props.tipoDrawer.includes(DRAWER_COLAPSADO) ||
      (this.props.tipoDrawer.includes(DRAWER_FIJO) &&
        this.props.ancho < 1200)) {
      anclado = false;
    }

    if (this.props.estiloNavegacion === NAVEGACION_HORIZONTAL) {
      estiloDrawer = '';
      anclado = false;
    }

    return (
      <BarraLateral
        anclado={anclado}
        estiloDrawer={estiloDrawer}
        navegacionColapsada={this.props.navegacionColapsada}
        tipoDrawer={this.props.tipoDrawer}
        onToggleNavegacionColapsada={this.onToggleNavegacionColapsada}
      />
    );
  }
}


const mapStateToProps = ({ configs }) => {
  const { navegacionColapsada, tipoDrawer, ancho, estiloNavegacion } = configs;
  return { navegacionColapsada, tipoDrawer, ancho, estiloNavegacion };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({toggleNavegacionColapsada, actualizarAnchoVentana}, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BarraLateralContainer));
