// Listar
export const OBTENER_ENCUESTAS = 'obtener_encuestas';
export const OBTENER_ENCUESTAS_EXITOSO = 'obtener_encuestas_exitoso';
export const OBTENER_ENCUESTAS_ERROR = 'obtener_encuestas_error';
export const CARGANDO_ENCUESTAS = 'cargando_encuestas';

// Listar Enviadas
export const OBTENER_ENCUESTAS_ENVIADAS = 'obtener_encuestas_enviadas';
export const OBTENER_ENCUESTAS_ENVIADAS_EXITOSO = 'obtener_encuestas_enviadas_exitoso';
export const OBTENER_ENCUESTAS_ENVIADAS_ERROR = 'obtener_encuestas_enviadas_error';
export const CARGANDO_ENCUESTAS_ENVIADAS = 'cargando_encuestas_enviadas';

// Detalle
export const OBTENER_ENCUESTA = 'obtener_encuesta';
export const OBTENER_ENCUESTA_EXITOSO = 'obtener_encuesta_exitoso';
export const OBTENER_ENCUESTA_ERROR = 'obtener_encuesta_error';
export const GUARDAR_ENCUESTA = 'guardar_encuesta';
export const GUARDAR_ENCUESTA_EXITOSO = 'guardar_encuesta_exitoso';
export const GUARDAR_ENCUESTA_ERROR = 'guardar_encuesta_error';
export const GUARDANDO_ENCUESTA = 'guardando_encuesta';
export const EDITANDO_ENCUESTA = 'editando_encuesta';
export const ELIMINAR_ENCUESTA = 'eliminar_encuesta';
export const ELIMINAR_ENCUESTA_EXITOSO = 'eliminar_encuesta_exitoso';
export const ELIMINAR_ENCUESTA_ERROR = 'eliminar_encuesta_error';

// Listar preguntas
export const OBTENER_ENCUESTA_PREGUNTAS = 'obtener_encuesta_preguntas';
export const OBTENER_ENCUESTA_PREGUNTAS_EXITOSO = 'obtener_encuesta_preguntas_exitoso';
export const OBTENER_ENCUESTA_PREGUNTAS_ERROR = 'obtener_encuesta_preguntas_error';
export const CARGANDO_ENCUESTA_PREGUNTAS = 'cargando_encuesta_preguntas';

// Detalle preguntas
export const OBTENER_ENCUESTA_PREGUNTA = 'obtener_encuesta_pregunta';
export const OBTENER_ENCUESTA_PREGUNTA_EXITOSO = 'obtener_encuesta_pregunta_exitoso';
export const OBTENER_ENCUESTA_PREGUNTA_ERROR = 'obtener_encuesta_pregunta_error';
export const GUARDAR_ENCUESTA_PREGUNTA = 'guardar_encuesta_pregunta';
export const GUARDAR_ENCUESTA_PREGUNTA_EXITOSO = 'guardar_encuesta_pregunta_exitoso';
export const GUARDAR_ENCUESTA_PREGUNTA_ERROR = 'guardar_encuesta_pregunta_error';
export const GUARDANDO_ENCUESTA_PREGUNTA = 'guardando_encuesta_pregunta';
export const ELIMINAR_ENCUESTA_PREGUNTA = 'eliminar_encuesta_pregunta';
export const ELIMINAR_ENCUESTA_PREGUNTA_EXITOSO = 'eliminar_encuesta_pregunta_exitoso';
export const ELIMINAR_ENCUESTA_PREGUNTA_ERROR = 'eliminar_encuesta_pregunta_error';
export const ELIMINANDO_ENCUESTA_PREGUNTA = 'eliminando_encuesta_pregunta';
export const EDITANDO_ENCUESTA_PREGUNTA = 'editando_encuesta_pregunta';
