
// Listar
export const OBTENER_LISTAS_DESTINATARIOS = 'obtener_listas_destinatarios';
export const OBTENER_LISTAS_DESTINATARIOS_EXITOSO = 'obtener_listas_destinatarios_exitoso';
export const OBTENER_LISTAS_DESTINATARIOS_ERROR = 'obtener_listas_destinatarios_error';
export const CARGANDO_LISTAS_DESTINATARIOS = 'cargando_listas_destinatarios';

// Detalle
export const OBTENER_LISTA_DESTINATARIOS = 'obtener_lista_destinatarios';
export const OBTENER_LISTA_DESTINATARIOS_EXITOSO = 'obtener_lista_destinatarios_exitoso';
export const OBTENER_LISTA_DESTINATARIOS_ERROR = 'obtener_lista_destinatarios_error';
export const GUARDAR_LISTA_DESTINATARIOS = 'guardar_lista_destinatarios';
export const GUARDAR_LISTA_DESTINATARIOS_EXITOSO = 'guardar_lista_destinatarios_exitoso';
export const GUARDAR_LISTA_DESTINATARIOS_ERROR = 'guardar_lista_destinatarios_error';
export const GUARDANDO_LISTA_DESTINATARIOS = 'guardando_lista_destinatarios';
export const ELIMINAR_LISTA_DESTINATARIOS = 'eliminar_lista_destinatarios';
export const ELIMINAR_LISTA_DESTINATARIOS_EXITOSO = 'eliminar_lista_destinatarios_exitoso';
export const ELIMINAR_LISTA_DESTINATARIOS_ERROR = 'eliminar_lista_destinatarios_error';
export const ERROR_LISTA_DESTINATARIOS = 'error_lista_destinatarios';
export const OBTENER_DESTINATARIOS = 'lista_destinatarios_obtener_destinatarios';
export const OBTENER_DESTINATARIOS_EXITOSO = 'lista_destinatarios_obtener_destinatarios_exitoso';
export const OBTENER_DESTINATARIOS_ERROR = 'lista_destinatarios_obtener_destinatarios_error';
export const CARGANDO_DESTINATARIOS = 'lista_destinatarios_cargando_destinatarios';
