import { put, call, all, fork, takeEvery } from 'redux-saga/effects';

import { INICIO_SESION_USUARIO, CERRAR_SESION_USUARIO } from '../constants/ActionTypes/Autenticacion';
import { USUARIO_SESION } from '../constants/Configuracion';
import {
  inicioSesionUsuarioExitoso,
  cerrarSesionUsuarioExitoso,
  inicioSesionUsuarioError
} from '../actions/Autenticacion';
import {
  setUnidades
} from '../actions/Configuracion';
import { httpFetch } from '../utils/Http/Fetch';
import { LOGIN_URL, PERFIL_URL } from '../constants/Urls/Autenticacion';


function iniciarSesionApi(email, password) {
  return httpFetch(`${LOGIN_URL}`, 'POST', { email, password })
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
}

function obtenerUsuarioApi() {
  return httpFetch(`${PERFIL_URL}`, 'GET')
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
}

// function cerrarSesionApi() {
//
// }


function* iniciarSesion(action) {
  const { email, clave } = action.payload;

  try {
    const token = yield call (iniciarSesionApi, email, clave);
    localStorage.setItem(USUARIO_SESION,  JSON.stringify(token));

    const usuario = yield call (obtenerUsuarioApi);
    const usuario_token = { ...usuario, ...token };
    const mensaje = 'Sesión iniciada correctamente. Bienvenido!';

    if (typeof usuario.saldo !== 'undefined') {
      yield put(setUnidades(usuario.saldo));
    }

    localStorage.setItem(USUARIO_SESION,  JSON.stringify(usuario_token));

    yield put(inicioSesionUsuarioExitoso(usuario_token, mensaje));
  } catch (error) {
    yield put(inicioSesionUsuarioError(error.message));
  }
}

function* cerrarSesion(action) {
  try {
    // const signOutUser = yield call (signOutRequest);
    // if (signInUser.message) {
    //   yield put (showAuthMessage (signInUser.message));
    // } else {
    localStorage.removeItem(USUARIO_SESION);
    yield put (cerrarSesionUsuarioExitoso(null));
    // }

  } catch (error) {
    // yield put (showAuthMessage (error));
  }
}


export function* iniciarSesionWatcher() {
  yield takeEvery (INICIO_SESION_USUARIO, iniciarSesion);
}

export function* cerrarSesionWatcher() {
  yield takeEvery (CERRAR_SESION_USUARIO, cerrarSesion);
}

export default function* rootSaga() {
  yield all ([
    fork (iniciarSesionWatcher),
    fork (cerrarSesionWatcher)
  ]);
}
