import {
  OBTENER_RESPUESTAS_CLAVE_EXITOSO,
  OBTENER_RESPUESTAS_CLAVE_ERROR,
  CARGANDO_RESPUESTAS_CLAVE,
  RESPUESTAS_OBTENER_CLAVES_EXITOSO,
  RESPUESTAS_OBTENER_CLAVES_ERROR,
  EXPORTAR_RESPUESTAS_CLAVE_EXITOSO,
  EXPORTAR_RESPUESTAS_CLAVE_ERROR
} from '../../../constants/ActionTypes/Claves';


const respuestasClaveInicial = {
  archivo: null,
  respuestas: [],
  claves: [],
  cantidad: 0,
  paginas: 1,
  error: false,
  cargando: false,
  mensaje: null
};

const listarRespuestasClave = (state=respuestasClaveInicial, action) => {
  switch (action.type) {

  case OBTENER_RESPUESTAS_CLAVE_EXITOSO: {
    return {
      ...respuestasClaveInicial,
      claves: state.claves,
      ...action.payload
    };
  }

  case OBTENER_RESPUESTAS_CLAVE_ERROR: {
    return {
      ...respuestasClaveInicial,
      error: true,
      claves: state.claves,
      mensaje: action.payload
    };
  }

  case CARGANDO_RESPUESTAS_CLAVE: {
    return {
      ...respuestasClaveInicial,
      respuestas: state.respuestas,
      claves: state.claves,
      cargando: action.payload
    };
  }

  case RESPUESTAS_OBTENER_CLAVES_EXITOSO: {
    return {
      ...respuestasClaveInicial,
      respuestas: state.respuestas,
      claves: action.payload
    };
  }

  case RESPUESTAS_OBTENER_CLAVES_ERROR: {
    return {
      ...respuestasClaveInicial,
      error: true,
      respuestas: state.respuestas,
      mensaje: action.payload
    };
  }

  case EXPORTAR_RESPUESTAS_CLAVE_EXITOSO: {
    return {
      ...respuestasClaveInicial,
      respuestas: state.respuestas,
      claves: state.claves,
      cantidad: state.cantidad,
      paginas: state.paginas,
      ...action.payload
    };
  }

  case EXPORTAR_RESPUESTAS_CLAVE_ERROR: {
    return {
      ...respuestasClaveInicial,
      respuestas: state.respuestas,
      claves: state.claves,
      cantidad: state.cantidad,
      paginas: state.paginas,
      error: true,
      mensaje: action.payload
    };
  }

  default:
    return state;
  }
};


export default listarRespuestasClave;
