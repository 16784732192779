import React from 'react';
import Drawer from 'rmc-drawer';

import BarraLateralContenidoContainer from './Contenido/ContenidoContainer';
import BarraLateralLogo from './Logo/Logo';

const BarraLateral = (props) => (
  <Drawer
    docked={props.anclado}
    className={`app-sidebar ${props.estiloDrawer}`}
    style={{overflow: 'auto'}}
    touch={true}
    position='left'
    transitions={true}
    enableDragHandle={true}
    open={props.navegacionColapsada}
    onOpenChange={props.onToggleNavegacionColapsada}
    sidebar={
      <div className="side-nav">
        <BarraLateralLogo tipoDrawer={props.tipoDrawer} />
        <BarraLateralContenidoContainer />
      </div>
    }>
    <div />
  </Drawer>
);

export default BarraLateral;
