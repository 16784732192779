import React from 'react';
// import PropTypes from 'prop-types';

import { Field } from 'formik';
import {
  FormGroup,
  Label,
  Input,
  FormFeedback
} from 'reactstrap';


const UIFormsCampoFormInput = (props) => (
  <FormGroup className={props.groupClassName}>
    {
      props.label ?
        <Label for={props.id} className={props.labelClassName}>{props.label}</Label>
        :
        null
    }

    {
      props.group ?
        null
        :
        <span>
          <Field
            name={props.name}
            render={({ field }) => {
              return (
                <Input
                  {...field}
                  type={props.type}
                  id={props.id}
                  className={`form-control-lg ${props.className}`}
                  placeholder={props.placeholder}
                  autoComplete={props.autoComplete}
                  readOnly={props.readOnly}
                  onChange={props.onChange ? props.onChange : field.onChange}
                  invalid={props.error && props.touched}
                />
              )
            }}
          />

          <FormFeedback tooltip>
            {props.error}
          </FormFeedback>
        </span>
    }
  </FormGroup>
);


UIFormsCampoFormInput.defaultProps = {
  className: '',
  groupClassName: '',
  labelClassName: ''
};

export default UIFormsCampoFormInput;
