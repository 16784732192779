import {
  OBTENER_CLIENTES,
  OBTENER_CLIENTES_EXITOSO,
  OBTENER_CLIENTES_ERROR,
  CARGANDO_CLIENTES
} from '../../constants/ActionTypes/Clientes';


export const obtenerClientes = (params) => {
  return {
    type: OBTENER_CLIENTES,
    payload: params
  };
};

export const obtenerClientesExitoso = (clientes) => {
  return {
    type: OBTENER_CLIENTES_EXITOSO,
    payload: clientes
  };
};

export const obtenerClientesError = (mensaje) => {
  return {
    type: OBTENER_CLIENTES_ERROR,
    payload: mensaje
  };
};

export const cargandoClientes = (cargando) => {
  return {
    type: CARGANDO_CLIENTES,
    payload: cargando
  };
};
