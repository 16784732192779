import {
  OBTENER_PERFIL_EXITOSO,
  OBTENER_PERFIL_ERROR,
  GUARDAR_PERFIL_EXITOSO,
  GUARDAR_PERFIL_ERROR,
  GUARDANDO_PERFIL
} from '../../constants/ActionTypes/Seguridad';


const perfilInicial = {
  perfil: {},
  error: false,
  mensaje: null,
  guardando: false,
  editando: false,
  guardado_exitoso: false
};

const perfil = (state=perfilInicial, action) => {
  switch (action.type) {

  case OBTENER_PERFIL_EXITOSO: {
    return {
      ...perfilInicial,
      perfil: action.payload,
      editando: true
    };
  }

  case OBTENER_PERFIL_ERROR: {
    return {
      ...perfilInicial,
      error: true,
      mensaje: action.payload,
      editando: true
    };
  }

  case GUARDAR_PERFIL_EXITOSO: {
    return {
      ...perfilInicial,
      perfil: action.payload.perfil,
      mensaje: action.payload.mensaje,
      editando: true,
      guardado_exitoso: true
    };
  }

  case GUARDAR_PERFIL_ERROR: {
    return {
      ...perfilInicial,
      error: true,
      mensaje: action.payload
    };
  }

  case GUARDANDO_PERFIL: {
    return {
      ...perfilInicial,
      guardando: action.payload
    };
  }

  default:
    return state;
  }
};

export default perfil;
