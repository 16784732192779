import React from 'react';


import UIBreadcrumbContainer from '../../../components/UI/Breadcrumb/BreadcrumbContainer';
import ReportesEncuestasListarContainer from '../../../components/Reportes/Encuestas/Listar/ListarContainer';


const ScreensReportesEncuestas = () => (
  <div className="animated slideInUpTiny animation-duration-3">

    <UIBreadcrumbContainer></UIBreadcrumbContainer>

    <ReportesEncuestasListarContainer />
  </div>
);


export default ScreensReportesEncuestas;
