import { put, call, all, fork, takeEvery } from 'redux-saga/effects';

import { sprintf } from 'sprintf-js';

import { httpFetch } from '../../utils/Http/Fetch';
import { LISTAS_DESTINATARIO_URL, LISTAS_DESTINATARIOS_URL } from '../../constants/Urls/ListasDestinatarios';
import { DESTINATARIOS_URL, DESTINATARIOS_LISTAS_URL } from '../../constants/Urls/Destinatarios';
import {
  OBTENER_LISTA_DESTINATARIOS,
  GUARDAR_LISTA_DESTINATARIOS,
  ELIMINAR_LISTA_DESTINATARIOS,
  OBTENER_DESTINATARIOS
} from '../../constants/ActionTypes/ListasDestinatarios';
import {
  obtenerListaDestinatariosExitoso,
  obtenerListaDestinatariosError,
  guardarListaDestinatariosExitoso,
  guardarListaDestinatariosError,
  eliminarListaDestinatariosExitoso,
  eliminarListaDestinatariosError,
  obtenerDestinatariosExitoso,
  obtenerDestinatariosError
} from '../../actions/ListasDestinatarios/Detalle';
import { setUnidades } from '../../actions/Configuracion';


const obtenerListaDestinatariosApi = async (uuid) => {
  const url = sprintf(LISTAS_DESTINATARIO_URL, { uuid });

  return httpFetch(url, 'GET')
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};

const guardarListaDestinatariosApi = async (datos) => {
  const verbo_http = datos.uuid ? 'PUT' : 'POST';
  let url = LISTAS_DESTINATARIOS_URL;

  if (datos.uuid) {
    url = sprintf(LISTAS_DESTINATARIO_URL, {
      uuid: datos.uuid
    });
  }

  return httpFetch(url, verbo_http, datos)
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};

const eliminarListaDestinatariosApi = async (uuid) => {
  const url = sprintf(LISTAS_DESTINATARIO_URL, {uuid});

  return httpFetch(url, 'DELETE')
    .catch((error) => {
      throw error;
    });
};

const obtenerDestinatariosApi = async (filtros) => {
  let esc = encodeURIComponent;
  let params = Object.keys(filtros).map(filtro => esc(filtro) + '=' + esc(filtros[filtro])).join('&');
  let url = DESTINATARIOS_URL;

  if (filtros.lista__uuid) {
    url = DESTINATARIOS_LISTAS_URL;
  }

  return httpFetch(`${url}?${params}`, 'GET')
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};


function* obtenerListaDestinatarios(action) {
  try {
    const lista = yield call(obtenerListaDestinatariosApi, action.payload);

    if (typeof lista.saldo !== 'undefined') {
      yield put(setUnidades(lista.saldo));
    }

    yield put(obtenerListaDestinatariosExitoso(lista));
  } catch (error) {
    yield put(obtenerListaDestinatariosError(error.message));
  }
}

function* guardarListaDestinatarios(action) {
  try {
    const destinatario = yield call(guardarListaDestinatariosApi, action.payload);
    const accion = action.payload.uuid ? 'actualizada' : 'creada';
    const mensaje = `Lista de destinatarios ${accion} correctamente.`;

    if (typeof destinatario.saldo !== 'undefined') {
      yield put(setUnidades(destinatario.saldo));
    }

    yield put(guardarListaDestinatariosExitoso({destinatario, mensaje}));
  } catch (error) {
    yield put(guardarListaDestinatariosError(error.message));
  }
}

function* eliminarListaDestinatarios(action) {
  try {
    yield call(eliminarListaDestinatariosApi, action.payload);
    const mensaje = 'Lista de destinatarios eliminada correctamente.';

    yield put(eliminarListaDestinatariosExitoso({ mensaje }));
  } catch (error) {
    yield put(eliminarListaDestinatariosError(error.message));
  }
}

function* obtenerDestinatarios(action) {
  try {
    const { filtros } = action.payload;
    const destinatarios = yield call(obtenerDestinatariosApi, filtros);
    const paginas = Math.ceil(destinatarios.cantidad / filtros.tamano_pagina);

    yield put(obtenerDestinatariosExitoso({destinatarios: destinatarios.resultado, cantidad: destinatarios.cantidad, paginas}));
  } catch (error) {
    yield put(obtenerDestinatariosError(error.message));
  }
}


export function* obtenerListaDestinatariosWatcher() {
  yield takeEvery (OBTENER_LISTA_DESTINATARIOS, obtenerListaDestinatarios);
}

export function* guardarListaDestinatariosWatcher() {
  yield takeEvery (GUARDAR_LISTA_DESTINATARIOS, guardarListaDestinatarios);
}

export function* eliminarListaDestinatariosWatcher() {
  yield takeEvery (ELIMINAR_LISTA_DESTINATARIOS, eliminarListaDestinatarios);
}

export function* obtenerDestinatariosWatcher() {
  yield takeEvery (OBTENER_DESTINATARIOS, obtenerDestinatarios);
}

export default function* rootSaga() {
  yield all ([
    fork (guardarListaDestinatariosWatcher),
    fork (obtenerListaDestinatariosWatcher),
    fork (eliminarListaDestinatariosWatcher),
    fork (obtenerDestinatariosWatcher)
  ]);
}
