import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ScreensEnviarSaldo from '../../screens/EnviarSaldo/EnviarSaldo';


const Transacciones = (props) => (
  <div className="app-wrapper">
    <Switch>
      <Route exact path={`${props.match.url}/enviar_saldo`} component={ScreensEnviarSaldo}/>
    </Switch>
  </div>
);


export default Transacciones;
