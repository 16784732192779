import {
  OBTENER_CLAVE,
  OBTENER_CLAVE_EXITOSO,
  OBTENER_CLAVE_ERROR,
  GUARDAR_CLAVE,
  GUARDAR_CLAVE_EXITOSO,
  GUARDAR_CLAVE_ERROR,
  GUARDANDO_CLAVE,
  ELIMINAR_CLAVE,
  ELIMINAR_CLAVE_EXITOSO,
  ELIMINAR_CLAVE_ERROR,
  ERROR_CLAVE
} from '../../../constants/ActionTypes/Claves';


export const obtenerClave = (uuid) => {
  return {
    type: OBTENER_CLAVE,
    payload: uuid
  };
};

export const obtenerClaveExitoso = (mensaje) => {
  return {
    type: OBTENER_CLAVE_EXITOSO,
    payload: mensaje
  };
};

export const obtenerClaveError = (mensaje) => {
  return {
    type: OBTENER_CLAVE_ERROR,
    payload: mensaje
  };
};

export const guardarClave = (datos) => {
  return {
    type: GUARDAR_CLAVE,
    payload: datos
  };
};

export const guardarClaveExitoso = (mensaje) => {
  return {
    type: GUARDAR_CLAVE_EXITOSO,
    payload: mensaje
  };
};

export const guardarClaveError = (mensaje) => {
  return {
    type: GUARDAR_CLAVE_ERROR,
    payload: mensaje
  };
};

export const guardandoClave = (cargando) => {
  return {
    type: GUARDANDO_CLAVE,
    payload: cargando
  };
};

export const eliminarClave = (uuid) => {
  return {
    type: ELIMINAR_CLAVE,
    payload: uuid
  };
};

export const eliminarClaveExitoso = (mensaje) => {
  return {
    type: ELIMINAR_CLAVE_EXITOSO,
    payload: mensaje
  };
};

export const eliminarClaveError = (mensaje) => {
  return {
    type: ELIMINAR_CLAVE_ERROR,
    payload: mensaje
  };
};

export const errorClave = (error) => {
  return {
    type: ERROR_CLAVE,
    payload: error
  };
};
