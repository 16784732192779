import {
  OBTENER_DESTINATARIOS_EXITOSO,
  OBTENER_DESTINATARIOS_ERROR,
  CARGANDO_DESTINATARIOS
} from '../../constants/ActionTypes/Destinatarios';


const destinatariosInicial = {
  destinatarios: [],
  cantidad: 0,
  paginas: 1,
  error: false,
  cargando: false,
  mensaje: null
};

const listarDestinatarios = (state=destinatariosInicial, action) => {
  switch (action.type) {

  case OBTENER_DESTINATARIOS_EXITOSO: {
    return {
      ...destinatariosInicial,
      ...action.payload
    };
  }

  case OBTENER_DESTINATARIOS_ERROR: {
    return {
      ...destinatariosInicial,
      error: true,
      mensaje: action.payload
    };
  }

  case CARGANDO_DESTINATARIOS: {
    return {
      ...destinatariosInicial,
      cargando: action.payload
    };
  }

  default:
    return state;
  }
};


export default listarDestinatarios;
