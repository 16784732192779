import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import Configuracion from './Configuracion';
import Autenticacion from './Autenticacion';
import SeguridadPerfil from './Seguridad/Perfil';
import Modal from './Modal';

import listarClientes from './Clientes/Listar';
import detalleClientes from './Clientes/Detalle';
import listarUsuarios from './Usuarios/Listar';
import detalleUsuarios from './Usuarios/Detalle';
import listarDestinatarios from './Destinatarios/Listar';
import detalleDestinatarios from './Destinatarios/Detalle';
import listarListasDestinatarios from './ListasDestinatarios/Listar';
import detalleListasDestinatarios from './ListasDestinatarios/Detalle';
import listarMensajes from './Mensajes/Listar';
import detalleMensajes from './Mensajes/Detalle';
import enviarMensaje from './Mensajes/Enviar';
import listarCampanias from './Campanias/Listar';
import detalleCampanias from './Campanias/Detalle';
import listarCampaniasProgramadas from './EnviarCampanias/Listar';
import detalleCampaniasProgramadas from './EnviarCampanias/Detalle';
import listarDobleViaClaves from './DobleVia/Claves/Listar';
import detalleDobleViaClaves from './DobleVia/Claves/Detalle';
import listarEncuestas from './Encuestas/Listar';
import detalleEncuestas from './Encuestas/Detalle';
import listarEncuestaPreguntas from './Encuestas/Preguntas/Listar';
import detalleEncuestaPreguntas from './Encuestas/Preguntas/Detalle';
import listarDobleViaRespuestasClave from './DobleVia/Respuestas/Listar';
import reporteCampanias from './Reportes/Campanias';
import reporteMensajes from './Reportes/Mensajes';
import reporteEncuestas from './Reportes/Encuestas';
import reporteEncuestasResultado from './Reportes/EncuestasResultado';
import enviarSaldo from './Transacciones/EnviarSaldo';
import listarGaleria from './Galeria/Listar';
import detalleGaleria from './Galeria/Detalle';


const reducers = combineReducers ({
  routing: routerReducer,
  configs: Configuracion,
  autenticacion: Autenticacion,
  seguridadPerfil: SeguridadPerfil,
  modal: Modal,
  listarClientes,
  detalleClientes,
  listarUsuarios,
  detalleUsuarios,
  listarDestinatarios,
  detalleDestinatarios,
  listarListasDestinatarios,
  detalleListasDestinatarios,
  listarMensajes,
  detalleMensajes,
  enviarMensaje,
  listarCampanias,
  detalleCampanias,
  listarCampaniasProgramadas,
  detalleCampaniasProgramadas,
  listarDobleViaClaves,
  detalleDobleViaClaves,
  listarEncuestas,
  detalleEncuestas,
  listarEncuestaPreguntas,
  detalleEncuestaPreguntas,
  listarDobleViaRespuestasClave,
  reporteCampanias,
  reporteMensajes,
  reporteEncuestas,
  reporteEncuestasResultado,
  enviarSaldo,
  listarGaleria,
  detalleGaleria
});


export default reducers;
