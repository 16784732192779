import { put, call, all, fork, takeEvery } from 'redux-saga/effects';

import { sprintf } from 'sprintf-js';

import { OBTENER_ENCUESTA_PREGUNTAS } from '../../../constants/ActionTypes/Encuestas';
import { ENCUESTA_PREGUNTAS_URL } from '../../../constants/Urls/Encuestas';
import { obtenerEncuestaPreguntasExitoso, obtenerEncuestaPreguntasError } from '../../../actions/Encuestas/Preguntas';
import { httpFetch } from '../../../utils/Http/Fetch';
import { setUnidades } from '../../../actions/Configuracion';


const obtenerEncuestaPreguntasApi = async (encuesta) => {
  const url = sprintf(ENCUESTA_PREGUNTAS_URL, { uuid_encuesta: encuesta });

  return httpFetch(url, 'GET')
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};

function* obtenerEncuestaPreguntas(action) {
  try {
    const preguntas = yield call(obtenerEncuestaPreguntasApi, action.payload);
    console.log(preguntas);

    if (typeof preguntas.saldo !== 'undefined') {
      yield put(setUnidades(preguntas.saldo));
    }

    yield put(obtenerEncuestaPreguntasExitoso({preguntas, cantidad: preguntas.length}));
  } catch (error) {
    yield put(obtenerEncuestaPreguntasError(error.message));
  }
}

export function* obtenerEncuestaPreguntasWatcher() {
  yield takeEvery (OBTENER_ENCUESTA_PREGUNTAS, obtenerEncuestaPreguntas);
}

export default function* rootSaga() {
  yield all ([
    fork (obtenerEncuestaPreguntasWatcher)
  ]);
}
