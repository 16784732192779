import {
  MOSTRAR_MODAL,
  OCULTAR_MODAL
} from '../constants/ActionTypes/Modal';


const modalInicial = {
  modales: []
};

const modal = (state=modalInicial, action) => {
  switch (action.type) {

  case MOSTRAR_MODAL: {
    return {
      ...state,
      modales: state.modales.concat({ ...action.payload, visible: true})
    };
  }

  case OCULTAR_MODAL: {
    return {
      ...state,
      modales: action.payload ? state.modales.filter(modal => modal.uuid !== action.payload) : []
    };
  }

  default:
    return state;
  }
};

export default modal;
