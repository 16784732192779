import {
  INICIO_SESION_USUARIO_EXITOSO,
  INICIO_SESION_USUARIO_ERROR,
  CERRAR_SESION_USUARIO_EXITOSO,
  URL_INICIAL,
  AUTENTICANDO
} from '../constants/ActionTypes/Autenticacion';

import { USUARIO_SESION } from '../constants/Configuracion';


const autenticacionInicial = {
  urlInicial: '',
  usuario: localStorage.getItem(USUARIO_SESION),
  autenticando: false,
  error: false,
  mensaje: null
};

const autenticacion = (state=autenticacionInicial, action) => {
  switch (action.type) {

  case INICIO_SESION_USUARIO_EXITOSO: {
    return {
      ...state,
      usuario: JSON.stringify(action.payload.usuario),
      autenticando: false,
      error: false,
      mensaje: action.payload.mensaje
    };
  }

  case INICIO_SESION_USUARIO_ERROR: {
    return {
      ...state,
      error: true,
      mensaje: action.payload.mensaje
    };
  }

  case CERRAR_SESION_USUARIO_EXITOSO: {
    return {
      ...state,
      urlInicial: '/',
      usuario: null,
      autenticando: false,
      error: false,
      mensaje: null
    };
  }

  case AUTENTICANDO: {
    return {
      ...state,
      error: false,
      autenticando: action.payload
    };
  }

  case URL_INICIAL: {
    return {
      ...state,
      urlInicial: action.payload
    };
  }

  default:
    return state;
  }
};

export default autenticacion;
