
// Listar
export const OBTENER_MENSAJES = 'obtener_mensajes';
export const OBTENER_MENSAJES_EXITOSO = 'obtener_mensajes_exitoso';
export const OBTENER_MENSAJES_ERROR = 'obtener_mensajes_error';
export const CARGANDO_MENSAJES = 'cargando_mensajes';

// Detalle
export const OBTENER_MENSAJE = 'obtener_mensaje';
export const OBTENER_MENSAJE_EXITOSO = 'obtener_mensaje_exitoso';
export const OBTENER_MENSAJE_ERROR = 'obtener_mensaje_error';
export const GUARDAR_MENSAJE = 'guardar_mensaje';
export const GUARDAR_MENSAJE_EXITOSO = 'guardar_mensaje_exitoso';
export const GUARDAR_MENSAJE_ERROR = 'guardar_mensaje_error';
export const GUARDANDO_MENSAJE = 'guardando_mensaje';
export const ELIMINAR_MENSAJE = 'eliminar_mensaje';
export const ELIMINAR_MENSAJE_EXITOSO = 'eliminar_mensaje_exitoso';
export const ELIMINAR_MENSAJE_ERROR = 'eliminar_mensaje_error';
export const OBTENER_LISTAS_DESTINATARIOS = 'obtener_mensaje_listas_destinatarios';
export const OBTENER_LISTAS_DESTINATARIOS_EXITOSO = 'obtener_mensaje_listas_destinatarios_exitoso';
export const OBTENER_LISTAS_DESTINATARIOS_ERROR = 'obtener_mensaje_listas_destinatarios_error';
export const CARGANDO_LISTAS_DESTINATARIOS = 'mensaje_cargando_listas_destinatarios';
export const OBTENER_GALERIA = 'obtener_mensaje_galeria';
export const OBTENER_GALERIA_EXITOSO = 'obtener_mensaje_galeria_exitoso';
export const OBTENER_GALERIA_ERROR = 'obtener_mensaje_galeria_error';
export const CARGANDO_GALERIA = 'mensaje_cargando_galeria';
export const OBTENER_ENCUESTAS = 'obtener_mensaje_encuestas';
export const OBTENER_ENCUESTAS_EXITOSO = 'obtener_mensaje_encuestas_exitoso';
export const OBTENER_ENCUESTAS_ERROR = 'obtener_mensaje_encuestas_error';
export const CARGANDO_ENCUESTAS = 'mensaje_cargando_encuestas';

// Enviar
export const ENVIAR_MENSAJE = 'enviar_mensaje';
export const ENVIAR_MENSAJE_EXITOSO = 'enviar_mensaje_exitoso';
export const ENVIAR_MENSAJE_ERROR = 'enviar_mensaje_error';
export const ENVIANDO_MENSAJE = 'enviando_mensaje';
export const ENVIAR_OBTENER_MENSAJES = 'enviar_obtener_mensajes';
export const ENVIAR_OBTENER_MENSAJES_EXITOSO = 'enviar_obtener_mensajes_exitoso';
export const ENVIAR_OBTENER_MENSAJES_ERROR = 'enviar_obtener_mensajes_error';
export const ENVIAR_CARGANDO_MENSAJES = 'enviar_cargando_mensajes';
export const ENVIAR_OBTENER_LISTAS_DESTINATARIOS = 'enviar_obtener_listas_destinatarios';
export const ENVIAR_OBTENER_LISTAS_DESTINATARIOS_EXITOSO = 'enviar_obtener_listas_destinatarios_exitoso';
export const ENVIAR_OBTENER_LISTAS_DESTINATARIOS_ERROR = 'enviar_obtener_listas_destinatarios_error';
export const ENVIAR_CARGANDO_LISTAS_DESTINATARIOS = 'enviar_cargando_listas_mensajes';
export const ENVIAR_LIMPIAR_ENVIO_MENSAJE = 'enviar_limpiar_envio_mensaje';
