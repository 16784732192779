import React from 'react';

import UIBreadcrumbContainer from '../../../components/UI/Breadcrumb/BreadcrumbContainer';
import DobleViaClavesListarContainer from '../../../components/DobleVia/Claves/Listar/ListarContainer';

const ScreensDobleViaClavesListar = () => (
  <div className="animated slideInUpTiny animation-duration-3">

    <UIBreadcrumbContainer></UIBreadcrumbContainer>

    <DobleViaClavesListarContainer />
  </div>
);


export default ScreensDobleViaClavesListar;
