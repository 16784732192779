import React from 'react';
import { NavLink } from 'react-router-dom';

import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import UIDataTable from '../../UI/DataTable/DataTable';
import {
  ESTADOS_USUARIO,
  ESTADO_USUARIO_ACTIVO_LABEL,
  ESTADO_USUARIO_INACTIVO_LABEL
} from '../../../constants/Usuarios/Estados';
import { USUARIO_TIPO_ADMIN } from '../../../constants/Usuarios/Tipos';


const UsuariosListar = (props) => (
  <div className="app-module">
    <div className="module-box">
      <div className="module-box-header">
        <div className="module-box-header-inner">
          <div className="module-box-header-right">
            <Button color="info" className="jr-btn" onClick={props.obtenerUsuarios}>
              <FontAwesomeIcon icon="sync" size="lg" className="mr-2" />
              <span>Actualizar</span>
            </Button>

            <NavLink className="jr-btn btn btn-primary" to='/usuarios/detalle'>
              <FontAwesomeIcon icon="plus-circle" size="lg" className="mr-2" />
              <span>Crear usuario</span>
            </NavLink>
          </div>
        </div>
      </div>
      <div className="module-box-content">

        <UIDataTable
          data={props.usuarios}
          manual
          filterable={true}
          filtered={props.filtrado}
          pages={props.paginas}
          page={props.paginacion.pagina}
          pageSize={props.paginacion.tamano_pagina}
          onPageChange={props.cambioPagina}
          onPageSizeChange={props.cambioTamanoPagina}
          onFilteredChange={props.cambioFiltro}
          columns={[
            {
              Header: 'Cliente',
              accessor: 'cliente__uuid',
              Cell: (row) => (
                row.original.cliente ? `${row.original.cliente.nombres} ${row.original.cliente.apellidos}` : 'Admin'
              ),
              Filter: ({ filter, onChange }) => (
                props.usuario.tipo === USUARIO_TIPO_ADMIN ?
                  <select
                    className="form-control form-control-sm"
                    onChange={(event) => onChange(event.target.value)}
                    value={filter ? filter.value : ''}
                  >
                    <option value="">Todos</option>
                    {
                      props.clientes.map((cliente, index) => (
                        <option key={index} value={cliente.value}>{cliente.label}</option>
                      ))
                    }
                  </select>
                  :
                  null
              )
            },
            {
              Header: 'Nombre completo',
              accessor: 'search',
              Cell: (row) => (
                `${row.original.first_name} ${row.original.last_name}`
              )
            },
            {
              Header: 'Número de documento',
              accessor: 'numero_documento',
              Cell: (row) => (
                `${row.original.numero_documento}`
              )
            },
            {
              Header: 'Correo electrónico',
              accessor: 'email',
              filterable: false,
              Cell: (row) => (
                `${row.original.email}`
              )
            },
            {
              Header: 'Estado',
              accessor: 'activo',
              Cell: (row) => (
                row.original.activo ? ESTADO_USUARIO_ACTIVO_LABEL : ESTADO_USUARIO_INACTIVO_LABEL
              ),
              Filter: ({ filter, onChange }) => (
                <select
                  className="form-control form-control-sm"
                  onChange={(event) => onChange(event.target.value)}
                  value={filter ? filter.value : ''}
                >
                  <option value="">Todos</option>
                  {
                    ESTADOS_USUARIO.map((estado, index) => (
                      <option key={index} value={estado.value}>{estado.label}</option>
                    ))
                  }
                </select>
              )
            },
            {
              Header: 'Tipo',
              accessor: 'tipo',
              filterable: false,
              Cell: (row) => (
                <span className="text-capitalize">
                  {row.original.tipo.toLowerCase()}
                </span>
              ),
            }
          ]}
          getTrProps={(state, rowInfo) => ({
            onClick: () => {
              props.editarCliente(rowInfo.original.uuid);
            }
          })}
          noDataText="No se ha encontrando ningún usuario"
          rowsText="usuarios"
          loading={props.cargando}
        />
      </div>
    </div>
  </div>
);


export default UsuariosListar;
