
// Listar
export const OBTENER_CAMPANIAS_PROGRAMADAS = 'obtener_campanias_programadas';
export const OBTENER_CAMPANIAS_PROGRAMADAS_EXITOSO = 'obtener_campanias_programadas_exitoso';
export const OBTENER_CAMPANIAS_PROGRAMADAS_ERROR = 'obtener_campanias_programadas_error';
export const CARGANDO_CAMPANIAS_PROGRAMADAS = 'cargando_campanias_programadas';

// Detalle
export const OBTENER_CAMPANIA_PROGRAMADA = 'obtener_campania_programada';
export const OBTENER_CAMPANIA_PROGRAMADA_EXITOSO = 'obtener_campania_programada_exitoso';
export const OBTENER_CAMPANIA_PROGRAMADA_ERROR = 'obtener_campania_programada_error';
export const ENVIAR_CAMPANIA = 'enviar_campania';
export const ENVIAR_CAMPANIA_EXITOSO = 'enviar_campania_exitoso';
export const ENVIAR_CAMPANIA_ERROR = 'enviar_campania_error';
export const ENVIANDO_CAMPANIA = 'enviando_campania';
export const CANCELAR_CAMPANIA_PROGRAMADA = 'cancelar_campania_programada';
export const CANCELAR_CAMPANIA_PROGRAMADA_EXITOSO = 'cancelar_campania_programada_exitoso';
export const CANCELAR_CAMPANIA_PROGRAMADA_ERROR = 'cancelar_campania_programada_error';
export const OBTENER_CAMPANIAS = 'obtener_enviar_campania_campanias';
export const OBTENER_CAMPANIAS_EXITOSO = 'obtener_enviar_campania_campanias_exitoso';
export const OBTENER_CAMPANIAS_ERROR = 'obtener_enviar_campania_campanias_error';
export const CARGANDO_CAMPANIAS = 'enviar_campania_cargando_campanias';
