import React from 'react';

import ReactTable from 'react-table';

import 'react-table/react-table.css';


const UIDataTable = (props) => (
  <ReactTable
    style={{
      height: '400px'
    }}
    className="-striped -highlight"
    noDataText="No se ha encontrando ningún registro"
    previousText='Anterior'
    nextText='Siguiente'
    loadingText='Cargando...'
    pageText='Página'
    ofText='de'
    rowsText='registros'
    { ...props }
  />
);


export default UIDataTable;
