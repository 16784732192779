import React from 'react';
import { NavLink } from 'react-router-dom';

import { Col, Row, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Form } from 'formik';

import { TIPOS_PERSONA, TIPO_PERSONA_NATURAL } from '../../../constants/Clientes/TiposPersona';
import UIFormsCampoFormInput from '../../UI/Forms/CampoForm/Input';
import UIFormsCampoFormSelect from '../../UI/Forms/CampoForm/Select';
import UIFormsCampoFormMoneda from '../../UI/Forms/CampoForm/Moneda';
import UIFormsCampoFormAutocompletar from '../../UI/Forms/CampoForm/Autocompletar';
import { clienteSchema } from './Schema';


const ClientesDetalle = (props) => (
  <Formik
    enableReinitialize
    initialValues={props.valoresIniciales}
    onSubmit={props.handleSubmit}
    validationSchema={clienteSchema}
    validateOnChange
  >
    {formikProps => (
      <Form>
        <Row>
          <Col sm={12}>
            <Row form>
              <Col md={4}>
                <UIFormsCampoFormInput
                  id="nombres"
                  type="text"
                  name="nombres"
                  label="Nombres"
                  labelClassName="requerido"
                  placeholder=""
                  autoComplete="off"
                  error={formikProps.errors.nombres ? formikProps.errors.nombres : null}
                  touched={formikProps.touched.nombres ? formikProps.touched.nombres : null}
                />
              </Col>
              <Col md={4}>
                <UIFormsCampoFormInput
                  id="apellidos"
                  type="text"
                  name="apellidos"
                  label="Apellidos"
                  labelClassName={formikProps.values.tipo_persona.value === TIPO_PERSONA_NATURAL ? 'requerido' : ''}
                  placeholder=""
                  autoComplete="off"
                  error={formikProps.errors.apellidos ? formikProps.errors.apellidos : null}
                  touched={formikProps.touched.apellidos ? formikProps.touched.apellidos : null}
                />
              </Col>
              <Col md={4}>
                <UIFormsCampoFormSelect
                  id="tipo_persona"
                  name="tipo_persona"
                  label="Tipo de persona"
                  labelClassName="requerido"
                  autoComplete="off"
                  formikProps={formikProps}
                  placeholder="Seleccione una opción"
                  className="padding-0"
                  options={TIPOS_PERSONA}
                  onChange={(e) => {
                    const tiposDocumento = props.setTiposDocumento(e.value);
                    formikProps.setFieldValue('tipo_persona', e);
                    formikProps.setFieldValue('tipo_documento', tiposDocumento[0]);
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Row form>
              <Col md={4}>
                <UIFormsCampoFormSelect
                  id="tipo_documento"
                  name="tipo_documento"
                  label="Tipo de documento"
                  labelClassName="requerido"
                  autoComplete="off"
                  formikProps={formikProps}
                  placeholder="Seleccione una opción"
                  className="padding-0"
                  options={props.tiposDocumento}
                />
              </Col>
              <Col md={4}>
                <UIFormsCampoFormInput
                  id="numero_documento"
                  type="number"
                  name="numero_documento"
                  label="Número de documento"
                  labelClassName="requerido"
                  placeholder=""
                  autoComplete="off"
                  error={formikProps.errors.numero_documento ? formikProps.errors.numero_documento : null}
                  touched={formikProps.touched.numero_documento ? formikProps.touched.numero_documento : null}
                />
              </Col>
              {
                !formikProps.values.uuid &&
                  <Col md={4}>
                    <UIFormsCampoFormInput
                      id="email"
                      type="email"
                      name="email"
                      label="Correo electrónico"
                      labelClassName="requerido"
                      placeholder=""
                      autoComplete="off"
                      error={formikProps.errors.email ? formikProps.errors.email : null}
                      touched={formikProps.touched.email ? formikProps.touched.email : null}
                    />
                  </Col>
              }
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Row form>
              <Col md={4}>
                <UIFormsCampoFormInput
                  id="celular"
                  type="number"
                  name="celular"
                  label="Número de celular"
                  labelClassName="requerido"
                  placeholder=""
                  autoComplete="off"
                  error={formikProps.errors.celular ? formikProps.errors.celular : null}
                  touched={formikProps.touched.celular ? formikProps.touched.celular : null}
                />
              </Col>
              <Col md={8}>
                <UIFormsCampoFormAutocompletar
                  id="ciudad"
                  name="ciudad"
                  label="Ciudad"
                  labelClassName="requerido"
                  autoComplete="off"
                  formikProps={formikProps}
                  placeholder="Seleccione una ciudad"
                  className="padding-0"
                  error={formikProps.errors.ciudad ? formikProps.errors.ciudad.value : null}
                  options={props.ciudades}
                  noOptionsMessage="Busque por nombre de la ciudad"
                  onInputChange={props.cargarCiudades}
                  onChange={(ciudad) => {
                    const { label, value } = ciudad;
                    formikProps.setFieldValue('ciudad', { label, value });
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Row form>
              <Col md={4}>
                <UIFormsCampoFormMoneda
                  id="precio_formateado"
                  type="text"
                  name="precio_formateado"
                  label="Precio de venta"
                  labelClassName="requerido"
                  placeholder=""
                  autoComplete="off"
                  error={formikProps.errors.precio_formateado ? formikProps.errors.precio_formateado : null}
                  touched={formikProps.touched.precio_formateado ? formikProps.touched.precio_formateado : null}
                  onValueChange={(values) => {
                    formikProps.setFieldValue('precio_venta', values.floatValue);
                  }}
                />
              </Col>
              <Col md={8}>
                <UIFormsCampoFormInput
                  id="direccion"
                  type="text"
                  name="direccion"
                  label="Dirección"
                  labelClassName="requerido"
                  placeholder=""
                  autoComplete="off"
                  error={formikProps.errors.direccion ? formikProps.errors.direccion : null}
                  touched={formikProps.touched.direccion ? formikProps.touched.direccion : null}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <hr/>
            <div className="text-center">
              <Button color="success" className="jr-btn" type="submit" disabled={props.guardando}>
                {
                  props.guardando ?
                    <FontAwesomeIcon icon="circle-notch" size="lg" spin className="mr-2" />
                    :
                    <FontAwesomeIcon icon="check" size="lg" className="mr-2" />
                }
                Guardar cliente
              </Button>

              <NavLink className="jr-btn btn btn-default" to='/clientes'>
                <span>Regresar al listado</span>
              </NavLink>
            </div>
          </Col>
        </Row>
      </Form>
    )}
  </Formik>
);


export default ClientesDetalle;
