import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { toast } from 'react-toastify';

import CampaniasListar from './Listar';
import { obtenerCampanias, cargandoCampanias } from '../../../actions/Campanias/Listar';
import { setTituloPagina } from '../../../actions/Configuracion';


class CampaniasListarContainer extends Component {
  constructor (props) {
    super(props);

    this.state = {
      paginacion: {
        pagina: 0,
        tamano_pagina: 20
      },
      filtrado: []
    };
  }

  handleCambioPagina = (pagina) => {
    this.setState({
      paginacion: {
        ...this.state.paginacion,
        pagina
      }
    });
  };

  handleCambioTamanoPagina = (tamano_pagina, pagina) => {
    this.setState({
      paginacion: {
        tamano_pagina,
        pagina
      }
    });
  };

  handleCambioFiltro = (filtrado) => {
    this.setState({
      paginacion: {
        ...this.state.paginacion,
        pagina: 0
      },
      filtrado
    });
  };

  handleObtenerCampanias = () => {
    const { pagina, tamano_pagina } = this.state.paginacion;

    let filtros = {pagina: pagina + 1, tamano_pagina};
    if (typeof this.state.filtrado !== 'undefined') {
      this.state.filtrado.map((filtro) => filtros[filtro.id] = filtro.value);
    }

    this.props.actions.cargandoCampanias(true);
    this.props.actions.obtenerCampanias({ filtros });
  };

  handleEditarCampania = (id_campania) => {
    this.props.history.push('campanias/detalle/' + id_campania);
  };

  componentDidMount() {
    this.props.actions.setTituloPagina({titulo: 'Campañas', icono: 'comments-dollar'});
    this.handleObtenerCampanias();
  }

  componentDidUpdate(props, state) {
    if (this.props.error) {
      if (this.props.mensaje) {
        toast.error(this.props.mensaje);
      }

      if (this.props.cargando) {
        this.props.actions.cargandoCampanias(false);
      }
    }

    if ((state.filtrado !== this.state.filtrado) || (state.paginacion !== this.state.paginacion)) {
      this.handleObtenerCampanias();
    }
  }

  render() {
    return (
      <CampaniasListar
        campanias={this.props.campanias}
        cargando={this.props.cargando}
        paginas={this.props.paginas}
        paginacion={this.state.paginacion}
        filtrado={this.state.filtrado}
        obtenerCampanias={this.handleObtenerCampanias}
        editarCampania={this.handleEditarCampania}
        cambioPagina={this.handleCambioPagina}
        cambioTamanoPagina={this.handleCambioTamanoPagina}
        cambioFiltro={this.handleCambioFiltro}
      />
    );
  }
}


const mapStateToProps = ({ listarCampanias }) => {
  return { ...listarCampanias };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      setTituloPagina,
      obtenerCampanias,
      cargandoCampanias
    }, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CampaniasListarContainer));
