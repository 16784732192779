import React from 'react';

import { Col, Row, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Form } from 'formik';

import UIFormsCampoFormInput from '../../UI/Forms/CampoForm/Input';
import UIFormsCampoFormAutocompletar from '../../UI/Forms/CampoForm/Autocompletar';
import { enviarSaldoSchema } from './Schema';


const EnviarSaldo = (props) => (
  <Formik
    enableReinitialize
    initialValues={props.valoresIniciales}
    onSubmit={props.handleSubmit}
    validationSchema={enviarSaldoSchema}
    validateOnChange
  >
    {formikProps => (
      <Form>
        <Row>
          <Col sm={12}>
            <Row form>
              <Col md={4}>
                <UIFormsCampoFormAutocompletar
                  id="cliente"
                  name="cliente"
                  label="Cliente"
                  labelClassName="requerido"
                  autoComplete="off"
                  formikProps={formikProps}
                  placeholder="Seleccione un cliente"
                  className="padding-0"
                  error={formikProps.errors.cliente ? formikProps.errors.cliente.value : null}
                  options={props.clientes}
                  noOptionsMessage="Busque por nombre del cliente"
                  onInputChange={props.cargarClientes}
                  onChange={(cliente) => {
                    const { label, value } = cliente;
                    formikProps.setFieldValue('cliente', { label, value });
                  }}
                />
              </Col>
              <Col md={4}>
                <UIFormsCampoFormInput
                  type="text"
                  id="unidades"
                  name="unidades"
                  label="Unidades"
                  labelClassName="requerido"
                  autoComplete="off"
                  formikProps={formikProps}
                  error={formikProps.errors.unidades ? formikProps.errors.unidades : null}
                  touched={formikProps.touched.unidades ? formikProps.touched.unidades : null}
                />
              </Col>
              <Col md={4}>
                <UIFormsCampoFormInput
                  type="text"
                  id="unidades_confirmacion"
                  name="unidades_confirmacion"
                  label="Confirmar unidades"
                  labelClassName="requerido"
                  autoComplete="off"
                  formikProps={formikProps}
                  error={formikProps.errors.unidades_confirmacion ? formikProps.errors.unidades_confirmacion : null}
                  touched={formikProps.touched.unidades_confirmacion ? formikProps.touched.unidades_confirmacion : null}
                />
              </Col>
            </Row>
            <Row form>
              <Col md={12}>
                <UIFormsCampoFormInput
                  type="textarea"
                  id="observaciones"
                  name="observaciones"
                  label="Observaciones"
                  autoComplete="off"
                  formikProps={formikProps}
                  className="padding-0"
                  error={formikProps.errors.observaciones ? formikProps.errors.observaciones : null}
                  touched={formikProps.touched.observaciones ? formikProps.touched.observaciones : null}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <hr/>
            <div className="text-center">
              <Button color="success" className="jr-btn" type="submit" disabled={props.guardando}>
                {
                  props.guardando ?
                    <FontAwesomeIcon icon="circle-notch" size="lg" spin className="mr-2" />
                    :
                    <FontAwesomeIcon icon="check" size="lg" className="mr-2" />
                }
                Enviar saldo
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    )}
  </Formik>
);


export default EnviarSaldo;
