import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { toast } from 'react-toastify';

import GaleriaListar from './Listar';
import { obtenerGaleria, cargandoGaleria } from '../../../actions/Galeria/Listar';
import { setTituloPagina } from '../../../actions/Configuracion';


class GaleriaListarContainer extends Component {
  constructor (props) {
    super(props);

    this.state = {
      paginacion: {
        pagina: 0,
        tamano_pagina: 20
      },
      filtrado: []
    };
  }

  handleCambioPagina = (pagina) => {
    this.setState({
      paginacion: {
        ...this.state.paginacion,
        pagina
      }
    });
  }

  handleCambioTamanoPagina = (tamano_pagina, pagina) => {
    this.setState({
      paginacion: {
        tamano_pagina,
        pagina
      }
    });
  }

  handleCambioFiltro = (filtrado) => {
    this.setState({
      paginacion: {
        ...this.state.paginacion,
        pagina: 0
      },
      filtrado
    });
  }

  handleObtenerGaleria = () => {
    const { pagina, tamano_pagina } = this.state.paginacion;

    let filtros = {pagina: pagina + 1, tamano_pagina};
    if (typeof this.state.filtrado !== 'undefined') {
      this.state.filtrado.map((filtro) => filtros[filtro.id] = filtro.value);
    }

    this.props.actions.cargandoGaleria(true);
    this.props.actions.obtenerGaleria({ filtros });
  };

  handleEditarArchivo = (id_archivo) => {
    this.props.history.push('galeria/detalle/' + id_archivo);
  };

  componentDidMount() {
    this.props.actions.setTituloPagina({titulo: 'Galeria de archivos', icono: 'images'});
    this.handleObtenerGaleria();
  }

  componentDidUpdate(props, state) {
    if (this.props.error) {
      if (this.props.mensaje) {
        toast.error(this.props.mensaje);
      }

      if (this.props.cargando) {
        this.props.actions.cargandoGaleria(false);
      }
    }

    if ((state.filtrado !== this.state.filtrado) || (state.paginacion !== this.state.paginacion)) {
      this.handleObtenerGaleria();
    }
  }

  render() {
    return (
      <GaleriaListar
        galeria={this.props.galeria}
        cargando={this.props.cargando}
        paginas={this.props.paginas}
        paginacion={this.state.paginacion}
        filtrado={this.state.filtrado}
        obtenerGaleria={this.handleObtenerGaleria}
        editarArchivo={this.handleEditarArchivo}
        cambioPagina={this.handleCambioPagina}
        cambioTamanoPagina={this.handleCambioTamanoPagina}
        cambioFiltro={this.handleCambioFiltro}
      />
    );
  }
}


const mapStateToProps = ({ listarGaleria }) => {
  return { ...listarGaleria };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      setTituloPagina,
      obtenerGaleria,
      cargandoGaleria
    }, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GaleriaListarContainer));
