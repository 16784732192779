import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ScreensReportesCampanias from './Campanias';
import ScreensReportesMensajes from './Mensajes';
import ScreensReportesEncuestas from './Encuestas';


const Mensajes = (props) => (
  <Switch>
    <Route path={`${props.match.url}/campanias`} component={ScreensReportesCampanias} />
    <Route path={`${props.match.url}/mensajes`} component={ScreensReportesMensajes} />
    <Route path={`${props.match.url}/encuestas`} component={ScreensReportesEncuestas} />
  </Switch>
);


export default Mensajes;
