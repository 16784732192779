import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  mostrarModal,
  ocultarModal
} from '../../../actions/Modal';
import Modal from './Modal';


class ModalContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      abierto: false
    };
  }

  toggle = (uuid) => {
    this.props.actions.ocultarModal(uuid);
  };

  render() {
    let modales = this.props.modales.map((modal, index) => {
      const propsCabecera = {};
      if (modal.atributos.cerrarDesdeCabecera) {
        propsCabecera.toggle = this.toggle;
      }

      return (
        <Modal
          key={index}
          uuid={modal.uuid}
          propsCabecera={propsCabecera}
          componente={modal.componente}
          componenteProps={modal.componenteProps}
          visible={modal.visible}
          className={modal.className}
          atributos={modal.atributos}
          handleToggle={this.toggle}
        />
      );
    });

    return (
      <div>
        { modales }
      </div>
    );
  }
}


const mapStateToProps = ({ modal }) => {
  return { ...modal };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      mostrarModal,
      ocultarModal
    }, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalContainer));
