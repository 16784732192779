import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import uuid from 'uuid';
import { toast } from 'react-toastify';

import {
  obtenerCampania,
  guardarCampania,
  guardandoCampania,
  eliminarCampania,
  obtenerListasDestinatarios,
  obtenerMensajes
} from '../../../actions/Campanias/Detalle';
import { mostrarModal, ocultarModal } from '../../../actions/Modal';
import { setTituloPagina } from '../../../actions/Configuracion';
import CampaniasDetalle from './Detalle';


class CampaniasDetalleContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      columnas: [],
      campania: {
        nombre: '',
        lista: '',
        mensaje: '',
        texto_mensaje: ''
      }
    };
  }

  cargarCampania = (campania) => {
    if (campania.lista) {
      this.setColumnasMensaje(campania.lista.columnas_destinatarios);

      let lista  = this.props.listas.find((lista) => lista.uuid === campania.lista.uuid);
      campania.lista = lista;
    }

    if (campania.mensaje) {
      let mensaje  = this.props.mensajes.find((mensaje) => mensaje.uuid === campania.mensaje.uuid);
      campania.mensaje = mensaje;
      campania.texto_mensaje = mensaje.mensaje;
    }

    this.setState({
      campania
    });
  };

  setColumnasMensaje = (columnas_mensaje) => {
    let columnas = columnas_mensaje.map((columna) => {
      return {
        'id': columna.toUpperCase(),
        'display': columna.toUpperCase()
      };
    });

    this.setState({
      columnas
    });
  };

  handleActualizacionCampania = () => {
    toast.success(this.props.mensaje);
    this.props.history.push('/campanias');
  };

  handleEliminar = (mensaje_uuid) => {
    this.props.actions.mostrarModal({
      uuid: uuid.v4(),
      atributos: {
        titulo: 'Eliminar campaña',
        mensaje: '¿Realmente desea eliminar esta campaña?',
        colorBotonAceptar: 'danger',
        textoBotonAceptar: 'Eliminar campaña',
        nota: 'Esta acción no se puede deshacer',
        colorNota: 'danger',
        tamano: 'sm',
        aceptar: () => this.props.actions.eliminarCampania(mensaje_uuid)
      }
    });
  };

  handleSubmit = (values) => {
    let datos = {
      nombre: values.nombre,
      texto_mensaje: values.texto_mensaje
    };

    if (typeof values.mensaje === 'object' && values.mensaje.uuid) {
      datos.mensaje = values.mensaje.uuid;
    }

    if (typeof values.lista === 'object' && values.lista.uuid) {
      datos.lista = values.lista.uuid;
    }

    this.props.actions.guardandoCampania(true);
    this.props.actions.guardarCampania(datos);
  };

  componentDidMount() {
    this.props.actions.setTituloPagina({titulo: 'Campañas', icono: 'comments-dollar'});

    if (this.props.match.params.uuid_campania) {
      this.props.actions.obtenerCampania(this.props.match.params.uuid_campania);
    } else {
      this.props.actions.obtenerListasDestinatarios();
      this.props.actions.obtenerMensajes();
    }
  }

  componentDidUpdate(props) {
    // Verificar si existió algún error
    if (this.props.error) {
      if (this.props.mensaje) {
        toast.error(this.props.mensaje);
      }

      if (this.props.guardando) {
        this.props.actions.guardandoCampania(false);
      }

    // Verificar si es una edición de mensajes
    } else if (this.props.editando) {
      if (this.props.eliminando) {
        this.props.actions.ocultarModal();
        this.handleActualizacionCampania();
      } else if (props.campania !== this.props.campania) {
        this.cargarCampania(this.props.campania);
      } else if (this.props.guardado_exitoso) {
        this.handleActualizacionCampania();
      }

    // Verificar si es creación de un nuevo campania
    } else if (this.props.guardado_exitoso) {
      this.handleActualizacionCampania();
    }
  }

  render() {
    return (
      <CampaniasDetalle
        handleSubmit={this.handleSubmit}
        handleEliminar={this.handleEliminar}
        valoresIniciales={this.state.campania}
        guardando={this.props.guardando}
        listasDestinatarios={this.props.listas}
        mensajes={this.props.mensajes}
        setColumnasMensaje={this.setColumnasMensaje}
        columnasMensaje={this.state.columnas}
      />
    );
  }
}


const mapStateToProps = ({ detalleCampanias }) => {
  return { ...detalleCampanias };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      setTituloPagina,
      mostrarModal,
      ocultarModal,
      obtenerCampania,
      guardarCampania,
      guardandoCampania,
      eliminarCampania,
      obtenerListasDestinatarios,
      obtenerMensajes
    }, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CampaniasDetalleContainer));
