import {
  OBTENER_MENSAJE,
  OBTENER_MENSAJE_EXITOSO,
  OBTENER_MENSAJE_ERROR,
  GUARDAR_MENSAJE,
  GUARDAR_MENSAJE_EXITOSO,
  GUARDAR_MENSAJE_ERROR,
  GUARDANDO_MENSAJE,
  ELIMINAR_MENSAJE,
  ELIMINAR_MENSAJE_EXITOSO,
  ELIMINAR_MENSAJE_ERROR,
  OBTENER_LISTAS_DESTINATARIOS,
  OBTENER_LISTAS_DESTINATARIOS_EXITOSO,
  OBTENER_LISTAS_DESTINATARIOS_ERROR,
  CARGANDO_LISTAS_DESTINATARIOS,
  OBTENER_GALERIA,
  OBTENER_GALERIA_EXITOSO,
  OBTENER_GALERIA_ERROR,
  CARGANDO_GALERIA,
  OBTENER_ENCUESTAS,
  OBTENER_ENCUESTAS_EXITOSO,
  OBTENER_ENCUESTAS_ERROR,
  CARGANDO_ENCUESTAS
} from '../../constants/ActionTypes/Mensajes';


export const obtenerMensaje = (uuid) => {
  return {
    type: OBTENER_MENSAJE,
    payload: uuid
  };
};

export const obtenerMensajeExitoso = (mensaje) => {
  return {
    type: OBTENER_MENSAJE_EXITOSO,
    payload: mensaje
  };
};

export const obtenerMensajeError = (mensaje) => {
  return {
    type: OBTENER_MENSAJE_ERROR,
    payload: mensaje
  };
};

export const guardarMensaje = (datos) => {
  return {
    type: GUARDAR_MENSAJE,
    payload: datos
  };
};

export const guardarMensajeExitoso = (mensaje) => {
  return {
    type: GUARDAR_MENSAJE_EXITOSO,
    payload: mensaje
  };
};

export const guardarMensajeError = (mensaje) => {
  return {
    type: GUARDAR_MENSAJE_ERROR,
    payload: mensaje
  };
};

export const guardandoMensaje = (cargando) => {
  return {
    type: GUARDANDO_MENSAJE,
    payload: cargando
  };
};

export const eliminarMensaje = (uuid) => {
  return {
    type: ELIMINAR_MENSAJE,
    payload: uuid
  };
};

export const eliminarMensajeExitoso = (mensaje) => {
  return {
    type: ELIMINAR_MENSAJE_EXITOSO,
    payload: mensaje
  };
};

export const eliminarMensajeError = (mensaje) => {
  return {
    type: ELIMINAR_MENSAJE_ERROR,
    payload: mensaje
  };
};

export const obtenerListasDestinatarios = () => {
  return {
    type: OBTENER_LISTAS_DESTINATARIOS
  };
};

export const obtenerListasDestinatariosExitoso = (destinatarios) => {
  return {
    type: OBTENER_LISTAS_DESTINATARIOS_EXITOSO,
    payload: destinatarios
  };
};

export const obtenerListasDestinatariosError = (mensaje) => {
  return {
    type: OBTENER_LISTAS_DESTINATARIOS_ERROR,
    payload: mensaje
  };
};

export const cargandoListasDestinatarios = (cargando) => {
  return {
    type: CARGANDO_LISTAS_DESTINATARIOS,
    payload: cargando
  };
};

export const obtenerGaleria = () => {
  return {
    type: OBTENER_GALERIA
  };
};

export const obtenerGaleriaExitoso = (galeria) => {
  return {
    type: OBTENER_GALERIA_EXITOSO,
    payload: galeria
  };
};

export const obtenerGaleriaError = (mensaje) => {
  return {
    type: OBTENER_GALERIA_ERROR,
    payload: mensaje
  };
};

export const cargandoGaleria = (cargando) => {
  return {
    type: CARGANDO_GALERIA,
    payload: cargando
  };
};

export const obtenerEncuestas = () => {
  return {
    type: OBTENER_ENCUESTAS
  };
};

export const obtenerEncuestasExitoso = (encuestas) => {
  return {
    type: OBTENER_ENCUESTAS_EXITOSO,
    payload: encuestas
  };
};

export const obtenerEncuestasError = (mensaje) => {
  return {
    type: OBTENER_ENCUESTAS_ERROR,
    payload: mensaje
  };
};

export const cargandoEncuestas = (cargando) => {
  return {
    type: CARGANDO_ENCUESTAS,
    payload: cargando
  };
};
