import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ScreensCampaniasListar from '../../screens/Campanias/CampaniasListar';
import ScreensCampaniasDetalle from '../../screens/Campanias/CampaniasDetalle';


const Campanias = (props) => (
  <div className="app-wrapper">
    <Switch>
      <Route exact path={`${props.match.url}/`} component={ScreensCampaniasListar}/>
      <Route path={`${props.match.url}/detalle/:uuid_campania`} component={ScreensCampaniasDetalle} />
      <Route path={`${props.match.url}/detalle`} component={ScreensCampaniasDetalle}/>
    </Switch>
  </div>
);


export default Campanias;
