import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { toast } from 'react-toastify';

import ClientesListar from './Listar';
import { obtenerClientes, cargandoClientes } from '../../../actions/Clientes/Listar';
import { setTituloPagina } from '../../../actions/Configuracion';


class ClientesListarContainer extends Component {
  constructor (props) {
    super(props);

    this.state = {
      paginacion: {
        pagina: 0,
        tamano_pagina: 20
      },
      filtrado: []
    };
  }

  handleCambioPagina = (pagina) => {
    this.setState({
      paginacion: {
        ...this.state.paginacion,
        pagina
      }
    });
  };

  handleCambioTamanoPagina = (tamano_pagina, pagina) => {
    this.setState({
      paginacion: {
        tamano_pagina,
        pagina
      }
    });
  };

  handleCambioFiltro = (filtrado) => {
    this.setState({
      paginacion: {
        ...this.state.paginacion,
        pagina: 0
      },
      filtrado
    });
  };

  handleObtenerClientes = () => {
    const { pagina, tamano_pagina } = this.state.paginacion;

    let filtros = {pagina: pagina + 1, tamano_pagina};
    if (typeof this.state.filtrado !== 'undefined') {
      this.state.filtrado.map((filtro) => filtros[filtro.id] = filtro.value);
    }

    this.props.actions.cargandoClientes(true);
    this.props.actions.obtenerClientes({ filtros });
  };

  handleEditarCliente = (id_cliente) => {
    this.props.history.push('clientes/detalle/' + id_cliente);
  };

  componentDidMount() {
    this.props.actions.setTituloPagina({titulo: 'Clientes', icono: 'users'});
    this.handleObtenerClientes();
  }

  componentDidUpdate(props, state) {
    if (this.props.error) {
      if (this.props.mensaje) {
        toast.error(this.props.mensaje);
      }

      if (this.props.cargando) {
        this.props.actions.cargandoClientes(false);
      }
    }

    if ((state.filtrado !== this.state.filtrado) || (state.paginacion !== this.state.paginacion)) {
      this.handleObtenerClientes();
    }
  }

  render() {
    return (
      <ClientesListar
        clientes={this.props.clientes}
        cargando={this.props.cargando}
        paginas={this.props.paginas}
        paginacion={this.state.paginacion}
        filtrado={this.state.filtrado}
        obtenerClientes={this.handleObtenerClientes}
        editarCliente={this.handleEditarCliente}
        cambioPagina={this.handleCambioPagina}
        cambioTamanoPagina={this.handleCambioTamanoPagina}
        cambioFiltro={this.handleCambioFiltro}
      />
    );
  }
}


const mapStateToProps = ({ listarClientes }) => {
  return { ...listarClientes };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      setTituloPagina,
      obtenerClientes,
      cargandoClientes
    }, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientesListarContainer));
