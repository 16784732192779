import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import _ from 'lodash';
import { toast } from 'react-toastify';

import {
  obtenerPerfil,
  guardarPerfil,
  guardandoPerfil
} from '../../../actions/Seguridad/Perfil';
import { cerrarSesionUsuario } from '../../../actions/Autenticacion';
import { setTituloPagina } from '../../../actions/Configuracion';
import PerfilDetalle from './Detalle';


class PerfilDetalleContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      perfil: {
        email: '',
        actual_password: '',
        nuevo_password: '',
        nuevo_password_confirmacion: ''
      },
    };
  }

  guardarPerfil = (values) => {
    let datos = _.cloneDeep(values);
    delete datos.nuevo_password_confirmacion;

    this.props.actions.guardandoPerfil(true);
    this.props.actions.guardarPerfil({ ...datos });
  };

  cargarPerfil = (perfil) => {
    perfil.actual_password = '';
    perfil.nuevo_password = '';
    perfil.nuevo_password_confirmacion = '';

    this.setState({
      perfil
    });
  };

  componentDidMount() {
    const usuario = JSON.parse(this.props.autenticacion.usuario);

    this.props.actions.setTituloPagina({titulo: 'Mi Perfil', icono: 'users'});
    this.props.actions.obtenerPerfil(usuario.uuid);
  }

  componentDidUpdate(props) {
    if (this.props.error) {
      if (this.props.mensaje) {
        toast.error(this.props.mensaje);
      }

      if (this.props.guardando) {
        this.props.actions.guardandoPerfil(false);
      }
    } else if (this.props.editando) {
      if (props.perfil !== this.props.perfil) {
        this.cargarPerfil(this.props.perfil);
      } else if (this.props.guardado_exitoso) {
        this.props.actions.guardandoPerfil(false);

        toast.success(this.props.mensaje);
        this.props.actions.cerrarSesionUsuario();
      }
    }
  }

  render() {
    return (
      <PerfilDetalle
        guardarPerfil={this.guardarPerfil}
        valoresIniciales={this.state.perfil}
        guardando={this.props.guardando}
      />
    );
  }
}

const mapStateToProps = ({ seguridadPerfil, autenticacion }) => {
  return { ...seguridadPerfil, autenticacion };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      setTituloPagina,
      obtenerPerfil,
      guardarPerfil,
      guardandoPerfil,
      cerrarSesionUsuario
    }, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PerfilDetalleContainer));
