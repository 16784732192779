import {
  OBTENER_LISTA_DESTINATARIOS,
  OBTENER_LISTA_DESTINATARIOS_EXITOSO,
  OBTENER_LISTA_DESTINATARIOS_ERROR,
  GUARDAR_LISTA_DESTINATARIOS,
  GUARDAR_LISTA_DESTINATARIOS_EXITOSO,
  GUARDAR_LISTA_DESTINATARIOS_ERROR,
  GUARDANDO_LISTA_DESTINATARIOS,
  ELIMINAR_LISTA_DESTINATARIOS,
  ELIMINAR_LISTA_DESTINATARIOS_EXITOSO,
  ELIMINAR_LISTA_DESTINATARIOS_ERROR,
  ERROR_LISTA_DESTINATARIOS,
  OBTENER_DESTINATARIOS,
  OBTENER_DESTINATARIOS_EXITOSO,
  OBTENER_DESTINATARIOS_ERROR,
  CARGANDO_DESTINATARIOS
} from '../../constants/ActionTypes/ListasDestinatarios';


export const obtenerListaDestinatarios = (uuid) => {
  return {
    type: OBTENER_LISTA_DESTINATARIOS,
    payload: uuid
  };
};

export const obtenerListaDestinatariosExitoso = (lista) => {
  return {
    type: OBTENER_LISTA_DESTINATARIOS_EXITOSO,
    payload: lista
  };
};

export const obtenerListaDestinatariosError = (mensaje) => {
  return {
    type: OBTENER_LISTA_DESTINATARIOS_ERROR,
    payload: mensaje
  };
};

export const guardarListaDestinatarios = (datos) => {
  return {
    type: GUARDAR_LISTA_DESTINATARIOS,
    payload: datos
  };
};

export const guardarListaDestinatariosExitoso = (lista) => {
  return {
    type: GUARDAR_LISTA_DESTINATARIOS_EXITOSO,
    payload: lista
  };
};

export const guardarListaDestinatariosError = (mensaje) => {
  return {
    type: GUARDAR_LISTA_DESTINATARIOS_ERROR,
    payload: mensaje
  };
};

export const guardandoListaDestinatarios = (cargando) => {
  return {
    type: GUARDANDO_LISTA_DESTINATARIOS,
    payload: cargando
  };
};

export const eliminarListaDestinatarios = (uuid) => {
  return {
    type: ELIMINAR_LISTA_DESTINATARIOS,
    payload: uuid
  };
};

export const eliminarListaDestinatariosExitoso = (mensaje) => {
  return {
    type: ELIMINAR_LISTA_DESTINATARIOS_EXITOSO,
    payload: mensaje
  };
};

export const eliminarListaDestinatariosError = (mensaje) => {
  return {
    type: ELIMINAR_LISTA_DESTINATARIOS_ERROR,
    payload: mensaje
  };
};

export const errorListaDestinatarios = (error) => {
  return {
    type: ERROR_LISTA_DESTINATARIOS,
    payload: error
  };
};

export const obtenerDestinatarios = (params) => {
  return {
    type: OBTENER_DESTINATARIOS,
    payload: params
  };
};

export const obtenerDestinatariosExitoso = (destinatarios) => {
  return {
    type: OBTENER_DESTINATARIOS_EXITOSO,
    payload: destinatarios
  };
};

export const obtenerDestinatariosError = (mensaje) => {
  return {
    type: OBTENER_DESTINATARIOS_ERROR,
    payload: mensaje
  };
};

export const cargandoDestinatarios = (cargando) => {
  return {
    type: CARGANDO_DESTINATARIOS,
    payload: cargando
  };
};
