import {
  CAMBIAR_ESTILO_NAVEGACION,
  CAMBIAR_DIRECCION,
  TIPO_DRAWER,
  COLOR_TEMA,
  TOGGLE_NAVEGACION_COLAPSADA,
  ANCHO_VENTANA,
  POSICION_MENU_HORIZONTAL,
  TITULO_PAGINA,
  UNIDADES
} from '../constants/ActionTypes/Configuracion';

export function toggleNavegacionColapsada(navegacionColapsada) {
  return {type: TOGGLE_NAVEGACION_COLAPSADA, navegacionColapsada};
}

export function setDrawerType(tipoDrawer) {
  return {type: TIPO_DRAWER, tipoDrawer};
}

export function actualizarAnchoVentana(ancho) {
  return {type: ANCHO_VENTANA, ancho};
}

export function setThemeColor(color) {
  return {type: COLOR_TEMA, color};
}

export function changeDirection() {
  return {type: CAMBIAR_DIRECCION};
}

export function changeNavigationStyle(layoutType) {
  return {
    type: CAMBIAR_ESTILO_NAVEGACION,
    payload: layoutType
  };
}

export function setHorizontalMenuPosition(navigationPosition) {
  return {
    type: POSICION_MENU_HORIZONTAL,
    payload: navigationPosition
  };
}

export function setTituloPagina(titulo) {
  return {
    type: TITULO_PAGINA,
    payload: titulo
  };
}

export function setUnidades(unds) {
  return {
    type: UNIDADES,
    payload: unds
  };
}
