import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { toast } from 'react-toastify';
import { sprintf } from 'sprintf-js';
import moment from 'moment';

import {
  obtenerEncuesta
} from '../../../../actions/Encuestas/Detalle';
import {
  obtenerReporte,
  cargandoReporte,
  exportarReporte
} from '../../../../actions/Reportes/Encuestas';
import { setTituloPagina } from '../../../../actions/Configuracion';
import ReportesEncuestasDetalle from './Detalle';

import {
  TIPO_RESPUESTA_NUMERICO,
  TIPO_RESPUESTA_SELECCION,
  TIPO_RESPUESTA_SELECCION_MULTIPLE
} from '../../../../constants/Encuestas/TiposRespuestaPregunta';


class ReportesEncuestasContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      datos: []
    };
  }

  descargarReporte = () => {
    toast.success(this.props.mensaje);
    const archivo = new Blob([this.props.archivo], {type: 'application/xlsx'});
    let url_archivo = URL.createObjectURL(archivo);

    // Simula descarga del archivo
    let link_archivo = document.createElement('a');
    link_archivo.href = url_archivo;
    link_archivo.download = sprintf('reporte_encuesta_enviada_%(fecha)s.xlsx', { fecha: moment().format('MMDDYYYY') });
    link_archivo.click();

    // Limpia descarga
    link_archivo.remove();
    URL.revokeObjectURL(url_archivo);
  };

  calcularGrafica = () => {
    const preguntasTabulables = [
      TIPO_RESPUESTA_NUMERICO, TIPO_RESPUESTA_SELECCION, TIPO_RESPUESTA_SELECCION_MULTIPLE
    ];
    let datos = {};

    this.props.resultados.forEach((resultado) => {
      const respuestas = resultado.respuestas.filter(
        (respuesta) => preguntasTabulables.includes(respuesta.pregunta.tipo)
      );

      respuestas.forEach((respuesta) => {
        const pregunta = respuesta.pregunta.uuid;

        if (!datos[pregunta]) {
          datos[pregunta] = respuesta.pregunta;
          datos[pregunta]['conteo'] = {};
        }

        respuesta.respuesta.split(',').forEach((opcion) => {
          if  (datos[pregunta]['conteo'][opcion]) {
            datos[pregunta]['conteo'][opcion]++;
          } else {
            datos[pregunta]['conteo'][opcion] = 1;
          }
        });
      });
    });

    datos = Object.values(datos).map((dato) => {
      let conteo = [];
      Object.entries(dato.conteo).forEach((entrada) => {
        const [opcion, valor] = entrada;

        conteo.push({
          name: opcion,
          color: `#${Math.floor(Math.random()*16777215).toString(16)}`,
          value: valor
        });
      });

      dato.conteo = conteo;
      return dato;
    });

    this.setState({
      datos
    });
  };

  handleExportarReporte = () => {
    let filtros = {encuesta_enviada__uuid: this.props.match.params.uuid_encuesta};

    if (typeof this.state.filtered !== 'undefined') {
      this.state.filtered.map((filtro) => filtros[filtro.id] = filtro.value);
    }

    this.props.actions.cargandoReporte(true);
    this.props.actions.exportarReporte({ filtros, encuesta: this.props.match.params.uuid_encuesta });
  };

  handleObtenerReporte = (state) => {
    let filtros = {};
    if (state && typeof state.filtered !== 'undefined') {
      state.filtered.map((filtro) => filtros[filtro.id] = filtro.value);
    }

    this.props.actions.cargandoReporte(true);
    this.props.actions.obtenerReporte({ filtros, encuesta: this.props.match.params.uuid_encuesta });
  };

  handleReporteEncuestaPreguntas = (encuesta, resultado) => {
    this.props.history.push(encuesta + '/resultado/' + resultado);
  };

  componentDidMount() {
    this.props.actions.setTituloPagina({titulo: 'Reporte de resultados de encuestas enviadas', icono: 'chart-bar'});

    if (this.props.match.params.uuid_encuesta) {
      this.props.actions.obtenerEncuesta(this.props.match.params.uuid_encuesta);
      this.handleObtenerReporte();
    }
  }

  componentDidUpdate(props) {
    // Verificar si existió algún error
    if (this.props.error) {
      if (this.props.mensaje) {
        toast.error(this.props.mensaje);
      }

      if (this.props.cargando) {
        this.props.actions.cargandoReporte(false);
      }
    } else if (props.resultados !== this.props.resultados) {
      this.calcularGrafica();
    } else if (this.props.archivo) {
      this.descargarReporte();
    }
  }

  render() {
    return (
      <ReportesEncuestasDetalle
        obtenerReporte={this.handleObtenerReporte}
        exportarReporte={this.handleExportarReporte}
        reporteEncuestaPreguntas={this.handleReporteEncuestaPreguntas}
        encuesta={this.props.detalleEncuestas.encuesta}
        resultados={this.props.resultados}
        cargando={this.props.cargando}
        datos={this.state.datos}
      />
    );
  }
}


const mapStateToProps = ({ reporteEncuestas, detalleEncuestas }) => {
  return { ...reporteEncuestas, detalleEncuestas };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      setTituloPagina,
      obtenerReporte,
      cargandoReporte,
      exportarReporte,
      obtenerEncuesta
    }, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportesEncuestasContainer));
