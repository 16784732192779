import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import uuid from 'uuid';
import { toast } from 'react-toastify';

import {
  obtenerDestinatario,
  guardarDestinatario,
  guardandoDestinatario,
  eliminarDestinatario
} from '../../../actions/Destinatarios/Detalle';
import { mostrarModal, ocultarModal } from '../../../actions/Modal';
import { setTituloPagina } from '../../../actions/Configuracion';
import DestinatariosDetalle from './Detalle';


class DestinatariosDetalleContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      destinatario: {
        nombres: '',
        numero: ''
      }
    };
  }

  cargarDestinatario = (destinatario) => {
    this.setState({
      destinatario
    });
  };

  handleActualizacionDestinatario = () => {
    toast.success(this.props.mensaje);
    this.props.history.push('/destinatarios');
  };

  handleEliminar = (destinatario_uuid) => {
    this.props.actions.mostrarModal({
      uuid: uuid.v4(),
      atributos: {
        titulo: 'Eliminar destinatario',
        mensaje: '¿Realmente desea eliminar este destinatario?',
        colorBotonAceptar: 'danger',
        textoBotonAceptar: 'Eliminar destinatario',
        nota: 'Esta acción no se puede deshacer',
        colorNota: 'danger',
        tamano: 'sm',
        aceptar: () => this.props.actions.eliminarDestinatario(destinatario_uuid)
      }
    });
  };

  handleSubmit = (values) => {
    this.props.actions.guardandoDestinatario(true);
    this.props.actions.guardarDestinatario(values);
  };

  componentDidMount() {
    this.props.actions.setTituloPagina({titulo: 'Destinatarios', icono: 'user-circle'});

    if (this.props.match.params.uuid_destinatario) {
      this.props.actions.obtenerDestinatario(this.props.match.params.uuid_destinatario);
    }
  }

  componentDidUpdate(props) {
    // Verificar si existió algún error
    if (this.props.error) {
      if (this.props.mensaje) {
        toast.error(this.props.mensaje);
      }

      if (this.props.guardando) {
        this.props.actions.guardandoDestinatario(false);
      }

    // Verificar si es una edición de destinatarios
    } else if (this.props.editando) {
      if (this.props.eliminando) {
        this.props.actions.ocultarModal();
        this.handleActualizacionDestinatario();
      } else if (props.destinatario !== this.props.destinatario) {
        this.cargarDestinatario(this.props.destinatario);
      } else if (this.props.guardado_exitoso) {
        this.handleActualizacionDestinatario();
      }

    // Verificar si es creación de un nuevo destinatario
    } else if (this.props.guardado_exitoso) {
      this.handleActualizacionDestinatario();
    }
  }

  render() {
    return (
      <DestinatariosDetalle
        handleSubmit={this.handleSubmit}
        handleEliminar={this.handleEliminar}
        valoresIniciales={this.state.destinatario}
        guardando={this.props.guardando}
      />
    );
  }
}


const mapStateToProps = ({ detalleDestinatarios }) => {
  return { ...detalleDestinatarios };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      setTituloPagina,
      mostrarModal,
      ocultarModal,
      obtenerDestinatario,
      guardarDestinatario,
      guardandoDestinatario,
      eliminarDestinatario
    }, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DestinatariosDetalleContainer));
