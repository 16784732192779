import {
  OBTENER_GALERIA_EXITOSO,
  OBTENER_GALERIA_ERROR,
  CARGANDO_GALERIA
} from '../../constants/ActionTypes/Galeria';


const galeriaInicial = {
  galeria: [],
  cantidad: 0,
  paginas: 1,
  error: false,
  cargando: false,
  mensaje: null
};

const listarGaleria = (state=galeriaInicial, action) => {
  switch (action.type) {

  case OBTENER_GALERIA_EXITOSO: {
    return {
      ...galeriaInicial,
      ...action.payload
    };
  }

  case OBTENER_GALERIA_ERROR: {
    return {
      ...galeriaInicial,
      error: true,
      mensaje: action.payload
    };
  }

  case CARGANDO_GALERIA: {
    return {
      ...galeriaInicial,
      cargando: action.payload
    };
  }

  default:
    return state;
  }
};


export default listarGaleria;
