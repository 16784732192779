
// Listar
export const OBTENER_CLAVES = 'obtener_claves';
export const OBTENER_CLAVES_EXITOSO = 'obtener_claves_exitoso';
export const OBTENER_CLAVES_ERROR = 'obtener_claves_error';
export const CARGANDO_CLAVES = 'cargando_claves';

// Detalle
export const OBTENER_CLAVE = 'obtener_clave';
export const OBTENER_CLAVE_EXITOSO = 'obtener_clave_exitoso';
export const OBTENER_CLAVE_ERROR = 'obtener_clave_error';
export const GUARDAR_CLAVE = 'guardar_clave';
export const GUARDAR_CLAVE_EXITOSO = 'guardar_clave_exitoso';
export const GUARDAR_CLAVE_ERROR = 'guardar_clave_error';
export const GUARDANDO_CLAVE = 'guardando_clave';
export const ELIMINAR_CLAVE = 'eliminar_clave';
export const ELIMINAR_CLAVE_EXITOSO = 'eliminar_clave_exitoso';
export const ELIMINAR_CLAVE_ERROR = 'eliminar_clave_error';
export const ERROR_CLAVE = 'error_clave';

// Respuestas
export const OBTENER_RESPUESTAS_CLAVE = 'obtener_respuestas_clave';
export const OBTENER_RESPUESTAS_CLAVE_EXITOSO = 'obtener_respuestas_clave_exitoso';
export const OBTENER_RESPUESTAS_CLAVE_ERROR = 'obtener_respuestas_clave_error';
export const CARGANDO_RESPUESTAS_CLAVE = 'cargando_respuestas_clave';
export const RESPUESTAS_OBTENER_CLAVES = 'respuestas_obtener_clave';
export const RESPUESTAS_OBTENER_CLAVES_EXITOSO = 'respuestas_obtener_clave_exitoso';
export const RESPUESTAS_OBTENER_CLAVES_ERROR = 'respuestas_obtener_clave_error';
export const EXPORTAR_RESPUESTAS_CLAVE = 'exportar_respuestas_clave';
export const EXPORTAR_RESPUESTAS_CLAVE_EXITOSO = 'exportar_respuestas_clave_exitoso';
export const EXPORTAR_RESPUESTAS_CLAVE_ERROR= 'exportar_respuestas_clave_error';
