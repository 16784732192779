import {
  OBTENER_ARCHIVO,
  OBTENER_ARCHIVO_EXITOSO,
  OBTENER_ARCHIVO_ERROR,
  GUARDAR_ARCHIVO,
  GUARDAR_ARCHIVO_EXITOSO,
  GUARDAR_ARCHIVO_ERROR,
  GUARDANDO_ARCHIVO,
  ELIMINAR_ARCHIVO,
  ELIMINAR_ARCHIVO_EXITOSO,
  ELIMINAR_ARCHIVO_ERROR
} from '../../constants/ActionTypes/Galeria';


export const obtenerArchivo = (uuid) => {
  return {
    type: OBTENER_ARCHIVO,
    payload: uuid
  };
};

export const obtenerArchivoExitoso = (archivo) => {
  return {
    type: OBTENER_ARCHIVO_EXITOSO,
    payload: archivo
  };
};

export const obtenerArchivoError = (mensaje) => {
  return {
    type: OBTENER_ARCHIVO_ERROR,
    payload: mensaje
  };
};

export const guardarArchivo = (datos) => {
  return {
    type: GUARDAR_ARCHIVO,
    payload: datos
  };
};

export const guardarArchivoExitoso = (archivo) => {
  return {
    type: GUARDAR_ARCHIVO_EXITOSO,
    payload: archivo
  };
};

export const guardarArchivoError = (mensaje) => {
  return {
    type: GUARDAR_ARCHIVO_ERROR,
    payload: mensaje
  };
};

export const guardandoArchivo = (cargando) => {
  return {
    type: GUARDANDO_ARCHIVO,
    payload: cargando
  };
};

export const eliminarArchivo = (uuid) => {
  return {
    type: ELIMINAR_ARCHIVO,
    payload: uuid
  };
};

export const eliminarArchivoExitoso = (mensaje) => {
  return {
    type: ELIMINAR_ARCHIVO_EXITOSO,
    payload: mensaje
  };
};

export const eliminarArchivoError = (mensaje) => {
  return {
    type: ELIMINAR_ARCHIVO_ERROR,
    payload: mensaje
  };
};
