import React from 'react';
import { NavLink } from 'react-router-dom';

import { Row, Col, Alert, Card, CardBody, CardTitle, CardSubtitle } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

const ReportesEncuestasPreguntasDetalle = (props) => (
  <div className="app-module">
    <div className="module-box">
      <div className="module-box-header">
        <div className="module-box-header-inner">
          <div className="module-box-header-right">
            <NavLink className="jr-btn btn btn-default" to={`/reportes/encuestas/${props.encuesta.uuid}`}>
              <span>Regresar a la encuesta</span>
            </NavLink>
          </div>
        </div>
      </div>
      <div className="module-box-content pt-2">
        <Row>
          <Col md={12}>
            {
              props.encuesta.uuid &&
                <div>
                  <h2 className="font-weight-bold pb-0 pl-4">
                    {props.encuesta.nombre}
                  </h2>
                  <h4 className="pl-4">{props.encuesta.descripcion}</h4>
                  <hr />
                </div>
            }
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h2 className="text-center mb-2">Resultados de la encuesta</h2>
            {
              props.cargando &&
                <Alert color="light" className="text-center">
                  <FontAwesomeIcon icon="sync" spin size="lg" className="mr-2" />
                  Cargando información...
                </Alert>
            }

            {
              props.resultado.destinatario &&
                <Card className="mx-4">
                  <CardBody className="pb-0">
                    <CardTitle tag="h5">
                      Datos del destinatario
                    </CardTitle>
                    <CardSubtitle tag="h6" className="mb-2 text-muted">
                      Información del destinatario que contestó la encuesta
                      <hr/>
                    </CardSubtitle>
                  </CardBody>
                  <CardBody className="pt-0">
                    <Row>
                      <Col>
                        <p className="mb-0">
                          <b>Nombre</b>
                        </p>
                        <p>
                          {props.resultado.destinatario.datos.nombre ||  props.resultado.destinatario.datos.NOMBRE}
                        </p>
                      </Col>
                      <Col>
                        <p className="mb-0">
                          <b>Número</b>
                        </p>
                        <p>
                          {props.resultado.destinatario.datos.numero || props.resultado.destinatario.datos.NUMERO}
                        </p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
            }

            {
              props.resultado.respuestas &&
                <Card className="mx-4">
                  <CardBody className="pb-0">
                    <CardTitle tag="h5">
                      Respuestas de la encuesta
                    </CardTitle>
                    <CardSubtitle tag="h6" className="mb-2 text-muted">
                      Encuesta contestada el {moment(props.resultado.created).format('LL')}
                      <hr/>
                    </CardSubtitle>
                  </CardBody>
                  <CardBody className="pt-0">
                    <Row>
                      {
                        props.resultado.respuestas.map((respuesta) =>
                          <Col sm={4}>
                            <p className="mb-0">
                              <b>{respuesta.pregunta.pregunta}</b>
                            </p>
                            <p>
                              {respuesta.respuesta.split(',').join(', ')}
                            </p>
                          </Col>
                        )
                      }
                    </Row>
                  </CardBody>
                </Card>
            }
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <hr/>
            <div className="text-center">
              <NavLink className="jr-btn btn btn-default" to={`/reportes/encuestas/${props.encuesta.uuid}`}>
                <span>Regresar a la encuesta</span>
              </NavLink>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  </div>
);


export default ReportesEncuestasPreguntasDetalle;
