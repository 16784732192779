import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { toast } from 'react-toastify';

import EnviarCampaniasListar from './Listar';
import { obtenerCampaniasProgramadas, cargandoCampaniasProgramadas } from '../../../actions/EnviarCampanias/Listar';
import { setTituloPagina } from '../../../actions/Configuracion';


class EnviarCampaniasListarContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paginacion: {
        pagina: 0,
        tamano_pagina: 20
      },
      filtrado: []
    };
  }

  handleCambioPagina = (pagina) => {
    this.setState({
      paginacion: {
        ...this.state.paginacion,
        pagina
      }
    });
  };

  handleCambioTamanoPagina = (tamano_pagina, pagina) => {
    this.setState({
      paginacion: {
        tamano_pagina,
        pagina
      }
    });
  };

  handleCambioFiltro = (filtrado) => {
    this.setState({
      paginacion: {
        ...this.state.paginacion,
        pagina: 0
      },
      filtrado
    });
  };

  handleObtenerCampaniasProgramadas = () => {
    const { pagina, tamano_pagina } = this.state.paginacion;

    let filtros = {pagina: pagina + 1, tamano_pagina};
    if (typeof this.state.filtrado !== 'undefined') {
      this.state.filtrado.map((filtro) => filtros[filtro.id] = filtro.value);
    }

    this.props.actions.cargandoCampaniasProgramadas(true);
    this.props.actions.obtenerCampaniasProgramadas({ filtros });
  };

  handleEditarCampaniaProgramadas = (id_campania) => {
    this.props.history.push('/campanias/enviar/detalle/' + id_campania);
  };

  componentDidMount() {
    this.props.actions.setTituloPagina({titulo: 'Campañas programadas', icono: 'comments-dollar'});
    this.handleObtenerCampaniasProgramadas();
  }

  componentDidUpdate(props, state) {
    if (this.props.error) {
      if (this.props.mensaje) {
        toast.error(this.props.mensaje);
      }

      if (this.props.cargando) {
        this.props.actions.cargandoCampaniasProgramadas(false);
      }
    }

    if ((state.filtrado !== this.state.filtrado) || (state.paginacion !== this.state.paginacion)) {
      this.handleObtenerCampaniasProgramadas();
    }
  }

  render() {
    return (
      <EnviarCampaniasListar
        campanias={this.props.campanias}
        cargando={this.props.cargando}
        paginas={this.props.paginas}
        paginacion={this.state.paginacion}
        filtrado={this.state.filtrado}
        obtenerCampanias={this.handleObtenerCampaniasProgramadas}
        editarCampania={this.handleEditarCampaniaProgramadas}
        cambioPagina={this.handleCambioPagina}
        cambioTamanoPagina={this.handleCambioTamanoPagina}
        cambioFiltro={this.handleCambioFiltro}
      />
    );
  }
}


const mapStateToProps = ({ listarCampaniasProgramadas }) => {
  return { ...listarCampaniasProgramadas };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      setTituloPagina,
      obtenerCampaniasProgramadas,
      cargandoCampaniasProgramadas
    }, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EnviarCampaniasListarContainer));
