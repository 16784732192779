
// Listar
export const OBTENER_CAMPANIAS = 'obtener_campanias';
export const OBTENER_CAMPANIAS_EXITOSO = 'obtener_campanias_exitoso';
export const OBTENER_CAMPANIAS_ERROR = 'obtener_campanias_error';
export const CARGANDO_CAMPANIAS = 'cargando_campanias';

// Detalle
export const OBTENER_CAMPANIA = 'obtener_campania';
export const OBTENER_CAMPANIA_EXITOSO = 'obtener_campania_exitoso';
export const OBTENER_CAMPANIA_ERROR = 'obtener_campania_error';
export const GUARDAR_CAMPANIA = 'guardar_campania';
export const GUARDAR_CAMPANIA_EXITOSO = 'guardar_campania_exitoso';
export const GUARDAR_CAMPANIA_ERROR = 'guardar_campania_error';
export const GUARDANDO_CAMPANIA = 'guardando_campania';
export const ELIMINAR_CAMPANIA = 'eliminar_campania';
export const ELIMINAR_CAMPANIA_EXITOSO = 'eliminar_campania_exitoso';
export const ELIMINAR_CAMPANIA_ERROR = 'eliminar_campania_error';
export const OBTENER_LISTAS_DESTINATARIOS = 'obtener_campania_listas_destinatarios';
export const OBTENER_LISTAS_DESTINATARIOS_EXITOSO = 'obtener_campania_listas_destinatarios_exitoso';
export const OBTENER_LISTAS_DESTINATARIOS_ERROR = 'obtener_campania_listas_destinatarios_error';
export const CARGANDO_LISTAS_DESTINATARIOS = 'campania_cargando_listas_destinatarios';
export const OBTENER_MENSAJES = 'obtener_campania_mensajes';
export const OBTENER_MENSAJES_EXITOSO = 'obtener_campania_mensajes_exitoso';
export const OBTENER_MENSAJES_ERROR = 'obtener_campania_mensajes_error';
export const CARGANDO_MENSAJES = 'campania_cargando_mensajes';
