import React from 'react';

const CajaContenido = (props) => {
  return (
    <div className={`${props.estilos}`}>
      {props.encabezadoFuera &&
        <div className="jr-entry-header">
          <h3 className="entry-heading heading">{props.encabezado}</h3>
          {props.children.length > 1 && <div className="entry-description">{props.children[0]}</div>}
        </div>
      }

      <div className={`jr-card ${props.estiloCaja}`}>
        {!props.encabezadoFuera &&
          (props.encabezado &&
            <div className="jr-card-header">
              <h3 className="card-heading">{props.encabezado}</h3>
              {props.children.length > 1 && <div className="sub-heading">{props.children[0]}</div>}
            </div>
          )
        }
        <div className={`jr-card-body ${props.estiloContenido}`}>
          {props.children.length > 1 ? props.children[1] : props.children}
        </div>
      </div>
    </div>
  )
};


CajaContenido.defaultProps = {
  estiloCaja: '',
  estiloContenido: '',
  estilos: 'col-lg-6 col-sm-12'
};

export default CajaContenido;
