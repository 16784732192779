import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

// import Menu from "components/Header/Menu";


class LayoutBarraSuperiorContainer extends Component {

  render() {
    return (
      <div className={`app-top-nav d-none d-md-block ${this.props.nombreEstilo}`}>
        <div className="d-flex app-toolbar align-items-center">
          {/* <Menu /> */}
        </div>
      </div>
    );
  }

}

LayoutBarraSuperiorContainer.defaultProps = {
  nombreEstilo: ''
};

export default withRouter(LayoutBarraSuperiorContainer);
