import * as Yup from 'yup';

import {
  CAMPO_REQUERIDO
} from '../../../constants/Forms/Validacion';


export const campaniaSchema = Yup.object().shape({
  campania: Yup.object({
    label: Yup.string().required(CAMPO_REQUERIDO),
    value: Yup.string().required(CAMPO_REQUERIDO)
  }),
});
