import { USUARIO_SESION } from '../../constants/Configuracion';


export function httpFetch(url, method, data, config={}) {
  return new Promise((resolve, reject) => {
    const usuario = JSON.parse(localStorage.getItem(USUARIO_SESION));
    const headers = {'Accept-Language': 'es'};

    if (usuario) {
      headers['Authorization'] = 'JWT ' + usuario.token;
    }

    var formData = undefined;
    if (data) {
      if ('Content-Type' in config && config['Content-Type'] === 'application/json') {
        formData = JSON.stringify(data);
      } else {
        formData = new FormData();
        for (let nombre in data) {
          formData.append(nombre, data[nombre]);
        }
      }
    }

    fetch(
      url,
      {
        method: method || 'GET',
        headers: { ...headers, ...config },
        body: formData
      })
      .then(httpHandleResponse)
      .catch(httpHandleErrorResponse);

    function httpHandleResponse (response) {
      if (response.ok) {
        resolve(response);
      } else {
        response
          .json()
          .then(data => obtenerMensaje(data))
          .catch(() => obtenerMensaje());
      }

      function obtenerMensaje(respuesta) {
        let msg = null;

        switch (response.status) {
        case 400:
          msg = typeof respuesta !== 'undefined' && respuesta.detail ?
            respuesta.detail : 'Datos inválidos, por favor intente de nuevo.';
          break;

        case 401:
          msg = typeof respuesta !== 'undefined' && respuesta.detail ?
            respuesta.detail : 'Sesión inválida, por favor ingrese de nuevo.';
          break;

        case 403:
          msg = typeof respuesta !== 'undefined' && respuesta.detail ?
            respuesta.detail : 'No posee permiso para realizar esta acción.';
          break;

        case 404:
          msg = typeof respuesta !== 'undefined' && respuesta.detail ?
            respuesta.detail : 'Recurso no encontrado.';
          break;

        case 500:
          msg = typeof respuesta !== 'undefined' && respuesta.detail ?
            respuesta.detail : 'Error interno del servidor, por favor intente de nuevo.';
          break;

        default:
          msg = 'Ha ocurrido un error desconocido.';
          break;
        }

        if (response.status === 401) {
          localStorage.removeItem(USUARIO_SESION);
          window.location.href = '/login';
        } else {
          reject(Error(msg));
        }
        reject(Error(msg));
      }
    }

    function httpHandleErrorResponse (e) {
      let msg = 'Fue imposible comunicarse con el servidor, por favor intente de nuevo';
      reject(Error(msg));
    }
  });
}
