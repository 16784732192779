import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import uuid from 'uuid';
import { toast } from 'react-toastify';
import moment from 'moment';

import {
  enviarCampania,
  enviandoCampania,
  obtenerCampaniaProgramada,
  obtenerCampanias,
  cancelarCampaniaProgramada
} from '../../../actions/EnviarCampanias/Detalle';
import { mostrarModal, ocultarModal } from '../../../actions/Modal';
import { setTituloPagina } from '../../../actions/Configuracion';
import { HORA_ENVIO_MAXIMA, HORA_ENVIO_MINIMA } from '../../../constants/Mensajes/HorasEnvio';
import CampaniasDetalle from './Detalle';


class EnviarCampaniasDetalleContainer extends Component {

  constructor(props) {
    super(props);

    this.horas_limite = {
      minima: moment().set({hour: HORA_ENVIO_MINIMA, minute: 0}).format('hh:mm A'),
      maxima: moment().set({hour: HORA_ENVIO_MAXIMA, minute: 0}).format('hh:mm A')
    };

    this.state = {
      programar_envio: false,
      campania: {
        campania: '',
        fecha_inicio: moment().add(1, 'days').toDate(),
        fecha_inicio_str: moment().add(1, 'days').format('ll'),
        fecha_finalizacion: moment().add(2, 'days').toDate(),
        fecha_finalizacion_str: moment().add(2, 'days').format('ll'),
        hora_inicio: moment().add(1, 'days'),
        hora_inicio_str: moment().add(1, 'days').format('hh:mm A'),
        hora_finalizacion: moment().add(1, 'days'),
        hora_finalizacion_str: moment().add(1, 'days').format('hh:mm A')
      }
    };
  }

  cargarCampania = (campania) => {
    var datos = {uuid: campania.uuid, campania: campania.campania};

    if (campania.fecha_inicio && campania.fecha_finalizacion) {
      datos.fecha_inicio = moment(campania.fecha_inicio).toDate();
      datos.fecha_inicio_str = moment(campania.fecha_inicio).format('ll');
      datos.hora_inicio = moment(campania.fecha_inicio).toDate();
      datos.hora_inicio_str = moment(campania.fecha_inicio).format('hh:mm A');
      datos.fecha_finalizacion = moment(campania.fecha_finalizacion).toDate();
      datos.fecha_finalizacion_str = moment(campania.fecha_finalizacion).format('ll');
      datos.hora_finalizacion = moment(campania.fecha_finalizacion).toDate();
      datos.hora_finalizacion_str = moment(campania.fecha_finalizacion).format('hh:mm A');
    }

    this.setState({
      campania: datos
    });
  };

  toggleProgramarEnvio = () => {
    this.setState({
      programar_envio: !this.state.programar_envio
    });
  };

  getHorasNoEnvio = () => {
    let horas = [];
    for (let i=0; i<=23; i++) {
      if (i < HORA_ENVIO_MINIMA || i > HORA_ENVIO_MAXIMA) {
        horas.push(i);
      }
    }

    return horas;
  };

  validarHorasEnvio = ({ fecha_inicio, hora_inicio, fecha_finalizacion, hora_finalizacion }) => {
    let horas_validas = {
      valido: false
    };

    if (fecha_inicio && hora_inicio && fecha_finalizacion && hora_finalizacion) {
      let ahora = moment();
      let inicio = moment(fecha_inicio);
      let fin = moment(fecha_finalizacion);

      let fecha_inicio_minima = inicio.clone();
      fecha_inicio_minima.set({hour: HORA_ENVIO_MINIMA, minute: 0});
      inicio.set({hour: hora_inicio.hour(), minute: hora_inicio.minute()});

      let fecha_inicio_maxima = inicio.clone();
      fecha_inicio_maxima.set({hour: HORA_ENVIO_MAXIMA, minute: 0});

      let fecha_fin_minima = fin.clone();
      fecha_fin_minima.set({hour: HORA_ENVIO_MINIMA, minute: 0});
      fin.set({hour: hora_finalizacion.hour(), minute: hora_finalizacion.minute()});

      let fecha_fin_maxima = fin.clone();
      fecha_fin_maxima.set({hour: HORA_ENVIO_MAXIMA, minute: 0});


      if (inicio.isAfter(ahora)) {

        if (inicio.isBetween(fecha_inicio_minima, fecha_inicio_maxima) && fin.isBetween(fecha_fin_minima, fecha_fin_maxima)) {

          if (inicio.isBefore(fin)) {
            horas_validas.valido = true;
            horas_validas.fecha_inicio = inicio;
            horas_validas.fecha_finalizacion = fin;
          } else {
            toast.error('Por favor ingrese una fecha y hora de inicio anterior a la final.');
          }

        } else {
          toast.error(`Por favor ingrese una hora de envío válida.
            Entre las ${this.horas_limite.minima} y las ${this.horas_limite.maxima}.`);
        }

      } else {
        toast.error('Por favor ingrese una hora de envío futura.');
      }
    } else {
      if (fecha_inicio && fecha_finalizacion) {
        toast.error('Por favor ingrese la fecha y hora de inicio y finalización.');
      }
    }

    return horas_validas;
  };

  handleActualizacionCampania = () => {
    toast.success(this.props.mensaje);
    this.props.history.push('/campanias/enviar');
  };

  handleCancelar = (campania_uuid) => {
    this.props.actions.mostrarModal({
      uuid: uuid.v4(),
      atributos: {
        titulo: 'Cancelar campaña',
        mensaje: '¿Realmente desea cancelar esta campaña?',
        colorBotonAceptar: 'danger',
        textoBotonAceptar: 'Cancelar campaña',
        nota: 'Esta acción no se puede deshacer',
        colorNota: 'danger',
        tamano: 'sm',
        aceptar: () => this.props.actions.cancelarCampaniaProgramada(campania_uuid)
      }
    });
  };

  handleSubmit = (values) => {
    let enviar_campania = false;
    let datos = {campania: values.campania.uuid};

    if (this.state.programar_envio) {
      let horas_validas = this.validarHorasEnvio(values);
      if (horas_validas.valido) {
        datos.fecha_inicio = horas_validas.fecha_inicio.toISOString(true);
        datos.fecha_finalizacion = horas_validas.fecha_finalizacion.toISOString(true);

        enviar_campania = true;
      }
    } else {
      enviar_campania = true;
    }


    if (enviar_campania) {
      this.props.actions.enviandoCampania(true);
      this.props.actions.enviarCampania(datos);
    }
  };

  componentDidMount() {
    this.props.actions.setTituloPagina({titulo: 'Detalle de campaña programada', icono: 'comment-dollar'});

    if (this.props.match.params.uuid_campania) {
      this.props.actions.obtenerCampaniaProgramada(this.props.match.params.uuid_campania);
    } else {
      this.props.actions.obtenerCampanias();
    }
  }

  componentDidUpdate(props) {
    // Verificar si existió algún error
    if (this.props.error) {
      if (this.props.mensaje) {
        toast.error(this.props.mensaje);
      }

      if (this.props.enviando) {
        this.props.actions.enviandoCampania(false);
      }

    // Verificar si es una edición de mensajes
    } else if (this.props.editando) {
      if (this.props.eliminando) {
        this.props.actions.ocultarModal();
        this.handleActualizacionCampania();
      } else if (props.campania !== this.props.campania) {
        this.cargarCampania(this.props.campania);
      } else if (this.props.guardado_exitoso) {
        this.handleActualizacionCampania();
      }

    // Verificar si es creación de un nuevo campania
    } else if (this.props.guardado_exitoso) {
      this.handleActualizacionCampania();
    }
  }

  render() {
    return (
      <CampaniasDetalle
        handleSubmit={this.handleSubmit}
        handleCancelar={this.handleCancelar}
        valoresIniciales={this.state.campania}
        enviando={this.props.enviando}
        campanias={this.props.campanias}
        programarEnvio={this.state.programar_envio}
        toggleProgramarEnvio={this.toggleProgramarEnvio}
        horasNoEnvio={this.getHorasNoEnvio}
        horasLimite={this.horas_limite}
      />
    );
  }
}


const mapStateToProps = ({ detalleCampaniasProgramadas }) => {
  return { ...detalleCampaniasProgramadas };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      setTituloPagina,
      mostrarModal,
      ocultarModal,
      enviarCampania,
      enviandoCampania,
      obtenerCampaniaProgramada,
      obtenerCampanias,
      cancelarCampaniaProgramada
    }, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EnviarCampaniasDetalleContainer));
