import {
  OBTENER_ENCUESTA,
  OBTENER_ENCUESTA_EXITOSO,
  OBTENER_ENCUESTA_ERROR,
  GUARDAR_ENCUESTA,
  GUARDAR_ENCUESTA_EXITOSO,
  GUARDAR_ENCUESTA_ERROR,
  GUARDANDO_ENCUESTA,
  EDITANDO_ENCUESTA,
  ELIMINAR_ENCUESTA,
  ELIMINAR_ENCUESTA_EXITOSO,
  ELIMINAR_ENCUESTA_ERROR
} from '../../constants/ActionTypes/Encuestas';


export const obtenerEncuesta = (uuid) => {
  return {
    type: OBTENER_ENCUESTA,
    payload: uuid
  };
};

export const obtenerEncuestaExitoso = (encuesta) => {
  return {
    type: OBTENER_ENCUESTA_EXITOSO,
    payload: encuesta
  };
};

export const obtenerEncuestaError = (mensaje) => {
  return {
    type: OBTENER_ENCUESTA_ERROR,
    payload: mensaje
  };
};

export const guardarEncuesta = (datos) => {
  return {
    type: GUARDAR_ENCUESTA,
    payload: datos
  };
};

export const guardarEncuestaExitoso = (encuesta) => {
  return {
    type: GUARDAR_ENCUESTA_EXITOSO,
    payload: encuesta
  };
};

export const guardarEncuestaError = (mensaje) => {
  return {
    type: GUARDAR_ENCUESTA_ERROR,
    payload: mensaje
  };
};

export const guardandoEncuesta = (cargando) => {
  return {
    type: GUARDANDO_ENCUESTA,
    payload: cargando
  };
};

export const editandoEncuesta = (editando) => {
  return {
    type: EDITANDO_ENCUESTA,
    payload: editando
  };
};

export const eliminarEncuesta = (uuid) => {
  return {
    type: ELIMINAR_ENCUESTA,
    payload: uuid
  };
};

export const eliminarEncuestaExitoso = (mensaje) => {
  return {
    type: ELIMINAR_ENCUESTA_EXITOSO,
    payload: mensaje
  };
};

export const eliminarEncuestaError = (mensaje) => {
  return {
    type: ELIMINAR_ENCUESTA_ERROR,
    payload: mensaje
  };
};
