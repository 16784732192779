import React from 'react';
import { NavLink } from 'react-router-dom';

import { Alert, Col, Row, Button, Card, CardHeader, CardTitle, CardSubtitle, CardBody, CardText, Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  TIPO_RESPUESTA_NUMERICO,
  TIPO_RESPUESTA_SELECCION,
  TIPO_RESPUESTA_SELECCION_MULTIPLE
} from '../../../constants/Encuestas/TiposRespuestaPregunta';
import moment from 'moment';


const EncuestasPreguntas = (props) => (
  <Row>
    <Col md={12}>
      <Row>
        <Col md={12}>
          {
            props.encuesta.uuid &&
              <div>
                <h2 className="font-weight-bold pb-0">
                  {props.encuesta.nombre}
                  <small>
                    {props.encuesta.url_corto.vencido ? ' Venció ' : ' Vence '}
                    el <b>{moment(props.encuesta.url_corto.fecha_vencimiento).format('LL')}</b>.
                  </small>
                </h2>
                <h4>{props.encuesta.descripcion}</h4>
                <hr />
              </div>
          }
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          {
            props.preguntas.length > 0 ? 
              props.preguntas.map((pregunta, index) => (
                <Card key={pregunta.uuid} className="mb-2">
                  <CardHeader
                    className="d-flex justify-content-between align-items-center"
                    onClick={() => props.handleColapsarPregunta(pregunta)}
                  >
                    <div>
                      <CardTitle>{index + 1}. {pregunta.pregunta}</CardTitle>
                      <CardSubtitle>{pregunta.descripcion ? pregunta.descripcion : 'Sin descripción.'}</CardSubtitle>
                    </div>
                    <CardSubtitle className="float-right">
                      {
                        props.preguntasColapsadas[pregunta.uuid] ?
                          <FontAwesomeIcon icon="chevron-down" size="lg" />
                          :
                          <FontAwesomeIcon icon="chevron-up" size="lg" />
                      }
                    </CardSubtitle>
                  </CardHeader>

                  {
                    props.preguntasColapsadas[pregunta.uuid] !== undefined &&
                      <Collapse isOpen={props.preguntasColapsadas[pregunta.uuid]}>
                        <CardBody>
                          <Row>
                            <Col md={4}>
                              <CardText>
                                <b>Obligatoria:</b> {pregunta.obligatoria ? 'SI' : 'NO'}
                              </CardText>
                            </Col>
                            <Col md={4}>
                              <CardText>
                                <b>Tipo de respuesta:</b> {pregunta.tipo_respuesta}
                              </CardText>
                            </Col>
                          </Row>
                          {
                            (
                              pregunta.tipo === TIPO_RESPUESTA_NUMERICO
                              || pregunta.tipo === TIPO_RESPUESTA_SELECCION
                              || pregunta.tipo === TIPO_RESPUESTA_SELECCION_MULTIPLE
                            ) &&
                              <Row>
                                <Col md={4} />
                                <Col md={8}>
                                  {
                                    pregunta.tipo === TIPO_RESPUESTA_NUMERICO ?
                                      <CardText>
                                        <span className="mr-2">
                                          <b>Mínimo:</b> {pregunta.minimo}
                                        </span>
                                        <span>
                                          <b>Máximo:</b> {pregunta.maximo}
                                        </span>
                                      </CardText>
                                      :
                                      <CardText>
                                        <b>Opciones:</b> {pregunta.opciones.split(',').join(', ')}
                                      </CardText>
                                  }
                                </Col>
                              </Row>
                          }
                          <Row>
                            <Col md={12}>
                              <hr />
                              <Button
                                color="primary"
                                className="jr-btn"
                                type="button"
                                onClick={() => props.handleEditarPregunta(pregunta)}
                              >
                                <FontAwesomeIcon icon="edit" size="lg" className="mr-2" />
                                Editar
                              </Button>
                              <Button
                                color="danger"
                                className="jr-btn fa-pull-right"
                                type="button"
                                onClick={() => props.handleEliminarPregunta(pregunta)}
                              >
                                <FontAwesomeIcon icon="ban" size="lg" className="mr-2" />
                                Eliminar pregunta
                              </Button>
                            </Col>
                          </Row>
                        </CardBody>
                      </Collapse>
                  }                  
                </Card>
              ))
              :
              <Alert color="info">
                <b>Información!</b> No se han agregado preguntas.
              </Alert>
          }
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <hr/>
          <div className="text-center">
            <Button
              color="primary"
              className="jr-btn fa-pull-left"
              type="button"
              onClick={() => props.handleEditarPregunta()}
            >
              <FontAwesomeIcon icon="plus-circle" size="lg" className="mr-2"/>
              Nueva pregunta
            </Button>

            <NavLink className="jr-btn btn btn-default" to={`/encuestas/detalle/${props.encuesta.uuid}/`}>
              <span>Regresar a la encuesta</span>
            </NavLink>
            
            <NavLink className="jr-btn btn btn-info fa-pull-right" to={`/encuestas/detalle/${props.encuesta.uuid}/vista_previa`}
            >
              <FontAwesomeIcon icon="eye" size="lg" className="mr-2" />
              Vista previa
            </NavLink>
          </div>
        </Col>
      </Row>
    </Col>
  </Row>
);


export default EncuestasPreguntas;
