import React from 'react';
// import PropTypes from 'prop-types';

import { Field } from 'formik';
import NumberFormat from 'react-number-format';
import {
  FormGroup,
  Label,
  Input,
  FormFeedback
} from 'reactstrap';


const UIFormsCampoFormMoneda = (props) => (
  <FormGroup>
    {
      props.label ?
        <Label for={props.id} className={props.labelClassName}>{props.label}</Label>
        :
        null
    }

    {
      props.group ?
        null
        :
        <span>
          <Field
            name={props.name}
            render={({ field }) => {
              return (
                <NumberFormat
                  decimalScale={2}
                  prefix="$ "
                  type={props.type}
                  id={props.id}
                  className={`form-control-lg ${props.className}`}
                  placeholder={props.placeholder}
                  autoComplete={props.autoComplete}
                  readOnly={props.readOnly}
                  onValueChange={props.onValueChange}
                  invalid={props.error && props.touched}
                  customInput={Input}
                  thousandSeparator=","
                  {...field}
                />
              )
            }}
          />

          <FormFeedback tooltip>
            {props.error}
          </FormFeedback>
        </span>
    }
  </FormGroup>
);


UIFormsCampoFormMoneda.defaultProps = {
  className: '',
  labelClassName: ''
};

export default UIFormsCampoFormMoneda;
