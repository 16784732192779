import {
  OBTENER_REPORTE_CAMPANIAS,
  OBTENER_REPORTE_CAMPANIAS_EXITOSO,
  OBTENER_REPORTE_CAMPANIAS_ERROR,
  CARGANDO_REPORTE_CAMPANIAS,
  EXPORTAR_REPORTE_CAMPANIAS,
  EXPORTAR_REPORTE_CAMPANIAS_EXITOSO,
  EXPORTAR_REPORTE_CAMPANIAS_ERROR
} from '../../constants/ActionTypes/Reportes';


export const obtenerReporte = (params) => {
  return {
    type: OBTENER_REPORTE_CAMPANIAS,
    payload: params
  };
};

export const obtenerReporteExitoso = (reporte) => {
  return {
    type: OBTENER_REPORTE_CAMPANIAS_EXITOSO,
    payload: reporte
  };
};

export const obtenerReporteError = (mensaje) => {
  return {
    type: OBTENER_REPORTE_CAMPANIAS_ERROR,
    payload: mensaje
  };
};

export const cargandoReporte = (cargando) => {
  return {
    type: CARGANDO_REPORTE_CAMPANIAS,
    payload: cargando
  };
};

export const exportarReporte = (params) => {
  return {
    type: EXPORTAR_REPORTE_CAMPANIAS,
    payload: params
  };
};

export const exportarReporteExitoso = (reporte) => {
  return {
    type: EXPORTAR_REPORTE_CAMPANIAS_EXITOSO,
    payload: reporte
  };
};

export const exportarReporteError = (mensaje) => {
  return {
    type: EXPORTAR_REPORTE_CAMPANIAS_ERROR,
    payload: mensaje
  };
};
