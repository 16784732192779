/*eslint no-template-curly-in-string: "off"*/

export const CAMPO_REQUERIDO = 'Este campo es obligatorio.';
export const CAMPO_EMAIL = 'Este campo debe ser un correo electrónico válido.';
export const CAMPO_NUMERO = 'Este campo debe ser numérico.';
export const CAMPO_NUMERO_POSITIVO = 'Este campo debe ser un número positivo.';
export const CAMPO_NUMERO_ENTERO = 'Este campo debe ser de tipo entero.';
export const CAMPO_NUMERO_MINIMO = 'El valor mínimo de este campo es ${min}.';
export const CAMPO_LONGITUD_MAXIMA = 'Este campo no debe tener más de ${max} caracteres.';
export const CAMPO_ALFANUMERICO = 'Este campo debe contener números y letras.';
export const CAMPO_COINCIDE = 'Los campos no coinciden.';
export const CAMPO_URL = 'Este campo debe ser una URL válida. Ejemplo: https://go-sms.co';
