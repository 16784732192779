import {
  OBTENER_REPORTE_ENCUESTAS,
  OBTENER_REPORTE_ENCUESTAS_EXITOSO,
  OBTENER_REPORTE_ENCUESTAS_ERROR,
  CARGANDO_REPORTE_ENCUESTAS,
  OBTENER_REPORTE_ENCUESTAS_RESULTADO,
  OBTENER_REPORTE_ENCUESTAS_RESULTADO_EXITOSO,
  OBTENER_REPORTE_ENCUESTAS_RESULTADO_ERROR,
  CARGANDO_REPORTE_ENCUESTAS_RESULTADO,
  EXPORTAR_REPORTE_ENCUESTAS,
  EXPORTAR_REPORTE_ENCUESTAS_EXITOSO,
  EXPORTAR_REPORTE_ENCUESTAS_ERROR
} from '../../constants/ActionTypes/Reportes';


export const obtenerReporte = (params) => {
  return {
    type: OBTENER_REPORTE_ENCUESTAS,
    payload: params
  };
};

export const obtenerReporteExitoso = (reporte) => {
  return {
    type: OBTENER_REPORTE_ENCUESTAS_EXITOSO,
    payload: reporte
  };
};

export const obtenerReporteError = (mensaje) => {
  return {
    type: OBTENER_REPORTE_ENCUESTAS_ERROR,
    payload: mensaje
  };
};

export const cargandoReporte = (cargando) => {
  return {
    type: CARGANDO_REPORTE_ENCUESTAS,
    payload: cargando
  };
};

export const obtenerReporteResultado = (params) => {
  return {
    type: OBTENER_REPORTE_ENCUESTAS_RESULTADO,
    payload: params
  };
};

export const obtenerReporteResultadoExitoso = (reporte) => {
  return {
    type: OBTENER_REPORTE_ENCUESTAS_RESULTADO_EXITOSO,
    payload: reporte
  };
};

export const obtenerReporteResultadoError = (mensaje) => {
  return {
    type: OBTENER_REPORTE_ENCUESTAS_RESULTADO_ERROR,
    payload: mensaje
  };
};

export const cargandoReporteResultado = (cargando) => {
  return {
    type: CARGANDO_REPORTE_ENCUESTAS_RESULTADO,
    payload: cargando
  };
};

export const exportarReporte = (params) => {
  return {
    type: EXPORTAR_REPORTE_ENCUESTAS,
    payload: params
  };
};

export const exportarReporteExitoso = (reporte) => {
  return {
    type: EXPORTAR_REPORTE_ENCUESTAS_EXITOSO,
    payload: reporte
  };
};

export const exportarReporteError = (mensaje) => {
  return {
    type: EXPORTAR_REPORTE_ENCUESTAS_ERROR,
    payload: mensaje
  };
};
