import { put, call, all, fork, takeEvery } from 'redux-saga/effects';

import { sprintf } from 'sprintf-js';

import {
  OBTENER_CAMPANIA_PROGRAMADA,
  ENVIAR_CAMPANIA,
  CANCELAR_CAMPANIA_PROGRAMADA,
  OBTENER_CAMPANIAS
} from '../../constants/ActionTypes/EnviarCampanias';
import { CAMPANIAS_ENVIADAS_URL, CAMPANIA_ENVIADA_URL } from '../../constants/Urls/EnviarCampanias';
import { CAMPANIAS_URL } from '../../constants/Urls/Campanias';
import {
  obtenerCampaniaProgramadaExitoso,
  obtenerCampaniaProgramadaError,
  enviarCampaniaExitoso,
  enviarCampaniaError,
  cancelarCampaniaProgramadaExitoso,
  cancelarCampaniaProgramadaError,
  obtenerCampaniasExitoso,
  obtenerCampaniasError
} from '../../actions/EnviarCampanias/Detalle';
import { httpFetch } from '../../utils/Http/Fetch';
import { ESTADO_CAMPANIA_CANCELADO } from '../../constants/EnviarCampanias/Estados';
import {setUnidades} from "../../actions/Configuracion";


const obtenerCampaniaProgramadaApi = async (uuid) => {
  const url = sprintf(CAMPANIA_ENVIADA_URL, { uuid });

  return httpFetch(url, 'GET')
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};

const enviarCampaniaProgramadaApi = async (datos) => {
  const verbo_http = datos.uuid ? 'PUT' : 'POST';
  let url = CAMPANIAS_ENVIADAS_URL;

  if (datos.uuid) {
    url = sprintf(CAMPANIA_ENVIADA_URL, {
      uuid: datos.uuid
    });
  }

  return httpFetch(url, verbo_http, datos)
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};

const cancelarCampaniaProgramadaApi = async (uuid) => {
  const url = sprintf(CAMPANIA_ENVIADA_URL, {uuid});

  return httpFetch(url, 'PUT', {'estado': ESTADO_CAMPANIA_CANCELADO})
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};

const obtenerCampaniasApi = async () => {
  return httpFetch(`${CAMPANIAS_URL}`, 'GET')
    .then(response => response.json())
    .then(json => json)
    .catch((error) => {
      throw error;
    });
};


function* obtenerCampaniaProgramada(action) {
  try {
    yield all([
      call(obtenerCampanias)
    ]);

    const campania = yield call(obtenerCampaniaProgramadaApi, action.payload);

    if (typeof campania.saldo !== 'undefined') {
      yield put(setUnidades(campania.saldo));
    }

    yield put(obtenerCampaniaProgramadaExitoso(campania));
  } catch (error) {
    yield put(obtenerCampaniaProgramadaError(error.message));
  }
}

function* enviarCampaniaProgramada(action) {
  try {
    const campania = yield call(enviarCampaniaProgramadaApi, action.payload);
    const accion = action.payload.uuid ? 'actualizada' : 'creada';
    const mensaje = `Campaña ${accion} correctamente.`;

    if (typeof campania.saldo !== 'undefined') {
      yield put(setUnidades(campania.saldo));
    }

    yield put(enviarCampaniaExitoso({campania, mensaje}));
  } catch (error) {
    yield put(enviarCampaniaError(error.message));
  }
}

function* cancelarCampaniaProgramada(action) {
  try {
    yield call(cancelarCampaniaProgramadaApi, action.payload);
    const mensaje = 'Campaña cancelada correctamente.';

    yield put(cancelarCampaniaProgramadaExitoso({ mensaje }));
  } catch (error) {
    yield put(cancelarCampaniaProgramadaError(error.message));
  }
}

function* obtenerCampanias() {
  try {
    const campanias = yield call(obtenerCampaniasApi);
    let campanias_opciones = campanias.map((campania) => {
      return {value: campania.uuid, label: `${campania.nombre}`, ...campania};
    });

    if (typeof campanias.saldo !== 'undefined') {
      yield put(setUnidades(campanias.saldo));
    }

    yield put(obtenerCampaniasExitoso(campanias_opciones));
  } catch (error) {
    yield put(obtenerCampaniasError(error.message));
  }
}


export function* obtenerCampaniaProgramadaWatcher() {
  yield takeEvery (OBTENER_CAMPANIA_PROGRAMADA, obtenerCampaniaProgramada);
}

export function* enviarCampaniaProgramadaWatcher() {
  yield takeEvery (ENVIAR_CAMPANIA, enviarCampaniaProgramada);
}

export function* cancelarCampaniaProgramadaWatcher() {
  yield takeEvery (CANCELAR_CAMPANIA_PROGRAMADA, cancelarCampaniaProgramada);
}

export function* obtenerCampaniasWatcher() {
  yield takeEvery (OBTENER_CAMPANIAS, obtenerCampanias);
}


export default function* rootSaga() {
  yield all ([
    fork (obtenerCampaniaProgramadaWatcher),
    fork (enviarCampaniaProgramadaWatcher),
    fork (cancelarCampaniaProgramadaWatcher),
    fork (obtenerCampaniasWatcher)
  ]);
}
