import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';


const BarraScroll = (props) => (
  <Scrollbars
    {...props}
    autoHide
    renderTrackHorizontal={
      props => (
        <div
          {...props}
          style={{ display: 'none' }}
          className="track-horizontal"
        />
      )
    }
  />
);

export default BarraScroll;
