import {
  OBTENER_RESPUESTAS_CLAVE,
  OBTENER_RESPUESTAS_CLAVE_EXITOSO,
  OBTENER_RESPUESTAS_CLAVE_ERROR,
  CARGANDO_RESPUESTAS_CLAVE,
  RESPUESTAS_OBTENER_CLAVES,
  RESPUESTAS_OBTENER_CLAVES_EXITOSO,
  RESPUESTAS_OBTENER_CLAVES_ERROR,
  EXPORTAR_RESPUESTAS_CLAVE,
  EXPORTAR_RESPUESTAS_CLAVE_EXITOSO,
  EXPORTAR_RESPUESTAS_CLAVE_ERROR
} from '../../../constants/ActionTypes/Claves';


export const obtenerRespuestasClave = (params) => {
  return {
    type: OBTENER_RESPUESTAS_CLAVE,
    payload: params
  };
};

export const obtenerRespuestasClaveExitoso = (respuestas) => {
  return {
    type: OBTENER_RESPUESTAS_CLAVE_EXITOSO,
    payload: respuestas
  };
};

export const obtenerRespuestasClaveError = (mensaje) => {
  return {
    type: OBTENER_RESPUESTAS_CLAVE_ERROR,
    payload: mensaje
  };
};

export const cargandoRespuestasClave = (cargando) => {
  return {
    type: CARGANDO_RESPUESTAS_CLAVE,
    payload: cargando
  };
};

export const obtenerClaves = () => {
  return {
    type: RESPUESTAS_OBTENER_CLAVES
  };
};

export const obtenerClavesExitoso = (claves) => {
  return {
    type: RESPUESTAS_OBTENER_CLAVES_EXITOSO,
    payload: claves
  };
};

export const obtenerClavesError = (mensaje) => {
  return {
    type: RESPUESTAS_OBTENER_CLAVES_ERROR,
    payload: mensaje
  };
};

export const exportarReporte = (params) => {
  return {
    type: EXPORTAR_RESPUESTAS_CLAVE,
    payload: params
  };
};

export const exportarReporteExitoso = (reporte) => {
  return {
    type: EXPORTAR_RESPUESTAS_CLAVE_EXITOSO,
    payload: reporte
  };
};

export const exportarReporteError = (mensaje) => {
  return {
    type: EXPORTAR_RESPUESTAS_CLAVE_ERROR,
    payload: mensaje
  };
};
