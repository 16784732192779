export const ESTADO_USUARIO_ACTIVO = true;
export const ESTADO_USUARIO_INACTIVO = false;

export const ESTADO_USUARIO_ACTIVO_LABEL = 'Activo';
export const ESTADO_USUARIO_INACTIVO_LABEL = 'Inactivo';

export const ESTADOS_USUARIO = [
  {label: ESTADO_USUARIO_ACTIVO_LABEL, value: ESTADO_USUARIO_ACTIVO},
  {label: ESTADO_USUARIO_INACTIVO_LABEL, value: ESTADO_USUARIO_INACTIVO}
];
