import * as Yup from 'yup';

import {
  CAMPO_REQUERIDO,
  CAMPO_LONGITUD_MAXIMA
} from '../../../constants/Forms/Validacion';


export const mensajeSchema = Yup.object().shape({
  tipo: Yup.object({
    label: Yup.string().required(CAMPO_REQUERIDO),
    value: Yup.string().required(CAMPO_REQUERIDO)
  }),

  nombre: Yup.string()
    .required(CAMPO_REQUERIDO),

  lista: Yup.object({
    label: Yup.string(),
    value: Yup.string()
  })
    .nullable(),

  mensaje: Yup.string()
    .max(160, CAMPO_LONGITUD_MAXIMA)
    .required(CAMPO_REQUERIDO),
});
