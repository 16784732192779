import {
  OBTENER_CLAVES,
  OBTENER_CLAVES_EXITOSO,
  OBTENER_CLAVES_ERROR,
  CARGANDO_CLAVES
} from '../../../constants/ActionTypes/Claves';


export const obtenerClaves = (params) => {
  return {
    type: OBTENER_CLAVES,
    payload: params
  };
};

export const obtenerClavesExitoso = (campanias) => {
  return {
    type: OBTENER_CLAVES_EXITOSO,
    payload: campanias
  };
};

export const obtenerClavesError = (mensaje) => {
  return {
    type: OBTENER_CLAVES_ERROR,
    payload: mensaje
  };
};

export const cargandoClaves = (cargando) => {
  return {
    type: CARGANDO_CLAVES,
    payload: cargando
  };
};
