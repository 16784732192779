import React from 'react';

import {
  Col, Row, Button, Collapse, Alert, Card, CardBody, CardText, CardHeader
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Form } from 'formik';
import { WithOutContext as ReactTags } from 'react-tag-input';
import moment from 'moment';

import { CODIGOS_TECLADO } from '../../../constants/Mensajes/CodigosTeclado';
import UIFormsCampoFormSelect from '../../UI/Forms/CampoForm/Select';
import UIFormsCampoFormMensaje from '../../UI/Forms/CampoForm/Mensaje/Mensaje';
import UIFormsCampoFormFecha from '../../UI/Forms/CampoForm/Fecha';
import UIFormsCampoFormHora from '../../UI/Forms/CampoForm/Hora';
import { enviarMensajeSchema } from './Schema';


const MensajesEnviar = (props) => (
  <Formik
    enableReinitialize
    initialValues={props.valoresIniciales}
    onSubmit={props.handleSubmit}
    validationSchema={enviarMensajeSchema}
    validateOnChange
  >
    {formikProps => (
      <Form autoComplete="off">
        <Row>
          <Col sm={12}>
            <Row form>
              <Col md={12}>
                <label htmlFor="destinatarios">Destinatarios</label>
                <ReactTags
                  classNames={{
                    tags: 'h2',
                    tag: 'badge badge-pill badge-light',
                    tagInput: 'form-group',
                    tagInputField: 'form-control form-control-lg'
                  }}
                  placeholder="Agregue los números de destinatarios separados por coma"
                  name="destinatarios"
                  allowDragDrop={false}
                  inline={false}
                  tags={props.destinatarios}
                  delimiters={CODIGOS_TECLADO}
                  handleDelete={props.eliminarDestinatario}
                  handleAddition={props.agregarDestinatario}
                  handleInputChange={() => {
                    formikProps.setFieldValue('lista', '');
                  }}
                  handleInputBlur={(destinatario) => props.agregarDestinatario({id: destinatario, text: destinatario})}
                />
              </Col>
            </Row>

            <Row form>
              <Col md={12}>
                <UIFormsCampoFormSelect
                  id="lista"
                  name="lista"
                  label="Lista de destinatarios"
                  formikProps={formikProps}
                  placeholder="Seleccione una opción"
                  className="padding-0"
                  options={props.listasDestinatarios}
                  onChange={(e) => {
                    props.setColumnasMensaje(e.columnas_destinatarios);
                    props.eliminarDestinatario();
                    formikProps.setFieldValue('lista', e);
                  }}
                />
              </Col>
            </Row>
            <Row form>
              <Col md={12}>
                <UIFormsCampoFormSelect
                  id="mensaje"
                  name="mensaje"
                  label="Mensaje creado"
                  formikProps={formikProps}
                  placeholder="Seleccione una opción"
                  className="padding-0"
                  options={props.mensajes}
                  onChange={(e) => {
                    formikProps.setFieldValue('texto_mensaje', e.mensaje ? e.mensaje : '');
                    formikProps.setFieldValue('mensaje', e);
                  }}
                />
              </Col>
            </Row>
            <Row form>
              <Col md={12}>
                <UIFormsCampoFormMensaje
                  id="texto_mensaje"
                  name="texto_mensaje"
                  label="Escribir mensaje"
                  labelClassName="requerido"
                  formikProps={formikProps}
                  placeholder="Dígite el mensaje que sea enviar."
                  longitudMaxima={160}
                  columnas={false}
                  readOnly={formikProps.values.mensaje && formikProps.values.mensaje.uuid}
                />
              </Col>
            </Row>
          </Col>
          <Col sm={12}>
            <Button color="light" className="jr-btn" type="button" onClick={props.toggleProgramarEnvio}>
              {
                props.programarEnvio.activo ?
                  <FontAwesomeIcon icon={'check-circle'} size="lg" className="mr-2" />
                  :
                  <FontAwesomeIcon icon={['far', 'circle']} size="lg" className="mr-2" />
              }
              Programar envío del mensaje
            </Button>
            <Collapse isOpen={props.programarEnvio}>
              <Row>
                <Col md={12}>
                  <Row>
                    <Col md={6}>
                      <Card className="mb-1">
                        <CardHeader className="py-3">Fecha y hora de inicio</CardHeader>
                        <CardBody className="py-2">
                          <CardText className="text-justify mb-0">
                            Use el calendario para seleccionar la <b>fecha de inicio de envío</b> de la campaña.
                          </CardText>

                          <UIFormsCampoFormFecha
                            id="fecha_inicio_str"
                            type="text"
                            name="fecha_inicio_str"
                            label="Fecha de inicio"
                            labelClassName="requerido"
                            className="col-12"
                            placeholder=""
                            autoComplete="off"
                            readOnly={true}
                            numberOfMonths={1}
                            disabledDays={[{
                              before: moment().toDate()
                            }]}
                            selectedDays={formikProps.values.fecha_inicio}
                            onDayClick={(dia) => {
                              formikProps.setFieldValue('fecha_inicio', dia);
                              formikProps.setFieldValue('fecha_inicio_str', moment(dia).format('ll'));
                            }}
                          />

                          <CardText className="text-justify">
                            Seleccione la hora de inicio de envío de la campaña, entre
                            las <b>{props.horasLimite.minima}</b> y las <b>{props.horasLimite.maxima}</b>.
                          </CardText>

                          <UIFormsCampoFormHora
                            id="hora"
                            type="text"
                            name="hora_inicio"
                            label="Hora de envío"
                            allowEmpty={false}
                            minuteStep={5}
                            value={formikProps.values.hora_inicio}
                            disabledHours={props.horasNoEnvio}
                            onChange={(hora) => {
                              formikProps.setFieldValue('hora_inicio', hora);
                              formikProps.setFieldValue('hora_inicio_str', moment(hora).format('hh:mm A'));
                            }}
                          />

                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={6}>
                      <Card className="mb-1">
                        <CardHeader className="py-3">Fecha y hora de finalización</CardHeader>
                        <CardBody className="py-2">
                          <CardText className="text-justify mb-0">
                            Use el calendario para seleccionar la <b>fecha de finalización</b> de la campaña.
                          </CardText>

                          <UIFormsCampoFormFecha
                            id="fecha_finalizacion_str"
                            type="text"
                            name="fecha_finalizacion_str"
                            label="Fecha de finalización"
                            labelClassName="requerido"
                            className="col-12"
                            placeholder=""
                            autoComplete="off"
                            readOnly={true}
                            numberOfMonths={1}
                            disabledDays={[{
                              before: moment().toDate()
                            }]}
                            selectedDays={formikProps.values.fecha_finalizacion}
                            onDayClick={(dia) => {
                              formikProps.setFieldValue('fecha_finalizacion', dia);
                              formikProps.setFieldValue('fecha_finalizacion_str', moment(dia).format('ll'));
                            }}
                          />

                          <CardText className="text-justify">
                            Seleccione la hora de finalización de la campaña, entre
                            las <b>{props.horasLimite.minima}</b> y las <b>{props.horasLimite.maxima}</b>.
                          </CardText>

                          <UIFormsCampoFormHora
                            id="hora"
                            type="text"
                            name="hora_finalizacion"
                            label="Hora de finalización"
                            allowEmpty={false}
                            minuteStep={5}
                            value={formikProps.values.hora_finalizacion}
                            disabledHours={props.horasNoEnvio}
                            onChange={(hora) => {
                              formikProps.setFieldValue('hora_finalizacion', hora);
                              formikProps.setFieldValue('hora_finalizacion_str', moment(hora).format('hh:mm A'));
                            }}
                          />

                        </CardBody>
                      </Card>
                    </Col>

                    <Col md={12}>
                      {
                        (formikProps.values.fecha_inicio && formikProps.values.hora_inicio &&
                          formikProps.values.fecha_finalizacion && formikProps.values.hora_finalizacion) ?
                          <Alert color={'success'}>
                            <FontAwesomeIcon icon={'check-circle'} size="lg" className="mr-2" />
                            La campaña será enviada desde el <b>{formikProps.values.fecha_inicio_str}</b> a
                            las <b>{formikProps.values.hora_inicio_str}</b> hasta máximo
                            el <b>{formikProps.values.fecha_finalizacion_str}</b> a las <b>{formikProps.values.hora_finalizacion_str}</b>
                          </Alert>
                          :
                          null
                      }
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Collapse>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <hr/>
            <div className="text-center">
              <Button color="success" className="jr-btn" type="submit" disabled={props.enviando}>
                {
                  props.enviando ?
                    <FontAwesomeIcon icon="circle-notch" size="lg" spin className="mr-2" />
                    :
                    <FontAwesomeIcon icon="check" size="lg" className="mr-2" />
                }

                {
                  props.programarEnvio.activo ?
                    'Programar mensaje'
                    :
                    'Enviar mensaje'
                }
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    )}
  </Formik>
);


export default MensajesEnviar;
