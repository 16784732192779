import React from 'react';
import { NavLink } from 'react-router-dom';

import { Button, Row, Col, Alert, Card, CardBody, CardTitle } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ResponsiveContainer, PieChart, Pie, Tooltip, Cell } from 'recharts';
import moment from 'moment';

import UIDataTable from '../../../UI/DataTable/DataTable';


const ReportesEncuestasDetalle = (props) => (
  <div className="app-module">
    <div className="module-box">
      <div className="module-box-header">
        <div className="module-box-header-inner">
          <div className="module-box-header-right">
            <Button color="info" className="jr-btn" type="button" disabled={props.guardando} onClick={props.obtenerReporte}>
              {
                props.guardando ?
                  <FontAwesomeIcon icon="circle-notch" size="lg" spin className="mr-2" />
                  :
                  <FontAwesomeIcon icon="sync" size="lg" className="mr-2" />
              }
              Actualizar reporte
            </Button>
            <Button
              color="success"
              className="jr-btn fa-pull-right"
              onClick={props.exportarReporte}
              disabled={props.cargando || !props.datos.length}>
              <FontAwesomeIcon icon="file-excel" size="lg" className="mr-2" />
              <span>Exportar a Excel</span>
            </Button>
            <NavLink className="jr-btn btn btn-default" to='/reportes/encuestas'>
              <span>Regresar al listado</span>
            </NavLink>
          </div>
        </div>
      </div>
      <div className="module-box-content pt-2">
        <Row>
          <Col md={12}>
            {
              props.encuesta.uuid &&
                <div>
                  <h2 className="font-weight-bold pb-0 pl-4">
                    {props.encuesta.nombre}
                  </h2>
                  <h4 className="pl-4">{props.encuesta.descripcion}</h4>
                  <hr />
                </div>
            }
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h2 className="text-center mb-2">Resultados de la encuesta</h2>
            {
              props.cargando &&
                <Alert color="light" className="text-center">
                  <FontAwesomeIcon icon="sync" spin size="lg" className="mr-2" />
                  Cargando reporte...
                </Alert>
            }

            {
              !props.cargando && !Object.keys(props.datos).length &&
                <Alert color="info" className="text-center">
                  <FontAwesomeIcon icon="info-circle" size="lg" className="mr-2" />
                  No se ha econtrado ningún resultado tabulable
                </Alert>
            }

            <Row>
              {
                props.datos.map(pregunta => (
                  <Col md={3} key={pregunta.uuid}>
                    <Card>
                      <CardBody className="pt-1 pb-0">
                        <CardTitle tag="h5" className="mb-0 text-center">{pregunta.pregunta}</CardTitle>
                      </CardBody>
                      <CardBody className="pt-0 pb-1">
                        <ResponsiveContainer width="99%" height={230}>
                          <PieChart>
                            <Pie
                              data={pregunta.conteo}
                              dataKey="value"
                              nameKey="name"
                              fill="#2196F3"
                              innerRadius={10}
                              minAngle={10}
                              cy={120}
                              cx={100}
                              labelLine={true}
                              label={true}>
                              {
                                pregunta.conteo.map((entry, index) => <Cell key={index} fill={entry.color} />)
                              }
                            </Pie>
                            <Tooltip/>
                          </PieChart>
                        </ResponsiveContainer>
                      </CardBody>
                    </Card>
                  </Col>
                ))
              }
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <UIDataTable
              data={props.resultados}
              filterable={true}
              columns={[
                {
                  Header: 'Fecha Respuesta',
                  filterable: false,
                  accessor: 'created',
                  Cell: (row) => row.original.created ?
                    `${moment(row.original.created).format('L')} ${moment(row.original.created).format('hh:mm A')}`
                    :
                    null
                },
                {
                  Header: 'Nombre Destinatario',
                  accessor: 'destinatario.datos.nombre',
                  Cell: (row) => row.original.destinatario.datos.nombre || row.original.destinatario.datos.NOMBRE
                },
                {
                  Header: 'Número Destinatario',
                  accessor: 'destinatario.datos.numero',
                  Cell: (row) => row.original.destinatario.datos.numero || row.original.destinatario.datos.NUMERO
                }
              ]}
              getTrProps={(state, rowInfo) => ({
                onClick: () => {
                  props.reporteEncuestaPreguntas(rowInfo.original.encuesta, rowInfo.original.uuid);
                }
              })}
              noDataText="No se ha encontrando ningún resultado"
              rowsText="resultados"
              loading={props.cargando}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <hr/>
            <div className="text-center">
              <Button color="info" className="jr-btn" type="button" disabled={props.guardando} onClick={props.obtenerReporte}>
                {
                  props.guardando ?
                    <FontAwesomeIcon icon="circle-notch" size="lg" spin className="mr-2" />
                    :
                    <FontAwesomeIcon icon="sync" size="lg" className="mr-2" />
                }
                Actualizar reporte
              </Button>

              <NavLink className="jr-btn btn btn-default" to='/reportes/encuestas'>
                <span>Regresar al listado</span>
              </NavLink>

              <Button
                color="success"
                className="jr-btn fa-pull-right"
                onClick={props.exportarReporte}
                disabled={props.cargando || !props.datos.length}>
                <FontAwesomeIcon icon="file-excel" size="lg" className="mr-2" />
                <span>Exportar a Excel</span>
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  </div>
);


export default ReportesEncuestasDetalle;
